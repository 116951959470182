export const Param = {
  email: "info@ganachesolution.com",
  companyName: "SCS PASTRY&CHOCADVICE",
  account: "6528 5552 3211",
  vat: "0660.751.132.",
  iban: "BE30 6528 5552 3211",
  adress: {
    street: "Stijn Streuvelsaan, 19",
    city: "1933 Sterrrebeek",
    country: "Belgique",
  },
};
