import { OverlayTrigger, Popover, Table } from "react-bootstrap";
import { IoMdEye } from "react-icons/io";
import GanacheFormat from "src/utils/ganacheFormat";
import I18n from "../../Config/I18n";
import { getLanguage } from "../../helpers/auth";
const l = getLanguage();

export default function LookupIngredient({ ingredient, contents, fitfors, placement = "right" }) {
  const getContent = ingredient => {
    var res = "";
    const content = ingredient.flags.contents;
    contents.forEach(c => {
      const cont = content.find(id => id === c._id);
      if (cont) {
        res += c.name + " ";
      }
    });
    return res;
  };

  const getFitFor = ingredient => {
    var res = "";
    const fitfor = ingredient.flags.fit;
    fitfors.forEach(f => {
      const fit = fitfor.find(id => id === f._id);
      if (fit) {
        res += f.name + " ";
      }
    });
    return res;
  };

  return (
    <OverlayTrigger
      placement={placement}
      overlay={
        <Popover id="popover-basic">
          <Popover.Title as="h3">{I18n.t("ingredient.content", { locale: l })}</Popover.Title>
          <Popover.Content>
            <h6>{I18n.t("ingredient.lookup.information", { locale: l })}</h6>
            <div>
              <small>{I18n.t("ingredient.nameIngredient", { locale: l })} :</small> {ingredient?.name || " "}{" "}
            </div>
            <div>
              <small>{I18n.t("ingredient.commercialName", { locale: l })} :</small> {ingredient?.commercialName || " "}{" "}
            </div>
            <div>
              <small>{I18n.t("ingredient.content", { locale: l })} :</small> {getContent(ingredient)}{" "}
            </div>
            <div>
              <small>{I18n.t("ingredient.fitfor", { locale: l })} :</small> {getFitFor(ingredient)}
            </div>
            <br />
            <h6>{I18n.t("ingredient.lookup.composition", { locale: l })}</h6>
            <small>
              <div>
                {I18n.t("ingredient.sugar", { locale: l })} : {GanacheFormat.percentage(ingredient?.composition?.sugar)} %
              </div>
              <div>
                {I18n.t("ingredient.cacaoSolid", { locale: l })} : {GanacheFormat.percentage(ingredient?.composition?.dry?.cocoa_solid)} %
              </div>
              <div>
                {I18n.t("ingredient.amp", { locale: l })} : {GanacheFormat.percentage(ingredient?.composition?.dry?.amp)} %
              </div>
              <div>
                {I18n.t("ingredient.lactose", { locale: l })} : {GanacheFormat.percentage(ingredient?.composition?.dry?.lactose)} %
              </div>
              <div>
                {I18n.t("ingredient.othersSolid", { locale: l })} : {GanacheFormat.percentage(ingredient?.composition?.dry?.other)} %
              </div>
              <div>
                {I18n.t("ingredient.oil", { locale: l })} : {GanacheFormat.percentage(ingredient?.composition?.fats?.oil)} %
              </div>
              <div>
                {I18n.t("ingredient.butter", { locale: l })} : {GanacheFormat.percentage(ingredient?.composition?.fats?.butter)} %
              </div>
              <div>
                {I18n.t("ingredient.cocoaButter", { locale: l })} : {GanacheFormat.percentage(ingredient?.composition?.fats?.cocoa_butter)}{" "}
                %
              </div>
              <div>
                {I18n.t("ingredient.water", { locale: l })} : {GanacheFormat.percentage(ingredient?.composition?.liquids?.water)} %
              </div>
              <div>
                {I18n.t("ingredient.alcohol", { locale: l })} : {GanacheFormat.percentage(ingredient?.composition?.liquids?.alcohol)} %
              </div>
              <div>
                {I18n.t("ingredient.pod", { locale: l })} : {GanacheFormat.round(ingredient?.composition?.other?.pod)}
              </div>
              <div>
                {I18n.t("ingredient.pac", { locale: l })} : {GanacheFormat.round(ingredient?.composition?.other?.pac)}
              </div>
              <div>
                {I18n.t("ingredient.kj", { locale: l })} : {GanacheFormat.round(ingredient?.composition?.other?.kj)}
              </div>
              <div>
                {I18n.t("ingredient.foodCost", { locale: l })} : {GanacheFormat.price(ingredient?.note?.foodCost)}/kg
              </div>
              {ingredient?.note?.note ? (
                <Table>
                  <thead>
                    <tr>
                      <th>{I18n.t("ingredient.note", { locale: l })}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{ingredient?.note?.note || " "}</td>
                    </tr>
                  </tbody>
                </Table>
              ) : null}
            </small>
          </Popover.Content>
        </Popover>
      }
    >
      <small className="text-muted">
        <IoMdEye className="ml-3" />
      </small>
    </OverlayTrigger>
  );
}
