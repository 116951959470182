import { createContext, FC, useContext, useEffect, useState } from "react";
import {
  GiSpeedometer,
  GiSecretBook,
  GiKnifeFork,
  GiSettingsKnobs,
  GiHamburgerMenu,
  GiExitDoor,
  GiGraduateCap,
  GiWallet,
} from "react-icons/gi";
import { Button } from "react-bootstrap";
import { canWrite, getLanguage, getRole, logout } from "../helpers/auth";
import Protected from "../routes/Protected";
import { Link, useHistory } from "react-router-dom";
import I18n from "../Config/I18n";
import { Url } from "../Config/url";
import PaymentAPI from "../backend/payment";
import PopupCorsi from "./payment/PopupCorsi";

const l = getLanguage();

export const isDrawerOpenContext = createContext(false);

export const Drawer: FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [noUpgrade, setNoUpgrade] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const history = useHistory();

  useEffect(() => {
    checkSubscription();
    checkNoUpgrade();
  }, []);

  const checkSubscription = async () => {
    const sub = await PaymentAPI.hasSubscription();
    if (!sub.error && sub.data.subscription) {
      setIsSubscribed(true);
    }
  };

  const handleClickPopUp = () => {
    setIsPopupOpen(true);
    // Uncomment these lines if you still want to navigate and reload
    // history.push(Url.payment);
    // history.go(0);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const checkNoUpgrade = async () => {
    const noup = await PaymentAPI.hasNoUpgrade();
    if (noup.data.noupgrade === true) {
      setNoUpgrade(true);
    }
  };

  const toggleDrawer = () => setIsOpen(prevState => !prevState);

  const closeDrawer = () => setIsOpen(false);

  return (
    <isDrawerOpenContext.Provider value={isOpen}>
      <div
        className={`fixed z-20 top-0 inset-x-0 sm:inset-y-0 h-screen flex flex-col bg-ganache-brown-500 transition-width ${
          isOpen ? "w-72" : "sm:w-14 w-0"
        }`}
      >
        <div className="mb-2">
          <div className="flex px-2 py-3 sm:py-4 gap-x-4">
            <Button variant="secondary" id="menu-toggle" onClick={toggleDrawer} size="sm">
              <GiHamburgerMenu size="1.5em" />
            </Button>
            <img className="hidden w-24 h-full sm:block" hidden={!isOpen} src={"/images/logos/logo-white.png"} alt="logo" />
          </div>
          {getRole() === "demo" ? (
            <>
              <hr className="color-primary" />
              <a href={Url.payment}>
                <h3 className="text-center text-light">Demo</h3>
              </a>
            </>
          ) : null}
        </div>
        <div className="flex flex-col justify-between flex-1">
          <div>
            <Protected
              role={getRole()}
              perform="dashboard-page-admin:visit"
              yes={() => (
                <ToolbarLink
                  url={Url.admin}
                  onClick={closeDrawer}
                  isToolbarOpen={isOpen}
                  extra={I18n.t("dashboard", { locale: l })}
                  icon={<GiSpeedometer size="1.5em" />}
                />
              )}
            />
            <ToolbarLink
              url={Url.folder}
              onClick={closeDrawer}
              isToolbarOpen={isOpen}
              extra={I18n.t("recipeObject.recipes", { locale: l })}
              icon={<GiSecretBook size="1.5em" />}
            />
            <ToolbarLink
              url={Url.ingredients}
              onClick={closeDrawer}
              isToolbarOpen={isOpen}
              extra={I18n.t("ingredients", { locale: l })}
              icon={<GiKnifeFork size="1.5em" />}
            />
            {canWrite() && (
              <ToolbarLink
                url={Url.manual}
                onClick={closeDrawer}
                isToolbarOpen={isOpen}
                extra={I18n.t("manual.title", { locale: l })}
                icon={<GiGraduateCap size="1.5em" />}
              />
            )}
          </div>
          <div>
            {noUpgrade && (
              <button
                onClick={handleClickPopUp}
                className={`flex w-full px-3 py-3 gap-x-2 text-ganache-neutral-100 hover:bg-ganache-orange-500 hover:no-underline ${
                  !isOpen && "hidden sm:block"
                }`}
              >
                <GiWallet size="1.5em" className="shrink-0" />
                <p hidden={!isOpen} className="overflow-hidden whitespace-nowrap">
                  {I18n.t("menu.payement", { locale: l })}
                </p>
              </button>
            )}
            {!isSubscribed && !noUpgrade && (
              <button
                onClick={() => {
                  history.push(Url.payment);
                  history.go(0);
                }}
                className={`flex w-full px-3 py-3 gap-x-2 text-ganache-neutral-100 hover:bg-ganache-orange-500 hover:no-underline ${
                  !isOpen && "hidden sm:block"
                }`}
              >
                <GiWallet size="1.5em" className="shrink-0" />
                <p hidden={!isOpen} className="overflow-hidden whitespace-nowrap">
                  {I18n.t("menu.payement", { locale: l })}
                </p>
              </button>
            )}
            <ToolbarLink
              url={Url.profile}
              onClick={closeDrawer}
              isToolbarOpen={isOpen}
              extra={I18n.t("parameters", { locale: l })}
              icon={<GiSettingsKnobs size="1.5em" />}
            />
            <button
              onClick={logout}
              className={`flex w-full px-3 py-3 gap-x-2 text-ganache-neutral-100 hover:bg-ganache-orange-500 hover:no-underline ${
                !isOpen && "hidden sm:block"
              }`}
            >
              <GiExitDoor size="1.5em" className="shrink-0" />
              <p hidden={!isOpen} className="overflow-hidden whitespace-nowrap">
                {I18n.t("disconnect", { locale: l })}
              </p>
            </button>
          </div>
        </div>
      </div>
      <div
        className={`min-h-screen transition-width overflow-hidden ml-auto ${
          isOpen ? "w-[calc(100vw-18rem)]" : "sm:w-[calc(100vw-3.5rem)]"
        }`}
      >
        <>
          <div className="fixed inset-x-0 top-0 z-10 flex justify-end w-full h-16 pr-6 bg-ganache-brown-500 sm:hidden">
            <img className="self-center w-24" src={"/images/logos/logo-white.png"} alt="logo" />
          </div>
          {children}
        </>
      </div>
      <PopupCorsi open={isPopupOpen} handleClosePopupCorsi={closePopup} />
    </isDrawerOpenContext.Provider>
  );
};

const ToolbarLink: FC<{ url: string; isToolbarOpen: boolean; extra: string; icon: any; onClick?: () => void }> = ({
  url,
  isToolbarOpen,
  extra,
  icon,
  onClick = () => null,
}) => {
  return (
    <Link to={url} className={`no-underline ${!isToolbarOpen && "hidden sm:block"}`} onClick={onClick}>
      <div className="flex px-3 py-3 gap-x-2 text-ganache-neutral-100 hover:bg-ganache-orange-500 hover:no-underline">
        <span className="shrink-0">{icon}</span>
        <p className="overflow-hidden whitespace-nowrap" hidden={!isToolbarOpen}>
          {extra}
        </p>
      </div>
    </Link>
  );
};

export const PageToolbarContainer: FC = ({ children }) => {
  const isDrawerOpen = useContext(isDrawerOpenContext);
  return (
    <div
      className={`z-10 mt-16 sm:mt-0 px-3 py-3 border-b bg-ganache-neutral-100 transition-width ${
        isDrawerOpen ? "w-[calc(100vw-18rem)]" : "sm:w-[calc(100vw-3.5rem)]"
      }`}
    >
      {children}
    </div>
  );
};
