import { useEffect, useState } from "react";
import { GET } from "../../../helpers/requests";
import RecipePreview from "./RecipePreview";
import Loader from "../../loader";
import recipeAPI from "../../../backend/recipe";

export default function RecipesPreview() {
  const [recipes, setRecipes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [status, setStatus] = useState([]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const loadData = async () => {
    setLoading(true);
    await Promise.all([loadCategoryRecipes(), loadStatusRecipes(), loadRecipesParallel()]);
    setLoading(false);
  };

  const loadRecipesParallel = async () => {
    const recipesId = await getRecipesIds();
    // transform requests into Promises, await all
    try {
      var data = await Promise.all(recipesId.map(loadRecipeById));
      setRecipes(data);
    } catch (err) {
      console.error(err);
    }
  };

  const loadRecipeById = async recipeId => {
    const recipe = await recipeAPI.getById(recipeId);
    if (recipe.error) {
      return console.error(recipe.error.message);
    }
    return recipe.data;
  };

  const loadCategoryRecipes = async () => {
    const categories = await GET("/recipe/category");
    if (categories.error) {
      return console.error(categories.error.message);
    }
    setCategories(categories.data);
  };

  const loadStatusRecipes = async () => {
    const status = await GET("/recipe/status");
    if (status.error) {
      return console.error(status.error.message);
    }
    setStatus(status.data);
  };

  // retrieve an array of RecipeId to print
  const getRecipesIds = () => {
    const url = window.location.href;
    const urlArray = url.split("/");
    const startIndex = urlArray.indexOf("print");
    const endIndex = urlArray.length;
    const ids = urlArray.slice(startIndex + 1, endIndex);
    return ids;
  };

  return (
    <div>
      {recipes.length > 0 ? (
        <RecipePreview setRecipes={setRecipes} recipes={recipes} status={status} categories={categories} />
      ) : (
        <div
          style={{
            marginTop: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {loading ? <Loader /> : null}
        </div>
      )}
    </div>
  );
}
