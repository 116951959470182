import { useEffect, useState } from "react";
import IngredientPageToolbar from "../components/Ingredients/Ingredient/IngredientPageToolbar";
import {
  CardSugar,
  CardFats,
  CardDry,
  CardLiquids,
  CardOthers,
  CardComment,
  CardContent,
  CardCost,
} from "../components/Ingredients/Ingredient/cards";
import SimpleSnackbar from "../components/SimpleSnackbar";
import IngredientAPI from "../backend/ingredient";
import BrandAPI from "../backend/brand";
import RecipeAPI from "../backend/recipe";
import { getLanguage, getRoot, getUser } from "../helpers/auth";
import { validationIngredient, computeTotalIngredient } from "../utils/ingredientValidation";
import I18n from "../Config/I18n";
import { getRecipeUrl } from "../Config/url";
import { useHistory } from "react-router-dom";
import { PageToolbarContainer } from "src/components/drawer";
import { useHaveWriteAccess } from "src/helpers/access";
import GanacheFormat from "src/utils/ganacheFormat";

const l = getLanguage();

const defaultIngredient = {
  _id: "",
  name: "",
  commercialName: "",
  brandId: "",
  categoryId: "",
  ownerId: "",
  composition: {
    sugar: 0,
    fats: {
      oil: 0,
      butter: 0,
      cocoa_butter: 0,
    },
    dry: {
      cocoa_solid: 0,
      amp: 0,
      lactose: 0,
      other: 0,
    },
    liquids: {
      water: 0,
      alcohol: 0,
    },
    other: {
      pod: 0,
      pac: 0,
      kcal: 0,
      kj: 0,
    },
  },
  flags: {
    contents: [],
    fit: [],
  },
  note: {
    foodCost: 0,
    note: "",
  },
  editable: true,
};

export default function Ingredient({
  ingredientProps = defaultIngredient,
  indexInRecipe = -1,
  fromRecipe = false,
  setAddFromRecipe,
  recipe = {},
  saveAsIng = false,
  setRecipe,
  isNew = false,
}) {
  const [updateIngredient, setUpdateIngredient] = useState(false);
  const [messageSnackBar, setMessageSnackBar] = useState("");
  const [alertVariant, setAlertVariant] = useState("dark");
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [open, setOpen] = useState(false);
  const [ingredient, setIngredient] = useState(ingredientProps);
  const [note, setNote] = useState("");

  const [isModified, setIsModified] = useState(false);

  const history = useHistory();

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const urlParams = new URLSearchParams(window.location.search.substring(1));
  useHaveWriteAccess(!urlParams.has("id"));

  const loadData = async () => {
    await setIngredientId();

    const categoriesResp = await IngredientAPI.getCategories();
    if (categoriesResp.error) return window.alert(categoriesResp.data.message);
    setCategories(categoriesResp.data);

    const brandResp = await BrandAPI.get();
    if (brandResp.error) return window.alert(brandResp.data.message);
    setBrands(sortByName(brandResp.data));

    //setNote(ingredient.note.note)
  };

  const setIngredientId = async () => {
    if (window.location.pathname.includes("ingredient")) {
      const urlParams = new URLSearchParams(window.location.search.substring(1));
      if (urlParams.has("id")) {
        setUpdateIngredient(true);
        const ingredientId = urlParams.get("id");
        getIngredientById(ingredientId);
      }
    } else {
      setUpdateIngredient(!isNew);
    }
  };

  // Get data. Call the api. Fetch data and get responses
  const getIngredientById = async id => {
    const resp = await IngredientAPI.getById(id);
    if (resp.error) return window.alert("Error : ", resp.data.message);
    setIngredient(resp.data);
    setNote(resp.data.note.note);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    ingredient.note.note = note;
    delete ingredient._id;
    delete ingredient.owner;
    const isValid = validationIngredient(ingredient);
    if (!isValid) {
      setMessageSnackBar(`${I18n.t("ingredient.errorMessageTotal", { locale: l })}`);
      setAlertVariant("danger");
      handleOpen();
      return console.error("Error : ", `${I18n.t("ingredient.errorMessageTotal", { locale: l })}`);
    }
    if (ingredient.brandId) ingredient.brandId = ingredient.brandId._id;
    if (ingredient.categoryId) ingredient.categoryId = ingredient.categoryId._id;
    const resp = await IngredientAPI.add(ingredient);
    if (resp.error) {
      setMessageSnackBar(resp.data.message);
      setAlertVariant("danger");
      handleOpen();
      return console.error("Error : ", resp.data.message);
    } else {
      setMessageSnackBar(`${I18n.t("ingredient.ingredientSaved", { locale: l })}`);
      setAlertVariant("info");
      handleOpen();

      // In the case where we are adding an ingredient from a recipe
      if (fromRecipe) {
        if (recipe._id) {
          const ingRow = {
            recipeId: recipe._id,
            ingredientId: resp.data._id,
            quantity: 0,
            coefficient: 1,
          };
          // We are adding an ingredient from a recipe -> checking if saving a recipe. If yes, no need to add the ing row in the current recipe.
          if (!saveAsIng) {
            const response = await RecipeAPI.addIngredient(ingRow);
            if (response.error) {
              setMessageSnackBar(resp.data.message);
              setAlertVariant("danger");
              handleOpen();
              return console.error("Error : ", resp.data.message);
            } else {
              setRecipe(response);
            }
          }
        } else {
          const ingredients = recipe.ingredients;
          ingredients.push({ quantity: 0, coefficient: 1, ingredient: resp.data, temperature: 0 });
          setRecipe({ ...recipe });
        }
        // Showing an ingredient from a recipe -> hidding the component
        setAddFromRecipe(false);
      } else {
        // In the other case we go back from where we were
        history.goBack();
      }
    }
    setIsModified(false);
  };

  const handleUpdate = async (event, id) => {
    event.preventDefault();
    ingredient.note.note = note;
    delete ingredient.owner;
    const isValid = await validationIngredient(ingredient);
    if (!isValid) {
      setMessageSnackBar(`${I18n.t("ingredient.errorMessageTotal", { locale: l })}`);
      setAlertVariant("danger");
      handleOpen();
      return console.error("Error : ", `${I18n.t("ingredient.errorMessageTotal", { locale: l })}`);
    }
    const resp = await IngredientAPI.update(ingredient);
    if (resp.error) {
      setMessageSnackBar(resp.data.message);
      setAlertVariant("danger");
      handleOpen();
      return console.error("Error : ", resp.data.message);
    } else {
      setMessageSnackBar(`${I18n.t("ingredient.ingredientSaved", { locale: l })}`);
      setAlertVariant("info");
      handleOpen();
      setIsModified(false);
      if (fromRecipe) {
        const urlparams = new URLSearchParams(window.location.search);
        const userFolder = urlparams.has("folderId") ? urlparams.get("folderId") : getRoot();
        history.push(getRecipeUrl(recipe._id, userFolder, "ingredients"));
        setAddFromRecipe(false);
      } else {
        history.goBack();
      }
    }
  };

  // open snackbar
  const handleOpen = () => {
    setOpen(true);
  };

  // close snackbar
  const handleClose = (event, reason) => {
    if (reason !== "clickaway") return setOpen(false);
  };

  const addBrand = newBrand => {
    /*
    const allBrands = sortByName([...brands, newBrand])
    setBrands(allBrands)
    */
    loadData();
  };

  const sortByName = array => {
    function compare(a, b) {
      // Use toUpperCase() to ignore character casing
      const brand1 = a.name.toUpperCase();
      const brand2 = b.name.toUpperCase();

      let comparison = 0;
      if (brand1 > brand2) {
        comparison = 1;
      } else if (brand1 < brand2) {
        comparison = -1;
      }
      return comparison;
    }
    return array.sort(compare);
  };

  const handleUpdateNote = async () => {
    const data = {
      ownerId: getUser()._id,
      ingredientId: ingredient._id,
      foodCost: ingredient.note?.foodCost || 0,
      note: note || "",
    };

    await IngredientAPI.updateIngredientNote(data);

    if (fromRecipe) {
      recipe.ingredients[indexInRecipe].ingredient.note.note = data.note;
      recipe.ingredients[indexInRecipe].ingredient.note.foodCost = data.foodCost;

      setRecipe(recipe);
      const urlparams = new URLSearchParams(window.location.search);
      const userFolder = urlparams.has("folderId") ? urlparams.get("folderId") : getRoot();
      history.push(getRecipeUrl(recipe._id, userFolder, "ingredients"));

      setAddFromRecipe(false);
    } else {
      history.goBack();
    }
  };
  return (
    <>
      <PageToolbarContainer>
        <IngredientPageToolbar
          ingredient={ingredient}
          setIngredient={setIngredient}
          brands={brands}
          setBrands={setBrands}
          categories={categories}
          handleSubmit={handleSubmit}
          handleUpdate={handleUpdate}
          updateIngredient={updateIngredient}
          isModified={isModified}
          setIsModified={setIsModified}
          handleUpdateNote={handleUpdateNote}
          isFromRecipe={fromRecipe}
          setAddFromRecipe={setAddFromRecipe}
          recipe={recipe}
        />
      </PageToolbarContainer>
      <div className="flex mx-3 mt-3 mb-4 gap-x-3">
        <div className="flex flex-col flex-1 gap-y-4">
          <div className="flex items-center h-7 gap-x-2">
            <h4 className="text-xl font-bold">{I18n.t("ingredient.calcdata", { locale: l })}</h4>
            <p hidden={!isModified} style={{ color: validationIngredient(ingredient) ? "green" : "red" }}>
              ({GanacheFormat.percentage(computeTotalIngredient(ingredient.composition))}%)
            </p>
          </div>
          <TwoCardsContainer>
            <FlexCardContainer>
              <CardSugar ingredient={ingredient} setIngredient={setIngredient} setIsModified={setIsModified} />
            </FlexCardContainer>
            <FlexCardContainer>
              <CardFats ingredient={ingredient} setIngredient={setIngredient} setIsModified={setIsModified} />
            </FlexCardContainer>
          </TwoCardsContainer>
          <CardContainer>
            <CardDry ingredient={ingredient} setIngredient={setIngredient} setIsModified={setIsModified} />
          </CardContainer>
          <TwoCardsContainer>
            <FlexCardContainer>
              <CardLiquids ingredient={ingredient} setIngredient={setIngredient} setIsModified={setIsModified} />
            </FlexCardContainer>
            <FlexCardContainer>
              <CardOthers ingredient={ingredient} setIngredient={setIngredient} setIsModified={setIsModified} />
            </FlexCardContainer>
          </TwoCardsContainer>
        </div>
        <div className="flex flex-col flex-1 gap-y-4">
          <h4 className="text-xl font-bold h-7">{I18n.t("ingredient.otherinfo", { locale: l })}</h4>
          <TwoCardsContainer>
            <FlexCardContainer>
              <CardContent ingredient={ingredient} setIngredient={setIngredient} setIsModified={setIsModified} />
            </FlexCardContainer>
            <FlexCardContainer>
              <CardCost
                ingredient={ingredient}
                setIngredient={setIngredient}
                updateIngredient={updateIngredient}
                setIsModified={setIsModified}
              />
            </FlexCardContainer>
          </TwoCardsContainer>

          <CardContainer>
            <CardComment note={note} setNote={setNote} updateIngredient={updateIngredient} setIsModified={setIsModified} />
          </CardContainer>
        </div>
      </div>
      <SimpleSnackbar open={open} handleClose={handleClose} message={messageSnackBar} variant={alertVariant} />
    </>
  );
}

const TwoCardsContainer = ({ children }) => <div className="flex gap-x-4">{children}</div>;
const FlexCardContainer = ({ children }) => <div className="flex-1 h-full p-4 border rounded-sm">{children}</div>;
const CardContainer = ({ children }) => <div className="w-full p-4 border rounded-sm h-min">{children}</div>;
