import { useEffect, useState } from "react";
import RecipeInfo from "./RecipeInfo";
import { GET } from "../../../../helpers/requests";
import { getLanguage } from "../../../../helpers/auth";
import I18n from "../../../../Config/I18n";
import { Badge, Card, Col, Row } from "react-bootstrap";
import SearchSelect from "../../../generic/SearchSelect";
import { useHistory } from "react-router-dom";

const l = getLanguage();

export default function RecipeComparaison({ recipe, getContribution, status }) {
  const [recipes, setRecipes] = useState([]);
  const [recipeSelectSecond, setRecipeSelectSecond] = useState(recipe);
  const [recipeSelectThird, setRecipeSelectThird] = useState(recipe);

  const history = useHistory();

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const loadData = async () => {
    await Promise.all([loadRecipes(), loadFromUrl()]);
  };

  const loadFromUrl = async () => {
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has("r1")) {
      const recipe1 = await loadRecipeById(urlParams.get("r1"));
      if (recipe1) {
        setRecipeSelectSecond(recipe1);
      } else {
        setRecipeSelectSecond(recipe);
      }
    }

    if (urlParams.has("r2")) {
      const recipe2 = await loadRecipeById(urlParams.get("r2"));
      if (recipe2) {
        setRecipeSelectThird(recipe2);
      } else {
        setRecipeSelectThird(recipe);
      }
    }
  };

  // Load all recipes
  const loadRecipes = async () => {
    const resp = await GET("/folders/all");
    if (resp.error) {
      return console.error(recipes.error.message);
    }
    setRecipes(resp.data);
  };

  // Load recipe by ID
  const loadRecipeById = async (recipeId, index) => {
    const recipe = await GET(`/recipe/${recipeId}`);
    if (recipe.error) {
      return console.error(recipe.error.message);
    }
    return recipe.data;
  };

  const handleChangeRecipe = async elm => {
    const recipeSelect = await loadRecipeById(elm._id);

    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has("r1")) {
      urlParams.set("r1", elm._id);
    } else {
      urlParams.append("r1", elm._id);
    }

    history.replace(`?${urlParams.toString()}`);

    setRecipeSelectSecond(recipeSelect);
  };

  const handleChangeRecipeThird = async elm => {
    const recipeSelect = await loadRecipeById(elm._id);

    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has("r2")) {
      urlParams.set("r2", elm._id);
    } else {
      urlParams.append("r2", elm._id);
    }

    history.replace(`?${urlParams.toString()}`);

    setRecipeSelectThird(recipeSelect);
  };
  const searchElmnt = recipes.map(recipe => {
    const statut = status.find(stat => stat._id === recipe.statusId);

    return {
      name: (
        <p>
          {recipe.name}
          <Badge variant={statut?.color || "light"} style={{ marginLeft: "10px" }}>
            {statut?.name || " - "}
          </Badge>
        </p>
      ),
      _id: recipe._id,
    };
  });
  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title className="my-auto">
            <h4>{I18n.t("recipeAction.compareRecipes", { locale: l })}</h4>
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col className="mt-2">
              <div className="h-20">
                <label>{I18n.t("recipe", { locale: l })}</label>
              </div>

              <div className="">
                <RecipeInfo recipe={recipe} status={status} />
              </div>
            </Col>

            <Col className="mt-2">
              <div className="h-20">
                <label>{I18n.t("ingredient.recipeSelected", { locale: l })}</label>
                <SearchSelect
                  elements={searchElmnt}
                  filterName={I18n.t("recipeAction.selectRecipe", { locale: l })}
                  handleChange={handleChangeRecipe}
                  value={recipeSelectSecond}
                />
              </div>
              <RecipeInfo recipe={recipeSelectSecond} status={status} />
            </Col>

            <Col className="mt-2">
              <div className="h-20">
                <label htmlFor="lastname">{I18n.t("ingredient.recipeSelected", { locale: l })}</label>

                <SearchSelect
                  elements={searchElmnt}
                  filterName={I18n.t("recipeAction.selectRecipe", { locale: l })}
                  handleChange={handleChangeRecipeThird}
                  value={recipeSelectThird}
                />
              </div>
              <RecipeInfo recipe={recipeSelectThird} status={status} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}
