
import {getLanguage, logout} from "../../helpers/auth";
import I18n from "../../Config/I18n";
import { Button, Modal } from "react-bootstrap";
import {Url} from "../../Config/url";

const l = getLanguage();

export default function PopupDemo({ handleClosePopupDemo, open, }) {
    // const classes = useStyles();
    // const [search, setSearch] = useState('');


    // Get all recipes in folder




    // const handleChangeSearch = (event) => {
    //   setSearch(event.target.value.substr(0,20));
    // }

    return (
        <>
            <Modal
                centered
                scrollable
                show={open}
                onHide={handleClosePopupDemo}
                backdrop="static"
                keyboard={false}>
                <Modal.Header >
                    <Modal.Title>{I18n.t("payment.popupDemoTitle", { locale: l })}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {I18n.t("payment.popupDemoBody", { locale: l })}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleClosePopupDemo} color="primary">
                        {I18n.t("close", { locale: l })}
                    </Button>
                    <Button href={Url.payment} color="primary" >
                        {I18n.t("payment.popupDemoButton", { locale: l })}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
