import React, { useEffect, useState } from "react";
import VerifyData from "./VerifyData";
import ReviewPayment from "./ReviewPayment";
import Payment from "./Payment";
import SimpleSnackbar from "../SimpleSnackbar";
import I18n from "../../Config/I18n";
import { getLanguage } from "../../helpers/auth";
import { Button, Card } from "react-bootstrap";
import UserAPI from "../../backend/user";
import PaymentAPI from "../../backend/payment";
import { Url, getUrlAfterPayment } from "../../Config/url";
import Loader from "../loader";
import { useHistory } from "react-router-dom";
import VerifyData2 from "./VerifyData2";

const l = getLanguage();

const variants = {
  error: "danger",
  success: "success",
  primary: "primary",
};

export default function PaymentStepper() {
  const [loading, setLoading] = useState(0);
  const [snackBar, setSnackBar] = useState({
    open: false,
    message: "",
    variant: "dark",
  });
  const [activeStep, setActiveStep] = useState(0);
  /**
   * @type {[import('../../Type/user').UserInfo]}
   */
  const [user, setUser] = useState({
    language: "",
    lastname: "",
    name: "",
    phone: "",
    company: {
      name: "",
      VAT: "",
    },
    address: {
      street: "",
      city: "",
      zipCode: "",
      number: "",
      country: "",
    },
  });

  const [isPaymentLoading, setIsPaymentLoading] = useState(false);

  const history = useHistory();

  const startLoading = () => {
    setLoading(loading + 1);
  };
  const stopLoading = () => {
    setLoading(loading - 1);
  };

  const sendSnack = (message, variant = variants.primary, delay = 2000) => {
    const snack = {
      open: true,
      message,
      variant,
    };
    setSnackBar(snack);
    setTimeout(handleClose, delay);
  };
  // close snackbar
  const handleClose = (event, reason) => {
    if (reason !== "clickaway") return setSnackBar({ ...snackBar, open: false });
  };

  /**
   * @type {[paymentStep]}
   */
  const steps = [
    {
      name: `${I18n.t("payment.steps.verify", { locale: l })}`,
      isValid: () => false,
    },
    {
      name: `${I18n.t("payment.steps.payment", { locale: l })}`,
      isValid: () => true,
      getCoupon: () => "",
    },
    {
      name: `${I18n.t("payment.steps.review", { locale: l })}`,
      isValid: () => true,
    },
  ];

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    retrieveStepPayment();
    startLoading();
    await Promise.all([loadUser(), exitIfSubscription()]);
    stopLoading();
  };

  /**
   * get user information
   */
  const loadUser = async () => {
    const usersData = await UserAPI.getInfo();
    if (usersData.error) {
      sendSnack("Error User info", variants.error);
      return console.error(usersData.error.message);
    }
    setUser(usersData.data);
  };

  /**
   * Redirect user if he has a valid subscription
   */
  const exitIfSubscription = async () => {
    const hasSubscription = await PaymentAPI.hasSubscription();
    if (hasSubscription.error) {
      return console.error(hasSubscription.error.message);
    }
    if (hasSubscription.data.subscription) {
      history.push(Url.dashboard);
    }
  };

  /**
   *
   */
  const retrieveStepPayment = () => {
    const stepParams = "step";
    const urlParams = new URLSearchParams(window.location.search);
    const hasId = urlParams.has(stepParams);
    if (hasId) {
      const step = parseInt(urlParams.get(stepParams));
      setActiveStep(step);
    }
  };

  /**
   * Redirect user to the payment pages
   */
  const paymentRedirection = async () => {
    const redirectUrl = getUrlAfterPayment(steps.length - 1);
    const couponCode = steps[1].getCoupon();
    const payment = await PaymentAPI.pay(redirectUrl, couponCode);
    if (payment.error) {
      return false;
    }
    window.location.href = payment.link;
  };

  const handleNext = async e => {
    const nexStep = activeStep + 1;

    switch (activeStep) {
      case 0:
        if (await steps[0].isValid()) setActiveStep(nexStep);
        break;
      case 1:
        setIsPaymentLoading(true);
        try {
          paymentRedirection();
        } finally {
          setIsPaymentLoading(false);
        }
        break;
      case 2:
        history.push(Url.dashboard);
        break;
      default:
    }
  };

  const handleBack = () => {
    if (activeStep > 0) setActiveStep(activeStep - 1);
  };

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return <VerifyData2 startLoading={startLoading} stopLoading={stopLoading} steper={steps[0]}></VerifyData2>;
      case 1:
        return <Payment step={steps[1]} />;
      case 2:
        return <ReviewPayment subscription={false} hasASubscription={exitIfSubscription} />;
      default:
        throw new Error("Unknown step");
    }
  };

  const getNextButtonText = () => {
    switch (activeStep) {
      case 1:
        return `${I18n.t("payment.pay", { locale: l })}`;
      case steps.length - 1:
        return I18n.t("payment.backToDashboard", { locale: l });
      default:
        return `${I18n.t("next", { locale: l })}`;
    }
  };

  const getTitle = () => {
    switch (activeStep) {
      case 0:
        return I18n.t("payment.steps.verify", { locale: l });
      case 1:
        return I18n.t("payment.steps.review", { locale: l });
      default:
        return I18n.t("payment.paymentProcess", { locale: l });
    }
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>
          <h3>{getTitle()}</h3>
        </Card.Title>
      </Card.Header>
      <Card.Body>
        {loading > 0 ? <Loader style={{ textAlgin: "center" }} /> : null}
        {getStepContent()}
      </Card.Body>
      <Card.Footer className="text-right">
        <Button
          onClick={() => history.push(Url.welcome)}
          className="mx-1"
          hidden={user.userType !== "demo" || activeStep === steps.length - 1}
        >
          {I18n.t("payment.demo", { locale: l })}
        </Button>
        <Button onClick={handleBack} className="mx-1" hidden={activeStep === 0}>
          {I18n.t("back", { locale: l })}
        </Button>
        <Button variant="primary" className="mx-1" onClick={handleNext} disabled={isPaymentLoading}>
          {isPaymentLoading ? <Loader variant="secondary" /> : getNextButtonText()}
        </Button>
      </Card.Footer>
      <SimpleSnackbar open={snackBar.open} handleClose={handleClose} message={snackBar.message} variant={snackBar.variant} />
    </Card>
  );
}
