import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import IngredientAPI from "../../../../backend/ingredient";
import RecipeAPI from "../../../../backend/recipe";
import { Recipe } from "src/Type/recipe2";
import { RecipeIngredient } from "src/Type/recipeIngredient2";
import { getLanguage, getUser } from "../../../../helpers/auth";
import I18n from "../../../../Config/I18n";
import { getGroupTemperatureFromRecipe } from "../../../../utils/Recipe";
import PopupActionsIngredients from "../PopupActionGroupIngredients";
import Ingredient from "../../../../pages/Ingredient";
import PopupIngredients from "../PopupIngredients";
import { GetTotalsTables } from "./ingredientsTable/totals";
import { DetailsTable } from "./ingredientsTable/details";
import { ListTable } from "./ingredientsTable/list";

const l = getLanguage();

const defaultIngredient = {
  _id: "",
  name: "",
  commercialName: "",
  brandId: "",
  categoryId: "",
  ownerId: "",
  composition: {
    sugar: 0,
    fats: {
      oil: 0,
      butter: 0,
      cocoa_butter: 0,
    },
    dry: {
      cocoa_solid: 0,
      amp: 0,
      lactose: 0,
      other: 0,
    },
    liquids: {
      water: 0,
      alcohol: 0,
    },
    other: {
      pod: 0,
      pac: 0,
      kcal: 0,
      kj: 0,
    },
  },
  flags: {
    contents: [],
    fit: [],
  },
  note: {
    foodCost: 0,
    note: "",
  },
  editable: true,
};

export const getTotalWeight: (cooked: any, reducer: any) => Number = (cooked, reducer) => {
  return Object.keys(cooked)
    .map(temp => {
      const qty = cooked[temp].total.quantity || 0;
      return qty;
    })
    .reduce(reducer, 0);
};

export const getTotalWeightAfterCookingWithHidden: (cooked: any, recipe: Recipe, reducer: any) => Number = (cooked, recipe, reducer) => {
  return (
    Object.keys(cooked)
      .map(temp => {
        const qty = cooked[temp].total.quantity || 0;
        return qty;
      })
      .reduce(reducer, 0) +
    recipe.ingredients
      .filter(ing => ing.hide)
      .map(ing => ing.quantity)
      .reduce(reducer, 0)
  );
};

export const arrayRemove: (arr: any[], indexInRecipe: number) => any[] = (arr, indexInRecipe) => {
  return arr.filter((ele, index) => {
    return index !== indexInRecipe;
  });
};

const TableWithDetails: FC<any> = ({
  reducer,
  contribution,
  recipe,
  cooking,
  setIsTemperaturePopupOpen,
  setIsIngredientsPopupOpen,
  brands,
  moveIngredientUp,
  moveIngredientDown,
  hide,
  onToggleDelete,
  notAuthorize,
  setAddFromRecipe,
  setIngredient,
  setIngredientIndex,
  setIngVar,
  isEditIndexIng,
  isEditIngTemp,
  setIsEditIngTemp,
  setIsEditIndexIng,
  setIsRecipeModified,
  setRecipe,
  changeTemp,
  totalWeigth,
}) => {
  return (
    <div>
      <Row>
        <ListTable
          recipe={recipe}
          reducer={reducer}
          cooking={cooking}
          setIsTemperaturePopupOpen={setIsTemperaturePopupOpen}
          setIsIngredientsPopupOpen={setIsIngredientsPopupOpen}
          brands={brands}
          moveIngredientUp={moveIngredientUp}
          moveIngredientDown={moveIngredientDown}
          hide={hide}
          onToggleDelete={onToggleDelete}
          notAuthorize={notAuthorize}
          setAddFromRecipe={setAddFromRecipe}
          setIngredient={setIngredient}
          setIngredientIndex={setIngredientIndex}
          setIngVar={setIngVar}
          isEditIndexIng={isEditIndexIng}
          isEditIngTemp={isEditIngTemp}
          setIsEditIngTemp={setIsEditIngTemp}
          setIsEditIndexIng={setIsEditIndexIng}
          setIsRecipeModified={setIsRecipeModified}
          setRecipe={setRecipe}
          changeTemp={changeTemp}
          totalWeigth={totalWeigth}
        />
        <DetailsTable recipe={recipe} contribution={contribution} cooking={cooking} reducer={reducer} />
      </Row>
      <Row>
        <Col lg={12} md={12}>
          <h3 className="text-3xl font-semibold">{I18n.t("listIngredients.totals", { locale: l })}</h3>
          <GetTotalsTables contribution={contribution} cooking={cooking} reducer={reducer} />
        </Col>
      </Row>
    </div>
  );
};

/**
 * Main Page
 */

// TODO: Add types for every field
const IngredientsTable: FC<{
  recipe: Recipe;
  setRecipe: Dispatch<SetStateAction<Recipe>>;
  getContribution: any;
  setContributions: Dispatch<SetStateAction<any>>;
  isCreatingIngredient: boolean;
  setIsCreatingIngredient: Dispatch<SetStateAction<boolean>>;
  notAuthorize: any;
  fitfors: any;
  contents: any;
  brands: any;
  ingredientCategories: any;
  createData: any;
  setIsRecipeModified: any;
  addFromRecipe: boolean;
  setAddFromRecipe: Dispatch<SetStateAction<boolean>>;
}> = ({
  recipe,
  setRecipe,
  getContribution,
  setContributions,
  notAuthorize,
  isCreatingIngredient,
  setIsCreatingIngredient,
  fitfors,
  contents,
  brands,
  ingredientCategories,
  createData,
  setIsRecipeModified,
  addFromRecipe,
  setAddFromRecipe,
}) => {
  const [isIngredientsPopupOpen, setIsIngredientsPopupOpen] = useState(false);
  const [isTemperaturePopupOpen, setIsTemperaturePopupOpen] = useState(false);
  // What is the previous ???
  const [previous, setPrevious] = useState({});
  const [ingredient, setIngredient] = useState(defaultIngredient);
  const [ingredientIndex, setIngredientIndex] = useState(-1);

  const [isEditIngTemp, setIsEditIngTemp] = useState(-1);
  const [isEditIndexIng, setIsEditIndexIng] = useState(-1);
  const [cooking, setCooking] = useState({});
  const [totalWeigth, setTotalWeigth] = useState<any>(0);

  useEffect(() => {
    computeEvaporation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipe, ingredient]);

  // TODO: Understand why there is this reducer here and which is not in a state
  const reducer = (accumulator, value) => accumulator + value;

  const computeEvaporation = async () => {
    const cooked = await getGroupTemperatureFromRecipe(recipe);
    const totalWeigthRecipe = getTotalWeightAfterCookingWithHidden(cooked, recipe, reducer);
    setCooking(cooked);
    setTotalWeigth(totalWeigthRecipe.toFixed(1));
  };

  const moveIngredientDown: (ingredient: RecipeIngredient) => void = ingredient => {
    const ingredients = recipe.ingredients;
    var to = 0;
    var from = -1;
    for (var i = 0; i < ingredients.length; i++) {
      const elmt = ingredients[i];
      if (elmt === ingredient) {
        from = i;
      } else if (from >= 0 && i > from && elmt.temperature === ingredient.temperature) {
        to = i;
        break;
      }
    }

    var temporary = ingredients[to]; // up one
    ingredients[to] = ingredients[from];
    ingredients[from] = temporary;

    setRecipe({ ...recipe, ingredients });
    setIsRecipeModified(true);
  };

  const moveIngredientUp: (ingredient: RecipeIngredient) => void = ingredient => {
    const ingredients = recipe.ingredients;
    var last = 0;
    var from = 0;
    for (var i = 0; i < ingredients.length; i++) {
      const elmt = ingredients[i];
      if (elmt === ingredient) {
        from = i;
        break;
      }
      if (elmt.temperature === ingredient.temperature) last = i;
    }

    var temporary = ingredients[last]; // up one
    ingredients[last] = ingredients[from];
    ingredients[from] = temporary;

    setRecipe({ ...recipe, ingredients });
    setIsRecipeModified(true);
  };

  // FIXME: his component looks weird
  const hide: (ingredient: RecipeIngredient) => void = ingredient => {
    ingredient.hide = !ingredient.hide;
    setIngredients([...recipe.ingredients]);
  };

  const onToggleDelete = indexInRecipe => {
    const rowsFiltered = arrayRemove(recipe.ingredients, indexInRecipe);
    const result = getContribution(rowsFiltered);
    setRecipe({
      ...recipe,
      ingredients: rowsFiltered,
    });
    setContributions(result);
    setIsRecipeModified(true);
  };

  const changeTemp = (oldTemp, newTemp) => {
    try {
      newTemp = parseInt(newTemp);
    } catch (err) {
      // cannot parse 'ambiant'
      newTemp = 0;
    }

    oldTemp = parseInt(oldTemp);
    const newRecipe = { ...recipe };
    newRecipe.ingredients = newRecipe.ingredients.map(ing => {
      try {
        // cannot parse 'ambiant'
        ing.temperature = parseInt(ing.temperature as any);
      } catch (err) {
        ing.temperature = 0;
      }
      if (ing.temperature === oldTemp) {
        ing.temperature = parseInt(newTemp);
      }
      return ing;
    });
    setRecipe(newRecipe);
  };

  // Adding an ingredient by a recipe redirect to the 'create ingredient' with the according recipe & name
  const handleNewIngredient = async search => {
    ingredient.name = search;
    setAddFromRecipe(true);
    setIsIngredientsPopupOpen(false);
    setIsCreatingIngredient(true);
    // If the recipe exists, saving it
    if (recipe._id) {
      var ingRes = [];
      for (const ing of recipe.ingredients) {
        const dico = {
          _id: ing.ingredient._id,
          quantity: ing.quantity,
          temperature: ing.temperature,
        };
        if (ing.temperature === 0) {
          delete dico.temperature;
        }
        ingRes.push(dico);
      }

      delete recipe.owner;
      //@ts-ignore
      delete recipe.createdOn;
      delete recipe.lastUpdateOn;
      delete recipe.editable;

      //@ts-ignore
      recipe.ownerId = getUser()._id;
      recipe.ingredients = ingRes;
      //@ts-ignore
      const result = await RecipeAPI.save(recipe);
      if (result.error) {
        setAddFromRecipe(false);
      } else {
        setRecipe(result.data);
      }
    }
    setIsRecipeModified(true);
    setIsCreatingIngredient(false);
  };

  const getFinalContribution = () => {
    const totalWeigth = getTotalWeight(cooking, reducer);
    const totalContributions = Object.keys(cooking).map(temp => {
      const temperature = parseInt(temp);
      const group = cooking[temperature];
      //@ts-ignore
      const coef = group.total.quantity / totalWeigth;
      const composition = group.total.composition;

      const contribution = {
        sugar: composition.sugar * coef,
        butter: composition.fats.butter * coef,
        cocoa_butter: composition.fats.cocoa_butter * coef,
        oil: composition.fats.oil * coef,
        amp: composition.dry.amp * coef,
        lactose: composition.dry.lactose * coef,
        cocoa_solid: composition.dry.cocoa_solid * coef,
        other: composition.dry.other * coef,
        water: composition.liquids.water * coef,
        alcohol: composition.liquids.alcohol * coef,
        //@ts-ignore
        pod: parseInt(composition.other.pod * coef),
        //@ts-ignore
        pac: parseInt(composition.other.pac * coef),
        kcal: parseInt(composition.other.kcal),
        kj: composition.other.kj,
      };
      return contribution;
    });

    const reducerContribution = (accumulator, currentValue) => {
      return {
        sugar: accumulator.sugar + currentValue.sugar,
        butter: accumulator.butter + currentValue.butter,
        cocoa_butter: accumulator.cocoa_butter + currentValue.cocoa_butter,
        oil: accumulator.oil + currentValue.oil,
        amp: accumulator.amp + currentValue.amp,
        lactose: accumulator.lactose + currentValue.lactose,
        cocoa_solid: accumulator.cocoa_solid + currentValue.cocoa_solid,
        other: accumulator.other + currentValue.other,
        water: accumulator.water + currentValue.water,
        alcohol: accumulator.alcohol + currentValue.alcohol,
        pod: parseInt(accumulator.pod + currentValue.pod),
        pac: parseInt(accumulator.pac + currentValue.pac),
        kcal: parseInt(accumulator.kcal + currentValue.kcal),
        kj: accumulator.kj + currentValue.kj,
      };
    };
    const contribution = totalContributions.reduce(reducerContribution, {
      sugar: 0,
      butter: 0,
      cocoa_butter: 0,
      oil: 0,
      amp: 0,
      lactose: 0,
      cocoa_solid: 0,
      other: 0,
      water: 0,
      alcohol: 0,
      pod: 0,
      pac: 0,
      kcal: 0,
      kj: 0,
    });
    const price = recipe.ingredients
      .map(ing => {
        const pricePerKg = ing.ingredient.note.foodCost || 0;

        const ingPrice = (pricePerKg / 1000) * ing.quantity;
        return ingPrice;
      })
      .reduce(reducer, 0);

    contribution.foodCost = price;
    return contribution;
  };

  const setIngVar = async (e, row, index) => {
    if (!previous[row.id]) {
      setPrevious(state => ({ ...state, [row.id]: row }));
    }
    let value = e.target.value;
    if (isNaN(parseFloat(value))) {
      value = 0;
    }
    const name = e.target.name;

    const newRows2 = [];
    for (var ingIndex in recipe.ingredients) {
      var rowIng = recipe.ingredients[ingIndex];
      if (row !== rowIng) {
        newRows2.push(rowIng);
        continue;
      }
      if (name === "temperature") {
        const result = await IngredientAPI.getById(rowIng.ingredient._id, value);
        const evaComputed = result.data;
        const element = { ...rowIng };
        element.coefficient = evaComputed.coefficient;
        element.temperature = parseFloat(value);
        element.ingredient = evaComputed.ingredient;
        newRows2.push(element);
        continue;
      }

      rowIng.quantity = value >= 10 ? parseInt(value) : parseFloat(value);

      newRows2.push({ ...rowIng, [name]: parseFloat(value) });
    }

    setRecipe({ ...recipe, ingredients: newRows2 });
    setIsRecipeModified(true);
  };

  const setIngredients = ingredients => {
    const newRecipe = {
      ...recipe,
      ingredients,
    };
    setRecipe(newRecipe);
    setIsRecipeModified(true);
  };

  return (
    <div>
      {addFromRecipe ? (
        <Ingredient
          ingredientProps={ingredient}
          indexInRecipe={ingredientIndex}
          fromRecipe={addFromRecipe}
          setAddFromRecipe={setAddFromRecipe}
          recipe={recipe}
          setRecipe={setRecipe}
          isNew={isCreatingIngredient}
        />
      ) : (
        <>
          <PopupIngredients
            isIngredientsPopupOpen={isIngredientsPopupOpen}
            setIsIngredientsPopupOpen={setIsIngredientsPopupOpen}
            createData={createData}
            handleNewIngredient={handleNewIngredient}
            recipe={recipe}
            setRecipe={setRecipe}
            fitfors={fitfors}
            contents={contents}
            brands={brands}
            ingredientCategories={ingredientCategories}
            setIsRecipeModified={setIsRecipeModified}
          />
          <PopupActionsIngredients
            isTemperaturePopupOpen={isTemperaturePopupOpen}
            setIsTemperaturePopupOpen={setIsTemperaturePopupOpen}
            ingredients={[...recipe.ingredients]}
            totalWeight={getTotalWeight(cooking, reducer)}
            setIngredients={setIngredients}
            getContribution={getContribution}
          />
          <TableWithDetails
            setIsIngredientsPopupOpen={setIsIngredientsPopupOpen}
            contribution={getFinalContribution()}
            reducer={reducer}
            recipe={recipe}
            setIsTemperaturePopupOpen={setIsTemperaturePopupOpen}
            cooking={cooking}
            brands={brands}
            moveIngredientUp={moveIngredientUp}
            moveIngredientDown={moveIngredientDown}
            hide={hide}
            onToggleDelete={onToggleDelete}
            notAuthorize={notAuthorize}
            setAddFromRecipe={setAddFromRecipe}
            setIngredient={setIngredient}
            setIngredientIndex={setIngredientIndex}
            setIngVar={setIngVar}
            isEditIndexIng={isEditIndexIng}
            isEditIngTemp={isEditIngTemp}
            setIsEditIngTemp={setIsEditIngTemp}
            setIsEditIndexIng={setIsEditIndexIng}
            setIsRecipeModified={setIsRecipeModified}
            setRecipe={setRecipe}
            changeTemp={changeTemp}
            totalWeigth={totalWeigth}
          />
        </>
      )}
    </div>
  );
};

export default IngredientsTable;
