import { deburr, escapeRegExp } from "lodash";

const isSearchingFit: (name: string, searchString: string) => boolean = (name, searchString) => {
  const searchWordRegexed = escapeRegExp(deburr(searchString).trim()).toLowerCase().split(" ").join(".*");
  const regex = new RegExp(`.*${searchWordRegexed}.*`, "g");
  return regex.exec(deburr(name).trim().toLowerCase()) ? true : false;
};

const SearchControler = {
  isSearchingFit,
};

export default SearchControler;
