import { ChangeEvent, FC, useEffect, useState } from "react";
import { Switch } from "@headlessui/react";
import { InformationCircleIcon } from "@heroicons/react/outline";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import I18n from "../../Config/I18n";
import DataAPI from "src/backend/data";
import { Country } from "src/Type/country2";
import { UserInfo } from "src/Type/user2";
import { getLanguage } from "src/helpers/auth";
import { Address } from "src/Type/address2";
import { CompanyVAT } from "src/Type/company2";
import { Spinner } from "@chakra-ui/react";
import userAPI from "src/backend/user";
import { isString, upperCase } from "lodash";

const l = getLanguage();

const VerifyData2: FC<{ startLoading: Function; stopLoading: Function; steper: { isValid: () => Promise<boolean> } }> = ({
  startLoading,
  stopLoading,
  steper,
}) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const [countries, setCountries] = useState<Country[]>([]);
  const [address, setAddress] = useState<Address>();
  const [isCompany, setIsCompany] = useState<boolean>(false);
  const [isCheckingVat, setIsCheckingVat] = useState<boolean>(false);
  const [company, setCompany] = useState<CompanyVAT & { isValid: boolean }>({ VAT: "", name: "", isValid: true });

  steper.isValid = async () => {
    const data: { [n: string]: any } = {
      address,
      company: {},
    };

    const isInEu = countries.find(country => country.countryCode === address.country)?.isEU || false;

    let isValid = !isInEu || !isCompany;

    if (isInEu && isCompany) {
      setIsCheckingVat(true);

      const vat = company.VAT;
      const countryCode = vat.slice(0, 2);
      const country = countries.find(c => c.countryCode === countryCode);
      if (!country) {
        setCompany({
          ...company,
          name: "",
          isValid: false,
        });
        isValid = false;
      } else {
        let result = await DataAPI.checkVAT(company.VAT.toUpperCase());

        setCompany({
          ...company,
          VAT: company.VAT.toUpperCase(),
          name: result.name,
          isValid: result.valid,
        });

        isValid = result.valid;
        const companyDuplicate = { ...company, address, name: result.name };
        delete companyDuplicate.isValid;

        data.company = companyDuplicate;
      }
    }

    if (isValid) await userAPI.update(data);
    setIsCheckingVat(false);
    return isValid;
  };

  useEffect(() => {
    const loadData = async () => {
      startLoading();
      await Promise.all([loadUser(), loadCountries()]);
      stopLoading();
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadUser = async () => {
    const userInfo = await userAPI.getInfo();

    if (userInfo.data) {
      const data: UserInfo = userInfo.data as any;
      if (data.company) {
        setAddress(data.company.address);
        setIsCompany(true);
        setCompany({ name: data.company.name, VAT: data.company.VAT, isValid: true });
      } else {
        setAddress(data.address);
      }
    }
  };
  const loadCountries = async () => {
    const resp = await DataAPI.getCountries();

    setCountries(resp.data);
  };

  const setCountry = (e: ChangeEvent<HTMLSelectElement>) => {
    setAddress({ ...address, country: e.target.value });
    const isInEu = countries.find(country => country.countryCode === e.target.value)?.isEU || false;
    if (!isInEu) setIsCompany(false);
  };

  const isInEu = countries.find(country => country.countryCode === address?.country)?.isEU || false;

  return (
    <>
      <form onSubmit={e => e.preventDefault()} className="">
        <div className="flex gap-x-4">
          <div className="flex-1">
            <label htmlFor="street" className="block text-sm font-medium text-gray-700">
              {I18n.t("user.street", { locale: l })}
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="street"
                id="street"
                className="h-10 focus:bg-orange-100 block w-full sm:text-sm border-solid border-2 border-gray-300 rounded-md  focus:outline-orange-500"
                placeholder="West Norwood Avenue"
                value={address?.street}
                onChange={e =>
                  setAddress({
                    ...address,
                    street: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="flex-2">
            <label htmlFor="streetNumber" className="block text-sm font-medium text-gray-700">
              {I18n.t("user.number", { locale: l })}
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="streetNumber"
                id="streetNumber"
                className="h-10 focus:bg-orange-100 block w-full sm:text-sm border-solid border-2 border-gray-300 rounded-md  focus:outline-orange-500"
                placeholder="1445"
                value={address?.number}
                onChange={e =>
                  setAddress({
                    ...address,
                    number: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>

        <div className="flex gap-x-2 pt-3">
          <div className="flex-auto w-5/12">
            <label htmlFor="city" className="block text-sm font-medium text-gray-700">
              {I18n.t("user.city", { locale: l })}
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="city"
                id="city"
                className="h-10 focus:bg-orange-100 block w-full sm:text-sm border-solid border-2 border-gray-300 rounded-md  focus:outline-orange-500"
                placeholder="Itasca"
                value={address?.city}
                onChange={e =>
                  setAddress({
                    ...address,
                    city: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="flex-auto w-2/12">
            <label htmlFor="zipCode" className="block text-sm font-medium text-gray-700">
              {I18n.t("user.zipCode", { locale: l })}
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="zipCode"
                id="zipCode"
                className="h-10 focus:bg-orange-100 block w-full sm:text-sm border-solid border-2 border-gray-300 rounded-md focus:outline-orange-500"
                placeholder="60143"
                value={address?.zipCode}
                onChange={e =>
                  setAddress({
                    ...address,
                    zipCode: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="flex-auto w-5/12">
            <label htmlFor="country" className="block text-sm font-medium text-gray-700">
              {I18n.t("user.country", { locale: l })}
            </label>
            <select
              id="country"
              name="country"
              className="h-10 focus:bg-orange-100 block w-full sm:text-sm border-solid border-2 border-gray-300 rounded-md  focus:outline-orange-500"
              value={address?.country}
              onChange={setCountry}
            >
              {countries.map(country => (
                <option key={country.countryCode} value={country.countryCode}>
                  {country.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        {isInEu && (
          <>
            <div className="flex pt-2">
              <div>
                <Switch.Group as="div" className="flex items-center">
                  <Switch.Label as="span" className="mr-3">
                    <span className="text-sm font-medium text-gray-900">{I18n.t("business", { locale: l })} </span>
                  </Switch.Label>
                  <Switch
                    checked={isCompany}
                    onChange={setIsCompany}
                    className={classNames(
                      isCompany ? "bg-orange-400" : "bg-gray-200",
                      "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        isCompany ? "translate-x-5" : "translate-x-0",
                        "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                      )}
                    />
                  </Switch>
                </Switch.Group>
              </div>
            </div>

            {isCompany && (
              <div className="flex">
                <div className="flex">
                  <div className="mt-2.5">
                    <label htmlFor="companyVat" className="block text-md font-medium text-gray-700">
                      {I18n.t("user.vat", { locale: l })} :
                    </label>
                  </div>
                  <div className="pl-2">
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <input
                        type="text"
                        name="companyVat"
                        id="companyVat"
                        className="uppercase h-10 focus:bg-orange-100 block w-full sm:text-sm border-solid border-2 border-gray-300 rounded-md focus:outline-orange-500"
                        placeholder="BE0999999999"
                        value={company.VAT}
                        onChange={e => {
                          setCompany({ ...company, VAT: e.target.value });
                        }}
                        aria-invalid={!company.isValid}
                      />
                      {!company.isValid && (
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                        </div>
                      )}
                      {isCheckingVat && (
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <Spinner />
                        </div>
                      )}
                    </div>

                    <a
                      href="https://ec.europa.eu/taxation_customs/vies/faqvies.do#item_11"
                      // eslint-disable-next-line react/jsx-no-target-blank
                      target="_blank"
                      className="text-sm underline"
                    >
                      <div className="flex">
                        <InformationCircleIcon className="h-5" />
                        <p>{I18n.t("user.VATEUFormat", { locale: l })}</p>
                      </div>
                    </a>
                  </div>
                </div>
                {company.isValid && <div className="mt-2.5 pl-3">{company.name}</div>}
              </div>
            )}
          </>
        )}
      </form>
    </>
  );
};

export default VerifyData2;
