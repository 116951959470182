import { useEffect, useRef, useState } from "react";
import RecipeAPI from "../backend/recipe";
import RecipeToolbar from "../components/recipes/toolbar/recipeToolbar";
import TextEditor from "../components/TextEditor";
import IngredientsTable from "../components/recipes/newRecipe/ingredients/IngredientsTable";
import EditRecipe from "../components/recipes/newRecipe/recipe/EditRecipe";
// import DescriptionAuthor from '../components/recipes/recipe/DescriptionAuthor'
import UploadImage from "../Layouts/UploadImage";
import Loader from "../components/loader";
import RecipeComparaison from "../components/recipes/newRecipe/compare/RecipeComparaison";
import { GET, POST, getRecipeFile } from "../helpers/requests";
import { canWrite, getId, getLanguage, getRoot, getUser } from "../helpers/auth";
import SimpleSnackbar from "../components/SimpleSnackbar";
import { getContribution, submitDataRecipe, getIngredients, getContentIds, getFitForIds, computeTemperatures } from "../utils/recipes";
import IngredientAPI from "../backend/ingredient";
import RecipeCategoryAPI from "../backend/recipeCategory";
import BrandsAPI from "../backend/brand";
import I18n from "../Config/I18n";
import { useLocation } from "react-router-dom";
import Ingredient from "../pages/Ingredient";
import { PageToolbarContainer } from "src/components/drawer";
import StringReducer from "src/components/generic/StringReducer";
import GenericMoreButton from "src/components/recipes/toolbar/GenericMoreButton";
import { Url } from "src/Config/url";
import FolderAPI from "../backend/folder";
import { useHistory } from "react-router-dom";
import { Button, ButtonGroup, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BiLeftArrowAlt } from "react-icons/bi";
import { FlagModal } from "src/components/recipes/FlagModal";
import FileExplorer from "src/components/folders/FileExplorer";
import { useHaveWriteAccess } from "src/helpers/access";

const l = getLanguage();

const url = {
  recipeId: "id",
  folderId: "folderId",
};

const snackStyle = {
  error: "danger",
  success: "success",
  warning: "warning",
};

export default function Recipe() {
  const [open, setOpen] = useState(false); // snackbar
  const [messageSnackBar, setMessageSnackBar] = useState("");
  const [isCreatingIngredient, setIsCreatingIngredient] = useState(false);
  const [alertVariant, setAlertVariant] = useState("dark");
  const [isLoading, setIsLoading] = useState(false);
  const [recipeCategories, setRecipeCategories] = useState([]);
  const [ingredientCategories, setIngredientCategories] = useState([]);
  const [contents, setContents] = useState([]);
  const [fitfor, setFitfor] = useState([]);
  const [status, setStatus] = useState([]);
  const [value, setValue] = useState("recipe");
  const [contributions, setContributions] = useState([]);

  let user = getUser();

  const [recipe, setRecipe] = useState({
    editable: true,
    ingredients: [],
    source: I18n.t("recipeObject.personalRecipe", { locale: l }),
    ownerId: user._id,
    owner: user?.name + " " + user?.lastname,
  });
  /**
   * @type {[import("../Type/recipe").Recipe,Function}]}
   */
  const [isSavingIngAsRecipe, setIsSavingIngAsRecipe] = useState(false);
  const [ingredientAsRecipe, setIngredientAsRecipe] = useState([]);

  const [isRecipeModified, setIsRecipeModified] = useState(false);
  const [brands, setBrands] = useState([]);
  const [addFromRecipe, setAddFromRecipe] = useState(false);
  // const [state, setState] = useState({bottom: false});

  const history = useHistory();

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const urlParams = new URLSearchParams(window.location.search);
  useHaveWriteAccess(!urlParams.has(url.recipeId));

  const loadData = async () => {
    setIsLoading(true);
    await Promise.all([
      loadRecipe(),
      loadCategoryRecipes(),
      loadStatusRecipes(),
      loadContents(),
      loadFitfor(),
      loadBrands(),
      loadCategoryIngredients(),
    ]);
    setIsLoading(false);
  };

  const loadCategoryIngredients = async () => {
    const categories = await IngredientAPI.getCategories();
    if (categories.error) {
      sendSnack("Error while load brands", snackStyle.error);
      return console.error(categories.error.message);
    }
    setIngredientCategories(categories.data);
  };
  const loadBrands = async () => {
    const brands = await BrandsAPI.get();
    if (brands.error) {
      sendSnack("Error while load brands", snackStyle.error);
      return console.error(brands.error.message);
    }
    setBrands(brands.data);
  };

  const loadRecipe = async () => {
    const urlParams = new URLSearchParams(window.location.search);

    if (!urlParams.has(url.recipeId)) return;
    const recipeId = urlParams.get(url.recipeId);
    const resp = await RecipeAPI.getById(recipeId);
    if (resp.error) {
      if (resp.error.notFound) sendSnack("Recipe not found", snackStyle.error);
      return console.error(resp.error.message);
    }

    if (resp.data.editable) {
      if (!resp.data.source) {
        resp.data.source = I18n.t("recipeObject.personalRecipe", { locale: l });
      }
    } else {
      if (!resp.data.source) {
        resp.data.source = resp.data.owner;
      }
    }
    setRecipe(resp.data);
  };

  const loadContents = async () => {
    const contents = await GET("/ingredient/content");
    if (contents.error) {
      sendSnack("Error while load contents", snackStyle.error);
      return console.error(contents.error.message);
    }
    setContents(contents.data);
  };

  const loadFitfor = async () => {
    const fitfor = await GET("/ingredient/fitfor");
    if (fitfor.error) {
      sendSnack("Error while load fitfor", snackStyle.error);
      return console.error(fitfor.error.message);
    }
    setFitfor(fitfor.data);
  };

  const loadCategoryRecipes = async () => {
    const userId = getUser()._id;

    const categories = await RecipeCategoryAPI.getByUser(userId);

    if (categories.error) {
      sendSnack("Error while load categories", snackStyle.error);
      return console.error(categories.error.message);
    }

    categories.data.forEach(elt => {
      if (elt.ownerId === userId) {
        elt.name += "*";
      } else {
        elt.name += "°";
      }
    });
    setRecipeCategories(categories.data);
  };

  const loadStatusRecipes = async () => {
    const status = await GET("/recipe/status");
    if (status.error) {
      sendSnack("Error while load categories", snackStyle.error);
      return console.error(status.error.message);
    }
    setStatus(status.data);
  };

  const onFileSubmit = async e => {
    e.preventDefault();
    const data = {
      image: e.target.files[0],
      _id: recipe._id,
    };
    await RecipeAPI.updatePicture(data);
  };

  const addIngredientsToRecipe = newIngredient => {
    const data = {
      recipeId: recipe._id,
      ingredientId: newIngredient.id,
      quantity: newIngredient.quantite,
    };
    POST("/recipe/ingredient", data).then(response => {
      if (response.error) {
        return console.error(response.error.message);
      }
    });
  };

  /**
   * TODO
   */
  const checkValidity = () => {
    if (!recipe.name) {
      sendSnack("name is required");
      return false;
    }
    return true;
  };

  /**
   * Save the recipe
   * @author Malo
   * @param {String=} folderId
   * @async
   */
  const saveRecipe = async (folderId, goBack = false) => {
    if (!checkValidity()) return false;

    const url = new URLSearchParams(window.location.search);

    if (folderId) {
      recipe.folderId = folderId;
    } else {
      if (url.has("folderId")) {
        const folder = url.get("folderId");
        recipe.folderId = folder;
      } else {
        url.append("folderId", recipe.folderId);
      }
    }

    const data = submitDataRecipe(recipe); // retrieve data for request
    const response = await RecipeAPI.save(data);
    if (response.error) {
      if (response.error.notFound) sendSnack("Not found", snackStyle.error);
      if (response.error.notEditable) sendSnack("You are not allowed to modify this recipe", snackStyle.error);
      console.error(response.error.message);
      return false;
    }
    setRecipe(response.data); // MUST BE UDAPTED

    if (!url.has("id")) {
      url.append("id", response.data._id);
    }
    if (!goBack) {
      // On recipe page, if we seva and don't go back, refreshing the page.
      window.location.search = url.toString();
    }

    const message = data._id
      ? `${I18n.t("recipeObject.recipeUpdated", { locale: l })}`
      : `${I18n.t("recipeObject.recipeCreated", { locale: l })}`;
    sendSnack(message, snackStyle.success);
    setIsRecipeModified(false);

    return true;
  };

  /**
   * Duplicate recipe
   */
  const duplicateRecipe = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const folderId = urlParams.get(url.folderId);
    let recipeId = urlParams.has(url.recipeId) ? urlParams.get(url.recipeId) : "";
    const data = {
      recipeId,
      folderId,
    };
    const response = await RecipeAPI.duplicate(data);
    if (response.error) {
      sendSnack("Error while trying to duplicate", snackStyle.error);
      return console.error(response.error.message);
    }
    sendSnack(`${I18n.t("recipeObject.recipeCopied", { locale: l })}`, snackStyle.success);
  };

  const SaveRecipeButton = ({ submitRecipe, setRecipe, recipe }) => {
    const [isFileExplorerModalOpen, setIsFileExporerModalOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(1);
    const [folder, setFolder] = useState({
      _id: "",
      name: "",
      childrens: [
        {
          _id: "",
          name: "",
          haveChild: false,
        },
      ],
      parentId: undefined,
    });

    const [folderId, setFolderId] = useState(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const hasId = urlParams.has("folderId");
      return hasId ? urlParams.get("folderId") : getRoot();
    });
    const anchorRef = useRef(null);

    useEffect(() => {
      let folderId = getRoot();
      const urlParams = new URLSearchParams(window.location.search);
      const hasId = urlParams.has("folderId");
      if (hasId) {
        folderId = urlParams.get("folderId");
      }
      loadFolder(folderId);
    }, []);

    const save = async event => {
      let savingFolderId = undefined;
      // Saving in the recipeFolder
      if (recipe._id) {
        const folderRecipe = await FolderAPI.getFolderRecipe(recipe._id);
        savingFolderId = folderRecipe ? folderRecipe._id : getRoot();
      } else {
        // No Id, new recipe, saving in the current user folder
        const urlParams = new URLSearchParams(window.location.search);
        savingFolderId = urlParams.has("folderId") ? urlParams.get("folderId") : getRoot();
      }
      submitRecipe(savingFolderId);
    };

    const loadFolder = async (id, event) => {
      if (event) {
        event.preventDefault();
        event.stopPropagation(); // don't close dropdown
      }
      const f = await FolderAPI.getById(id);
      setFolder(f);
    };

    const handleSelect = async (event, folderId, index) => {
      setSelectedIndex(index);
      await setRecipe({
        ...recipe,
        folderId: folderId,
      });
      await submitRecipe(folderId);
    };

    const saveElsewhere = async newFolderId => {
      setFolderId(newFolderId);
      submitRecipe(newFolderId);
      setIsFileExporerModalOpen(false);
    };

    return (
      <>
        <Dropdown ref={anchorRef} as={ButtonGroup}>
          {canWrite() && (
            <>
              <Button onClick={save}>{I18n.t("save", { locale: l })}</Button>
              <OverlayTrigger placement="bottom" overlay={<Tooltip>{I18n.t("recipeAction.saveAs", { locale: l })}</Tooltip>}>
                <Dropdown.Toggle split onClick={() => setIsFileExporerModalOpen(true)} />
              </OverlayTrigger>
            </>
          )}
          <Dropdown.Menu alignRight>
            {folder && folder.parentId && (
              <>
                <Dropdown.Item className="flex items-center gap-x-3">
                  <Button onClick={e => loadFolder(folder.parentId, e)} variant="light">
                    <BiLeftArrowAlt className="ml-1" />
                  </Button>
                  {folder.name}
                </Dropdown.Item>
                <Dropdown.Divider />
              </>
            )}
            {/* We can only save a recipe in a fodler that is our (cannont save in the ganache_solution) */}
            {folder &&
              folder.childrens
                .filter(child => child.ownerId === folder.ownerId)
                .map((child, index) => {
                  return (
                    <Dropdown.Item key={index} selected={index === selectedIndex}>
                      <Button onClick={e => loadFolder(child._id, e)} variant="light">
                        {child.name}
                      </Button>
                    </Dropdown.Item>
                  );
                })}
            <Dropdown.Divider />
            <Button onClick={e => handleSelect(e, folder._id)} size="sm" variant="primary" className="ml-3">
              {I18n.t("save", { locale: l })}
            </Button>
          </Dropdown.Menu>
        </Dropdown>
        <FileExplorer
          folderId={folderId}
          open={isFileExplorerModalOpen}
          close={() => setIsFileExporerModalOpen(false)}
          selectIdFunction={saveElsewhere}
        ></FileExplorer>
      </>
    );
  };

  /**
   * Create a new ingredient from the current recipe and then, redirect the user to the edit page of this new ingredient
   */
  const saveAsIngredient = async () => {
    const ingredients = getIngredients(recipe);
    const contents = getContentIds(ingredients);
    const fit = getFitForIds(ingredients);
    const cooking = await computeTemperatures(recipe.ingredients);
    const totalWeigth = Object.keys(cooking)
      .map(temp => {
        const temperature = parseInt(temp);
        const group = cooking[temperature];
        return group.total.quantity;
      })
      .reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
      }, 0);
    const compoEmpty = {
      sugar: 0,
      butter: 0,
      cocoa_butter: 0,
      oil: 0,
      amp: 0,
      lactose: 0,
      cocoa_solid: 0,
      other: 0,
      water: 0,
      alcohol: 0,
      pod: 0,
      pac: 0,
      kcal: 0,
      kj: 0,
    };
    //if (!cooking) return compoEmpty

    const totalContributions = Object.keys(cooking).map(temp => {
      const temperature = parseInt(temp);
      const group = cooking[temperature];
      const coef = group.total.quantity / totalWeigth;
      const composition = group.total.composition;

      const contribution = {
        sugar: composition.sugar * coef,
        butter: composition.fats.butter * coef,
        cocoa_butter: composition.fats.cocoa_butter * coef,
        oil: composition.fats.oil * coef,
        amp: composition.dry.amp * coef,
        lactose: composition.dry.lactose * coef,
        cocoa_solid: composition.dry.cocoa_solid * coef,
        other: composition.dry.other * coef,
        water: composition.liquids.water * coef,
        alcohol: composition.liquids.alcohol * coef,
        pod: parseInt(composition.other.pod * coef),
        pac: parseInt(composition.other.pac * coef),
        kcal: parseInt(composition.other.kcal),
        kj: composition.other.kj,
      };
      return contribution;
    });

    const reducerContribution = (accumulator, currentValue) => {
      return {
        sugar: accumulator.sugar + currentValue.sugar,
        butter: accumulator.butter + currentValue.butter,
        cocoa_butter: accumulator.cocoa_butter + currentValue.cocoa_butter,
        oil: accumulator.oil + currentValue.oil,
        amp: accumulator.amp + currentValue.amp,
        lactose: accumulator.lactose + currentValue.lactose,
        cocoa_solid: accumulator.cocoa_solid + currentValue.cocoa_solid,
        other: accumulator.other + currentValue.other,
        water: accumulator.water + currentValue.water,
        alcohol: accumulator.alcohol + currentValue.alcohol,
        pod: parseInt(accumulator.pod + currentValue.pod),
        pac: parseInt(accumulator.pac + currentValue.pac),
        kcal: parseInt(accumulator.kcal + currentValue.kcal),
        kj: accumulator.kj + currentValue.kj,
      };
    };
    const contribution = totalContributions.reduce(reducerContribution, compoEmpty);
    const foodcost = totalWeigth
      ? (recipe.ingredients
          .map(ing => (ing.quantity / 1000) * ing.ingredient.note.foodCost)
          .reduce((accumulator, currentValue) => {
            return accumulator + currentValue;
          }, 0) *
          1000) /
        totalWeigth
      : 0;
    contribution.foodCost = foodcost;
    const composition = contribution; //getTotalContributionRecipe(recipe)
    const ingredient = {
      name: recipe.name,
      commercialName: recipe.name,
      brandId: "",
      categoryId: (await IngredientAPI.getRecipeCategoryId()).data,
      fromTechnicalSheet: false,
      composition: {
        sugar: composition.sugar,
        fats: {
          oil: composition.oil,
          butter: composition.butter,
          cocoa_butter: composition.cocoa_butter,
        },
        dry: {
          cocoa_solid: composition.cocoa_solid,
          amp: composition.amp,
          lactose: composition.lactose,
          other: composition.other,
        },
        liquids: {
          water: composition.water,
          alcohol: composition.alcohol,
        },
        other: {
          pod: composition.pod,
          pac: composition.pac,
          kcal: composition.kcal,
          kj: composition.kj,
        },
      },
      flags: {
        contents,
        fit,
      },
      note: {
        foodCost: composition.foodCost,
        note: `<p>From recipe ${recipe.name || ""}</p>${recipe.note || ""}`,
      },
      editable: true,
    };
    setTimeout(() => setIsSavingIngAsRecipe(true), 500);
    setIngredientAsRecipe(ingredient);
  };

  const createData = async (id, name, quantite, temperature = 0, mode = false) => {
    const ingredient = await getIngredientById(id);
    const rowIngredient = {
      quantity: quantite,
      coefficient: 1,
      ingredient: ingredient,
      temperature: temperature,
    };
    return rowIngredient;
  };

  const getIngredientById = async id => {
    const response = await GET(`/ingredient/${id}`);
    if (response.error) {
      return window.alert("Error : ", response.error.message);
    }
    return response.data;
  };

  // close snackbar
  const handleClose = (event, reason) => {
    if (reason !== "clickaway") return setOpen(false);
  };

  /**
   *
   * @param {*} message
   * @param {snackStyle} type
   * @param {*} time
   */
  const sendSnack = (message = "", type = "primary", time = 2000) => {
    setMessageSnackBar(message);
    setAlertVariant(type);
    setOpen(true);
    setTimeout(handleClose, time);
  };

  const notAuthorize = () => {
    sendSnack(I18n.t("snackbarMessages.notAuthorize", { locale: l }), snackStyle.error);
  };

  const deleteRecipe = async () => {
    const data = {
      parentId: recipe.folderId,
      recipeId: recipe._id,
    };
    const recipeToDelete = await POST("/recipe/delete", data);
    if (recipeToDelete.error) {
      return console.error(recipeToDelete.error.message);
    }
    history.push(Url.folder);
    window.location.reload();
  };

  if (isSavingIngAsRecipe)
    return (
      <Ingredient
        ingredientProps={ingredientAsRecipe}
        fromRecipe={isSavingIngAsRecipe}
        setAddFromRecipe={setIsSavingIngAsRecipe}
        saveAsIng={isSavingIngAsRecipe}
        recipe={recipe}
        setRecipe={setRecipe}
        isNew={true}
      />
    );

  const saveRecipeInRecipeFolder = async () => {
    const folderId = await FolderAPI.getFolderRecipe(recipe._id);
    return saveRecipe(folderId);
  };

  return (
    <>
      <PageToolbarContainer>
        <RecipeToolbar
          recipe={recipe}
          submitRecipe={(folderId, goBack) => saveRecipe(folderId, goBack)}
          isRecipeModified={isRecipeModified}
        />
      </PageToolbarContainer>
      <div className="px-3 pb-4 mt-3">
        <FlagModal recipe={recipe} setRecipe={setRecipe} />
        {isLoading ? (
          <Loader className="loader" />
        ) : (
          <>
            {!addFromRecipe && (
              <div className="flex items-center justify-between mb-2">
                <b>
                  <StringReducer stringToReduce={recipe.name} maxShow={30} placement="bottom" />
                </b>
                <div className="flex items-center gap-x-4">
                  <GenericMoreButton
                    submitRecipe={saveRecipeInRecipeFolder}
                    duplicateRecipe={duplicateRecipe}
                    recipe={recipe}
                    deleteRecipe={deleteRecipe}
                    notAuthorize={notAuthorize}
                    saveAsIngredient={saveAsIngredient}
                    categories={recipeCategories}
                    setRecipeCategories={setRecipeCategories}
                  />
                  {recipe.ownerId === getId() && (
                    <SaveRecipeButton
                      submitRecipe={(folderId, goBack) => saveRecipe(folderId, goBack)}
                      setRecipe={setRecipe}
                      recipe={recipe}
                    />
                  )}
                </div>
              </div>
            )}
            <PageContent
              value={value}
              setValue={setValue}
              recipe={recipe}
              setRecipe={setRecipe}
              saveRecipe={saveRecipe}
              recipeCategories={recipeCategories}
              status={status}
              contents={contents}
              fitfor={fitfor}
              isCreatingIngredient={isCreatingIngredient}
              setIsCreatingIngredient={setIsCreatingIngredient}
              sendSnack={sendSnack}
              loadCategoryRecipes={loadCategoryRecipes}
              createData={createData}
              setIsRecipeModified={setIsRecipeModified}
              contributions={contributions}
              setContributions={setContributions}
              addIngredientsToRecipe={addIngredientsToRecipe}
              notAuthorize={notAuthorize}
              brands={brands}
              ingredientCategories={ingredientCategories}
              getIngredientById={getIngredientById}
              onFileSubmit={onFileSubmit}
              addFromRecipe={addFromRecipe}
              setAddFromRecipe={setAddFromRecipe}
            />
          </>
        )}
      </div>
      <SimpleSnackbar open={open} handleClose={handleClose} message={messageSnackBar} variant={alertVariant} />
    </>
  );
}

const PageContent = ({
  value,
  setValue,
  recipe,
  setRecipe,
  saveRecipe,
  recipeCategories,
  status,
  contents,
  fitfor,
  sendSnack,
  loadCategoryRecipes,
  createData,
  setIsRecipeModified,
  setContributions,
  addIngredientsToRecipe,
  notAuthorize,
  brands,
  ingredientCategories,
  getIngredientById,
  isCreatingIngredient,
  setIsCreatingIngredient,
  onFileSubmit,
  addFromRecipe,
  setAddFromRecipe,
}) => {
  const location = useLocation();

  useEffect(() => {
    loadPageUrl();
  }, [location.search]);

  const loadPageUrl = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    let page = "recipe";
    if (urlParams.has("page")) {
      page = urlParams.get("page");
      // Checking if the url value match any of our recipe toolbar 'pages'. If not default page = 'recipe'
      const regEx = new RegExp("(recipe|ingredients|method|picture|comparaison)");
      const regMatch = page.match(regEx);
      page = regMatch && page === regMatch[0] ? regMatch[0] : "recipe";
    }
    setValue(page);
  };

  switch (value) {
    case "recipe":
      return (
        <EditRecipe
          recipe={recipe}
          setRecipe={setRecipe}
          saveRecipe={(folderId, goBack) => saveRecipe(folderId, goBack)}
          categories={recipeCategories}
          status={status}
          contents={contents}
          fitfors={fitfor}
          notification={sendSnack}
          loadCategories={loadCategoryRecipes}
          createData={createData}
          setIsRecipeModified={setIsRecipeModified}
        />
      );
    case "ingredients":
      return (
        <IngredientsTable
          recipe={recipe}
          setRecipe={setRecipe}
          getContribution={getContribution}
          isCreatingIngredient={isCreatingIngredient}
          setIsCreatingIngredient={setIsCreatingIngredient}
          setContributions={setContributions}
          notAuthorize={notAuthorize}
          fitfors={fitfor}
          contents={contents}
          brands={brands}
          ingredientCategories={ingredientCategories}
          createData={createData}
          setIsRecipeModified={setIsRecipeModified}
          addFromRecipe={addFromRecipe}
          setAddFromRecipe={setAddFromRecipe}
        />
      );
    case "method":
      return (
        <TextEditor
          title={I18n.t("recipeObject.method", { locale: l })}
          defaultValue={recipe.procedure}
          maxLines={7}
          alertTooLong={I18n.t("recipeObject.procedureTooLong", {
            locale: l,
          })}
          handleChange={value => {
            setRecipe({
              ...recipe,
              procedure: value,
            });
            setIsRecipeModified(true);
          }}
          readOnly={!recipe.editable}
        />
      );
    case "picture":
      return (
        <div className="upload">
          <label htmlFor="single">
            {/* TODO POUR MALO */}
            <UploadImage />
            {recipe.picture ? <img src={getRecipeFile(recipe._id)} alt={""} /> : null}
          </label>
          <input type="file" id="single" hidden="hidden" onChange={onFileSubmit} />
        </div>
      );
    case "comparaison":
      return <RecipeComparaison recipe={recipe} status={status} />;
    default:
      sendSnack("Page doesn't exist");
  }
};
