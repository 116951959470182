import { useToast } from "@chakra-ui/react";
import { MutationFunction, MutationKey, useMutation, UseMutationOptions, UseMutationResult } from "react-query";

const ERROR_MESSAGE = "An error occurred while updating the data";

function useMutationWithToast<TData = unknown, TError = any, TVariables = void, TContext = unknown>(
  mutationKey: MutationKey,
  mutationFn?: MutationFunction<TData, TVariables>,
  options?: UseMutationOptions<TData, TError, TVariables, TContext>
): UseMutationResult<TData, TError, TVariables, TContext> {
  const toast = useToast();

  return useMutation<TData, TError, TVariables, TContext>(mutationKey, mutationFn, {
    ...options,
    onError: (error: any, variables: any, context: any) => {
      const errorMessage = error.response?.data?.message || ERROR_MESSAGE;
      toast({
        title: error.response?.status === 404 ? ERROR_MESSAGE : errorMessage,
        status: "error",
        position: "bottom-right",
        isClosable: true,
        duration: 2500,
      });
      options && options.onError && options.onError(error, variables, context);
    },
    onSuccess: (data, variables, context) => {
      toast({
        title: "Change saved",
        status: "success",
        position: "bottom-right",
        isClosable: true,
        duration: 2500,
      });
      options && options.onSuccess && options?.onSuccess(data, variables, context);
    },
  });
}

export default useMutationWithToast;
