import { Form, ListGroup, ListGroupItem, Badge } from "react-bootstrap";
import recipeAPI from "../../../backend/recipe";
import brandAPI from "../../../backend/brand";
import { getLanguage, getUser } from "../../../helpers/auth";
import RecipeOverview from "../RecipeOverview";

import FilterSelect from "../../generic/FilterSelect";
import I18n from "../../../Config/I18n";
import { useState } from "react";
const l = getLanguage();

export default function CheckboxListRecipes({
  recipes,
  handleClosePopupPrint,
  allRecipes,
  recipeSelected,
  setRecipeSelected,
  setIds,
  ids,
  status,
  categories,
}) {
  const [checked, setChecked] = useState([]);
  const [recipeHoover, setRecipe] = useState({ name: "Default", ingredients: [] }); // default value to prevent loading error.
  const [brands, setBrands] = useState([{ _id: "", name: "Default" }]);
  const [searchByName, setSearchName] = useState("");
  const [searchCategory, setSearchCategory] = useState("");
  const [searchStatus, setSearchStatus] = useState("");

  // select recipes to print
  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value._id);
    const newChecked = [...checked];
    const newRecripeSelected = [...recipeSelected];

    if (currentIndex === -1) {
      newChecked.push(value._id);
      newRecripeSelected.push(value);
      setIds(`${ids}/${value._id}`);
    } else {
      newChecked.splice(currentIndex, 1);
      newRecripeSelected.splice(currentIndex, 1);
      ids = ids.replace("/" + value._id, "");
      setIds(`${ids}`);
    }

    setChecked(newChecked);
    setRecipeSelected(newRecripeSelected);
  };

  const getStatusName = id => {
    const stat = status.find(elt => elt._id === id);
    return stat.name;
  };

  const getStatusColor = id => {
    const stat = status.find(elt => elt._id === id);
    return stat.color;
  };

  // When on the recipe cart, loading the recipe
  const loadRecipe = async id => {
    const recipe = await recipeAPI.getById(id);
    const brands = await brandAPI.get();

    setBrands(brands.data);
    setRecipe(recipe.data);
  };

  const handleChangeCategory = eventKey => {
    setSearchCategory(eventKey);
  };

  const handleChangeStatus = eventKey => {
    setSearchStatus(eventKey);
  };

  const handleChangeName = event => {
    setSearchName(event.target.value);
  };

  const filter = () => {
    var recipes = [...allRecipes];
    if (searchByName) {
      recipes = recipes.filter(recipe => {
        return recipe.name.toLowerCase().includes(searchByName.toLowerCase());
      });
    }
    if (searchCategory) {
      recipes = recipes.filter(recipe => {
        if (!recipe.categoryId) return false;
        return recipe.categoryId.includes(searchCategory);
      });
    }
    if (searchStatus) {
      recipes = recipes.filter(recipe => {
        if (!recipe.statusId) return false;
        return recipe.statusId.includes(searchStatus);
      });
    }
    return recipes;
  };

  const isPersonalRecipe = recipe => {
    const userId = getUser()._id;

    if (recipe.ownerId === userId) {
      return recipe.name + "*";
    } else {
      return recipe.name + "°";
    }
  };

  return (
    <div>
      <Form inline>
        <FilterSelect
          elements={categories}
          filterName={I18n.t("category", { locale: l })}
          handleChange={handleChangeCategory}
          className="mx-2"
        />
        <FilterSelect
          elements={status}
          filterName={I18n.t("recipeObject.status", { locale: l })}
          handleChange={handleChangeStatus}
          className="mx-2"
        />
        <Form.Control
          type="text"
          placeholder={I18n.t("recipeObject.searchRecipeByName", { locale: l })}
          value={searchByName}
          onChange={handleChangeName}
        />
      </Form>
      <br />
      <div>{recipeSelected.length > 0 ? recipeSelected.length + " " + I18n.t("selectedRecipes", { locale: l }) : ""}</div>
      <br />
      <ListGroup>
        {filter().length === 0 ? (
          <div> {I18n.t("notMatchRecipe", { locale: l })} </div>
        ) : (
          filter().map((recipe, index) => {
            return (
              <ListGroupItem
                key={index}
                role={undefined}
                dense
                button
                onClick={handleToggle(recipe)}
                onMouseEnter={() => loadRecipe(recipe._id)}
              >
                <Form.Check checked={checked.indexOf(recipe._id) !== -1} label={`${isPersonalRecipe(recipe)}`} />
                {recipe.statusId ? (
                  <Badge variant={`${getStatusColor(recipe.statusId)}`}> {`${getStatusName(recipe.statusId)}`} </Badge>
                ) : null}
                <RecipeOverview recipeHoover={recipeHoover} brands={brands} />
              </ListGroupItem>
            );
          })
        )}
      </ListGroup>
    </div>
  );
}
