import { useToast } from "@chakra-ui/react";
import { QueryFunction, QueryKey, useQuery, UseQueryOptions, UseQueryResult } from "react-query";

const ERROR_MESSAGE = "An error occurred while loading the data";

function useQueryWithErrorToast<TQueryFnData = unknown, TError = unknown, TVariables = TQueryFnData, TQueryKey extends QueryKey = QueryKey>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: UseQueryOptions<TQueryFnData, TError, TVariables, TQueryKey>
): UseQueryResult<unknown, TError> {
  const toast = useToast();
  return useQuery<TQueryFnData, TError, TVariables, TQueryKey>(queryKey, queryFn, {
    ...options,
    onError: error => {
      console.error(error);
      // const errorMessage = data.response?.data?.message || ERROR_MESSAGE;
      // data.response?.status === 404 ? ERROR_MESSAGE : errorMessage,
      toast({
        title: "he",
        status: "error",
        position: "bottom-right",
        isClosable: true,
        duration: 2500,
      });
      options && options.onError && options?.onError(error);
    },
  });
}

export default useQueryWithErrorToast;
