import { DELETE, GET, POST } from "../helpers/requests";

const get = async name => {
  const recipe = await GET("/recipe/category");
  if (recipe.error) {
    recipe.error.notFound = true;
  }
  return recipe;
};

const update = async (id, name) => {
  const recipe = await POST("/recipe/category/update", { id, name });
  if (recipe.error) {
    recipe.error.notFound = true;
  }
  return recipe;
};

const add = async name => {
  const recipe = await POST("/recipe/category/add", { name });
  if (recipe.error) {
    recipe.error.notFound = true;
  }
  return recipe;
};

const getByUser = async userId => {
  const categories = await GET("/recipe/category/", { userId });
  if (categories.error) {
    categories.error.notFound = true;
  }
  return categories;
};

const deleteCategory = async _id => {
  const resp = await DELETE("/recipe/category", { _id });
  return !!resp.error;
};

export default {
  get,
  update,
  add,
  getByUser,
  deleteCategory,
};
