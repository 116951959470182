import { Dispatch, FC, SetStateAction } from "react";
import { GiMagnifyingGlass } from "react-icons/gi";
import { ButtonOutlineIconBrown } from "../buttons/iconButton";

const SearchBoxToggle: FC<{ isSearching: boolean; setIsSearching: Dispatch<SetStateAction<boolean>> }> = ({
  isSearching,
  setIsSearching,
}) => {
  return (
    <ButtonOutlineIconBrown isActive={isSearching} onClick={() => setIsSearching(prevState => !prevState)}>
      <GiMagnifyingGlass />
    </ButtonOutlineIconBrown>
  );
};

export default SearchBoxToggle;
