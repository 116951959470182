import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { MdVisibility } from "react-icons/md";
import { convertTimeDay } from "../../../utils/index";
import I18n from "../../../Config/I18n";
import { getLanguage } from "../../../helpers/auth";

const l = getLanguage();


const columns = [
  {
    id: "invoiceNumber",
    label: I18n.t("invoices.number", { locale: l }),
    format: value => value,
  },
  {
    id: "name",
    label: I18n.t("invoices.name", { locale: l }),
  },
  {
    id: "paidDate",
    label: I18n.t("invoices.paiddate", { locale: l }),
    format: value => `${convertTimeDay(value)}`,
  },
  {
    id: "amount",
    label: I18n.t("invoices.amount", { locale: l }),
    format: amount => `${(amount.value + amount.VAT.value - amount.coupon.value).toFixed(2)} €`,
  },
];

const columns1 = [
  {
    id: "invoiceNumber",
    label: I18n.t("invoices.number", { locale: l }),
    format: value => value,
  },
  {
    id: "name",
    label: I18n.t("invoices.name", { locale: l }),
  },
  {
    id: "paidDate",
    label: I18n.t("invoices.paiddate", { locale: l }),
    format: value => `${convertTimeDay(value)}`,
  },
  {
    id: "amount",
    label: I18n.t("invoices.amount", { locale: l }),
    format: amount => `${(amount.value + amount.VAT.value ).toFixed(2)} €`,
  },
];

const columns2 = [
  {
    id: "invoiceNumber",
    label: I18n.t("invoices.number", { locale: l }),
    format: value => value,
  },
  {
    id: "name",
    label: I18n.t("invoices.name", { locale: l }),
  },
  {
    id: "paidDate",
    label: I18n.t("invoices.paiddate", { locale: l }),
    format: value => `${convertTimeDay(value)}`,
  },
  {
    id: "amount",
    label: I18n.t("invoices.amount", { locale: l }),
    format: amount => `${(amount.value + 0 - amount.coupon.value).toFixed(2)} €`,
  },
];

const columns3 = [
  {
    id: "invoiceNumber",
    label: I18n.t("invoices.number", { locale: l }),
    format: value => value,
  },
  {
    id: "name",
    label: I18n.t("invoices.name", { locale: l }),
  },
  {
    id: "paidDate",
    label: I18n.t("invoices.paiddate", { locale: l }),
    format: value => `${convertTimeDay(value)}`,
  },
  {
    id: "amount",
    label: I18n.t("invoices.amount", { locale: l }),
    format: amount => `${(amount.value + 0 - 0).toFixed(2)} €`,
  },
];

export default function ListInvoices({ invoices }) {
  return (
    <>
      {invoices.length > 0 ? (
        <Table bordered striped hover responsive>
          <thead>
            <tr>
              {columns.map(column => (
                <th key={column.id}>{column.label}</th>
              ))}
              <th>{I18n.t("actions", { locale: l })}</th>
            </tr>
          </thead>
          <tbody>
            {invoices.map(invoice => {
              if (invoice.amount.coupon) {
                if (invoice.amount.VAT) {
                  return (
                    <tr key={invoice._id}>
                      {columns.map(column => {
                        const value = invoice[column.id];
                        return <td key={column.id}>{column.format ? column.format(value) : value}</td>;
                      })}
                      <td>
                        <Button href={`invoice?id=${invoice._id}`}>
                          <MdVisibility />
                        </Button>
                      </td>
                    </tr>
                  );
                } else {
                  return (
                    <tr key={invoice._id}>
                      {columns2.map(column => {
                        const value = invoice[column.id];
                        return <td key={column.id}>{column.format ? column.format(value) : value}</td>;
                      })}
                      <td>
                        <Button href={`invoice?id=${invoice._id}`}>
                          <MdVisibility />
                        </Button>
                      </td>
                    </tr>
                  );
                }
              } else {
                  if (invoice.amount.VAT) {
                  return (
                    <tr key={invoice._id}>
                      {columns1.map(column1 => {
                        const value = invoice[column1.id];
                        return <td key={column1.id}>{column1.format ? column1.format(value) : value}</td>;
                      })}
                      <td>
                        <Button href={`invoice?id=${invoice._id}`}>
                          <MdVisibility />
                        </Button>
                      </td>
                    </tr>
                  );
                    } else {
                      return (
                        <tr key={invoice._id}>
                          {columns3.map(column1 => {
                            const value = invoice[column1.id];
                            return <td key={column1.id}>{column1.format ? column1.format(value) : value}</td>;
                          })}
                          <td>
                            <Button href={`invoice?id=${invoice._id}`}>
                              <MdVisibility />
                            </Button>
                          </td>
                        </tr>
                      );
                    }
                };
            })}
          </tbody>
        </Table>
      ) : (
        <div>
          <br />
          <h5 style={{ textAlign: "center" }}> {I18n.t("invoices.noInvoices", { locale: l })}</h5>
        </div>
      )}
    </>
  );
}
