import React from "react";
import { Alert } from "react-bootstrap";

export default function SimpleSnackbar({ open, handleClose, message, variant }) {
  return (
    <Alert
      variant={variant}
      onClose={handleClose}
      show={open}
      style={{
        position: "absolute",
        minWidth: "100px",
        bottom: 0,
        right: 15,
        zIndex:10
      }}
      className="m*-auto"
      dismissible
    >
      {message}
    </Alert>
  );
}
