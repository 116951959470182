import { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import Avatar from "react-avatar";
import I18n from "../../Config/I18n";
import { GET, getUserFile } from "../../helpers/requests";
import UserAPI from "../../backend/user";
import CompanyAPI from "../../backend/company";
import { getName, getFirstname, getLastname, getEmail, getLanguage } from "../../helpers/auth";
import { languages } from "../../locales/languages";
import DataAPI from "../../backend/data";
import useQueryWithErrorToast from "src/hooks/useQueryWithErrorToast";
import { User, UserData } from "src/Type/user2";
import useMutationWithToast from "src/hooks/useMutationWithToast";
import { BsFileEarmarkPlus } from "react-icons/bs";
import { CgProfile } from "react-icons/cg";
import Loader from "../loader";
import RHF from "../reactHookForm";
import { useFormContext } from "react-hook-form";
import { UseMutationResult, useQuery } from "react-query";
import { pick } from "lodash";
import { string } from "prop-types";

const l = getLanguage();

const Profile = () => {
  const [user, setUser] = useState<User | undefined>(undefined);

  const userQuery = useQueryWithErrorToast(
    "User",
    async () => {
      const resp = await UserAPI.getInfo();
      if (resp?.error) throw new Error("Unknown error");
      return resp.data;
    },
    {
      onSuccess: (user: any) => {
        setUser({
          ...user,
          address: user.company
            ? user.company.address
            : user.address?.country
            ? user.address
            : {
                country: "BE",
              },
        });
      },
    }
  );

  const userCompanyQuery = useQueryWithErrorToast(
    ["UserCompany", (userQuery.data as any)?.companyId],
    async () => {
      const resp = await CompanyAPI.getCompanyById((userQuery.data as any)?.companyId);
      if (resp?.error) throw new Error("Unknown error");
      return resp.data;
    },
    // TODO: assign a company
    { enabled: !!(userQuery.data as any)?.companyId }
  );

  if (!user) return <div>Loading...</div>;

  return (
    <div className="flex flex-col gap-y-10">
      {/* <h3 className="my-3 text-3xl font-semibold">{I18n.t("DashboardUser.myProfile", { locale: l })}</h3> */}
      <div className="flex flex-wrap gap-x-10">
        <ProfilePicture user={user} />
        <div className="flex-1 space-y-8">
          <CurrencyFormContainer user={user} />
          <PasswordFormContainer user={user} />
        </div>
      </div>
      <ContactDetailsFormContainer setUser={setUser} user={user} />
      {/*
      <SimpleSnackbar open={snackBar.open} handleClose={handleClose} message={snackBar.message} variant={snackBar.variant} /> */}
    </div>
  );
};

const ProfilePicture: FC<{ user: any }> = ({ user }) => {
  const [profilePicture, setProfilePicture] = useState(() => getUserFile());

  const mutation = useMutationWithToast(
    "UpdateProfilePicture",
    async (e: ChangeEvent<HTMLInputElement>) => {
      const resp = await UserAPI.updatePicture({
        image: e.target.files[0],
      });
      if (resp?.error) throw new Error("Unknown error");
      return resp.data;
    },
    {
      onSuccess: () => setProfilePicture(`${getUserFile()}?${Date.now()}`),
    }
  );

  return (
    <div className="space-y-4">
      <form className="flex flex-col gap-y-2">
        <div className="relative flex items-center justify-center w-40 h-40 group">
          {user.image ? (
            <div
              className="flex items-center justify-center w-full h-full bg-center bg-no-repeat bg-contain"
              style={{ backgroundImage: `url(${profilePicture})` }}
            ></div>
          ) : (
            <Avatar
              //@ts-ignore
              color={Avatar.getRandomColor("sitebase", ["rgb(230, 126, 34)", "rgba(30,85,98,1)", "#2E2E2E"])}
              name={getName()}
              round
              size="6em"
            />
          )}
          {mutation.isLoading ? (
            <div className="absolute">
              <Loader />
            </div>
          ) : (
            <>
              <label
                htmlFor="profile-picture"
                className="absolute w-full h-full p-12 mb-0 transition-opacity ease-out opacity-0 cursor-pointer group-hover:opacity-100"
              >
                <div className="flex items-center justify-center w-full h-full rounded-full ring-1 ring-ganache-orange-500">
                  <BsFileEarmarkPlus className="w-6 h-6 text-ganache-orange-500" />
                </div>
              </label>
            </>
          )}
        </div>
        <div>
          <label
            htmlFor="profile-picture"
            className="flex items-center h-8 px-2 py-1 mb-0 transition-colors rounded-sm cursor-pointer gap-x-2 w-max bg-ganache-orange-500 text-ganache-neutral-100 hover:bg-ganache-orange-600 active:text-ganache-neutral-100 focus:ring-orange-100 focus:ring-offset-2 active:scale-95 focus:ring focus:outline-none"
          >
            <CgProfile className="w-4 h-4 text-ganache-neutral-100" />
            {I18n.t("account.uploadLogo", { locale: user.language })}
          </label>
          <input type="file" hidden id="profile-picture" name="image" disabled={mutation.isLoading} onChange={mutation.mutate} />
          <p className="text-sm italic text-gray-500">3mb max</p>
        </div>
      </form>
      <div className="space-y-1">
        <h4 className="text-lg">
          {user.name}&nbsp;{user.lastname}
        </h4>
        {user.userType && <p className="text-sm text-gray-500"> {user.userType} </p>}
      </div>
    </div>
  );
};

const currencies = [
  { value: "د.إ", label: "ED - د.إ" },
  { value: "Fr.", label: "CHF - Fr." },
  { value: "¥", label: "CNY - ¥" },
  { value: "kr", label: "DKK - kr" },
  { value: "€", label: "EUR - €" },
  { value: "£", label: "GBP - £" },
  { value: "₹", label: "INR - ₹" },
  { value: "zł", label: "PLN - zł" },
  { value: "₽", label: "RUB - ₽" },
  { value: "฿", label: "THD - ฿" },
  { value: "₺", label: "TRY - ₺" },
  { value: "$", label: "USD - $" },
    { value: "¥", label: "JPY - ¥" }
];

type Currency = { currency: "" };

const CurrencyFormContainer: FC<{ user: any }> = ({ user }) => {
  const mutation = useMutationWithToast("UpdateCurrency", async (values: Currency) => {
    delete user.rootId;
    delete user._id;
    delete user.userType;
    delete user.image;
    const newUser = { ...user, currency: values.currency };
    const resp = await UserAPI.update(newUser);
    if (resp?.error) throw new Error("Unknown error");
    return resp.data;
  });

  return (
    <RHF onSubmit={values => mutation.mutate(values)} defaultValues={{ currency: user.currency }} className="flex flex-col gap-y-3">
      <CurrencyForm user={user} mutation={mutation} />
    </RHF>
  );
};

const CurrencyForm: FC<{ user: any; mutation: UseMutationResult }> = ({ user, mutation }) => {
  return (
    <>
      <div className="flex items-center justify-between p-2 border-b bg-gray-50">
        <label className="mb-0 text-lg font-semibold capitalize" htmlFor="currency-select">
          {I18n.t("account.currency", { locale: user.language })}
        </label>
      </div>
      <div className="flex">
        <div className="sm:w-1/2">
          <RHF.SelectOrange name="currency" disabled={mutation.isLoading}>
            {currencies.map(currency => (
              <option key={currency.value} value={currency.value}>
                {currency.label}
              </option>
            ))}
          </RHF.SelectOrange>
        </div>
        <div className="flex justify-end sm:w-1/2">
          <RHF.InputOrange value={I18n.t("save", { locale: user.language })} type="submit" />
        </div>
      </div>
    </>
  );
};

const PasswordFormContainer: FC<{ user: any }> = ({ user }) => {
  const mutation = useMutationWithToast("UpdatePassword", async (data: PasswordFormValues) => {
    const resp = await UserAPI.update({ password: data.confirmationPassword });
    if (resp?.error) throw new Error("Unknown error");
    return resp.data;
  });

  return (
    <RHF
      onSubmit={values => mutation.mutate(values)}
      resetValues
      className="flex flex-col gap-y-3"
      defaultValues={{ password: "", confirmationPassword: "" }}
    >
      <PasswordForm user={user} />
    </RHF>
  );
};

type PasswordFormValues = { password: ""; confirmationPassword: "" };

const PasswordForm: FC<{ user: any }> = ({ user }) => {
  const { getValues } = useFormContext();
  return (
    <>
      <div className="flex items-center justify-between p-2 border-b bg-gray-50">
        <label className="mb-0 text-lg font-semibold capitalize" htmlFor="password">
          {I18n.t("account.password", { locale: user.language })}
        </label>
      </div>
      <div className="flex gap-x-3">
        <div className="flex flex-col sm:w-1/2 gap-y-3">
          <RHF.InputOrange
            type="password"
            autoComplete="new-password"
            name="password"
            placeholder={I18n.t("user.password", { locale: user.language })}
            options={{ required: true, minLength: 6 }}
          />
          <RHF.InputOrange
            type="password"
            autoComplete="new-password"
            name="confirmationPassword"
            placeholder={I18n.t("account.confirmPassword", { locale: user.language })}
            options={{ required: true, minLength: 6, validate: pwd => pwd === getValues("password") }}
          />
        </div>
        <div className="flex justify-end sm:w-1/2">
          <RHF.InputOrange value={I18n.t("save", { locale: user.language })} type="submit" />
        </div>
      </div>
      <RHF.InputOrange type="text" autoComplete="username" name="username1" className="hidden" />
    </>
  );
};

// FIXME: Changing the language doesn't change the language across the whole app but only in the current component (I think). We should redo the i18n configuration to fix this.
const ContactDetailsFormContainer: FC<{ setUser: Dispatch<SetStateAction<User>>; user: any }> = ({ setUser, user }) => {
  const mutation = useMutationWithToast(
    "UpdateAddress",
    async (values: any) => {
      delete user.rootId;
      delete user._id;
      delete user.userType;
      delete user.image;
      const newAddress = { ...pick(values, "street", "country", "city"), zipCode: values.postalCode };
      const newCompany = { name: values.companyName, VAT: values.vatNumber, address: newAddress };
      const newUser = {
        ...user,
        ...pick(values, "language", "email"),
        address: newAddress,
        company: newCompany,
        name: values.firstName,
        lastname: values.lastName,
        phone: values.phoneNumber,
      };

      if (!values.companyName) delete newUser.company;

      const resp = await UserAPI.update(newUser);
      if (resp?.error) throw new Error("Unknown error");
      return resp.data;
    },
    {
      onSuccess: async () =>
        // There is a timeout so the user has enough time to see the toast which pops up when the profile is saved.
        await setTimeout(() => {
          window.location.reload();
        }, 500),
    }
  );

  return (
    <RHF
      onSubmit={values => mutation.mutate(values)}
      defaultValues={{
        firstName: user.name || "",
        lastName: user.lastname || "",
        phoneNumber: user?.phone || "",
        email: user?.email || "",
        language: user?.language || "",
        country: user?.address?.country || "",
        street: user?.address?.street || "",
        city: user?.address?.city || "",
        postalCode: user?.address?.zipCode || "",
        companyName: user?.company?.name || "",
        vatNumber: user?.company?.VAT || "",
      }}
      className="flex flex-col gap-y-3"
    >
      <ContactDetailsForm user={user} setUser={setUser} />
    </RHF>
  );
};

const ContactDetailsForm: FC<{ setUser: Dispatch<SetStateAction<User>>; user: any }> = ({ setUser, user }) => {
  const { watch, getValues, setValue, reset } = useFormContext();

  const countriesQuery = useQuery(
    "Countries",
    async () => {
      const resp = await GET(`/data/countries/${user.language || getLanguage()}`);
      if (resp?.error) throw new Error("Unknown error");
      return resp.data;
    },
    {
      onSuccess: data => {
        reset({ ...getValues(), country: user?.address?.country });
      },
    }
  );

  const countries = countriesQuery.data;

  return (
    <>
      <div className="flex items-center justify-between p-2 border-b bg-gray-50">
        <label className="mb-0 text-lg font-semibold capitalize" htmlFor="firstName">
          {I18n.t("account.generalinfo", { locale: user.language })}
        </label>
      </div>
      <RHF.InputOrange autoComplete="username" name="username2" className="hidden" />
      <div className="grid grid-cols-9 gap-4">
        <div className="col-span-3">
          <RHF.InputOrange
            autoComplete="given-name"
            name="firstName"
            label={I18n.t("user.firstnameUC", { locale: user.language })}
            placeholder={I18n.t("user.firstnameUC", { locale: user.language })}
            autoCapitalize="words"
            options={{ required: true }}
          />
        </div>
        <div className="col-span-3">
          <RHF.InputOrange
            autoCapitalize="words"
            autoComplete="family-name"
            name="lastName"
            label={I18n.t("user.lastnameUC", { locale: user.language })}
            placeholder={I18n.t("user.lastnameUC", { locale: user.language })}
            options={{ required: true }}
          />
        </div>
        <div className="col-span-3">
          <RHF.InputOrange
            autoComplete="tel-country-code"
            name="phoneNumber"
            label={I18n.t("user.phoneNumber", { locale: user.language })}
            placeholder={I18n.t("user.phoneNumber", { locale: user.language })}
          />
        </div>
        <div className="col-span-5">
          <RHF.InputOrange
            autoComplete="email"
            name="email"
            label={I18n.t("user.email", { locale: user.language })}
            placeholder={I18n.t("user.email", { locale: user.language })}
            options={{
              required: true,
              validate: email => /^[a-zA-Z0-9.!#$%&’*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email),
            }}
          />
        </div>
        <div className="col-span-2">
          <RHF.SelectOrange
            autoComplete="language"
            name="language"
            label={I18n.t("user.language", { locale: user.language })}
            placeholder={I18n.t("user.language", { locale: user.language })}
            customOnChange={e => setUser({ ...user, language: e.target.value })}
            options={{ required: true }}
          >
            {Object.keys(languages).map(slug => {
              return (
                <option key={slug} value={slug}>
                  {languages[slug]}
                </option>
              );
            })}
          </RHF.SelectOrange>
        </div>
        {/*
          <>
            <div className="col-span-2">
              <RHF.SelectOrange
                autoComplete="country"
                name="country"
                label={I18n.t("user.country", { locale: user.language })}
                placeholder={I18n.t("user.country", { locale: user.language })}
                options={{ required: true }}
              >
                {countries?.map((country, index) => {
                  return (
                    <option key={index} value={country.countryCode}>
                      {country.name}
                    </option>
                  );
                })}
              </RHF.SelectOrange>
            </div>
            <div className="col-span-4">
              <RHF.InputOrange
                autoComplete="address-line1"
                name="street"
                label={I18n.t("user.street", { locale: user.language })}
                placeholder={I18n.t("user.street", { locale: user.language })}
              />
            </div>
            <div className="col-span-3">
              <RHF.InputOrange
                autoComplete="address-level2"
                name="city"
                label={I18n.t("user.city", { locale: user.language })}
                placeholder={I18n.t("user.city", { locale: user.language })}
              />
            </div>
            <div className="col-span-2">
              <RHF.InputOrange
                autoComplete="postal-code"
                name="postalCode"
                label={I18n.t("user.zipCode", { locale: user.language })}
                placeholder={I18n.t("user.zipCode", { locale: user.language })}
              />
            </div>
            <div className="grid grid-cols-9 col-span-9 pt-2 border-t gap-x-4">
              <div className="col-span-6">
                <RHF.InputOrange
                  autoComplete="organization"
                  name="companyName"
                  label={I18n.t("user.companyName", { locale: user.language })}
                  placeholder={I18n.t("user.companyName", { locale: user.language })}
                />
              </div>
              <div className="col-span-3">
                <RHF.InputNumberFormatOrange
                  name="vatNumber"
                  allowLeadingZeros
                  onChangeFormat="formattedValue"
                  prefix={watch("country")}
                  label={I18n.t("user.vat", { locale: user.language })}
                  placeholder={I18n.t("user.vat", { locale: user.language })}
                  options={{
                    validate: async vatNumber => {
                      if (vatNumber) {
                        const resp = await DataAPI.checkVAT(vatNumber);
                        if (resp.valid && resp.eu) {
                          setValue("companyName", resp.name);
                          return true;
                        }
                        setValue("companyName", "");
                        return false;
                      }
                      return true;
                    },
                  }}
                  extra={
                    <a
                      className="text-sm text-gray-400 underline hover:text-ganache-orange-400"
                      href="https://ec.europa.eu/taxation_customs/vies/faqvies.do#item_11"
                      rel="noreferrer"
                      target="_blank"
                    >
                      {I18n.t("user.VATEUFormat", { locale: user.language })}
                    </a>
                  }
                  error={I18n.t("user.VATNotEU", { locale: user.language })}
                />
              </div>
            </div>
          </>
                */}
      </div>
      <div className="flex justify-end">
        <RHF.InputOrange value={I18n.t("save", { locale: user.language })} type="submit" />
      </div>
    </>
  );
};

// const CoordinatesForm: FC = () => {
//   const [countries, setCountries] = useState([]);

//   useEffect(() => {
//     loadCountries();
//   }, []);

//   const loadCountries = async (lang = user.language || getLanguage()) => {
//     const countriesData = await GET(`/data/countries/${lang}`);
//     if (countriesData.error) {
//       return console.error(countriesData.error.message);
//     }
//     setCountries(countriesData.data);
//   };

//   const handleChangeLanguage = event => {
//     const language = event.target.value;
//     setUser({
//       ...user,
//       language,
//     });
//     loadCountries(language);
//     setReload(true);
//   };

//   return (
//     <Card id="userInfo">
//       <Card.Header>
//         <Card.Title className="my-auto">{I18n.t("account.generalinfo", { locale: user.language })}</Card.Title>
//       </Card.Header>
//       <Card.Body>
//         <Form id="updateUserInfo" onSubmit={handleUpdate}>
//           <Form.Row>
//             <Form.Group as={Col}>
//               <Form.Label htmlFor="firstname">{I18n.t("user.firstnameUC", { locale: user.language })}</Form.Label>
//               <Form.Control
//                 type="text"
//                 value={user.name}
//                 id="firstname"
//                 name="name"
//                 placeholder={I18n.t("user.firstnameUC", { locale: user.language })}
//                 onChange={handleChange}
//                 required
//                 autoComplete="given-name"
//                 autoCapitalize="words"
//               />
//             </Form.Group>

//             <Form.Group as={Col}>
//               <Form.Label htmlFor="lastname">{I18n.t("user.lastnameUC", { locale: user.language })}</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder={I18n.t("user.lastnameUC", { locale: user.language })}
//                 id="lastname"
//                 name="lastname"
//                 value={user.lastname}
//                 onChange={handleChange}
//                 required
//                 autoComplete="family-name"
//                 autoCapitalize="words"
//               />
//             </Form.Group>

//             <Form.Group as={Col}>
//               <Form.Label htmlFor="phone">{I18n.t("user.phoneNumber", { locale: user.language })}</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder={I18n.t("user.phoneNumber", { locale: user.language })}
//                 id="phone"
//                 name="phone"
//                 value={user.phone}
//                 onChange={handleChange}
//                 autoComplete="tel-country-code"
//               />
//             </Form.Group>
//           </Form.Row>
//           <Form.Row>
//             <Form.Group as={Col} md={6}>
//               <Form.Label htmlFor="inputEmail4">{I18n.t("user.email", { locale: user.language })}</Form.Label>
//               <Form.Control
//                 type="email"
//                 placeholder={I18n.t("user.email", { locale: user.language })}
//                 id="inputEmail4"
//                 name="email"
//                 value={user.email}
//                 onChange={handleChange}
//                 required
//                 autoComplete="email"
//               />
//             </Form.Group>

//             <Form.Group as={Col}>
//               <Form.Label>{I18n.t("user.language", { locale: user.language })}</Form.Label>
//               <Form.Control as="select" value={user.language} onChange={handleChangeLanguage}>
//                 {Object.keys(languages).map(slug => {
//                   return (
//                     <option key={slug} value={slug}>
//                       {languages[slug]}
//                     </option>
//                   );
//                 })}
//               </Form.Control>
//             </Form.Group>

//             <Form.Group as={Col}>
//               <Form.Label>{I18n.t("user.country", { locale: user.language })}</Form.Label>
//               <Form.Control
//                 as="select"
//                 id="inputState"
//                 onChange={handleChangeAddress}
//                 value={user.address.country}
//                 name="country"
//                 autoComplete="country"
//               >
//                 {/* user.address
//                             ? <option defaultValue>{user.address.country}</option>
//                           : <option defaultValue>{I18n.t('selectLanguage', { locale: user.language })}</option> */}
//                 {countries.map((country, index) => {
//                   return (
//                     <option key={index} value={country.countryCode}>
//                       {country.name}
//                     </option>
//                   );
//                 })}
//               </Form.Control>
//             </Form.Group>
//           </Form.Row>

//           <Form.Row>
//             <Form.Group as={Col} md={5}>
//               <Form.Label htmlFor="inputStreet">{I18n.t("user.street", { locale: user.language })}</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder={I18n.t("user.street", { locale: user.language })}
//                 id="inputStreet"
//                 value={user.address ? user.address.street : ""}
//                 onChange={handleChangeAddress}
//                 name="street"
//                 autoComplete="street-address"
//               />
//             </Form.Group>
//             <Form.Group as={Col}>
//               <Form.Label htmlFor="inputCity">{I18n.t("user.city", { locale: user.language })}</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder={I18n.t("user.city", { locale: user.language })}
//                 id="inputCity"
//                 value={user.address ? user.address.city : ""}
//                 onChange={handleChangeAddress}
//                 name="city"
//               />
//             </Form.Group>
//             <Form.Group as={Col} md={2}>
//               <Form.Label htmlFor="inputZip">{I18n.t("user.zipCode", { locale: user.language })}</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder={I18n.t("user.zipCode", { locale: user.language })}
//                 id="inputZip"
//                 value={user.address ? user.address.zipCode : ""}
//                 onChange={handleChangeAddress}
//                 name="zipCode"
//                 autoComplete="postal-code"
//               />
//             </Form.Group>
//           </Form.Row>
//           <hr />
//           <Form.Row>
//             <Form.Group as={Col} md={8}>
//               <Form.Label htmlFor="inputCompanyName">{I18n.t("user.companyName", { locale: user.language })}</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder={I18n.t("user.companyName", { locale: user.language })}
//                 id="inputCompanyName"
//                 value={company.name ? company.name : ""}
//                 onChange={handleChangeCompany}
//                 name="name"
//                 autoComplete="organization"
//               />
//             </Form.Group>
//             <Form.Group as={Col}>
//               <Form.Label htmlFor="inputCompanyVAT">
//                 {I18n.t("user.vat", { locale: l })}
//                 {<Spinner animation="border" variant="primary" hidden={!isCheckingVAT} />}
//               </Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder={I18n.t("user.vat", { locale: user.language })}
//                 id="inputCompanyVAT"
//                 value={company.VAT ? company.VAT : ""}
//                 onChange={handleChangeCompany}
//                 name="VAT"
//                 onBlur={checkNewVAT}
//                 isValid={valid.companyVat}
//                 isInvalid={!valid.companyVat}
//               />
//               <Form.Text id="passwordHelpBlock" muted>
//                 <a
//                   href="https://ec.europa.eu/taxation_customs/vies/faqvies.do#item_11"
//                   // eslint-disable-next-line react/jsx-no-target-blank
//                   target="_blank"
//                 >
//                   {I18n.t("user.VATEUFormat", { locale: l })}
//                 </a>
//               </Form.Text>
//               <Form.Control.Feedback type="invalid">{I18n.t("user.VATNotEU", { locale: l })}</Form.Control.Feedback>
//             </Form.Group>
//           </Form.Row>
//           <div className="text-right">
//             <Button type="submit" className="button-submit">
//               {I18n.t("save", { locale: user.language })}
//             </Button>
//           </div>
//         </Form>
//       </Card.Body>
//     </Card>
//   );
// };

export default Profile;
