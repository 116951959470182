import moment from "moment";
import I18n from "../../../Config/I18n";
import { getLanguage } from "../../../helpers/auth";
import { Badge, Table } from "react-bootstrap";
import GanacheFormat from "src/utils/ganacheFormat";
const l = getLanguage();

export default function TablePreviewRecipeInfo({
  recipe,
  arrayFitforInRecipe,
  arrayContentInRecipe,
  statut,
  category,
  checked,
  foodCost,
  allFitFors,
}) {
  return (
    <Table bordered striped aria-label="customized table">
      <thead>
        <tr>
          <th colSpan={2}>{I18n.t("recipeObject.recipeInfo", { locale: l })}</th>
        </tr>
      </thead>
      <tbody>
        {checked.name ? (
          <tr>
            <td>{I18n.t("print.category.nameRecipe", { locale: l })}</td>
            <td align="left">{recipe.name}</td>
          </tr>
        ) : null}

        {checked.owner ? (
          <tr>
            <td>{I18n.t("print.category.owner", { locale: l })}</td>
            <td align="left">{recipe.owner}</td>
          </tr>
        ) : null}

        {checked.lastUpdateOn ? (
          <tr>
            <td>{I18n.t("print.category.lastUpdate", { locale: l })}</td>
            <td align="left">{moment(recipe.lastUpdateOn).format("DD MMM YYYY")}</td>
          </tr>
        ) : null}

        {checked.categories && category ? (
          <tr>
            <td>{I18n.t("print.category.category", { locale: l })}</td>
            <td align="left">{category}</td>
          </tr>
        ) : null}

        {checked.status && statut ? (
          <tr>
            <td>{I18n.t("print.category.status", { locale: l })}</td>
            <td align="left">
              {" "}
              <Badge variant={statut.color}>{statut.name}</Badge>
            </td>
          </tr>
        ) : null}

        {checked.contents && arrayContentInRecipe ? (
          <tr>
            <td>{I18n.t("print.category.contents", { locale: l })}</td>
            <td align="left">
              {arrayContentInRecipe.map(content => (
                <Badge key={content._id} variant="warning">
                  {content.name}
                </Badge>
              ))}
            </td>
          </tr>
        ) : null}

        {checked.fitfor ? (
          <tr>
            <td>{I18n.t("print.category.fitfor", { locale: l })}</td>
            <td align="left">
              {allFitFors.map((fitfor, index) => {
                return (
                  <Badge key={fitfor._id} variant={arrayFitforInRecipe.includes(fitfor) ? "success" : "danger"}>
                    {fitfor.name}
                  </Badge>
                );
              })}
            </td>
          </tr>
        ) : null}

        {checked.note ? (
          <tr>
            <td>{I18n.t("print.category.note", { locale: l })}</td>
            <td>
              <div dangerouslySetInnerHTML={{ __html: recipe.note }}></div>
            </td>
          </tr>
        ) : null}

        {checked.source ? (
          <tr>
            <td>{I18n.t("print.category.source", { locale: l })}</td>
            <td>{recipe.source}</td>
          </tr>
        ) : null}

        {checked.price ? (
          <tr>
            <td>{I18n.t("print.category.price", { locale: l })}</td>
            <td>{`${GanacheFormat.price(foodCost)}/kg`}</td>
          </tr>
        ) : null}
      </tbody>
    </Table>
  );
}
