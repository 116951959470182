import { GET, POST } from "../helpers/requests";

const getCompanyByName = async name => {
  try {
    return await GET(`/company/name/${name}`);
  } catch (err) {
    console.error(err);
  }
};

const getCompanyByVAT = async VAT => {
  try {
    return await GET(`/company/VAT/${VAT}`);
  } catch (err) {
    console.error(err);
  }
};

const getCompanyById = async id => {
  try {
    return await GET(`/company/id/${id}`);
  } catch (err) {
    console.error(err);
  }
};

const update = async (data, id) => {
  try {
    const compDB = await POST(`/company/update/${id}`, data);
    return compDB;
  } catch (err) {
    console.error(err);
  }
};

const insert = async data => {
  try {
    const inserted = await POST("/company/add", data);
    return inserted;
  } catch (err) {
    console.error(err);
  }
};

const matchUser = async data => {
  try {
    const match = await POST("/company/matchUser", data);
    return match;
  } catch (err) {
    console.error(err);
  }
};

export default {
  getCompanyByName,
  getCompanyByVAT,
  getCompanyById,
  update,
  insert,
  matchUser,
};
