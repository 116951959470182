import { languages, defaultLanguage } from "../locales/languages";
import jwt_decode from "jwt-decode";
const authTokenName = "auth-token";

export const Roles = {
  visitor: "visitor",
  demo: "demo",
  user: "user",
  translator: "translator",
  administrator: "administrator",
};

/***********************************************/

/***************************
 ****    Login/Logout   ****
 ***************************/

export const logout = () => {
  deleteCookie(authTokenName);
  window.location.reload();
};

export const isLogged = () => {
  const token = getCookie(authTokenName);
  if (token) {
    return true;
  }
  return false;
};

/***********************
 **       Cookies      **
 ***********************/

export const getCookie = cname => {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const getToken = name => {
  const token = getCookie(name);
  try {
    const decoded = jwt_decode(token);
    return decoded;
  } catch (err) {
    return null;
  }
};

export const setCookie = (cname, cvalue, exdays) => {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

export const deleteCookie = name => {
  var cook = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  const url = window.location.hostname;
  if (url !== "localhost") {
    const index = url.indexOf(".");
    const mainDomain = url.substring(index);
    cook += `domain=${mainDomain};`;
  }
  document.cookie = cook;
};

export const isTokenExpired = token => {
  try {
    const decoded = jwt_decode(token);
    if (decoded.iat < Date.now() / 1000) {
      // Checking if token is expired.
      return true;
    } else return false;
  } catch (err) {
    console.error("expired check failed!");
    return false;
  }
};

/***********************
 **  get Data on user **
 *****    cookies   *****
 ***********************/

export const getUser = () => {
  const token = getCookie(authTokenName);
  if (!token) {
    return null;
  }
  const user = jwt_decode(token);
  return user;
};
/** @return {String} */
export const getId = () => {
  const user = getUser();
  if (!user) return "";
  return user._id;
};
/** @return {String} */
export const getRoot = () => {
  const user = getUser();
  if (!user) return "";
  return user.rootId || undefined;
};
/** @return {String} */
export const getRole = () => {
  const user = getUser();
  if (!user) {
    return Roles.visitor;
  }
  // user.userType = 'user';
  return user.userType;
};

/** @return {String} */
export const getLanguage = () => {
  const user = getUser(true);
  if (!user) {
    const lang = navigator.language || navigator.userLanguage;
    return Object.keys(languages).includes(lang) ? lang : defaultLanguage;
  }
  return user.language || defaultLanguage;
};
/** @return {String} */
export const getFirstname = () => {
  const user = getUser();
  if (!user) return undefined;
  return user.name || Roles.visitor;
};
/** @return {String} */
export const getLastname = () => {
  const user = getUser();
  if (!user) return undefined;
  return user.lastname || Roles.visitor;
};
/** @return {String} */
export const getName = () => {
  const user = getUser();
  if (!user) return Roles.visitor;
  return `${user.name} ${user.lastname}`;
};
/** @return {String} */
export const getEmail = () => {
  const user = getUser();
  if (!user) return "";
  return user.email || "";
};
/** @return {String} */
export const getCurrency = () => {
  const user = getUser();
  if (!user) return "€";
  return user.currency || "€";
};
/** @return {Boolean} */
export const canRead = () => {
  const user = getUser();
  if (!user) {
    return false;
  }
  if (!user.canRead) {
    return false;
  }
  return user.canRead;
};
/** @return {Boolean} */
export const canWrite = () => {
  const user = getUser();
  // user.canWrite = false;
  if (!user) {
    return false;
  }
  if (!user.canWrite) {
    return false;
  }
  return user.canWrite;
};


/** ************************* OLD WAY ***********************************/

/* Lors de la connexion à la plateforme, on va :
 *   1) Stocker dans le localStorage les données de 'user ' sous forme d'objet
 *       Id / Lastname / Firstname / email / Role
 *   2) Stocker un cookie avec le token
 *
 */

// export const login = (token) => {
//   setToken(token);
// }
//
// export const isLogged = () => {
//   // Checks if there is a saved token and it's still valid
//   const token = this.getToken(); // Getting token from localstorage
//   return !!token && !this.isTokenExpired(token); // handwaiving here
// };

// export const isLogin = () => {
//   if (localStorage.getItem('user')) {
//     return true;
//   }
//   return false;
// }

// export const setToken = token => {
//   // Saves user token to localStorage
//   localStorage.setItem("auth-token", token);
// };
//
// export const getToken = () => {
//   // Retrieves the user token from localStorage
//   return localStorage.getItem("auth-token");
// };

/***********************
 **  get Data on user **
 ***** localStorage *****
 ***********************/
/*
export const getUserId = () => {
  if (JSON.parse(localStorage.getItem('user')) === undefined || JSON.parse(localStorage.getItem('user')) === null) {
    return false
  }
  return JSON.parse(localStorage.getItem('user'))._id
}

export const getUserRoot = () => {
  if (JSON.parse(localStorage.getItem('user')) === undefined || JSON.parse(localStorage.getItem('user')) === null) {
    return false
  }
  return JSON.parse(localStorage.getItem('user')).rootId
}

export const getUserRole = () => {
  if (JSON.parse(localStorage.getItem('user')) === undefined || JSON.parse(localStorage.getItem('user')) === null) {
    return 'visitor'
  }
  return JSON.parse(localStorage.getItem('user')).userType
}

export const getUserLanguage = () => {
  if (JSON.parse(localStorage.getItem('user')) === undefined || JSON.parse(localStorage.getItem('user')) === null) {
    return 'EN' // default language
  }
  return JSON.parse(localStorage.getItem('user')).language
}

export const getName = () => {
  if (JSON.parse(localStorage.getItem('user')) === undefined || JSON.parse(localStorage.getItem('user')) === null) {
    return 'visitor'
  }
  var firstname = JSON.parse(localStorage.getItem('user')).name
  var lastname = JSON.parse(localStorage.getItem('user')).lastname
  var name = firstname.concat(' ', lastname)
  return name
}

export const getUserFirstname = () => {
  if (JSON.parse(localStorage.getItem('user')) === undefined || JSON.parse(localStorage.getItem('user')) === null) {
    return 'visitor'
  }
  var firstname = JSON.parse(localStorage.getItem('user')).name
  return firstname
}

export const getUserLastname = () => {
  if (JSON.parse(localStorage.getItem('user')) === undefined || JSON.parse(localStorage.getItem('user')) === null) {
    return 'visitor'
  }
  var lastname = JSON.parse(localStorage.getItem('user')).lastname
  return lastname
}

export const getUserEmail = () => {
  if (JSON.parse(localStorage.getItem('user')) === undefined || JSON.parse(localStorage.getItem('user')) === null) {
    return 'visitor'
  }
  var email = JSON.parse(localStorage.getItem('user')).email
  return email
}
*/
