import { GET, POST, POSTFile } from "../helpers/requests";
import { validImage } from "./tools";

/**
 *
 * @param {String} id
 * @return {{
 * data:{
 *  _id : String,
 *  name: String,
 *  owner: String,
 *  statusId: String,
 *  categoryId: String,
 *  ingredients:[{
 *    quantity: Number,
 *    coefficient: Number,
 *    temperature: Number,
 *    ingredient:{}
 *  }],
 *  picture:String,
 *  lastUpdateOn: Date,
 *  note: String,
 *  procedure:String,
 * message: String
 * },
 * error:{
 *  notFound: Boolean
 * }
 * }}
 */
const getById = async id => {
  const recipe = await GET(`/recipe/${id}`);
  if (recipe.error) {
    recipe.error.notFound = true;
  }
  return recipe;
};

const getSummaryById = async id => {
  const recipe = await GET(`/recipe/summary/${id}`);
  if (recipe.error) {
    recipe.error.notFound = true;
  }
  return recipe;
};

const getSummaryByIds = async ids => {
  const recipe = await POST(`/recipe/summary`, { ids });
  if (recipe.error) {
    recipe.error.notFound = true;
  }
  return recipe.data;
};

/**
 *
 * @param {{
 * _id: (String | undefined),
 * folderId: (String | undefined),
 * name: String,
 * statusId: String,
 * categoryId: String,
 * note: String,
 * procedure: String,
 * ingredients: {},
 * image: String
 * }} data
 * @return {{
 *  data: import('../Type/recipe').Recipe,
 *  error:{
 *    notFound: Boolean,
 *    notEditable: Boolean
 *  }
 * }}
 */
const save = async data => {
  const resp = await POST("/recipe", data);
  if (resp.error) {
    switch (resp.data.message) {
      case "notFound":
        resp.error.notFound = true;
        break;
      case "editable":
        resp.error.notEditable = true;
        break;
      default:
    }
  }
  return resp;
};

const updatePicture = async data => {
  if (data.image) {
    const validation = validImage(data.image);
    if (Object.keys(validation).find(key => validation[key])) {
      return {
        error: { ...validation, message: "wrong image" },
      };
    }
  }
  const resp = await POSTFile("/recipe", data);
  return resp;
};

/**
 *
 * @param {{
 * recipeId: String,
 * folderId: String
 * }} data
 */
const duplicate = async data => {
  const resp = await POST("/recipe/duplicate", data);
  return resp;
};

/**
 *
 * @param {String} id
 */
const getRecipesByIngredient = async id => {
  const resp = await GET(`/recipe/ingredient/${id}`);
  return resp;
};

const getAllFitFors = async () => {
  const resp = await GET("/ingredient/fitfor/");
  if (resp.error) {
    console.error("error", resp.error);
    return [];
  }
  return resp.data;
};

const getAllContents = async () => {
  const resp = await GET("/ingredient/content/");
  if (resp.error) {
    console.error("error", resp.error);
    return [];
  }
  return resp.data;
};

const addIngredient = async body => {
  const resp = await POST("/recipe/ingredient", body);
  if (resp.error) {
    console.error("error", resp.error);
    return null;
  }
  return resp.data;
};

/**
 *
 * @param {number} temperature
 * @param {{
 *  quantity: number,
 *  composition:{}
 * }[]} elements
 * @returns
 */
const computeEvaporation = async (temperature = 0, elements = []) => {
  const body = {
    temperature,
    elements,
  };
  const resp = await POST("/ingredient/evaporation", body);
  if (resp.error) {
    console.error("error", resp.error);
    return null;
  }
  return resp.data;
};

const dismissFlag = async (id, flag) => {
  const body = {
    id,
    flag,
  };

  const resp = await POST("/recipe/dismissflag", body);
  if (resp.error) {
    console.error("error", resp.error);
    return null;
  }
  return resp.data;
};

export const RecipeController = {
  getById,
  getSummaryById,
  getSummaryByIds,
  save,
  updatePicture,
  duplicate,
  getRecipesByIngredient,
  getAllFitFors,
  getAllContents,
  addIngredient,
  computeEvaporation,
  dismissFlag,
};

export default {
  getById,
  save,
  updatePicture,
  duplicate,
  getRecipesByIngredient,
  getAllFitFors,
  getAllContents,
  addIngredient,
  computeEvaporation,
  dismissFlag,
};
