import { FC, MouseEventHandler } from "react";

export const ButtonOutlineIconBrown: FC<{ hidden?: boolean; onClick: MouseEventHandler<HTMLButtonElement>; isActive?: boolean }> = ({
  isActive = false,
  children,
  ...props
}) => {
  return (
    <button
      className={`px-2 py-2 transition-colors rounded-sm bg-transparant  h-max w-max ring-1 ring-ganache-brown-500 focus:outline-none ${
        isActive
          ? "bg-ganache-brown-500 text-ganache-neutral-100"
          : "hover:bg-ganache-brown-500 text-ganache-brown-500 hover:text-ganache-neutral-100"
      }`}
      {...props}
    >
      {children}
    </button>
  );
};

export const ButtonOutlineIconOrange: FC<{ hidden: boolean; onClick: MouseEventHandler<HTMLButtonElement>; isActive?: boolean }> = ({
  isActive = false,
  children,
  ...props
}) => {
  return (
    <button
      className={`px-2 py-2 transition-colors rounded-sm bg-transparant  h-max w-max ring-1 ring-ganache-orange-500 focus:outline-none ${
        isActive
          ? "bg-ganache-orange-500 text-ganache-neutral-100"
          : "hover:bg-ganache-orange-500 text-ganache-orange-500 hover:text-ganache-neutral-100"
      }`}
      {...props}
    >
      {children}
    </button>
  );
};

export const ButtonIconOrange: FC<{ hidden: boolean; onClick: MouseEventHandler<HTMLButtonElement> }> = ({ children, ...props }) => {
  return (
    <button
      className="px-2 py-2 transition-colors rounded-sm bg-transparant h-max w-max focus:outline-none bg-ganache-orange-500 text-ganache-neutral-100 hover:bg-ganache-orange-600 active:text-ganache-neutral-100"
      {...props}
    >
      {children}
    </button>
  );
};

export const ButtonIconBrown: FC<{ hidden: boolean; onClick: MouseEventHandler<HTMLButtonElement> }> = ({ children, ...props }) => {
  return (
    <button
      className="px-2 py-2 transition-colors rounded-sm bg-transparant h-max w-max focus:outline-none bg-ganache-brown-500 text-ganache-neutral-100 hover:bg-ganache-brown-600 active:text-ganache-neutral-100"
      {...props}
    >
      {children}
    </button>
  );
};
