import { ComponentPropsWithoutRef, FC, FormEvent, ReactNode, useEffect, useState } from "react";
import { get } from "lodash";
import { Controller, FormProvider, RegisterOptions, SubmitHandler, useForm, useFormContext } from "react-hook-form";
import { UseMutationResult, UseQueryResult } from "react-query";
import NumberFormat, { NumberFormatValues, NumberFormatProps } from "react-number-format";

interface FormProps {
  resetValues?: boolean;
  onClose?: () => void;
  onSubmitSuccess?: () => void;
  onSubmit: (values: any) => void;
  defaultValues?: any;
  isModal?: boolean;
  mode?: "onChange" | "onBlur";
}

const RHF: FC<FormProps & ComponentPropsWithoutRef<"form">> & {
  InputOrange: typeof RHFInputOrange;
  SelectOrange: typeof RHFSelectOrange;
  // InputFileOrange: typeof RHFInputFileOrange;
  InputNumberFormatOrange: typeof RHFInputNumberFormatOrange;
} = ({ mode = "onBlur", resetValues = false, onClose, onSubmit, onSubmitSuccess, defaultValues, children, isModal = false, ...props }) => {
  const methods = useForm({ mode, defaultValues });

  // Modal purpose
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);

  const handleSubmit: SubmitHandler<FormEvent<HTMLFormElement>> = async values => {
    try {
      setIsSubmitting(true);
      setIsSubmitSuccessful(false);
      await onSubmit(values);
      setIsSubmitSuccessful(true);
    } catch (error) {
      console.error(error);
      return;
    } finally {
      if (isModal && onClose) onClose();
      if (onSubmitSuccess) onSubmitSuccess();
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful && resetValues) methods.reset(defaultValues);
  }, [isSubmitSuccessful, defaultValues, methods, resetValues]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)} {...props}>
        {children}
      </form>
    </FormProvider>
  );
};

export const RHFSelectOrange: FC<
  {
    customOnChange?: (e) => void;
    label?: string;
    extra?: ReactNode;
    query?: UseQueryResult;
    options?: RegisterOptions;
  } & ComponentPropsWithoutRef<"select">
> = ({ customOnChange, label, extra, query, options, children, ...props }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const error = get(errors, props.name);

  const selectField = register(props.name, options);

  return (
    <div className="w-full">
      {label && (
        <label className="mb-1 text-gray-600" htmlFor={props.name}>
          {label}
        </label>
      )}
      <select
        id={props.name}
        disabled={query?.isError}
        className="w-full h-8 px-2 placeholder-gray-400 border border-gray-500 rounded-sm focus:outline-none focus:border-ganache-orange-200 focus:ring focus:ring-orange-100"
        {...selectField}
        onChange={e => {
          selectField.onChange(e);
          customOnChange && customOnChange(e);
        }}
        {...props}
      >
        {children}
      </select>
      {extra && extra}
    </div>
  );
};
RHF.SelectOrange = RHFSelectOrange;

export const RHFInputOrange: FC<
  {
    label?: string;
    error?: string;
    extra?: ReactNode;
    query?: UseQueryResult | UseMutationResult;
    options?: RegisterOptions;
  } & ComponentPropsWithoutRef<"input">
> = ({ query, error, type = "text", options, label, children, extra, ...props }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const isError = get(errors, props.name);

  if (type === "submit")
    return (
      <input
        id={props.name}
        type={"submit"}
        disabled={query?.isLoading}
        className={`px-2 py-1 transition-colors w-max bg-ganache-orange-500 text-ganache-neutral-100 hover:bg-ganache-orange-600 active:text-ganache-neutral-100 focus:ring-orange-100 focus:ring-offset-2 active:scale-95 h-8 focus:ring rounded-sm focus:outline-none ${
          type === "number" && "text-right"
        }`}
        {...props}
      />
    );

  return (
    <div className="w-full">
      {label && (
        <label className="mb-1 text-gray-600" htmlFor={props.name}>
          {label}
        </label>
      )}
      <input
        id={props.name}
        type={type}
        disabled={query?.isError}
        className={`${
          isError
            ? "placeholder-red-400/70 border-red-500 focus:border-red-400 focus:ring-red-100"
            : "placeholder-gray-400 border-gray-300 focus:border-ganache-orange-200 focus:ring-orange-100"
        } px-2 bg-transparant border-t border-b border-l border-r h-8 w-full focus:ring rounded-sm focus:outline-none ${
          type === "number" && "text-right"
        }`}
        {...props}
        {...register(props.name, options)}
      />
      {extra && extra}
      {isError && error && <p className="text-sm text-red-500">{error}</p>}
    </div>
  );
};
RHF.InputOrange = RHFInputOrange;

// FIXME: component doesn't work
// export const RHFInputFileOrange: FC<{ query?: UseQueryResult; options?: RegisterOptions } & ComponentPropsWithoutRef<"input">> = ({
//   query,
//   options,
//   children,
//   ...props
// }) => {
//   const { register } = useFormContext();

//   return <input name={props.name} id={props.name} disabled={query?.isError} type="file" {...register(props.name, options)} {...props} />;

//   // return <input id={props.name} disabled={query?.isError} type="file" hidden {...props} {...register(props.name, options)} />;
// };
// RHF.InputFileOrange = RHFInputFileOrange;

export const RHFInputNumberFormatOrange: FC<
  {
    onChangeFormat?: any;
    handleValue?: any;
    label?: string;
    error?: string;
    extra?: ReactNode;
    query?: UseQueryResult;
    options?: RegisterOptions;
  } & NumberFormatProps
> = ({ onChangeFormat, label, handleValue, extra, query, error, options, children, ...props }) => {
  const { formState, control } = useFormContext();
  const isError = get(formState.errors, props.name);

  return (
    <div className="w-full">
      {label && (
        <label className="mb-1 text-gray-600" htmlFor={props.name}>
          {label}
        </label>
      )}
      <Controller
        name={props.name}
        control={control}
        rules={options}
        render={({ field: { onChange, onBlur, value, name } }) => {
          return (
            <NumberFormat
              id={name}
              disabled={query?.isError}
              className={`${
                isError
                  ? "placeholder-red-400/70 border-red-500 focus:border-red-400 focus:ring-red-100"
                  : "placeholder-gray-400 border-gray-300 focus:border-ganache-orange-200 focus:ring-orange-100"
              } px-2 bg-transparant border-t border-b border-l border-r h-8 w-full focus:ring rounded-sm focus:outline-none focus-visible:outline-none}`}
              value={handleValue ? handleValue(value) : value}
              onValueChange={(values: NumberFormatValues) => {
                let value;
                if (onChangeFormat === "formattedValue") value = values.formattedValue;
                else if (onChangeFormat === "floatValue") value = values.floatValue;
                else value = values.value;
                return onChange(value);
              }}
              onBlur={onBlur}
              {...props}
            />
          );
        }}
      />
      {extra && extra}
      {isError && error && <p className="text-sm text-red-500">{error}</p>}
    </div>
  );
};

RHF.InputNumberFormatOrange = RHFInputNumberFormatOrange;

export default RHF;
