import { FC } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { getCurrency, getLanguage } from "src/helpers/auth";
import GanacheFormat from "src/utils/ganacheFormat";
import I18n from "../../../../../Config/I18n";
import { getTotalWeight } from "../IngredientsTable";

const l = getLanguage();

const TableSubHeader: FC<{ name: string }> = ({ name }) => {
  const maxLength = 18;
  const nameToShow = name.length < maxLength ? name : `${name.slice(0, maxLength - 3)}...`;
  return (
    <OverlayTrigger overlay={<Tooltip id="1">{name}</Tooltip>}>
      <th scope="col" className="p-2 border whitespace-nowrap">
        {nameToShow}
      </th>
    </OverlayTrigger>
  );
};

const TableData = ({ children }) => {
  return <td className="p-2 border whitespace-nowrap">{children}</td>;
};

export const GetTotalsTables = ({ contribution, cooking, reducer }) => {
  const sugarContribution = isNaN(contribution.sugar) ? 0 : contribution.sugar.toFixed(1);
  const fatsContribution = isNaN(contribution.oil) || isNaN(contribution.butter) || isNaN(contribution.cocoa_butter);
  const drySubstanceContrib =
    isNaN(contribution.cocoa_solid) || isNaN(contribution.amp) || isNaN(contribution.lactose) || isNaN(contribution.other);
  const liquidsContrib = isNaN(contribution.alcohol) || isNaN(contribution.water);
  const totalWeight = getTotalWeight(cooking, (a: number, b: number) => a + b);
  const reduceWheight = getTotalWeight(cooking, reducer);
  // @ts-ignore
  const kCalPer100g = (contribution.kcal * 100) / reduceWheight;

  // @ts-ignore
  const pricePerKilo = totalWeight > 0 ? (contribution.foodCost / totalWeight) * 1000 : 0;
  return (
    <div className="overflow-x-scroll">
      <table className="w-full border border-collapse table-auto">
        <col />
        <colgroup span={3} />
        <colgroup span={4} />
        <colgroup span={3} />
        <colgroup span={4} />
        <tr className="bg-ganache-orange-50">
          <th scope="col" className="p-2 border border-ganache-orange-300 whitespace-nowrap">
            <u>{I18n.t("ingredient.sugars", { locale: l })}</u>&nbsp;
            {GanacheFormat.percentage(sugarContribution)}&nbsp;%
          </th>
          <th colSpan={3} scope="colgroup" className="p-2 border border-ganache-orange-300 whitespace-nowrap">
            <u>{I18n.t("ingredient.fats", { locale: l })}</u>&nbsp;
            {GanacheFormat.percentage(fatsContribution ? 0.0 : contribution.oil + contribution.butter + contribution.cocoa_butter)}&nbsp;%
          </th>
          <th colSpan={4} scope="colgroup" className="p-2 border border-ganache-orange-300 whitespace-nowrap">
            <u>{I18n.t("ingredient.drySubstances", { locale: l })}</u>&nbsp;
            {/*{GanacheFormat.percentage(*/}
            {/*  drySubstanceContrib ? 0 : contribution.cocoa_solid + contribution.amp + contribution.lactose + contribution.other*/}
            {/*)}*/}
            {GanacheFormat.percentage(
                drySubstanceContrib ? 0 : contribution.cocoa_solid + contribution.amp + contribution.other
            )}
            &nbsp;%
          </th>
          <th colSpan={3} scope="colgroup" className="p-2 border border-ganache-orange-300 whitespace-nowrap">
            <u>{I18n.t("ingredient.liquids", { locale: l })}</u>&nbsp;
            {GanacheFormat.percentage(liquidsContrib ? 0 : contribution.alcohol + contribution.water)}&nbsp;%
          </th>
          <th colSpan={4} scope="colgroup" className="p-2 border border-ganache-orange-300 whitespace-nowrap">
            <u>{I18n.t("ingredient.others", { locale: l })}</u>
          </th>
        </tr>
        <tr>
          <TableSubHeader name={I18n.t("ingredient.sugars", { locale: l })} />
          <TableSubHeader name={I18n.t("ingredient.oil", { locale: l })} />
          <TableSubHeader name={I18n.t("ingredient.butter", { locale: l })} />
          <TableSubHeader name={I18n.t("ingredient.cocoaButter", { locale: l })} />
          <TableSubHeader name={I18n.t("ingredient.cocoa", { locale: l })} />
          <TableSubHeader name={I18n.t("ingredient.amp", { locale: l })} />
          <TableSubHeader name={I18n.t("ingredient.lactose", { locale: l })} />
          <TableSubHeader name={I18n.t("ingredient.other", { locale: l })} />
          <TableSubHeader name={I18n.t("ingredient.water", { locale: l })} />
          <TableSubHeader name={I18n.t("ingredient.alcohol", { locale: l })} />
          <TableSubHeader name={I18n.t("ingredient.alcoholLiquids", { locale: l })} />
          <TableSubHeader name={I18n.t("ingredient.pod", { locale: l })} />
          <TableSubHeader name={I18n.t("ingredient.pac", { locale: l })} />
          <TableSubHeader name={`${I18n.t("ingredient.kcal", { locale: l })}/100g`} />
          <TableSubHeader name={`${I18n.t("ingredient.foodCost", { locale: l })}/kg`} />
        </tr>
        <tr className="bg-gray-100">
          <TableData>{GanacheFormat.percentage(contribution.sugar)}&nbsp;%</TableData>
          <TableData>{GanacheFormat.percentage(contribution.oil)}&nbsp;%</TableData>
          <TableData>{GanacheFormat.percentage(contribution.butter)}&nbsp;%</TableData>
          <TableData>{GanacheFormat.percentage(contribution.cocoa_butter)}&nbsp;%</TableData>
          <TableData>{GanacheFormat.percentage(contribution.cocoa_solid)}&nbsp;%</TableData>
          <TableData>{GanacheFormat.percentage(contribution.amp)}&nbsp;%</TableData>
          <TableData>{GanacheFormat.percentage(contribution.lactose)}&nbsp;%</TableData>
          <TableData>{GanacheFormat.percentage(contribution.other)}&nbsp;%</TableData>
          <TableData>{GanacheFormat.percentage(contribution.water)}&nbsp;%</TableData>
          <TableData>{GanacheFormat.percentage(contribution.alcohol)}&nbsp;%</TableData>
          <TableData>
            {GanacheFormat.percentage((contribution.alcohol / (contribution.water + contribution.alcohol)) * 100)}
            &nbsp;%
          </TableData>
          <TableData>{GanacheFormat.round(contribution.pod)}</TableData>
          <TableData>{GanacheFormat.round(contribution.pac)}</TableData>
          <TableData>{GanacheFormat.round(kCalPer100g)}</TableData>
          <TableData>{GanacheFormat.price(pricePerKilo)}</TableData>
        </tr>
      </table>
    </div>
  );
};
