import { useEffect, useState } from "react";
import { Table, Form, Button } from "react-bootstrap";
import I18n from "../../../Config/I18n";
import { getLanguage } from "../../../helpers/auth";
import { getIngredientsByTemperature, getContribution } from "../../../utils/recipes";
import { getPersonnalIngredient } from "../../../utils/ingredientValidation";
import { IoMdArrowDropdownCircle, IoMdArrowDropupCircle } from "react-icons/io";

import { getGroupTemperatureFromRecipe } from "../../../utils/Recipe";
import GanacheFormat from "src/utils/ganacheFormat";

const l = getLanguage();

export default function TablePreviewRecipeComposition({ recipes, checked, value, setRecipes }) {
  const [isEdit, setIsEdit] = useState(false);
  const [scalerTemp, setScalerTemp] = useState(-1);
  const [scalerIndex, setScalerIndex] = useState(-1);

  /**
   * @type {[Object.<number,import("../../../Type/recipe").GroupTemperatureData>, Function]}
   */
  const [cooking, setCooking] = useState({});
  const [totalWeight, setTotalWeight] = useState(0);

  useEffect(() => {
    computeEvaporation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipes, value]); //recipes, value
  const reducer = (accumulator, value) => accumulator + value;

  const computeEvaporation = async () => {
    const cooked = await getGroupTemperatureFromRecipe(recipes[value]);
    setCooking(cooked);
    const totalWeightRecipe =
      getTotalWeightAfterCooking(cooked) +
      recipes[value].ingredients
        .filter(ing => ing.hide)
        .map(ing => ing.quantity)
        .reduce(reducer, 0);
    setTotalWeight(GanacheFormat.round(totalWeightRecipe));
  };

  /**
   * @returns {number}
   */
  const getTotalWeightAfterCooking = (cooked = cooking) => {
    return Object.keys(cooked)
      .map(temp => {
        const qty = cooked[temp].total.quantity || 0;
        return qty;
      })
      .reduce(reducer, 0);
  };

  const computeQtyPercentage = (quantity, temperature) => {
    /*
    const total = totalWeightRecipeNoCoef(recipes[value].ingredients)
    if (total === 0 || quantity === 0) return 0
    const percentageComposition = parseFloat(quantity / total) * 100
    return isNaN(percentageComposition.toFixed(1)) ? 0 : percentageComposition.toFixed(1)
    */
    temperature = parseInt(temperature);
    const total = temperature
      ? recipes[value].ingredients
          .filter(ing => ing.temperature === temperature)
          .map(ing => ing.quantity)
          .reduce(reducer, 0) || 0
      : totalWeight;
    return ((quantity * 100) / total).toFixed(1);
  };

  // TEST  from ListIng2
  const moveIngredientUp2 = ing => {
    const ingredients = recipes[value].ingredients;
    var last = 0;
    var from = 0;
    for (var i = 0; i < ingredients.length; i++) {
      const elmt = ingredients[i];
      if (elmt === ing) {
        from = i;
        break;
      }
      if (elmt.temperature === ing.temperature) last = i;
    }

    var temporary = ingredients[last]; // up one
    ingredients[last] = ingredients[from];
    ingredients[from] = temporary;

    recipes[value].ingredients = ingredients;

    setRecipes([...recipes]);
  };

  const moveIngredientDown2 = ing => {
    const ingredients = recipes[value].ingredients;
    var to = 0;
    var from = -1;
    for (var i = 0; i < ingredients.length; i++) {
      const elmt = ingredients[i];
      if (elmt === ing) {
        from = i;
      } else if (from >= 0 && i > from && elmt.temperature === ing.temperature) {
        to = i;
        break;
      }
    }

    var temporary = ingredients[to]; // up one
    ingredients[to] = ingredients[from];
    ingredients[from] = temporary;

    recipes[value].ingredients = ingredients;

    setRecipes([...recipes]);
  };

  const scaleWeigth = e => {
    setTotalWeight(e.target.value);
  };

  const scaleRecipe = newValue => {
    //TODO
    //let newValue = e.target.value;

    if (newValue < 1) newValue = 1;

    if (isNaN(parseFloat(newValue))) {
      newValue = 0.1;
    }
    /*
    const contribution = getContribution(recipes[value].ingredients)

    const newRows = recipes[value].ingredients.map((row, index) => {
      const ret = (contribution[index].percentage / row.coefficient) * newValue
      return { ...row, quantity: ret }
    })
    recipes[value].ingredients = newRows
    */

    const totalWeigthC = getTotalWeightAfterCooking();
    const coef = newValue / totalWeigthC;
    const newRows = recipes[value].ingredients.map((ing, index) => {
      ing.quantity *= coef;
      return ing;
    });
    recipes[value].ingredients = newRows;
    setRecipes([...recipes]);
  };

  const makeTemperatureLine = temp => {
    const temperature = parseInt(temp);
    const cook = cooking[temperature];
    const weight = cook?.total?.quantity || 0;
    const proportion = (weight * 100) / totalWeight;
    return (
      <tr className="table-row-temp table-warning">
        <td colSpan="2">
          {I18n.t("listIngredients.totalIngredientsTemp", { locale: l })} {temp}
          °C
        </td>
        <td align="center">{GanacheFormat.round(weight)}</td>
        <td align="center">{GanacheFormat.percentage(proportion)}%</td>
      </tr>
    );
  };

  const scaleRecipeFromIngredient = (event, temp, scalerIndexTemp, indexInRecipe) => {
    var newMass = event.target.value;

    if (isNaN(parseFloat(newMass)) || parseFloat(newMass) <= 0) {
      newMass = 0.1;
    }

    const ingPerTemp = getIngredientsByTemperature(recipes[value].ingredients);
    const scalerIng = ingPerTemp[temp === 0 ? "ambiant" : temp][scalerIndexTemp];
    const oldMass = scalerIng?.quantity || 0;
    const massAdd = newMass - oldMass;

    const contrib = getContribution(recipes[value].ingredients);
    const scalerIngredientPerc = contrib[indexInRecipe].percentageTotal || 0.1;

    const newIngredients = recipes[value].ingredients.map((row, index) => {
      // modify row qtt
      const newQty = row.quantity + (contrib[index].percentageTotal / scalerIngredientPerc) * massAdd;

      //const newQty = row.quantity + (scalerIngredientPerc / contrib[index].percentage) * massAdd
      return { ...row, quantity: newQty < 0 ? 0 : newQty };
    });
    recipes[value].ingredients = newIngredients;
    setRecipes([...recipes]);
  };

  const ingredientsByTemp = getIngredientsByTemperature(recipes[value].ingredients);
  // For each temperature -> displaying the ing and when the temp end, makeTemperatureLine for this temp
  const tempKeys = Object.keys(ingredientsByTemp);

  return (
    <Table bordered striped aria-label="customized table">
      <thead>
        <tr>
          {recipes[value].ingredients.length > 1 ? <th /> : null}
          <th>
            {I18n.t("recipeObject.ingredientName", { locale: l })}
            {checked.commercialName ? (
              <>
                <br />
                <small>({I18n.t("ingredient.commercialName", { locale: l })})</small>
              </>
            ) : null}
          </th>
          <th>
            {I18n.t("listIngredients.qty", { locale: l })}
            {" (g)"}
          </th>
          <th>
            {I18n.t("listIngredients.qty", { locale: l })} {" (%)"}
          </th>
        </tr>
      </thead>
      <tbody>
        {tempKeys.map((temp, index) => (
          <>
            {ingredientsByTemp[temp].map((ing, indexInTemp) => {
              const ingIndexRecipe = recipes[value].ingredients.indexOf(ing);
              const focus = ingIndexRecipe === scalerIndex && scalerTemp == (ing?.temperature || 0);
              const qtyPercIng = computeQtyPercentage(ing.quantity, ing.temperature);
              return (
                <>
                  <tr key={indexInTemp}>
                    {recipes[value].ingredients.length > 1 ? (
                      <td>
                        {indexInTemp !== 0 ? (
                          <IoMdArrowDropupCircle size="1.5em" className="mr-3" onClick={() => moveIngredientUp2(ing)} />
                        ) : null}

                        {indexInTemp !== ingredientsByTemp[temp].length - 1 ? (
                          <IoMdArrowDropdownCircle size="1.5em" className="mr-3" onClick={() => moveIngredientDown2(ing)} />
                        ) : null}
                      </td>
                    ) : null}
                    <td component="th">
                      {getPersonnalIngredient(ing.ingredient)}
                      {checked.commercialName && ing.ingredient.commercialName ? (
                        <>
                          <br />
                          <small> ({ing.ingredient.commercialName})</small>
                        </>
                      ) : null}
                    </td>
                    <td align="center">
                      {checked.scale && ing.quantity && ing.quantity !== 0 ? (
                        <Form
                          onSubmit={e => {
                            e.preventDefault();
                          }}
                        >
                          <Form.Control
                            type="number"
                            min="1"
                            value={isNaN(ing.quantity) ? 0 : GanacheFormat.round(ing.quantity)}
                            onChange={e => {
                              setScalerTemp(ing?.temperature || 0);
                              setScalerIndex(recipes[value].ingredients.indexOf(ing));
                              scaleRecipeFromIngredient(e, ing?.temperature || 0, indexInTemp, ingIndexRecipe);
                            }}
                            onClick={e => {
                              e.target.select();
                            }}
                            onBlur={e => {
                              setScalerTemp(-1);
                              setScalerIndex(-1);
                            }}
                            style={{
                              textAlign: "center",
                              minWidth: "50px",
                              maxWidth: "80px",
                            }}
                            autoFocus={focus}
                          ></Form.Control>
                        </Form>
                      ) : (
                        <div>{GanacheFormat.round(ing.quantity)}</div>
                      )}
                    </td>
                    <td align="center">{isNaN(qtyPercIng) ? 0.0 : qtyPercIng}</td>
                  </tr>
                </>
              );
            })}
            {temp !== "ambiant" ? makeTemperatureLine(temp) : null}
          </>
        ))}
        <tr>
          {recipes[value].ingredients.length > 1 ? (
            <td colSpan="2">
              <u>
                <strong> {I18n.t("listIngredients.totals", { locale: l })} </strong>
              </u>
            </td>
          ) : (
            <td>
              <u>
                <strong> {I18n.t("listIngredients.totals", { locale: l })} </strong>
              </u>
            </td>
          )}
          <td align="center">
            {checked.scale ? (
              <Form
                onSubmit={e => {
                  e.preventDefault();
                  scaleRecipe(totalWeight);
                }}
              >
                <Form.Control
                  type="number"
                  placeholder="0"
                  value={isEdit ? parseFloat(totalWeight) || 0 : GanacheFormat.round(totalWeight || 0)}
                  style={{
                    minWidth: "50px",
                    maxWidth: "80px",
                    textAlign: "right",
                    display: "inline",
                  }}
                  size="sm"
                  autoFocus={isEdit}
                  onClick={e => {
                    e.target.select();
                  }}
                  onChange={e => {
                    scaleWeigth(e);
                    setIsEdit(true);
                  }}
                  onBlur={e => {
                    setIsEdit(false);
                  }}
                  key={Math.random}
                  //readOnly={!recipes[value].editable}
                />
                g<Button onClick={() => scaleRecipe(totalWeight)}>scale</Button>
              </Form>
            ) : (
              <div>{totalWeight} g</div>
            )}
          </td>
          <td align="center"> 100% </td>
        </tr>
      </tbody>
    </Table>
  );
}
