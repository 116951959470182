import { Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { GiChemicalTank, GiChocolateBar, GiOpenBook, GiPencil, GiPieChart } from "react-icons/gi";
import { FaArrowLeft } from "react-icons/fa";
import I18n from "../../../Config/I18n";
import { getLanguage, getRoot } from "../../../helpers/auth";
import FolderAPI from "../../../backend/folder";
import { Url } from "../../../Config/url";
import { ButtonIconOrange } from "src/components/buttons/iconButton";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { useState } from "react";

const l = getLanguage();

export default function RecipeToolbar({ recipe, submitRecipe, isRecipeModified }) {
  const [openPopup, setOpenPopup] = useState(false);
  const history = useHistory();

  const showPopup = () => {
    if (isRecipeModified && recipe.editable) {
      setOpenPopup(true);
    } else {
      goBackToFolder();
    }
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const handleSubmitRecipe = async () => {
    setOpenPopup(false);

    let savingFolderId = undefined;
    if (recipe._id) {
      const folder = await FolderAPI.getFolderRecipe(recipe._id);
      savingFolderId = folder ? folder._id : getRoot();
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      savingFolderId = urlParams.has("folderId") ? urlParams.get("folderId") : getRoot();
    }
    const success = await submitRecipe(savingFolderId, true);
    if (success) setTimeout(() => goBackToFolder(), 1000);
  };

  const goBackToFolder = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const hasId = urlParams.has("folderId");
    var url = Url.folder;
    const folderId = hasId ? urlParams.get("folderId") : recipe.folderId;
    const result = await FolderAPI.getPath(folderId);
    url += "/" + result.path;
    return history.push(url);
  };

  return (
    <>
      <Modal show={openPopup && isRecipeModified} onHide={handleClosePopup}>
        <Modal.Header closeButton>{I18n.t("recipeGoBackSure", { locale: l })}</Modal.Header>
        <Modal.Body></Modal.Body>
        <Modal.Footer>
          <Button onClick={goBackToFolder} variant="danger">
            {I18n.t("back", { locale: l })}
          </Button>
          <Button onClick={handleSubmitRecipe} variant="success">
            {I18n.t("save", { locale: l })}
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="flex flex-wrap justify-between gap-x-3 gap-y-2">
        <div className="flex items-center gap-x-4">
          <ButtonIconOrange onClick={showPopup}>
            <FaArrowLeft />
          </ButtonIconOrange>
        </div>
        <div className="flex flex-wrap flex-1 max-w-lg gap-x-4 sm:flex-nowrap">
          <NavItemRecipe slug="recipe" Icon={GiChemicalTank} />
          <NavItemRecipe slug="ingredients" Icon={GiChocolateBar} />
          <NavItemRecipe slug="method" Icon={GiPencil} />
          {/*<NavItemRecipe slug='picture' Icon={GiPhotoCamera} />*/}
          <NavItemRecipe slug="comparaison" Icon={GiPieChart} />
        </div>
        <div className="flex items-center justify-end">
          <ButtonIconOrange>
            <a rel="noreferrer" target="_blank" href={Url.manualComposition}>
              <GiOpenBook className="text-ganache-neutral-100 hover:text-ganache-neutral-100" />
            </a>
          </ButtonIconOrange>
        </div>
      </div>
    </>
  );
}

const NavItemRecipe = ({ slug, Icon }) => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  if (urlParams.has("page")) {
    urlParams.set("page", slug);
  } else {
    urlParams.append("page", slug);
  }

  return (
    <NavLink
      isActive={(match, location) => {
        const urlParams = new URLSearchParams(location.search);
        if (urlParams.has("page")) {
          if (urlParams.get("page") === slug) return true;
        } else {
          if (slug === "recipe") return true;
        }
      }}
      to={`${Url.recipe}?${urlParams.toString()}`}
      className={isActive =>
        "w-28 no-underline flex-1 flex flex-col items-center justify-center rounded-sm py-1.5 px-1 sm:py-2.5 sm:px-3 sm:w-36 " +
        (isActive
          ? "text-ganache-neutral-100 bg-ganache-brown-500"
          : "text-ganache-brown-500 hover:bg-ganache-brown-500 hover:text-ganache-neutral-100")
      }
    >
      <Icon size="1.5em" />
      {I18n.t(slug, { locale: l })}
    </NavLink>
  );
};
