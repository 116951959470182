import { getCurrency, getLanguage } from "../../../helpers/auth";
import I18n from "../../../Config/I18n";
import HelpTooltip from "../../generic/HelpTooltip";
import { formatNumber, percent } from "../../../helpers/utils";
import { OverlayTrigger, Popover, Form, Button, Tooltip } from "react-bootstrap";
import { useEffect, useState } from "react";
import { GET } from "../../../helpers/requests";
import { RiLeafFill } from "react-icons/ri";
import { GiAcorn, GiCarrot, GiGrain, GiMilkCarton } from "react-icons/gi";
import ReactQuill from "react-quill";
import { FormSelectOrange, InputOrange } from "src/components/form";

const l = getLanguage();

export function CardSugar({ ingredient, setIngredient, setIsModified }) {
  const handleChange = event => {
    const updatedIngredient = { ...ingredient };
    const value = event.target.value ? parseFloat(event.target.value) : 0;
    updatedIngredient.composition.sugar = percent(value);
    setIngredient(updatedIngredient);
    setIsModified(true);
  };

  return (
    <>
      <div className="flex justify-between mb-2">
        <h5 className="text-lg font-semibold">{I18n.t("ingredient.sugars", { locale: l })}</h5>
        <HelpTooltip brol="ingredient.sugarsinfo" />
      </div>
      <form className="flex flex-col">
        <label className="mb-0" htmlFor="formGroupExampleInput">
          {I18n.t("ingredient.saccharose", { locale: l })} (%)
        </label>
        <input
          name="sugar"
          type="number"
          min="0"
          max="100"
          className="form-control"
          id="sugar"
          placeholder="0"
          value={formatNumber(ingredient.composition.sugar)}
          onChange={handleChange}
          onClick={e => e.target.select()}
          disabled={!ingredient.editable}
          style={{ textAlign: "right" }}
          autoFocus
        />
      </form>
    </>
  );
}

export function CardOthers({ ingredient, setIngredient, setIsModified }) {
  const handleChange = event => {
    const ingredientModified = { ...ingredient };
    const name = event.target.name;
    const value = event.target.value ? parseFloat(event.target.value) : 0;
    ingredientModified.composition.other[name] = value;
    if (name === "kcal") ingredientModified.composition.other.kj = parseInt(value * 4.184);
    if (name === "kj") ingredientModified.composition.other.kcal = parseInt(value / 4.184);
    setIngredient(ingredientModified);
    setIsModified(true);
  };

  return (
    <>
      <div className="flex justify-between mb-2">
        <h5 className="text-lg font-semibold"> {I18n.t("ingredient.other", { locale: l })} </h5>
        <HelpTooltip brol="ingredient.othersinfo" />
      </div>
      <form className="flex flex-col gap-y-4">
        <div class="grid grid-cols-4 grid-rows-[repeat(2,max-content)] gap-1">
          <OverlayTrigger
            placement="top"
            overlay={
              <Popover>
                <Popover.Content> {I18n.t("ingredient.POD", { locale: l })} </Popover.Content>
              </Popover>
            }
          >
            <label className="col-span-1 row-span-1 mb-0 " htmlFor="formGroupExampleInput">
              {I18n.t("ingredient.pod", { locale: l })}
            </label>
          </OverlayTrigger>
          <input
            name="pod"
            type="number"
            min="0"
            max="9999"
            className="col-span-1 row-span-1 row-start-2 form-control"
            placeholder="0"
            value={formatNumber(ingredient.composition.other.pod, 0)}
            onChange={handleChange}
            onClick={e => e.target.select()}
            disabled={!ingredient.editable}
            style={{ textAlign: "right" }}
          />
          <OverlayTrigger
            placement="top"
            overlay={
              <Popover>
                <Popover.Content> {I18n.t("ingredient.PAC", { locale: l })} </Popover.Content>
              </Popover>
            }
          >
            <label className="col-span-1 row-span-1 mb-0 " htmlFor="formGroupExampleInput">
              {I18n.t("ingredient.pac", { locale: l })}
            </label>
          </OverlayTrigger>

          <input
            name="pac"
            type="number"
            min="0"
            max="9999"
            className="col-span-1 row-span-1 row-start-2 form-control"
            placeholder="0"
            value={formatNumber(ingredient.composition.other.pac, 0)}
            onChange={handleChange}
            onClick={e => e.target.select()}
            disabled={!ingredient.editable}
            style={{ textAlign: "right" }}
          />
          <OverlayTrigger
            placement="top"
            overlay={
              <Popover>
                <Popover.Content> {I18n.t("ingredient.KCAL", { locale: l })} </Popover.Content>
              </Popover>
            }
          >
            <label className="col-span-1 row-span-1 mb-0 ">{I18n.t("ingredient.kcal", { locale: l })}</label>
          </OverlayTrigger>
          <input
            name="kcal"
            type="number"
            min="0"
            max="9999"
            className="col-span-1 row-span-1 row-start-2 form-control"
            placeholder="0"
            value={formatNumber(ingredient.composition.other.kcal, 0)}
            onChange={handleChange}
            onClick={e => e.target.select()}
            disabled={!ingredient.editable}
            style={{ textAlign: "right" }}
          />
          <OverlayTrigger
            placement="top"
            overlay={
              <Popover>
                <Popover.Content> {I18n.t("ingredient.KJ", { locale: l })} </Popover.Content>
              </Popover>
            }
          >
            <label className="col-span-1 row-span-1 mb-0 ">{I18n.t("ingredient.kj", { locale: l })}</label>
          </OverlayTrigger>
          <input
            name="kj"
            type="number"
            min="0"
            max="9999"
            className="col-span-1 row-span-1 row-start-2 form-control"
            placeholder="0"
            value={formatNumber(ingredient.composition.other.kj, 0)}
            onChange={handleChange}
            onClick={e => e.target.select()}
            disabled={!ingredient.editable}
            style={{ textAlign: "right" }}
          />
        </div>
      </form>
    </>
  );
}

export function CardLiquids({ ingredient, setIngredient, setIsModified }) {
  const handleChange = event => {
    const value = event.target.value ? percent(event.target.value) : 0;

    setIngredient({
      ...ingredient,
      composition: {
        ...ingredient.composition,
        liquids: {
          ...ingredient.composition.liquids,
          [event.target.name]: value,
        },
      },
    });
    setIsModified(true);
  };

  return (
    <>
      <div className="flex justify-between mb-2">
        <h5 className="text-lg font-semibold">{I18n.t("ingredient.liquids", { locale: l })}</h5>
        <HelpTooltip brol="ingredient.liquidsinfo" />
      </div>
      <form className="flex flex-col gap-y-4">
        <div class="grid grid-cols-2 grid-rows-[repeat(2,max-content)] gap-1">
          <label className="col-span-1 row-span-1 mb-0" htmlFor="formGroupExampleInput">
            {I18n.t("ingredient.water", { locale: l })} (%)
          </label>
          <input
            name="water"
            type="number"
            min="0"
            max="100"
            className="col-span-1 row-span-1 row-start-2 form-control"
            placeholder="0"
            value={formatNumber(ingredient.composition.liquids.water)}
            onChange={handleChange}
            onClick={e => e.target.select()}
            disabled={!ingredient.editable}
            style={{ textAlign: "right" }}
          />
          <label className="col-span-1 row-span-1 mb-0" htmlFor="formGroupExampleInput">
            {I18n.t("ingredient.alcohol", { locale: l })} (%)
          </label>
          <input
            name="alcohol"
            type="number"
            min="0"
            max="100"
            className="col-span-1 row-span-1 row-start-2 form-control"
            placeholder="0"
            value={formatNumber(ingredient.composition.liquids.alcohol)}
            onChange={handleChange}
            onClick={e => e.target.select()}
            disabled={!ingredient.editable}
            style={{ textAlign: "right" }}
          />
        </div>
      </form>
    </>
  );
}

export function CardFats({ ingredient, setIngredient, setIsModified }) {
  const handleChange = event => {
    const value = event.target.value ? parseFloat(event.target.value) : 0;

    setIngredient({
      ...ingredient,
      composition: {
        ...ingredient.composition,
        fats: {
          ...ingredient.composition.fats,
          [event.target.name]: percent(value),
        },
      },
    });
    setIsModified(true);
  };

  return (
    <>
      <div className="flex justify-between mb-2">
        <h5 className="text-lg font-semibold">{I18n.t("ingredient.fats", { locale: l })}</h5>
        <HelpTooltip brol="ingredient.fatsinfo" />
      </div>
      <form className="flex flex-col gap-y-4">
        <div className="grid grid-cols-3 grid-rows-[repeat(2,max-content)] gap-1">
          <label className="col-span-1 row-span-1 mb-0" htmlFor="formGroupExampleInput">
            {I18n.t("ingredient.oil", { locale: l })} (%)
          </label>
          <input
            name="oil"
            type="number"
            min="0"
            max="100"
            className="col-span-1 row-span-1 row-start-2 form-control"
            placeholder="0"
            value={formatNumber(ingredient.composition.fats.oil)}
            onChange={handleChange}
            onClick={e => e.target.select()}
            disabled={!ingredient.editable}
            style={{ textAlign: "right" }}
          />
          <label className="col-span-1 row-span-1 mb-0" htmlFor="formGroupExampleInput">
            {I18n.t("ingredient.butter", { locale: l })} (%)
          </label>
          <input
            name="butter"
            type="number"
            min="0"
            max="100"
            className="col-span-1 row-span-1 row-start-2 form-control"
            placeholder="0"
            value={formatNumber(ingredient.composition.fats.butter)}
            onChange={handleChange}
            onClick={e => e.target.select()}
            disabled={!ingredient.editable}
            style={{ textAlign: "right" }}
          />
          <label className="col-span-1 row-span-1 mb-0" htmlFor="formGroupExampleInput">
            {I18n.t("ingredient.cocoaButter", { locale: l })} (%)
          </label>
          <input
            name="cocoa_butter"
            type="number"
            min="0"
            max="100"
            className="col-span-1 row-span-1 row-start-2 form-control "
            placeholder="0"
            value={formatNumber(ingredient.composition.fats.cocoa_butter)}
            onChange={handleChange}
            onClick={e => e.target.select()}
            disabled={!ingredient.editable}
            style={{ textAlign: "right" }}
          />
        </div>
      </form>
    </>
  );
}

export function CardDry({ ingredient, setIngredient, setIsModified }) {
  const handleChange = event => {
    const updatedIngredient = { ...ingredient };
    const name = event.target.name;
    const value = event.target.value ? parseFloat(event.target.value) : 0;
    updatedIngredient.composition.dry[name] = percent(value);
    switch (name) {
      case "amp":
        updatedIngredient.composition.dry.lactose = percent(value * 0.52);
        break;
      case "lactose":
        updatedIngredient.composition.dry.amp = percent(value / 0.52);
        break;
      default:
        console.error(`unexpected value: ${name}`);
    }
    setIngredient(updatedIngredient);
    setIsModified(true);
  };

  return (
    <>
      <div className="flex justify-between mb-2">
        <h5 className="text-lg font-semibold">{I18n.t("ingredient.drySubstances", { locale: l })}</h5>
        <HelpTooltip brol="ingredient.drySubstancesinfo" />
      </div>
      <form className="ingredient-dry">
        <div class="grid grid-cols-4 grid-rows-[repeat(2,max-content)] gap-1">
          <label className="col-span-1 row-span-1 mb-0 " htmlFor="formGroupExampleInput">
            {I18n.t("ingredient.cacaoSolid", { locale: l })} (%)
          </label>
          <input
            name="cocoa_solid"
            type="number"
            min="0"
            max="100"
            className="col-span-1 row-span-1 row-start-2 form-control"
            placeholder="0"
            value={formatNumber(ingredient.composition.dry.cocoa_solid)}
            onChange={handleChange}
            onClick={e => e.target.select()}
            disabled={!ingredient.editable}
            style={{ textAlign: "right" }}
          />
          <OverlayTrigger
            placement="top"
            overlay={
              <Popover>
                <Popover.Content> {I18n.t("ingredient.LMPD", { locale: l })} </Popover.Content>
              </Popover>
            }
          >
            <label className="col-span-1 row-span-1 mb-0" htmlFor="formGroupExampleInput">
              {`${I18n.t("ingredient.LMPDTitle", { locale: l })} (%)`}
            </label>
          </OverlayTrigger>
          <input
            name="amp"
            type="number"
            min="0"
            max="100"
            className="col-span-1 row-span-1 row-start-2 form-control"
            placeholder="0"
            value={formatNumber(ingredient.composition.dry.amp)}
            onChange={handleChange}
            onClick={e => e.target.select()}
            disabled={!ingredient.editable}
            style={{ textAlign: "right" }}
          />
          <label className="col-span-1 row-span-1 mb-0" htmlFor="formGroupExampleInput">
            {I18n.t("ingredient.lactose", { locale: l })} (%)
          </label>
          <input
            name="lactose"
            type="number"
            min="0"
            max="100"
            className="col-span-1 row-span-1 row-start-2 form-control"
            placeholder="0"
            value={formatNumber(ingredient.composition.dry.lactose)}
            onChange={handleChange}
            onClick={e => e.target.select()}
            disabled={!ingredient.editable}
            style={{ textAlign: "right", backgroundColor: "#dedede" }}
          />
          <label className="col-span-1 row-span-1 mb-0" htmlFor="formGroupExampleInput">
            {I18n.t("ingredient.othersSolid", { locale: l })} (%)
          </label>
          <input
            name="other"
            type="number"
            min="0"
            max="100"
            className="col-span-1 row-span-1 row-start-2 form-control"
            placeholder="0"
            value={formatNumber(ingredient.composition.dry.other)}
            onChange={handleChange}
            onClick={e => e.target.select()}
            disabled={!ingredient.editable}
            style={{ textAlign: "right" }}
          />
        </div>
      </form>
    </>
  );
}

export function CardCost({ ingredient, setIngredient, updateIngredient, setIsModified }) {
  const [focus, setFocus] = useState(false);

  const handleChangeSource = event => {
    const fromTechnicalSheet = event.target.value === "true";
    const ingredientUpdated = { ...ingredient, fromTechnicalSheet };
    setIngredient(ingredientUpdated);
    setIsModified(true);
  };
  const handleChange = event => {
    const updatedIngredient = { ...ingredient };
    updatedIngredient.note.foodCost = parseFloat(event.target.value);
    setIngredient(updatedIngredient);
    setIsModified(true);
  };

  return (
    <>
      <div className="flex justify-end mb-2">
        <HelpTooltip brol="ingredient.costsinfo" />
      </div>
      <div className="flex flex-col gap-y-4">
        <div>
          <label className="mb-0">{I18n.t("ingredient.source", { locale: l })}</label>
          <FormSelectOrange defaultValue="Choose..." onChange={handleChangeSource} value={ingredient.fromTechnicalSheet}>
            <option value="true">{I18n.t("ingredient.sourceTechnicalSheet", { locale: l })}</option>
            <option value="false">{I18n.t("ingredient.sourcePersonnal", { locale: l })}</option>
          </FormSelectOrange>
        </div>
        <div>
          <label className="mb-0">
            {I18n.t("ingredient.costs", { locale: l })}({getCurrency()}/kg)
          </label>
          <InputOrange
            type="number"
            placeholder="0"
            min="0"
            onClick={e => {
              e.target.select();
              setFocus(true);
            }}
            onChange={e => {
              handleChange(e);
            }}
            onBlur={e => {
              setFocus(false);
            }}
            value={focus ? parseFloat(ingredient?.note?.foodCost?.toFixed(2)) || 0.0 : ingredient?.note?.foodCost?.toFixed(2) || 0.0}
          />
        </div>
      </div>
    </>
  );
}

export function CardContent({ ingredient, setIngredient, setIsModified }) {
  const [contents, setContents] = useState([]);
  const [fitfor, setFitfor] = useState([]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const loadData = () => {
    GET("/ingredient/content").then(response => {
      if (response.error) {
        return console.error(response.error.message);
      }
      if (response.data) {
        setContents(response.data);
      }
    });

    GET("/ingredient/fitfor").then(response => {
      if (response.error) {
        return console.error(response.error.message);
      }
      // If it is a new ingredient, all the fit fors are added to the ingredient ('success' display for the icon).
      if (!ingredient._id) {
        for (const fitfors of response.data) {
          ingredient.flags.fit.push(fitfors._id);
        }
      }
      setFitfor(response.data);
    });
  };

  function arrayRemove(arr, value) {
    return arr.filter(function (ele) {
      return ele !== value;
    });
  }

  const handleChange = (event, value) => {
    let contentsSelected = ingredient.flags.contents;
    if (contentsSelected.includes(value)) {
      contentsSelected = arrayRemove(contentsSelected, value);
    } else {
      contentsSelected.push(value);
    }
    setIngredient({
      ...ingredient,
      flags: {
        ...ingredient.flags,
        contents: contentsSelected,
      },
    });
    setIsModified(true);
  };

  const handleChangeFitfor = (event, value) => {
    let fitforSelected = ingredient.flags.fit;
    if (fitforSelected.includes(value)) {
      fitforSelected = arrayRemove(fitforSelected, value);
    } else {
      fitforSelected.push(value);
    }

    setIngredient({
      ...ingredient,
      flags: {
        ...ingredient.flags,
        fit: fitforSelected,
      },
    });
    setIsModified(true);
  };
  /*
  const getFitForIcons = (fitName) => {
    switch (fitName) {
      case 'Vegan':
        return fitforIcons[1]
      case 'Veggie':
        return fitforIcons[0]
      default :
        break
    }
  }

  const getTitleFor = (fitName) => {
    switch (fitName) {
      case 'Vegan':
        return titleFitfor[1]
      case 'Veggie':
        return titleFitfor[0]
    }
  }
*/
  const titleFitfor = [`${I18n.t("fitfors.vegetalian", { locale: l })}`, `${I18n.t("fitfors.vegetable", { locale: l })}`];

  const fitforIcons = [<RiLeafFill key={1} size="1.5em" />, <GiCarrot key={0} size="1.5em" />];

  const titleContents = [
    `${I18n.t("contents.gluten", { locale: l })}`,
    `${I18n.t("contents.milk", { locale: l })}`,
    `${I18n.t("contents.nut", { locale: l })}`,
  ];

  const contentsIcons = [<GiGrain key={0} size="1.5em" />, <GiMilkCarton key={1} size="1.5em" />, <GiAcorn key={2} size="1.5em" />];

  const selectContentIcon = id => {
    const contentsSelected = ingredient.flags.contents;
    return contentsSelected.includes(id);
  };

  const selectFitforIcon = id => {
    const fitforSelected = ingredient.flags.fit;
    if (fitforSelected.includes(id)) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div className="flex justify-between mb-2">
        <h5 className="text-lg font-semibold">{I18n.t("ingredient.contentTitle", { locale: l })}</h5>
        <HelpTooltip brol="ingredient.contentTitleinfo" />
      </div>
      <form className="flex flex-col gap-y-4">
        <div className="flex flex-col flex-wrap gap-2">
          <label className="mb-0">{I18n.t("ingredient.content", { locale: l })}</label>
          <div className="flex gap-x-2">
            {contents.map((content, index) => {
              return (
                <OverlayTrigger key={index} placement="bottom" overlay={<Tooltip>{titleContents[index]}</Tooltip>}>
                  <button
                    type="button"
                    value={content._id}
                    disabled={!ingredient.editable}
                    onClick={e => handleChange(e, content._id)}
                    className={`px-2 py-2 transition-colors rounded-sm bg-transparant h-max w-max focus:outline-none text-ganache-neutral-100 active:text-ganache-neutral-100 ${
                      selectContentIcon(content._id) ? "bg-red-600 text-ganache-neutral-100" : "text-ganache-neutral-900"
                    }`}
                  >
                    {contentsIcons[index]}
                  </button>
                </OverlayTrigger>
              );
            })}
          </div>
        </div>
        <div class="flex flex-col flex-wrap gap-2">
          <label className="mb-0">{I18n.t("ingredient.fitfor", { locale: l })}</label>
          <div className="flex gap-x-2">
            {fitfor.map((fit, index) => {
              return (
                <OverlayTrigger key={index} placement="bottom" overlay={<Tooltip>{titleFitfor[index]}</Tooltip>}>
                  <button
                    type="button"
                    value={fit._id}
                    disabled={!ingredient.editable}
                    onClick={e => handleChangeFitfor(e, fit._id)}
                    className={`px-2 py-2 transition-colors rounded-sm bg-transparant h-max w-max focus:outline-none text-ganache-neutral-100 active:text-ganache-neutral-100 ${
                      selectFitforIcon(fit._id, index) ? "bg-green-600" : "bg-red-600"
                    }`}
                  >
                    {fitforIcons[index]}
                  </button>
                </OverlayTrigger>
              );
            })}
          </div>
        </div>
      </form>
    </>
  );
}

export function CardComment({ note, setNote, updateIngredient, setIsModified }) {
  const handleChange = value => {
    setNote(value);
    setIsModified(true);
  };
  const handleOnBlur = value => {};
  return (
    <>
      <div class="flex justify-between mb-2">
        <h5 className="text-lg font-semibold"> {I18n.t("remarks", { locale: l })} </h5>
        <HelpTooltip brol="remarksinfo" />
      </div>
      <form>
        <Form.Group controlId="exampleForm.ControlTextarea1" className="mb-0">
          <Form.Label>{I18n.t("ingredient.addComment", { locale: l })}</Form.Label>
          {updateIngredient ? (
            <ReactQuill name="note" theme="snow" value={note} onChange={handleChange} onBlur={handleOnBlur} />
          ) : (
            <ReactQuill theme="snow" value={note || ""} onChange={handleChange} />
          )}
        </Form.Group>
      </form>
    </>
  );
}
