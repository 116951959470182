import React, {FC, useState} from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { IoMdAdd } from "react-icons/io";
import { getId, getLanguage } from "../../../helpers/auth";
import I18n from "../../../Config/I18n";
import { Url } from "../../../Config/url";
import { IconLinkOrange } from "src/components/links/iconLinkOrangeOutline";
import { getRole, Roles } from "../../../helpers/auth";
import PopupDemo from "../../payment/PopupDemo";

const l = getLanguage();




//   return (
//     <div className="flex px-3 pb-3 mb-3 border-b gap-x-3">
//       <h3 className="my-auto text-xl font-bold">{I18n.t("recipeObject.recipes", { locale: l })}</h3>
//       {folderData?._id && (
//         <OverlayTrigger overlay={<Tooltip id="button-tooltip">{I18n.t("recipeObject.addRecipe", { locale: l })}</Tooltip>}>
//           <IconLinkOrange redirectUrl={`${Url.recipe}?folderId=${folderData._id}`} hidden={folderData.ownerId !== getId()}>
//             <IoMdAdd />
//           </IconLinkOrange>
//         </OverlayTrigger>
//       )}
//     </div>
//   );
// };

const RecipesToolbar: FC<{ folderData?: any; recipeCount: number }> = ({ folderData, recipeCount }) => {
    const role = getRole();
    const [isPopupDemoOpen, setIsPopupDemoOpen] = useState(false);

    console.log("recipeCount",recipeCount);
    const handleAddClick = () => {
        if (role === "demo" && recipeCount > 0) {
            setIsPopupDemoOpen(true);
        }
    };



    const handleClosePopupDemo = () => {
        setIsPopupDemoOpen(false);
    };

    const renderAddButton = () => {
        if (role === "demo" && recipeCount > 0) {
            return (
                <div>
                {isPopupDemoOpen && (
                    <PopupDemo
                        open={isPopupDemoOpen}
                        handleClosePopupDemo={handleClosePopupDemo}
                    />
                )}
                <button onClick={handleAddClick} className="icon-link-orange">
                    <IoMdAdd />
                </button>
                </div>
            );
        }

        return (
            <IconLinkOrange redirectUrl={`${Url.recipe}?folderId=${folderData._id}`} hidden={folderData.ownerId !== getId()}>
                <IoMdAdd />
            </IconLinkOrange>
        );
    };

    return (
        <div className="flex px-3 pb-3 mb-3 border-b gap-x-3">
            <h3 className="my-auto text-xl font-bold">{I18n.t("recipeObject.recipes", { locale: l })}</h3>
            {folderData?._id && (
                <OverlayTrigger overlay={<Tooltip id="button-tooltip">{I18n.t("recipeObject.addRecipe", { locale: l })}</Tooltip>}>
                    {renderAddButton()}
                </OverlayTrigger>
            )}
        </div>
    );
};

export default RecipesToolbar;
