import { Card } from "react-bootstrap";
import CardMoreButtonIngredient from "../generic/CardMoreButtonIngredient";
import { getIngredientUrl } from "../../Config/url";
import Lookup from "./Lookup";
import { getLanguage } from "src/helpers/auth";

const l = getLanguage();

const IngredientCard = props => {
    const {
        position,
        ingredient,
        categoryName,
        brand,
        reloadIngredients,
        deleteIng,
        deleteIngredient,
        contents,
        fitfors,
        filterSearch
    } =
        props;
    let path = getIngredientUrl(ingredient._id);

    path += filterSearch.name ? "&name=" + filterSearch.name : "";
    path += filterSearch.brandId ? "&brand=" + filterSearch.brandId : "";
    path += filterSearch.categoryId ? "&category=" + filterSearch.categoryId : "";

    let ingName;
    let ingComercialName;

    if (l !== "jp") {

         ingName = ingredient.nameTranslation && ingredient.nameTranslation[l] ? ingredient.nameTranslation[l] : ingredient.name;
         ingComercialName =
            ingredient.commercialNameTranslation && ingredient.commercialNameTranslation[l]
                ? ingredient.commercialNameTranslation[l]
                : ingredient.commercialName || "";
        //+ brand ? ` [${brand}]` : ""
    } else {
         // ingName = ingredient.nameTranslation && ingredient.nameTranslation[l] ? ingredient.nameTranslation[l] : ingredient.nameTranslation["en"];

         // ingComercialName =
         //    ingredient.commercialNameTranslation && ingredient.commercialNameTranslation[l]
         //        ? ingredient.commercialNameTranslation[l]
         //        : ingredient.commercialNameTranslation["en"] || "";

        ingName = ingredient.nameTranslation && ingredient.nameTranslation["jp"]
            ? ingredient.nameTranslation["jp"]
            : (ingredient.nameTranslation && ingredient.nameTranslation["en"]
                ? ingredient.nameTranslation["en"]
                : ingredient.name);

        ingComercialName =
            (ingredient.commercialNameTranslation && ingredient.commercialNameTranslation["jp"])
                ? ingredient.commercialNameTranslation["jp"]
                : ((ingredient.commercialNameTranslation && ingredient.commercialNameTranslation["en"])
                    ? ingredient.commercialNameTranslation["en"]
                    : ingredient.commercialName || "");
    }

  return (
    <Card className="py-3 ingredient-card h-100">
      {/*<GiChocolateBar
        size='1.5em'
        className='my-auto ml-3 ingredient-icon'
      />*/}
      <a href={path} className="px-2 mx-auto text-center ingredient-card-body">
        {`${ingName}${brand ? ` [${brand}]` : ""}`} <br />
        <small>{ingComercialName}</small>
        <br />
        <small className="text-muted">{categoryName}</small>
        <br />
        <small className="text-muted">{ingredient.owner}</small>
      </a>
      {
        <Lookup
          ingredient={ingredient}
          contents={contents}
          fitfors={fitfors}
          placement={
            position % 3 === 2 ? "left" : "right"
          } /* Displaying 3 ing per rows, the last ingredient pop-over must be displayed on the left, the others on the right (starts to 0)*/
        />
      }
      <CardMoreButtonIngredient
        ingredient={ingredient}
        pathToEdit={path}
        reloadIngredients={reloadIngredients}
        deleteIng={deleteIng}
        deleteIngredient={deleteIngredient}
        className="my-auto mr-1"
      />
    </Card>
  );
};

export default IngredientCard;
