import React, { memo } from "react";
import { canWrite, getLanguage, getUser } from "../../helpers/auth";
import I18n from "../../Config/I18n";
import { POST, GET } from "../../helpers/requests";
import { Dropdown } from "react-bootstrap";
import { GiPencil, GiTrashCan } from "react-icons/gi";
import { MdControlPointDuplicate } from "react-icons/md";
import { getIngredientUrl } from "../../Config/url";
import { useHistory } from "react-router-dom";

const l = getLanguage();

function CardMoreButtonIngredient({ ingredient, pathToEdit, reloadIngredients, className, deleteIng }) {
  const history = useHistory();

  const redirectToEdit = () => {
    history.push(pathToEdit);
    window.location.reload();
  };

  const duplicate = async () => {
    const originalIngredient = await (await GET(`/ingredient/${ingredient._id}`)).data;
    const newIngredient = {
      name: `${originalIngredient.name} COPY`,
      commercialName: `${originalIngredient.commercialName} COPY`,
      brandId: originalIngredient.brandId,
      categoryId: originalIngredient.categoryId,
      composition: originalIngredient.composition,
      flags: originalIngredient.flags,
      note: originalIngredient.note,
    };
    const copy = await (await POST("/ingredient/add", newIngredient)).data;
    history.push(getIngredientUrl(copy._id));
    window.location.reload();
  };

  const deleteThis = async () => {
    // await POST('/ingredient/delete', { ingredientId: ingredient._id })
    // deleteIngredient(ingredient._id)
    deleteIng({
      id: ingredient._id,
      name: ingredient.name,
    });
    // reloadIngredients()
  };

  return (
    <Dropdown drop="down" className={className}>
      <Dropdown.Toggle split variant="outline-primary" />
      <Dropdown.Menu>
        <Dropdown.Item className="flex items-center gap-x-3" onClick={redirectToEdit}>
          <GiPencil size="1.5em" />
          {I18n.t("recipeObject.edit", { locale: l })}
        </Dropdown.Item>

        {canWrite() ? (
          <>
            <Dropdown.Item className="flex items-center gap-x-3" onClick={duplicate}>
              <MdControlPointDuplicate size="1.5em" />
              {I18n.t("recipeAction.copy", { locale: l })}
            </Dropdown.Item>
            <Dropdown.Item
              className="flex items-center gap-x-3"
              onClick={deleteThis}
              hidden={ingredient.owner !== getUser().name + " " + getUser().lastname}
            >
              <GiTrashCan size="1.5em" />
              {I18n.t("recipeAction.delete", { locale: l })}
            </Dropdown.Item>
          </>
        ) : (
          <></>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default memo(CardMoreButtonIngredient);
