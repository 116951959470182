import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { getRole } from "../helpers/auth";
import { Url } from "../Config/url";
import { getUrlFile } from "../helpers/requests";
import { Document, Page, pdfjs } from "react-pdf";
import { useHistory } from "react-router-dom";

export default function ManualComposition() {
  const [numPages, setNumPages] = useState(0);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return getRole() !== "demo" ? (
    <div id="manual">
      <Document
        file={{ url: getUrlFile("/manual/composition"), withCredentials: true }}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={err => {
          console.error(err);
        }}
        loading={<Spinner animation="border" variant="primary" />}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} width={document.getElementById("manual")?.offsetWidth || 1000} />
        ))}
      </Document>
    </div>
  ) : (
    <Demo />
  );
}
/*
const Page = () => {
  return (
    <Container>
      <Row>
        <h2>{I18n.t('manual.title', { locale: l })}</h2>
      </Row>
      <Row>
        <Col xs lg='9'>
          <Card>
            <Card.Title id='recipesList'>{I18n.t('manual.menu.listRecipe', { locale: l })}</Card.Title>
            <div dangerouslySetInnerHTML={{ __html: '<iframe src="../locales/manuals/recipeList_fr.html"></iframe>' }} />
          </Card>
          <Card>
            <Card.Title id='recipesPage'>{I18n.t('manual.menu.recipePage', { locale: l })}</Card.Title>
            <p dangerouslySetInnerHTML={{ __html: I18n.t('manual.menu.recipePageContent', { locale: l }) }} />
          </Card>
          <Card>
            <Card.Title id='listIngredients'>{I18n.t('manual.menu.listIngredients', { locale: l })}</Card.Title>
            <Card.Title id='ingredientPage'>{I18n.t('manual.menu.ingredientPage', { locale: l })}</Card.Title>
          </Card>
          <Card>
            <Card.Title id='ganacheTable'>{I18n.t('manual.menu.ganacheTable', { locale: l })}</Card.Title>
          </Card>
        </Col>
        <Col>
          <Row>
            <Card>
              <Card.Body>
                <ListGroup defaultActiveKey='#recipesList'>
                  <Card.Title>{I18n.t('manual.menu.recipes', { locale: l })}</Card.Title>
                  <ListGroup.Item action href='#recipesList'>{I18n.t('manual.menu.listRecipe', { locale: l })}</ListGroup.Item>
                  <ListGroup.Item action href='#recipesPage'>{I18n.t('manual.menu.recipePage', { locale: l })}</ListGroup.Item>

                  <Card.Title>{I18n.t('manual.menu.ingredients', { locale: l })}</Card.Title>
                  <ListGroup.Item action href='#listIngredients'>{I18n.t('manual.menu.listIngredients', { locale: l })}</ListGroup.Item>
                  <ListGroup.Item action href='#ingredientPage'>{I18n.t('manual.menu.ingredientPage', { locale: l })}</ListGroup.Item>

                  <Card.Title>{I18n.t('manual.menu.ganacheTable', { locale: l })}</Card.Title>
                  <ListGroup.Item action href='#ganacheTable'>{I18n.t('manual.menu.ganacheTable', { locale: l })}</ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Card>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}
*/

const Demo = () => {
  const history = useHistory();
  history.push(Url.payment);
  window.location.reload();
  return <h3>Not Allowed</h3>;
};
