import { Children, forwardRef, useEffect, useState } from "react";
import { Dropdown, FormControl, Button } from "react-bootstrap";
import SearchControler from "src/utils/filter";

const FilterSelect = forwardRef(({ elements, filterName, handleChange, className, defaultValue = "" }, ref) => {
  const [value, setValue] = useState("");
  const [active, setActive] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");

  const CustomToggle = forwardRef(({ children, onClick }, ref) => (
    <Button
      href=""
      ref={ref}
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
      className="mx-auto"
    >
      {children}
      {"  "}▼
    </Button>
  ));

  // forwardRef again here!
  // Dropdown needs access to the DOM of the Menu to measure it
  const CustomMenu = forwardRef(({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    return (
      <div ref={ref} className={className} aria-labelledby={labeledBy} style={style}>
        <FormControl
          autoFocus
          placeholder={value}
          className="w-auto mx-3 my-2"
          onChange={e => setValue(e.target.value.toLowerCase())}
          value={value}
        />
        <hr />
        <ul className="list-unstyled scrollable-menu">
          {Children.toArray(children).filter(child => !value || SearchControler.isSearchingFit(child.props.children, value))}
        </ul>
      </div>
    );
  });

  const handleSelect = (eventKey, elem) => {
    setActive(true);
    handleChange(eventKey);
    setSelectedValue(elem.name);
  };

  const clearFilter = () => {
    setActive(false);
    handleChange("");
  };

  useEffect(() => {
    if (selectedValue === "" && defaultValue !== "") {
      setSelectedValue(defaultValue);
      setActive(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearFilter]);

  return (
    <div className={className}>
      <Dropdown className="form-control filter-select">
        <Dropdown.Toggle as={CustomToggle}>{active ? selectedValue : filterName} </Dropdown.Toggle>

        <Dropdown.Menu as={CustomMenu}>
          {elements.map(elem => {
            return (
              <Dropdown.Item onSelect={eventKey => handleSelect(eventKey, elem)} key={elem._id} eventKey={elem._id}>
                {elem.name}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
      {active ? (
        <Button className="mx-1" variant="outline-primary" size="sm" onClick={clearFilter}>
          X
        </Button>
      ) : null}
    </div>
  );
});
export default FilterSelect;
