import React, { useEffect, useState } from "react";
import { Button, Card, Dropdown, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaUserFriends } from "react-icons/fa";
import { GiOpenFolder, GiPencil, GiTrashCan } from "react-icons/gi";
import { Link } from "react-router-dom";
import { FolderController } from "../../backend/folder";
import I18n from "../../Config/I18n";
import { canWrite, getId, getLanguage } from "../../helpers/auth";
import { POST } from "../../helpers/requests";
import { formatString } from "../../utils";
import Loader from "../loader";
import PopUpFolderDelete from "./PopUpDeleteFolder";

const l = getLanguage();

const FolderCard2 = ({ id, update }) => {
  const [deleted, setDeleted] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [loadingFolder, setLoadingFolder] = useState(false);
  const [name, setName] = useState(id);
  const [rename, setRename] = useState(id);
  const [ownerId, setOwnerId] = useState("");
  const [parentId, setParentId] = useState("");
  const [renameOpen, setRenameOpen] = useState(false);

  useEffect(() => {
    loadFolder();
  }, []);

  const loadFolder = async () => {
    setLoadingFolder(true);
    const loaded = await FolderController.getById(id);
    setName(loaded.name);
    setOwnerId(loaded.ownerId);
    setParentId(loaded.parentId);
    setLoadingFolder(false);
  };

  const canEdit = canWrite() && ownerId === getId();

  const deleteFolder = () => {
    const data = {
      parentId,
      folderId: id,
    };
    setOpenDeletePopup(false);
    setDeleted(true);
    POST("/folders/delete", data).then(response => {
      if (response.error) {
        setDeleted(false);
        return console.error(response.error.message);
      }
    });
  };

  const openModalRename = () => {
    setRename(name);
    setRenameOpen(true);
  };
  const closeModalRename = () => setRenameOpen(false);

  const handleRename = e => {
    setRename(e.target.value);
  };

  const saveRename = async () => {
    const result = await FolderController.rename(id, rename);
    if (result.name === rename) {
      closeModalRename();
      setName(rename);
    }
  };

  const path = window.location.pathname;
  const fold = `${path}/${name}`;
  const foldEncoded = fold;

  return (
    <Card className="folder-card h-100 group" hidden={deleted}>
      {loadingFolder ? (
        <Loader />
      ) : (
        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">{name}</Tooltip>}>
          <a href={foldEncoded} className="flex w-full px-3 py-3 gap-x-2 group-hover:text-ganache-neutral-100 group-hover:no-underline">
            {canEdit ? <GiOpenFolder size="1.5em" className="folder-icon" /> : <FaUserFriends size="1.5em" className="folder-icon" />}
            {formatString(name, 15)}
          </a>
        </OverlayTrigger>
      )}
      <Dropdown className="my-auto mr-1 ">
        <Dropdown.Toggle split />
        <Dropdown.Menu>
          <Dropdown.Item className="flex items-center gap-x-3" onClick={openModalRename} disabled={!canEdit}>
            <GiPencil size="1.5em" />
            {I18n.t("folder.renameButton", { locale: l })}
          </Dropdown.Item>
          <Dropdown.Item className="flex items-center gap-x-3" onClick={() => setOpenDeletePopup(true)} disabled={!canEdit}>
            <GiTrashCan size="1.5em" />
            {I18n.t("recipeAction.delete", { locale: l })}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Modal show={renameOpen} onHide={closeModalRename} centered>
        <Modal.Header closeButton>
          <Modal.Title>{I18n.t("folder.renameFolder", { locale: l })}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={e => {
              e.preventDefault();
              saveRename();
            }}
          >
            <Form.Group>
              <Form.Label>{I18n.t("folder.nameFolder", { locale: l })}</Form.Label>
              <Form.Control type="text" id="name" value={rename} autoFocus onChange={handleRename} />
              <Form.Control.Feedback type="invalid">This name already exists.</Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={saveRename} color="primary">
            {I18n.t("folder.renameButton", { locale: l })}
          </Button>
        </Modal.Footer>
      </Modal>
      <PopUpFolderDelete
        open={openDeletePopup}
        folderId={id}
        folderName={name}
        closeFunction={() => setOpenDeletePopup(false)}
        deleteFunction={deleteFolder}
      />
    </Card>
  );
};

export default FolderCard2;
