import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Document, Page, pdfjs } from "react-pdf";
import { getRole } from "../helpers/auth";
import { Url } from "../Config/url";
import { getUrlFile } from "../helpers/requests";
import { useHistory } from "react-router-dom";

export default function Manual() {
  const [numPages, setNumPages] = useState(0);
  const history = useHistory();

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  if (getRole() === "demo") {
    history.push(Url.payment);
    return history.go(0);
  }

  return (
    <div id="manual">
      <Document
        file={{ url: getUrlFile("/manual"), withCredentials: true }}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={err => {
          console.error(err);
        }}
        loading={<Spinner animation="border" variant="primary" />}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} width={document.getElementById("manual")?.offsetWidth || 1000} />
        ))}
      </Document>
    </div>
  );
}
