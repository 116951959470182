import { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { GiMagnifyingGlass } from "react-icons/gi";
import PaymentAPI from "../../backend/payment";
import I18n from "../../Config/I18n";
import { getLanguage } from "../../helpers/auth";
import Loader from "../loader";
const l = getLanguage();

export default function Payment({ step }) {
  const [price, setPrice] = useState({
    currency: "EUR",
    value: 0,
    VAT: {
      percentage: 21,
      value: 0,
    },
    coupon: {
      name: "",
      percentage: 0,
      value: 0,
    },
    frequency: "unique",
    isOnboard: true,
  });
  const [isLoadingPrice, setIsLoadingPrice] = useState<boolean>(false);

  useEffect(() => {
    loadPrice();
    // eslint-disable-next-line
  }, []);

  const loadPrice = async () => {
    setIsLoadingPrice(true);
    const resp = await PaymentAPI.getPrice(price.coupon.name);
    if (resp.error) return console.error(resp.error);
    setPrice(resp);

    setIsLoadingPrice(false);
  };

  const handleChangeCoupon = event => {
    const newPrice = { ...price };
    newPrice.coupon.name = event.target.value;
    setPrice(newPrice);
  };

  step.getCoupon = () => {
    return price.coupon.name;
  };

  const total = price.value + price.VAT.value - price.coupon.value;
  return (
    <div>
      <Row className="my-3">
        {isLoadingPrice ? (
          <Col className="text-right">
            <Loader />
          </Col>
        ) : (
          <Col className="text-right">
            {price.value !== total && (
              <h6>
                {I18n.t("payment.price", { locale: l })} : {price.value} €
              </h6>
            )}
            {price.coupon.value !== 0 ? (
              <h6>
                {" "}
                {I18n.t("payment.reduction", { locale: l })} : ({price.coupon.name}) -{price.coupon.value} €{" "}
              </h6>
            ) : null}
            {price.VAT.value !== 0 ? (
              <h6>
                {I18n.t("payment.VAT", { locale: l })} ({price.VAT.percentage}%): {price.VAT.value} €
              </h6>
            ) : null}
            <h5>
              {I18n.t("payment.totalPrice", { locale: l })} : {total} €
            </h5>
          </Col>
        )}
      </Row>
      {!price.isOnboard ? (
        <Row className="my-2">
          <Col>
            <Form.Label htmlFor="coupon">{I18n.t("payment.addCoupon", { locale: l })}</Form.Label>
            <InputGroup>
              <Form.Control
                required
                id="coupon"
                autoComplete="coupon"
                type="text"
                value={price.coupon.name}
                placeholder={I18n.t("payment.addCoupon", { locale: l })}
                onChange={handleChangeCoupon}
              />
              <InputGroup.Append>
                <Button variant="primary" onClick={loadPrice} aria-label="search">
                  <GiMagnifyingGlass />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
        </Row>
      ) : null}
    </div>
  );
}
