import { Modal, Button, Form } from "react-bootstrap";
import { getLanguage } from "../../helpers/auth";
import I18n from "../../Config/I18n";
import { useState } from "react";
import { useHaveWriteAccess } from "src/helpers/access";

const l = getLanguage();

export default function CreateFolderPopup({ open, handleClose, linkNewRecipe, addFolder }) {
  const [name, setName] = useState("");

  useHaveWriteAccess(open);

  const handleChange = event => {
    // No space in the name of folder
    setName(event.target.value);
  };

  const submitFolder = () => {
    addFolder(name.trim());
    handleClose();
    setName("");
  };

  return (
    <Modal show={open} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t("folder.createFolder", { locale: l })}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          onSubmit={e => {
            e.preventDefault();
            submitFolder();
          }}
        >
          <Form.Group>
            <Form.Label>{I18n.t("folder.nameFolder", { locale: l })}</Form.Label>
            <Form.Control type="text" id="name" value={name} autoFocus onChange={handleChange} />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={submitFolder} color="primary">
          {I18n.t("create", { locale: l })}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
