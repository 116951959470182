import { FC } from "react";

export const ButtonText: FC<{ type: "button" | "submit" | "reset"; variant: "primary" | "secondary" }> = ({
  type = "button",
  variant = "primary",
  children,
  ...props
}) => {
  return (
    <button
      type={type}
      className={`px-2 py-1.5 transition-colors rounded-sm h-max w-max focus:outline-none ${
        variant === "primary"
          ? "bg-ganache-orange-500 text-ganache-neutral-100 hover:bg-ganache-orange-600 active:text-ganache-neutral-100"
          : "bg-gray-500 text-ganache-neutral-100 hover:bg-gray-600 active:text-ganache-neutral-100"
      }`}
      {...props}
    >
      {children}
    </button>
  );
};
