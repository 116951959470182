import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { FaCheck, FaEdit, FaRegTrashAlt, FaReply } from "react-icons/fa";
import { getLanguage, getId } from "../../helpers/auth";
import I18n from "../../Config/I18n";
import CategoryApi from "../../backend/recipeCategory";
import { ButtonIconOrange, ButtonIconBrown } from "../buttons/iconButton";
import { InputOrange } from "../form";

const l = getLanguage();

const Content = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    loadCategories();
  }, []);

  const loadCategories = async () => {
    const userId = getId();
    const resp = await CategoryApi.getByUser(userId);
    const cats = resp.data;
    setCategories(cats.filter(cat => cat.ownerId === userId));
  };

  const deleteCategory = async id => {
    await CategoryApi.deleteCategory(id);
    loadCategories();
  };

  return (
    <Card id="content">
      <Card.Header>
        <h3 className="my-3 text-3xl font-semibold">{I18n.t("content.myRecipeCategory", { locale: l })}</h3>
      </Card.Header>
      <Card.Body>
        {categories.map((cat, i) => (
          <div className={`py-2 px-2 hover:bg-gray-200/80 ${i % 2 === 0 && "bg-gray-100"}`}>
            <TableRow key={cat._id} category={cat} deleteCategory={deleteCategory} />
          </div>
        ))}
      </Card.Body>
    </Card>
  );
};

const TableRow = ({ category, deleteCategory }) => {
  const [isEdit, setEdit] = useState(false);
  const [name, setName] = useState(category.name);

  const update = async () => {
    await CategoryApi.update(category._id, name);
    setEdit(false);
  };

  return (
    <>
      {isEdit ? (
        <form
          onSubmit={e => {
            e.preventDefault();
            update();
          }}
          className="flex items-center justify-between gap-x-5"
        >
          <InputOrange type="text" value={name} onChange={e => setName(e.target.value)} />
          <div className="flex gap-x-2">
            <ButtonIconBrown
              type="button"
              onClick={() => {
                setName(category.name);
                setEdit(false);
              }}
            >
              <FaReply />
            </ButtonIconBrown>
            <ButtonIconOrange type="submit">
              <FaCheck />
            </ButtonIconOrange>
          </div>
        </form>
      ) : (
        <div className="flex items-center justify-between gap-x-5">
          <p className="pl-2">{name}</p>
          <div className="flex gap-x-2">
            <ButtonIconOrange onClick={() => deleteCategory(category._id)}>
              <FaRegTrashAlt />
            </ButtonIconOrange>
            <ButtonIconOrange onClick={() => setEdit(true)}>
              <FaEdit />
            </ButtonIconOrange>
          </div>
        </div>
      )}
    </>
  );
};

export default Content;
