import { useEffect, useState } from "react";
import { ButtonText } from "src/components/buttons/button";
import { Modal } from "react-bootstrap";
import RecipeAPI from "../../backend/recipe";
import I18n from "../../Config/I18n";
import { getLanguage } from "../../helpers/auth";

const l = getLanguage();

export const FlagModal = ({ recipe, setRecipe }) => {
  const [show, setShow] = useState();

  useEffect(() => setShow(recipe.flags && recipe.flags.migrationV1), [recipe.flags]);

  const handleDismiss = async () => {
    const response = await RecipeAPI.dismissFlag(recipe._id, "migrationV1");
    setShow(false);
    setRecipe(response);
  };

  const handleHidde = async () => {
    setShow(false);
  };

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t("recipeError.title", { locale: l })}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{I18n.t("recipeError.message", { locale: l })}</Modal.Body>
      <Modal.Footer>
        <ButtonText variant="secondary" onClick={handleHidde}>
          {I18n.t("recipeError.hide", { locale: l })}
        </ButtonText>
        <ButtonText variant="primary" onClick={handleDismiss}>
          {I18n.t("recipeError.dismiss", { locale: l })}
        </ButtonText>
      </Modal.Footer>
    </Modal>
  );
};
