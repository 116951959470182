import { useEffect, useState } from "react";
import I18n from "../../Config/I18n";
import { getLanguage } from "../../helpers/auth";
import { Col, Form, Spinner } from "react-bootstrap";
import UserAPI from "../../backend/user";
import DataAPI from "../../backend/data";
const l = getLanguage();

/**
 *
 * @param {{
 * startLoading:Function,
 * stopLoading:Function,
 * step: paymentStep
 * }} param0
 */
export default function VerifyData({ startLoading, stopLoading, step }) {
  /**
   * @type {[import('../../Type/user').UserInfo, Function]}
   */
  const [user, setUser] = useState({});
  const [isCompany, setIsCompany] = useState(false);
  const [isCheckingVAT, setIsCheckingVAT] = useState(false);
  /**
   * @type {[[Country], Function]}
   */
  const [countries, setCountries] = useState([]);
  const [valid, setValid] = useState({
    companyName: true,
    companyVat: true,
    country: true,
  });

  const company = user.company
    ? user.company
    : {
        name: "",
        VAT: "",
      };
  const address =
    isCompany && user.company && user.company.address
      ? user.company.address
      : user.address || {
          street: "",
          city: "",
          zipCode: "",
          country: "",
        };
  useEffect(() => {
    const loadData = async () => {
      startLoading();
      await Promise.all([loadUser(), loadCountries()]);
      stopLoading();
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadUser = async () => {
    startLoading();
    const userInfo = await UserAPI.getInfo();
    if (userInfo.data && userInfo.data.company) setIsCompany(true);
    setUser(userInfo.data);
    stopLoading();
  };
  const loadCountries = async () => {
    startLoading();
    const resp = await DataAPI.getCountries();
    setCountries(resp.data);
    stopLoading();
  };

  const handleChangeAddress = event => {
    let userUpdate = { ...user };
    if (isCompany) {
      userUpdate.company.address = {
        ...user.company.address,
        [event.target.name]: event.target.value,
      };
    } else {
      userUpdate.address = {
        ...user.address,
        [event.target.name]: event.target.value,
      };
    }
    setUser(userUpdate);
  };

  const handleChangeCompany = event => {
    const userUpdate = { ...user };
    userUpdate.company = user.company ? { ...user.company } : { name: "", VAT: "" };
    userUpdate.company[event.target.name] = event.target.value.replace(/[^\w\s]/gi, "");
    setUser(userUpdate);
  };

  /**
   * CHECK VAT
   */

  const checkVAT = async () => {
    /** @type {String} */
    var vat = user?.company?.VAT;
    if (!vat) return false;
    if (!/^[a-zA-Z()]+$/.test(vat.substring(0, 2)) && address.country) {
      vat = address.country + vat;
    }

    setIsCheckingVAT(true);
    const resp = await DataAPI.checkVAT(vat);
    var validity = false;
    if (resp && resp.valid) {
      const u = { ...user };
      u.company.name = resp.name;
      u.company.VAT = vat;

      if (!u.company.address) {
        u.company.address = { country: "" };
      }
      u.company.address.country = resp.countryCode;

      setUser(u);
      validity = resp.valid;
    }
    setValid({
      ...valid,
      companyVat: validity,
    });
    setIsCheckingVAT(false);
  };

  const isValid = () => {
    if (isCompany) {
      if (!user.company) {
        valid.companyName = false;
        valid.companyVat = false;
        setValid({ ...valid });
        return false;
      }
      valid.companyName = user.company.name.length > 0;
      valid.companyVat = user.company.VAT.length > 0;
      setValid({ ...valid });
      return valid.companyName && valid.companyVat;
    }
    return user.address && user.address.country;
  };

  // setup function to be use in parent
  step.isValid = async () => {
    if (isValid()) {
      try {
        const data = {
          address: user.address,
        };
        if (isCompany) {
          data.company = { ...user.company, address };
        }
        await UserAPI.update(data);
        return true;
      } catch (err) {
        console.error(err);
      }
    }
    return false;
  };

  return (
    <>
      <h6>{I18n.t("informations", { locale: l })}</h6>
      <Form>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Check type="radio" checked={!isCompany} onClick={() => setIsCompany(false)} label={I18n.t("consumer", { locale: l })} />
            <Form.Check type="radio" checked={isCompany} onClick={() => setIsCompany(true)} label={I18n.t("business", { locale: l })} />
          </Form.Group>
        </Form.Row>
        <hr />
        <Form.Row>
          <Form.Group hidden={!isCompany} as={Col}>
            <Form.Label>
              {I18n.t("user.vat", { locale: l })}
              {<Spinner animation="border" variant="primary" hidden={!isCheckingVAT} />}
            </Form.Label>
            <Form.Control
              required
              id="VAT"
              placeholder={I18n.t("user.vat", { locale: l })}
              onChange={handleChangeCompany}
              onBlur={checkVAT}
              name="VAT"
              value={company.VAT}
              isInvalid={!valid.companyVat}
              disabled={isCheckingVAT}
            />
            <Form.Text id="passwordHelpBlock" muted>
              <a
                href="https://ec.europa.eu/taxation_customs/vies/faqvies.do#item_11"
                // eslint-disable-next-line react/jsx-no-target-blank
                target="_blank"
              >
                {I18n.t("user.VATEUFormat", { locale: l })}
              </a>
            </Form.Text>
            <Form.Control.Feedback type="invalid" isInvalid={!valid.companyVat}>
              {I18n.t("user.VATNotEU", { locale: l })}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group hidden={!isCompany} as={Col}>
            <Form.Label>{I18n.t("user.companyName", { locale: l })}</Form.Label>
            <Form.Control
              required
              id="name"
              name="name"
              placeholder={I18n.t("user.companyName", { locale: l })}
              onChange={handleChangeCompany}
              autoComplete="Company"
              value={company.name}
              isInvalid={!valid.companyName}
              disabled={isCheckingVAT}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md={8}>
            <Form.Label>{I18n.t("user.street", { locale: l })}</Form.Label>
            <Form.Control
              required
              id="street"
              name="street"
              placeholder={I18n.t("user.street", { locale: l })}
              onChange={handleChangeAddress}
              autoComplete="Street"
              value={address.street ? address.street : ""}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>{I18n.t("user.number", { locale: l })}</Form.Label>
            <Form.Control
              required
              id="number"
              name="number"
              placeholder={I18n.t("user.number", { locale: l })}
              onChange={handleChangeAddress}
              autoComplete="Number"
              value={address.number ? address.number : ""}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>{I18n.t("user.city", { locale: l })}</Form.Label>
            <Form.Control
              required
              id="city"
              name="city"
              placeholder={I18n.t("user.city", { locale: l })}
              onChange={handleChangeAddress}
              autoComplete="city"
              value={address.city ? address.city : ""}
            />
          </Form.Group>
          <Form.Group as={Col} md={3}>
            <Form.Label>{I18n.t("user.zipCode", { locale: l })}</Form.Label>
            <Form.Control
              required
              id="zipCode"
              name="zipCode"
              placeholder={I18n.t("user.zipCode", { locale: l })}
              onChange={handleChangeAddress}
              autoComplete="zipCode"
              value={address.zipCode ? address.zipCode : ""}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>{I18n.t("user.country", { locale: l })}</Form.Label>
            <Form.Control as="select" name="country" value={address.country} onChange={handleChangeAddress}>
              {countries.map(element => {
                // <div> <Image src={element.flag} rounded /> }<h6>{element.name}</h6></div>
                return (
                  <option key={element.countryCode} value={element.countryCode}>
                    {element.name}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
        </Form.Row>
      </Form>
    </>
  );
}

// onChange={(e) => handleChangeLastname(e.target.value)}
/*

const typeOptions = [
  {
    value: 'consumer',
    title: `${I18n.t('consumer', { locale: l })}`
  },
  {
    value: 'business',
    title: `${I18n.t('business', { locale: l })}`
  }
]
*/
