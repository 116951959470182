import { GET, POST, POSTFile, backend } from "../helpers/requests";

/**
 *
 * @param {import('../Type/ingredient').Ingredient} newIngredient
 * @return {{
 *  data: import('../Type/ingredient').Ingredient,
 *  error: {}
 * }}
 */
const add = newIngredient => {
  return POST("/ingredient/add", newIngredient);
};
/**
 *
 * @param {import('../Type/ingredient').Ingredient} ingredient
 * @return {{
 *  data: import('../Type/ingredient').Ingredient,
 *  error: {}
 * }}
 */
const update = ingredient => {
  return POST("/ingredient", ingredient);
};

const getById: (id: string, temperature?: number) => Promise<{ data: any; error: any }> = (id, temperature) => {
  var url = `/ingredient/${id}`;
  if (temperature) url += `/${temperature}`;
  return GET(url);
};

const getCategories = () => {
  return GET("/ingredient/category");
};

const getRecipeCategoryId = () => {
  return GET("/ingredient/category/recipe");
};

const deleteById = async ingId => {
  await POST("/ingredient/delete", { ingredientId: ingId });
};

/**
 *
 * @param {{
 * categoryId?:String,
 * brandId?:String,
 * name?:String,
 * start?: Number,
 * limit?:Number
 * details?: Boolean
 * }} filter
 */
const filter = filter => {
  return POST("/ingredient/filter", filter);
};

const getAll = () => {
  return POST("/ingredient/getIngredients", {});
};

const importFoodCost = data => {
  return POSTFile("/ingredient/import", data);
};

const uploadIngTranslation = data => {
  return POSTFile("/ingredient/importTranslate", { data });
};

const downloadIngredientDataUrl = data => {
  return `${backend}/ingredient/exportAll`;
};
const uploadIngTData = data => {
  return POSTFile("/ingredient/importAll", { data });
};

const downloadIngredientTranslationUrl = () => {
  return `${backend}/ingredient/exportTranslate`;
};

const updateIngredientNote = data => {
  return POST("/ingredient/updateNote", data);
};

export default {
  add,
  update,
  getById,
  filter,
  getCategories,
  getRecipeCategoryId,
  deleteById,
  getAll,
  importFoodCost,
  updateIngredientNote,
  uploadIngTranslation,
  downloadIngredientDataUrl,
  uploadIngTData,
  downloadIngredientTranslationUrl,
};
