import { FC, useEffect, useState } from "react";
import { OverlayTrigger, Popover, Table } from "react-bootstrap";
import I18n from "../../Config/I18n";
import { getLanguage } from "../../helpers/auth";
import { IoMdEye } from "react-icons/io";
import { Recipe } from "src/Type/recipe2";
import { Brand } from "src/Type/brand";
import GanacheFormat from "src/utils/ganacheFormat";
import { getGroupTemperatureFromRecipe } from "src/utils/Recipe";

const l = getLanguage();

const reducer = (accumulator, value) => accumulator + value;
/**
 * @returns {number}
 */
const getTotalWeightAfterCooking = cooked => {
  if (!cooked) return 0;
  return Object.keys(cooked)
    .map(temp => {
      const qty = cooked[temp].total.quantity || 0;
      return qty;
    })
    .reduce(reducer, 0);
};

// The recipeHoover MUST contains all the ingredients (id, ...) of the recipe.
/**
 *
 * @param {{
 *  recipeHoover: import("../../Type/recipe").Recipe
 * }} param0
 * @returns
 */
const RecipeOverview: FC<{ recipeHoover: Recipe; brands: Brand[] }> = ({ recipeHoover, brands }) => {
  /**
   * @type {[Object.<number,import("../../Type/recipe").GroupTemperatureData>, Function]}
   */
  const [cooking, setCooking] = useState({});
  /**
   * @type {[number[], Function]}
   */
  const [totalWeight, setTotalWeight] = useState(0);

  useEffect(() => {
    computeEvaporation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipeHoover]);

  const computeEvaporation = async () => {
    const cooked = await getGroupTemperatureFromRecipe(recipeHoover);
    setCooking(cooked);
    const totalWeightRecipe = getTotalWeightAfterCooking(cooked);
    setTotalWeight(totalWeightRecipe.toFixed(1));
  };

  const getBrandName = id => {
    const brand = brands.find(elt => elt._id === id);
    return brand ? `(${brand.name})` : "";
  };

  return (
    <OverlayTrigger
      placement="right"
      overlay={
        <Popover id="popover-basic" style={{ overflowWrap: "anywhere", minWidth: "400px" }}>
          <Popover.Title>{recipeHoover.name ? recipeHoover.name : ""}</Popover.Title>
          <Popover.Content>
            <Table striped bordered>
              <thead>
                <tr>
                  <th style={{ flex: 3 }}>
                    <u>{I18n.t("ingredient.nameIngredient", { locale: l })}</u>
                  </th>
                  <th style={{ flex: 1 }}>
                    <u>{I18n.t("listIngredients.qty", { locale: l })} (g)</u>
                  </th>
                  <th style={{ flex: 1 }}>
                    <u>{I18n.t("listIngredients.qty", { locale: l })} (%)</u>
                  </th>
                </tr>
              </thead>
              <tbody>
                {recipeHoover.ingredients &&
                  Object.keys(cooking)
                    .sort((a, b) => parseInt(b) - parseInt(a))
                    .map(temp => {
                      const temperature = parseInt(temp);
                      const ingredients = recipeHoover.ingredients.filter(ing => {
                        const ingTemp = ing.temperature || 0;
                        return ingTemp === temperature;
                      });
                      const groupWeight = temperature ? ingredients.map(ing => ing.quantity).reduce(reducer, 0) : totalWeight;
                      const result = ingredients.map((ing, index) => (
                        <tr key={index}>
                          <td>
                            {ing.ingredient.name} {ing.ingredient.brandId ? getBrandName(ing.ingredient.brandId) : ""}
                          </td>
                          <td>{GanacheFormat.round(ing.quantity)}</td>
                          <td>{GanacheFormat.percentage((ing.quantity * 100) / groupWeight)}%</td>
                        </tr>
                      ));
                      if (temperature)
                        result.push(
                          <tr key={temperature + "temperature"} className="table-warning">
                            <td>
                              {I18n.t("listIngredients.totalIngredientsTemp", { locale: l })}
                              {temperature}°C
                            </td>
                            <td>{GanacheFormat.round(cooking[temperature]?.total?.quantity)}g</td>
                            <td>{GanacheFormat.percentage((cooking[temperature].total.quantity * 100) / totalWeight)}%</td>
                          </tr>
                        );

                      return result;
                    })}
                <tr>
                  <td>
                    <strong>{I18n.t("listIngredients.totals", { locale: l })}</strong>
                  </td>
                  <td>{GanacheFormat.round(totalWeight)}</td>
                  <td>100%</td>
                </tr>
              </tbody>
            </Table>
          </Popover.Content>
        </Popover>
      }
      delay={{ show: 100, hide: 100 }}
    >
      <IoMdEye />
    </OverlayTrigger>
  );
};

export default RecipeOverview;
