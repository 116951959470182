import { FC } from "react";
import { HiBadgeCheck, HiBan } from "react-icons/hi";
import { Invoice } from "src/Type/invoice";
import { format } from "date-fns";

export const AdminInvoicesTable: FC<{ invoices: Invoice[] }> = ({ invoices, children }) => {
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Number
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Date
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Name
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Coupon
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Total
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Paid
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {invoices.map((invoice, index) => (
                  <InvoiceLine key={invoice._id} invoice={invoice} index={index} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const InvoiceLine: FC<{ invoice: Invoice; index: number }> = ({ invoice, index }) => {
  return (
    <tr className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{invoice.invoiceNumber}</td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{format(new Date(invoice.paidDate), "yyyy-MM-dd")}</td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{invoice.name}</td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {invoice.amount?.coupon?.name ? `${invoice.amount.coupon.name} - ${invoice.amount.coupon.percentage}%` : "-"}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{`${
        invoice.amount.value + (invoice.amount?.VAT?.value | 0) - (invoice.amount?.coupon?.value | 0)
      } ${invoice.amount.currency}`}</td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {invoice.isPaid ? <HiBadgeCheck className="text-green-600 text-xl" /> : <HiBan className="text-red-600 text-xl" />}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
        <a href={`https://www.mollie.com/dashboard/org_9831587/payments/${invoice.transactionId}`} target="_blank" rel="noreferrer">
          see on Mollie
        </a>
      </td>
    </tr>
  );
};
