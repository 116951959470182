import { useState, useEffect, FC } from "react";
import TablePreviewRecipeComposition from "./TablePreviewRecipeComposition";
import TablePreviewRecipeInfo from "./TablePreviewRecipeInfo";
// import logo from '../../../Layouts/logo.png'
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import RecipePDF from "./RecipePDF";
import SwitchRecipePDF from "./SwitchRecipePDF";
import MultipleRecipesPrintPreview from "./MultipleRecipesPrintPreview";
import { GET } from "../../../helpers/requests";
import { getStatusById, getNameCategoryById } from "../../../utils/index";
import { getContentIds, getFitForIds, getIngredients, getTotalContributionRecipe } from "../../../utils/recipes";
import I18n from "../../../Config/I18n";
import { getProfilePicture } from "../../../helpers/utils";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
//@ts-ignore
import defaultLogo from "../../../assets/img/logo.png";
import { getLanguage } from "../../../helpers/auth";
import { FaArrowLeft } from "react-icons/fa";
import { Url } from "../../../Config/url";
import FolderAPI from "../../../backend/folder";
import { getGroupTemperatureFromRecipe } from "../../../utils/Recipe";
import { useHistory } from "react-router-dom";
import { FlagModal } from "../FlagModal";
import { Recipe } from "src/Type/recipe2";

const l = getLanguage();

const RecipePreview: FC<{ categories; status; recipes: Recipe[]; setRecipes: Function }> = ({
  categories,
  status,
  recipes,
  setRecipes,
}) => {
  const [viewPDF, setViewPDF] = useState(false);
  const [contents, setContents] = useState([]);
  const [fitfor, setFitfor] = useState([]);
  const [value, setValue] = useState(0);
  const [fileName, setFileName] = useState(recipes.length > 1 ? I18n.t("print.defaultDocumentTitle", { locale: l }) : recipes[0].name);
  const [combinedRecipe, setCombinedRecipe] = useState(false);
  const [userLogo, setUserLogo] = useState(defaultLogo);
  const [checked, setChecked] = useState({
    picture: true,
    procedure: true,
    owner: true,
    name: true,
    status: true,
    categories: true,
    note: true,
    lastUpdateOn: true,
    fitfor: true,
    contents: true,
    price: true,
    source: true,
    commercialName: true,
    totals: true,
  });

  const [price, setPrice] = useState(0);

  const history = useHistory();

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const loadData = async () => {
    // setLoading(true);
    await Promise.all([loadContents(), loadFitfor(), loadUserImage(), computeEvaporation()]);
    // setLoading(false);
  };

  const loadUserImage = async () => {
    const image = await getProfilePicture();
    setUserLogo(image);
  };

  const loadContents = async () => {
    const contents = await GET("/ingredient/content");
    if (contents.error) {
      return console.error(contents.error.message);
    }
    setContents(contents.data);
  };

  const loadFitfor = async () => {
    const fitfor = await GET("/ingredient/fitfor");
    if (fitfor.error) {
      return console.error(fitfor.error.message);
    }
    setFitfor(fitfor.data);
  };

  const handleToggleCombinedRecipe = () => {
    setCombinedRecipe(!combinedRecipe);
  };

  const backToRecipe = async () => {
    const urlParams = new URLSearchParams(window.location.search);

    const urlToks = window.location.pathname.split("/");
    const recipeId = urlToks[urlToks.length - 1];
    const folder = await FolderAPI.getFolderRecipe(recipeId);

    // GO back from the edit recipe page
    if (urlParams.has("page")) {
      var url = "";
      url += "?id=" + recipeId;
      url += "&folderId=" + folder._id;
      url += "&page=" + urlParams.get("page");
      history.push(Url.recipe + url);
      // GO back from the folder view
    } else {
      let folderName = "";
      if (folder.name) {
        folderName = "/" + encodeURI(folder.name);
      }
      history.push(Url.folder + folderName);
    }
  };
  // MALO ->

  /**
   * @type {import('../../../Type/recipe').Recipe}
   */
  const recipe = recipes[value];
  const ingredients = getIngredients(recipe);
  const contentsIds = getContentIds(ingredients);
  const recipeContents = contents.filter(content => contentsIds.includes(content._id));
  const fitforIds = getFitForIds(ingredients);
  const recipeFitfors = fitfor.filter(fit => fitforIds.includes(fit._id));
  const statut = getStatusById(status, recipe.statusId);
  const category = getNameCategoryById(categories, recipe.categoryId);

  const reducer = (accumulator, value) => accumulator + value;

  const getTotalWeigthAfterCooking = cooked => {
    if (!cooked) return 0;
    return Object.keys(cooked)
      .map(temp => {
        const qty = cooked[temp].total.quantity || 0;
        return qty;
      })
      .reduce(reducer, 0);
  };

  const computeEvaporation = async () => {
    const cooked = await getGroupTemperatureFromRecipe(recipe);
    const totalWeigthRecipe = getTotalWeigthAfterCooking(cooked);
    const price = (1000 / totalWeigthRecipe) * getTotalContributionRecipe(recipe).foodCost;
    setPrice(price);
  };

  useEffect(() => {
    computeEvaporation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipes]);

  return (
    <>
      <FlagModal recipe={recipe} setRecipe={null} />
      <div className="mx-3 mt-20 mb-4">
        <Card>
          <Card.Header>
            <Row>
              <Col>
                <Button onClick={() => backToRecipe()}>
                  <FaArrowLeft />
                </Button>
                <Card.Title className="mt-2">
                  {combinedRecipe
                    ? I18n.t("print.combinedRecipe", { locale: l })
                    : recipes.length > 1
                    ? I18n.t("print.printRecipesPreview", { locale: l })
                    : I18n.t("print.printRecipePreview", { locale: l })}
                </Card.Title>
              </Col>
              <Col className="text-right">
                <Form hidden={recipes.length < 2}>
                  <Form.Check
                    id="comb"
                    type="switch"
                    checked={combinedRecipe}
                    onChange={() => handleToggleCombinedRecipe()}
                    label={I18n.t("print.combined", { locale: l })}
                    name="Combined Recipe"
                  />
                </Form>
                <Button onClick={() => setViewPDF(true)} className="mx-2">
                  {I18n.t("print.preview", { locale: l })}
                </Button>
                <PDFDownloadLink
                  document={
                    <RecipePDF
                      fileName={fileName}
                      recipes={recipes}
                      checked={checked}
                      contents={contents}
                      fitfor={fitfor}
                      categories={categories}
                      status={status}
                      combinedPrint={combinedRecipe}
                      userLogo={userLogo}
                    />
                  }
                  fileName={fileName || recipes[0].name}
                  style={{ textDecoration: "none" }}
                >
                  <Button>{I18n.t("print.download", { locale: l })}</Button>
                </PDFDownloadLink>
              </Col>
            </Row>
          </Card.Header>
          {viewPDF ? (
            <Modal scrollable centered size="lg" show={viewPDF} onHide={() => setViewPDF(false)}>
              <Modal.Header closeButton>
                <Modal.Title>PDF</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <PDFViewer width="100%" height="1000px" style={{ border: "none" }}>
                  <RecipePDF
                    fileName={fileName}
                    recipes={recipes}
                    checked={checked}
                    contents={contents}
                    fitfor={fitfor}
                    categories={categories}
                    status={status}
                    combinedPrint={combinedRecipe}
                    userLogo={userLogo}
                  />
                </PDFViewer>
              </Modal.Body>
            </Modal>
          ) : null}
          <Card.Body>
            <Row>
              <Col lg={3}>
                <SwitchRecipePDF
                  checked={checked}
                  setChecked={setChecked}
                  fileName={fileName}
                  setFileName={setFileName}
                  combinedRecipe={combinedRecipe}
                />
              </Col>
              <Col className="p-3 border">
                <Row className="mb-3 justify-content-between">
                  <Col className="my-auto text-left">
                    <h3>{fileName}</h3>
                    <MultipleRecipesPrintPreview recipes={recipes} value={value} setValue={setValue} />
                  </Col>
                  <Col>
                    {checked.picture && (
                      <img alt="logo" src={userLogo} style={{ maxHeight: "100px", textAlign: "right" }} className="m-3" />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <TablePreviewRecipeComposition recipes={recipes} checked={checked} value={value} setRecipes={setRecipes} />
                  </Col>
                  <Col>
                    <TablePreviewRecipeInfo
                      recipe={recipes[value]}
                      arrayContentInRecipe={recipeContents}
                      arrayFitforInRecipe={recipeFitfors}
                      statut={statut}
                      category={category}
                      checked={checked}
                      foodCost={price}
                      allFitFors={fitfor}
                    />
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col>
                    {checked.procedure ? (
                      <>
                        <h5>{I18n.t("print.category.method", { locale: l })}</h5>
                        <div dangerouslySetInnerHTML={{ __html: recipe.procedure }}></div>
                        {/*procedureRecipe*/}
                      </>
                    ) : null}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default RecipePreview;
