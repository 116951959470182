import { isNaN, isString } from "lodash";
import { getCurrency } from "src/helpers/auth";

const round: (value: string | number) => number = value => {
  if (!value || (isString(value) && isNaN(Number(value)))) return 0;
  if (isString(value)) value = Number(value);
  return Number(value.toFixed(value < 10 ? 1 : 0));
};

const percentage: (value: string | number) => string = value => {
  if (!value || (isString(value) && isNaN(Number(value)))) value = 0;
  if (isString(value)) value = Number(value);
  return value.toFixed(1);
};

const price: (value: string | number) => string = value => {
  if (!value || (isString(value) && isNaN(Number(value)))) value = 0;
  if (isString(value)) value = Number(value);
  return `${value.toFixed(2)} ${getCurrency()}`;
};

const GanacheFormat = {
  round,
  percentage,
  price,
};

export default GanacheFormat;
