import { FC, useEffect, useState } from "react";
import { getCurrency, getLanguage } from "../../../../helpers/auth";
import { Card, Table } from "react-bootstrap";
import I18n from "../../../../Config/I18n";

import StringReducer from "../../../generic/StringReducer";
import { getGroupTemperatureFromRecipe } from "../../../../utils/Recipe";
import GanacheFormat from "src/utils/ganacheFormat";

const l = getLanguage();

export default function RecipeInfo({ recipe, status }) {
  /**
   * @type {[Object.<number,import("../../../../Type/recipe").GroupTemperatureData>, Function]}
   */
  const [cooking, setCooking] = useState({});
  const [totalWeigth, setTotalWeigth] = useState(0);

  useEffect(() => {
    computeEvaporation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipe]);
  const reducer = (accumulator, value) => accumulator + value;

  const computeEvaporation = async () => {
    const cooked = await getGroupTemperatureFromRecipe(recipe);
    setCooking(cooked);
    const totalWeigthRecipe = getTotalWeigthAfterCooking(cooked);
    setTotalWeigth(totalWeigthRecipe.toFixed(1));
  };

  /**
   * @returns {number}
   */
  const getTotalWeigthAfterCooking = (cooked = cooking) => {
    return Object.keys(cooked)
      .map(temp => {
        const qty = cooked[temp].total.quantity || 0;
        return qty;
      })
      .reduce(reducer, 0);
  };

  const getFinalContribution = () => {
    const totalWeigth = getTotalWeigthAfterCooking();
    const totalContributions = Object.keys(cooking).map(temp => {
      const temperature = parseInt(temp);
      const group = cooking[temperature];
      const coef = group.total.quantity / totalWeigth;
      const composition = group.total.composition;

      const contribution = {
        sugar: composition.sugar * coef,
        butter: composition.fats.butter * coef,
        cocoa_butter: composition.fats.cocoa_butter * coef,
        oil: composition.fats.oil * coef,
        amp: composition.dry.amp * coef,
        lactose: composition.dry.lactose * coef,
        cocoa_solid: composition.dry.cocoa_solid * coef,
        other: composition.dry.other * coef,
        water: composition.liquids.water * coef,
        alcohol: composition.liquids.alcohol * coef,
        pod: composition.other.pod * coef,
        pac: composition.other.pac * coef,
        kcal: composition.other.kcal,
        kj: composition.other.kj,
      };
      return contribution;
    });

    const reducerContribution = (accumulator, currentValue) => {
      return {
        sugar: accumulator.sugar + currentValue.sugar,
        butter: accumulator.butter + currentValue.butter,
        cocoa_butter: accumulator.cocoa_butter + currentValue.cocoa_butter,
        oil: accumulator.oil + currentValue.oil,
        amp: accumulator.amp + currentValue.amp,
        lactose: accumulator.lactose + currentValue.lactose,
        cocoa_solid: accumulator.cocoa_solid + currentValue.cocoa_solid,
        other: accumulator.other + currentValue.other,
        water: accumulator.water + currentValue.water,
        alcohol: accumulator.alcohol + currentValue.alcohol,
        pod: parseInt(accumulator.pod + currentValue.pod),
        pac: parseInt(accumulator.pac + currentValue.pac),
        kcal: parseInt(accumulator.kcal + currentValue.kcal),
        kj: accumulator.kj + currentValue.kj,
      };
    };
    const contribution = totalContributions.reduce(reducerContribution, {
      sugar: 0,
      butter: 0,
      cocoa_butter: 0,
      oil: 0,
      amp: 0,
      lactose: 0,
      cocoa_solid: 0,
      other: 0,
      water: 0,
      alcohol: 0,
      pod: 0,
      pac: 0,
      kcal: 0,
      kj: 0,
    });

    const price = recipe.ingredients.map(ing => (ing.ingredient.note.foodCost / 100) * ing.quantity).reduce(reducer, 0);
    contribution.foodCost = price;
    return contribution;
  };

  const contribution = getFinalContribution();

  const kcalContrib = (contribution.kcal * 100) / totalWeigth;

  const foodCostContrib =
    (recipe.ingredients
      .map(ing => {
        const foodCost = ing.ingredient.note.foodCost;
        const price = (foodCost * ing.quantity) / 1000;
        return price;
      })
      .reduce(reducer, 0) *
      1000) /
    totalWeigth;

  const fatsContrib = contribution.oil + contribution.butter + contribution.cocoa_butter;
  const solidContrib = contribution.cocoa_solid + contribution.amp + contribution.other;
  const liquidContrib = contribution.alcohol + contribution.water;

  const statut = status.find(stat => stat._id === recipe.statusId) || {
    color: "",
    name: "-",
  };

  return (
    <>
      <Card bg={statut.color}>
        <Card.Header>
          <Card.Title>
            <StringReducer stringToReduce={recipe.name} maxShow={35} />
          </Card.Title>
          <h6>{statut.name}</h6>
        </Card.Header>
      </Card>
      <Table striped bordered hover>
        <tbody>
          <TableHeaderRow name={I18n.t("ingredient.sugar", { locale: l })} value={`${GanacheFormat.percentage(contribution.sugar)} %`} />

          <TableHeaderRow name={I18n.t("ingredient.fats", { locale: l })} value={`${GanacheFormat.percentage(fatsContrib)} %`} />
          <TableRow name={I18n.t("ingredient.oil", { locale: l })} value={`${GanacheFormat.percentage(contribution.oil)} %`} />
          <TableRow name={I18n.t("ingredient.butter", { locale: l })} value={`${GanacheFormat.percentage(contribution.butter)} %`} />
          <TableRow
            name={I18n.t("ingredient.cocoaButter", { locale: l })}
            value={`${GanacheFormat.percentage(contribution.cocoa_butter)} %`}
          />

          <TableHeaderRow name={I18n.t("ingredient.drySubstances", { locale: l })} value={`${GanacheFormat.percentage(solidContrib)} %`} />
          <TableRow
            name={I18n.t("ingredient.cacaoSolid", { locale: l })}
            value={`${GanacheFormat.percentage(contribution.cocoa_solid)} %`}
          />
          <TableRow name={I18n.t("ingredient.amp", { locale: l })} value={`${GanacheFormat.percentage(contribution.amp)} %`} />
          <TableRow name={I18n.t("ingredient.lactose", { locale: l })} value={`${GanacheFormat.percentage(contribution.lactose)} %`} />
          <TableRow name={I18n.t("ingredient.othersSolid", { locale: l })} value={`${GanacheFormat.percentage(contribution.other)} %`} />

          <TableHeaderRow name={I18n.t("ingredient.liquids", { locale: l })} value={`${GanacheFormat.percentage(liquidContrib)} %`} />
          <TableRow name={I18n.t("ingredient.water", { locale: l })} value={`${GanacheFormat.percentage(contribution.water)} %`} />
          <TableRow name={I18n.t("ingredient.alcohol", { locale: l })} value={`${GanacheFormat.percentage(contribution.alcohol)} %`} />
          <TableRow
            name={I18n.t("ingredient.alcoholLiquids", { locale: l })}
            value={`${GanacheFormat.percentage(contribution.alcohol / liquidContrib)} %`}
          />

          <TableHeaderRow name={I18n.t("ingredient.others", { locale: l })} />
          <TableRow name={I18n.t("ingredient.pod", { locale: l })} value={GanacheFormat.round(contribution.pod)} />
          <TableRow name={I18n.t("ingredient.pac", { locale: l })} value={GanacheFormat.round(contribution.pac)} />
          <TableRow name={I18n.t("ingredient.kcal", { locale: l })} value={GanacheFormat.round(kcalContrib)} />
          <TableRow name={I18n.t("ingredient.kcal", { locale: l })} value={`${GanacheFormat.price(foodCostContrib)}/Kg`} />
        </tbody>
      </Table>
    </>
  );
}

const TableHeaderRow: FC<{ name: string; value?: string }> = ({ name, value }) => {
  return (
    <tr>
      <th>{name}</th>
      {!value ? <th /> : <th style={{ textAlign: "right" }}>{value}</th>}
    </tr>
  );
};

const TableRow: FC<{ name: string; value: string | number }> = ({ name, value }) => {
  return (
    <tr>
      <td className="pl-5">{name}</td>
      <td style={{ textAlign: "right" }}>{value}</td>
    </tr>
  );
};
