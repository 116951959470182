import { Modal, Button, ListGroup } from "react-bootstrap";
import RecipeAPI from "../../backend/recipe";
import { getRecipeUrl } from "../../Config/url";
import I18n from "../../Config/I18n";
import { getLanguage } from "../../helpers/auth";
import { useHistory } from "react-router-dom";
import { useState } from "react";

const l = getLanguage();

export default function DeleteIngredient({ show = false, handleClose, ingredientId, ingredientName, nextDeleteConfirm }) {
  const [recipes, setRecipes] = useState([]);
  const [ingId, setIngId] = useState("");

  const history = useHistory();

  const loadRecipe = async () => {
    const resp = await RecipeAPI.getRecipesByIngredient(ingredientId);
    if (resp.error) return;
    setRecipes(resp.data);
  };

  if (recipes.length === 0 && show && ingredientId !== ingId) {
    loadRecipe();
    setIngId(ingredientId);
  }

  const close = () => {
    setRecipes([]);
    handleClose();
  };

  const deleteIng = () => {
    close();
    nextDeleteConfirm(ingId);
  };

  const recipesUsed = () => {
    return recipes.length ? (
      <>
        <br />
        <h3>{I18n.t("ingredient.usingInRecipe", { locale: l })} :</h3>
        <ListGroup>
          {recipes.map((recipe, id) => (
            <ListGroup.Item key={recipe._id} onClick={() => history.push(getRecipeUrl(recipe._id))}>
              {recipe.name}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </>
    ) : (
      ""
    );
  };

  return (
    <>
      <Modal show={show} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>{I18n.t("ingredient.deleteIngTitle", { locale: l })}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {I18n.t("ingredient.deleteSummary", { locale: l })} {ingredientName}?{recipesUsed()}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={deleteIng}>
            {I18n.t("ingredient.deleteAnyway", { locale: l })}
          </Button>
          <Button variant="primary" onClick={handleClose}>
            {I18n.t("cancel", { locale: l })}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
