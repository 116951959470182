/* eslint-disable react/no-array-index-key */
import PropTypes from "prop-types";
import moment from "moment";
import { Document, Page, View, Text, Image, StyleSheet } from "@react-pdf/renderer";

import I18n from "../../Config/I18n";
import { getLanguage } from "../../helpers/auth";
import PaymentAPI from "../../backend/payment";
import { useEffect, useState } from "react";

const l = getLanguage();

const COL1_WIDTH = 60;
const COLN_WIDTH = (100 - COL1_WIDTH) / 2;

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    padding: 24,
  },
  h1: {
    fontSize: 24,
    fontWeight: 500,
  },
  h5: {
    fontSize: 12,
    fontWeight: 500,
  },
  h6: {
    fontSize: 10,
    fontWeight: 500,
  },
  body1: {
    fontSize: 9,
    lineHeight: 1.5,
  },
  body2: {
    fontSize: 8,
    lineHeight: 1.5,
  },
  mb1: {
    marginBottom: 4,
  },
  space: {
    marginTop: 32,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  brand: {
    height: 24,
    width: 24,
  },
  company: {
    marginTop: 32,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  references: {
    marginTop: 32,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  billing: {
    marginTop: 32,
  },
  items: {
    marginTop: 32,
  },
  notes: {
    marginTop: 32,
  },
  table: {
    display: "table",
    width: "auto",
  },
  tableHeader: {},
  tableBody: {},
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: "#eeeeee",
    borderStyle: "solid",
  },
  tableCell1: {
    padding: 6,
    width: `${COL1_WIDTH}%`,
  },
  tableCellN: {
    padding: 6,
    width: `${COLN_WIDTH}%`,
  },
  alignRight: {
    textAlign: "right",
  },
});

function InvoicePDF({ invoice }) {
  const [price, setPrice] = useState("");

  useEffect(() => {
    loadPrice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadPrice = async () => {
    const price = await PaymentAPI.getPrice(invoice._id);
    if (price.error) {
      return console.error(price.error.message);
    }
    setPrice(price);
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View>
            <Text style={styles.h5}>{"SCS PASTRY&CHOCADVICE"}</Text>
          </View>
          <View>
            <Image source="/static/logo.png" style={styles.brand} />
            {/*<Text style={styles.h5}>
              Invoice #
              {invoice._id}
            </Text>*/}
          </View>
        </View>

        <View style={styles.references}>
          <View>
            <Text style={[styles.h5, styles.mb1]}>{I18n.t("invoice.billedTo", { locale: l })}</Text>
            <Text style={styles.body1}>{invoice.name}</Text>
            {invoice.address ? <Text style={styles.body1}>{invoice.address.street}</Text> : null}
            {invoice.address ? <Text style={styles.body1}>{invoice.address.city}</Text> : null}
            {invoice.address ? <Text style={styles.body1}>{invoice.address.zipCode}</Text> : null}
          </View>

          <View>
            <Text style={[styles.h5, styles.mb1]}>{I18n.t("invoice.paidDate", { locale: l })}</Text>
            <Text style={styles.body1}>{moment(invoice.paidDate).format("DD MMM YYYY")}</Text>
          </View>

          <View>
            <Text style={[styles.h5, styles.mb1]}>{I18n.t("invoice.invoiceReference", { locale: l })}</Text>
            <Text style={styles.body1}>{invoice.invoiceNumber}</Text>
          </View>
        </View>

        <View style={styles.items}>
          <View style={styles.table}>
            <View style={styles.tableHeader}>
              <View style={styles.tableRow}>
                <View style={styles.tableCell1}>
                  <Text style={styles.h6}>{I18n.t("invoice.description", { locale: l })}</Text>
                </View>
                <View style={styles.tableCellN} />
                <View style={styles.tableCellN}>
                  <Text style={[styles.h6, { textAlign: "right" }]}>{I18n.t("invoice.price", { locale: l })}</Text>
                </View>
              </View>
            </View>
            {invoice.amount ? (
              <View style={styles.tableBody}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCell1}>
                    <Text style={styles.body2}>
                      {I18n.t("invoice.type", { locale: l })} : {I18n.t("account." + price.frequency, { locale: l })}
                    </Text>
                  </View>
                  <View style={styles.tableCellN}>
                    <Text style={styles.body2}>{I18n.t("invoice.subtotal", { locale: l })}</Text>
                  </View>
                  <View style={styles.tableCellN}>
                    <Text style={[styles.body2, styles.alignRight]}>{invoice.amount.value} €</Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableCell1} />
                  <View style={styles.tableCellN}>
                  {(invoice.amount.VAT) ? (
                    <Text style={styles.body2}>
                      {I18n.t("invoice.tva", { locale: l })} ({invoice.amount.VAT.percentage}%)
                    </Text>
                  ) : (
                    <Text style={styles.body2}>
                    {I18n.t("invoice.tva", { locale: l })} (0%)
                  </Text>
                  )}
                  </View>
                  <View style={styles.tableCellN}>
                    {(invoice.amount.VAT) ? (
                    <Text style={[styles.body2, styles.alignRight]}>{invoice.amount.VAT.value} €</Text>
                    ) : (
                      <Text style={[styles.body2, styles.alignRight]}>0 €</Text>
                    )}

                  </View>
                </View>

                {invoice.amount.coupon && invoice.amount.coupon.value !== 0 ? (
                  <View style={styles.tableRow}>
                    <View style={styles.tableCell1} />
                    <View style={styles.tableCellN}>
                      <Text style={styles.body2}>{I18n.t("invoice.coupon", { locale: l })}</Text>
                    </View>
                    <View style={styles.tableCellN}>
                      <Text style={[styles.body2, styles.alignRight]}>- {invoice.amount.coupon.value} €</Text>
                    </View>
                  </View>
                ) : null}

                {(invoice.amount.coupon) ? (
                <View style={styles.tableRow}>
                  <View style={styles.tableCell1} />
                  <View style={styles.tableCellN}>
                    <Text style={styles.body2}>{I18n.t("invoice.total", { locale: l })}</Text>
                  </View>
                  <View style={styles.tableCellN}>
                    {(invoice.amount.VAT) ? (
                    <Text style={[styles.body2, styles.alignRight]}>
                      {invoice.amount.value + invoice.amount.VAT.value - invoice.amount.coupon.value} €
                    </Text>
                    ) : (
                      <Text style={[styles.body2, styles.alignRight]}>
                        {invoice.amount.value + 0 - invoice.amount.coupon.value} €
                      </Text>
                    )}
                  </View>
                </View>
                ): (
                  <View style={styles.tableRow}>
                    <View style={styles.tableCell1} />
                    <View style={styles.tableCellN}>
                      <Text style={styles.body2}>{I18n.t("invoice.total", { locale: l })}</Text>
                    </View>
                    <View style={styles.tableCellN}>
                    {(invoice.amount.VAT) ? (
                      <Text style={[styles.body2, styles.alignRight]}>
                        {invoice.amount.value + invoice.amount.VAT.value} €
                      </Text>
                    ) : (
                      <Text style={[styles.body2, styles.alignRight]}>
                        {invoice.amount.value + 0} €
                      </Text>
                    )}
                    </View>
                  </View>
                  )}
              </View>
            ) : null}
          </View>
        </View>

        <View style={styles.space} />

        <View style={styles.company}>
          <View>
            <Text style={styles.body1}>Stijn Streuvelsaan, 19</Text>
            <Text style={styles.body1}>1933 Sterrrebeek</Text>
            <Text style={styles.body1}>Belgique</Text>
          </View>
          <View>
            <Text style={styles.body1}>ING BANK - Account 6528 5552 3211</Text>
            <Text style={styles.body1}>EU VAT No. 0660.751.132.</Text>
            <Text style={styles.body1}>IBAN BE30 6528 5552 3211</Text>
          </View>
          <View>
            <Text style={styles.body1}>Email: alexandre@ganachesolution.com</Text>
          </View>
        </View>

        {/*<View style={styles.notes}>
          <Text style={[styles.h5, styles.mb1]}>
            Notes
          </Text>
          <Text style={styles.body1}>
            Please make sure you have the right bank registration number as I
            had issues before and make sure you guys cover transfer expenses.
          </Text>
        </View>*/}
      </Page>
    </Document>
  );
}

InvoicePDF.propTypes = {
  invoice: PropTypes.object.isRequired,
};

export default InvoicePDF;
