import React, { useState } from "react";
import ReactQuill from "react-quill";
import { Alert, Card } from "react-bootstrap";
import { RiAlertFill } from "react-icons/ri";

/**
 *
 * @param {{
 *  recipe: import('../Type/recipe').Recipe,
 *  setRecipe: Function
 * }} param0
 */
export default function TextEditor({ title = "Title", maxLines = 6, alertTooLong, handleChange, defaultValue, readOnly = false }) {
  if (!defaultValue) defaultValue = "";
  const isToLong = value => {
    const numH1 = value.split("<h1>").length - 1;
    const numH2 = value.split("<h2>").length - 1;
    const numH3 = value.split("<h3>").length - 1;
    const numP = value.split("<p>").length - 1;
    const numLi = value.split("<li>").length - 1;
    const numLines = numH1 + numH2 + numH3 + numP + numLi;
    value += "to long";
    return numLines > maxLines;
  };
  const [tolong, setToLong] = useState(isToLong(defaultValue));

  const handleChangeProcess = value => {
    setToLong(isToLong(value));
    handleChange(value);
  };

  return (
    <Card>
      <Card.Header htmlFor="procedure">
        <Card.Title className="my-2">
          <h5>{title}</h5>
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <ReactQuill theme="snow" defaultValue={defaultValue} className="editor" onChange={handleChangeProcess} readOnly={readOnly} focus />
        <div hidden={!tolong}>
          <Alert variant="danger">
            <RiAlertFill /> {alertTooLong}
          </Alert>
        </div>
      </Card.Body>
    </Card>
  );
}
