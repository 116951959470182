import { Button, Modal } from "react-bootstrap";
import { getNameCategoryById, getNameBrandById } from "../../../utils/index";
import { getLanguage, getRoot } from "../../../helpers/auth";
import I18n from "../../../Config/I18n";
import SearchSelect from "../../generic/SearchSelect";
import { MdArrowBack } from "react-icons/md";
import brandAPI from "../../../backend/brand";
import { getRecipeUrl } from "../../../Config/url";
import HelpTooltip from "../../generic/HelpTooltip";
import { useHistory } from "react-router-dom";
import { ButtonIconOrange } from "src/components/buttons/iconButton";
import { InputOrange } from "src/components/form";
import { ButtonText } from "src/components/buttons/button";
import { useState } from "react";

const l = getLanguage();

export default function IngredientPageToolbar({
  handleSubmit,
  handleUpdate,
  ingredient,
  setIngredient,
  brands,
  categories,
  updateIngredient,
  setBrands,
  isModified,
  setIsModified,
  handleUpdateNote,
  isFromRecipe,
  setAddFromRecipe,
  recipe = {},
}) {
  const [showPopup, setShowPopUp] = useState(false);

  const history = useHistory();

  const handleChange = event => {
    setIngredient({
      ...ingredient,
      [event.target.name]: event.target.value,
    });
    setIsModified(true);
  };

  const handleSubmitBrand = eventKey => {
    setIngredient({
      ...ingredient,
      brandId: eventKey,
    });
    setIsModified(true);
  };

  const addBrand = async eventKey => {
    const brand = await brandAPI.add({ name: eventKey });
    const allBrands = await brandAPI.get();

    if (allBrands.error) {
      return console.error(allBrands.error.message);
    }

    setBrands(allBrands.data);

    setIngredient({
      ...ingredient,
      brandId: brand._id,
    });

    setIsModified(true);
  };

  const handleSubmitCategory = eventKey => {
    setIngredient({
      ...ingredient,
      categoryId: eventKey,
    });
    setIsModified(true);
  };

  const brandTitle = getNameBrandById(brands, ingredient.brandId);
  const categoryTitle = getNameCategoryById(categories, ingredient.categoryId);

  const goBack = () => {
    /*var path = Url.ingredients + '?'
    path += filterSearch.name ? "name=" + filterSearch.name + '&' : ''
    path += filterSearch.brand ? "brand=" + filterSearch.brand  + '&': ''
    path += filterSearch.category ? "category=" + filterSearch.category : ''*/

    if (isModified) {
      setShowPopUp(true);
    } else {
      if (isFromRecipe) {
        const urlParams = new URLSearchParams(window.location.search);

        history.push(getRecipeUrl(recipe._id, urlParams.has("folderId") ? urlParams.get("folderId") : getRoot(), "ingredients"));

        setAddFromRecipe(false);
      } else {
        history.goBack();
      }
    }
  };

  const handleGoBack = () => {
    if (isFromRecipe) {
      const urlParams = new URLSearchParams(window.location.search);

      history.push(getRecipeUrl(recipe._id, urlParams.has("folderId") ? urlParams.get("folderId") : getRoot(), "ingredients"));
      setAddFromRecipe(false);
    } else {
      history.goBack();
    }
  };

  const handleClosePopup = () => {
    setShowPopUp(false);
  };

  return (
    <>
      <Modal show={showPopup} onHide={handleClosePopup}>
        <Modal.Header closeButton>{I18n.t("ingGoBackSure", { locale: l })}</Modal.Header>
        <Modal.Body>
          {I18n.t("ingredient.ingSelect", { locale: l })}
          {" : "}
          {ingredient.name ? ingredient.name : I18n.t("unnamedIngredient", { locale: l })}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleGoBack} variant="danger" size="lg" style={{ fontSize: "15px" }}>
            {" "}
            {I18n.t("back", { locale: l })}
          </Button>
          <SaveButton
            isEditing={ingredient.editable}
            isUpdating={updateIngredient}
            handleUpdate={handleUpdate}
            handleSubmit={handleSubmit}
            handleUpdateNote={handleUpdateNote}
          />
        </Modal.Footer>
      </Modal>

      <div>
        <div className="flex items-center justify-between gap-x-2">
          <div className="w-40">
            <ButtonIconOrange onClick={() => goBack()}>
              <MdArrowBack />
            </ButtonIconOrange>
          </div>
          <div className="flex gap-x-2">
            <div className="flex flex-col">
              <label className="mb-0" htmlFor="brand">
                {I18n.t("name", { locale: l })}
              </label>
              <InputOrange
                name="name"
                placeholder={I18n.t("ingredient.nameIngredient", { locale: l })}
                value={ingredient.name}
                disabled={!ingredient.editable}
                onChange={handleChange}
                autoComplete="off"
              />
            </div>
            <div className="flex flex-col">
              <label className="mb-0" htmlFor="brand">
                {I18n.t("ingredient.commercialName", { locale: l })}
              </label>
              <InputOrange
                name="commercialName"
                placeholder={I18n.t("ingredient.commercialName", { locale: l })}
                value={ingredient.commercialName}
                disabled={!ingredient.editable}
                onChange={handleChange}
                autoComplete="off"
              />
            </div>
            <div>
              <div className="flex items-center gap-x-1">
                <label className="mb-0" htmlFor="brand">
                  {I18n.t("brand", { locale: l })}
                </label>
                <HelpTooltip brol="ingredient.addBrand" placement="bottom" />
              </div>
              <SearchSelect
                elements={brands}
                filterName={brandTitle || I18n.t("ingredient.selectBrand", { locale: l })}
                handleChange={handleSubmitBrand}
                defValue={brandTitle}
                disabled={!ingredient.editable}
                searchAdd={addBrand}
              />
            </div>
            <div>
              <label className="mb-0" htmlFor="category">
                {I18n.t("category", { locale: l })}
              </label>
              <SearchSelect
                elements={categories}
                filterName={categoryTitle || I18n.t("ingredient.selectCategory", { locale: l })}
                handleChange={handleSubmitCategory}
                defValue={categoryTitle}
                disabled={!ingredient.editable}
              />
            </div>
          </div>
          <div className="flex items-center justify-end w-40">
            <SaveButton
              isEditing={ingredient.editable}
              isUpdating={updateIngredient && ingredient._id}
              handleUpdate={handleUpdate}
              handleSubmit={handleSubmit}
              handleUpdateNote={handleUpdateNote}
            />
          </div>
        </div>
      </div>
    </>
  );
}

const SaveButton = ({ isEditing, isUpdating, handleUpdate, handleSubmit, handleUpdateNote }) => {
  if (isEditing) {
    if (isUpdating) {
      return <ButtonText onClick={handleUpdate}>{I18n.t("save", { locale: l })}</ButtonText>;
    } else {
      return <ButtonText onClick={handleSubmit}>{I18n.t("save", { locale: l })}</ButtonText>;
    }
  } else {
    return <ButtonText onClick={handleUpdateNote}>{I18n.t("saveCostComment", { locale: l })}</ButtonText>;
  }
};
