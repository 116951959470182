import { useState } from "react";
import { Form } from "react-bootstrap";
import { getLanguage } from "../../../helpers/auth";
import I18n from "../../../Config/I18n";
import { AiFillPrinter } from "react-icons/ai";
import FilterSelect from "../../generic/FilterSelect";
import SearchBoxToggle from "../../generic/SearchBoxToggle";
import { ButtonOutlineIconBrown } from "../../buttons/iconButton";
const l = getLanguage();

const RecipesPageToolbar = props => {
  const [isSearching, setIsSearching] = useState(false);
  const searchByName = props.searchByName;
  const setSearchByName = props.setSearchByName;
  const searchByAuthor = props.searchByAuthor;
  const setSearchByAuthor = props.setSearchByAuthor;
  const setSearchByCategory = props.setSearchByCategory;
  const setSearchByStatus = props.setSearchByStatus;
  const categories = props.categories;
  const status = props.status;

  const handleChangeName = event => {
    setSearchByName(event.target.value);
  };
  const handleChangeAuthor = event => {
    setSearchByAuthor(event.target.value);
  };
  const handleChangeCategory = eventKey => {
    setSearchByCategory(eventKey);
  };
  const handleChangeStatus = eventKey => {
    setSearchByStatus(eventKey);
  };

  const PrintButton = () => {
    return (
      <ButtonOutlineIconBrown onClick={() => props.handleClickOpenPopupPrint()}>
        <AiFillPrinter />
      </ButtonOutlineIconBrown>
    );
  };

  return (
    <div className="flex flex-wrap justify-between gap-4">
      <h3 className="self-center text-xl font-bold">{I18n.t("folder.folders", { locale: l })}</h3>
      {isSearching && (
        <div className="flex flex-wrap gap-2">
          <FilterSelect elements={categories} filterName={I18n.t("category", { locale: l })} handleChange={handleChangeCategory} />
          <FilterSelect elements={status} filterName={I18n.t("recipeObject.status", { locale: l })} handleChange={handleChangeStatus} />
          <Form.Group controlId="name" className="mb-0">
            <Form.Control
              type="text"
              placeholder={I18n.t("recipeObject.searchRecipeByName", { locale: l })}
              value={searchByName}
              onChange={handleChangeName}
            />
          </Form.Group>
          <Form.Group controlId="commercialName" className="mb-0">
            <Form.Control
              type="text"
              placeholder={I18n.t("recipeObject.searchRecipeByAuthor", { locale: l })}
              value={searchByAuthor}
              onChange={handleChangeAuthor}
            />
          </Form.Group>
        </div>
      )}
      <div className="flex flex-nowrap gap-x-2">
        <SearchBoxToggle isSearching={isSearching} setIsSearching={setIsSearching} />
        <PrintButton />
      </div>
    </div>
  );
};

export default RecipesPageToolbar;
