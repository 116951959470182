import {
  GiChecklist,
  GiPhotoCamera,
  GiPencil,
  GiIdCard,
  GiAbstract051,
  GiCardboardBox,
  GiStabbedNote,
  GiBackwardTime,
  GiMilkCarton,
  GiCarrot,
  GiMoneyStack,
  GiShop,
  GiScales,
} from "react-icons/gi";
import { FaCreativeCommonsBy } from "react-icons/fa";
import { BsTable } from "react-icons/bs";
import I18n from "../../../Config/I18n";
import { getCurrency, getLanguage } from "../../../helpers/auth";
import { Col, Form, Row } from "react-bootstrap";
const l = getLanguage();

const params = [
  {
    name: `${I18n.t("print.category.picture", { locale: l })}`,
    value: "picture",
    icon: <GiPhotoCamera size="1.5em" />,
  },
  {
    name: `${I18n.t("print.category.method", { locale: l })}`,
    value: "procedure",
    icon: <GiChecklist size="1.5em" />,
  },
  {
    name: `${I18n.t("print.category.owner", { locale: l })}`,
    value: "owner",
    icon: <GiIdCard size="1.5em" />,
  },
  {
    name: `${I18n.t("print.category.nameRecipe", { locale: l })}`,
    value: "name",
    icon: <GiPencil size="1.5em" />,
  },
  {
    name: `${I18n.t("print.category.status", { locale: l })}`,
    value: "status",
    icon: <GiAbstract051 size="1.5em" />,
  },
  {
    name: `${I18n.t("print.category.category", { locale: l })}`,
    value: "categories",
    icon: <GiCardboardBox size="1.5em" />,
  },
  {
    name: `${I18n.t("print.category.note", { locale: l })}`,
    value: "note",
    icon: <GiStabbedNote size="1.5em" />,
  },
  {
    name: `${I18n.t("print.category.lastUpdate", { locale: l })}`,
    value: "lastUpdateOn",
    icon: <GiBackwardTime size="1.5em" />,
  },
  {
    name: `${I18n.t("print.category.fitfor", { locale: l })}`,
    value: "fitfor",
    icon: <GiCarrot size="1.5em" />,
  },
  {
    name: `${I18n.t("print.category.contents", { locale: l })}`,
    value: "contents",
    icon: <GiMilkCarton size="1.5em" />,
  },
  {
    name: `${I18n.t("print.category.price", { locale: l })} (${getCurrency()})`,
    value: "price",
    icon: <GiMoneyStack size="1.5em" />,
  },
  {
    name: `${I18n.t("print.category.commercialName", { locale: l })}`,
    value: "commercialName",
    icon: <GiShop size="1.5em" />,
  },
  {
    name: `${I18n.t("print.category.source", { locale: l })}`,
    value: "source",
    icon: <FaCreativeCommonsBy size="1.5em" />,
  },
  {
    name: `${I18n.t("print.category.totals", { locale: l })}`,
    value: "totals",
    icon: <BsTable size="1.5em" />,
  },
  {
    name: `${I18n.t("recipeAction.scale", { locale: l })}`,
    value: "scale",
    icon: <GiScales size="1.5em" />,
  },
];

export default function SwitchRecipePDF({ checked, setChecked, fileName, setFileName, combinedRecipe }) {
  const handleToggle = event => {
    setChecked({ ...checked, [event.target.name]: event.target.checked });
    /*
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
    */
  };

  const handleChangeName = event => {
    setFileName(event.target.value);
  };

  return (
    <>
      <h5>{I18n.t("print.parameters", { locale: l })}</h5>
      <hr />
      <Form className="mx-2">
        <Form.Group>
          <Form.Label htmlFor="standard-basic">
            {combinedRecipe ? I18n.t("print.combinedFile", { locale: l }) : I18n.t("print.titleFile", { locale: l })}
          </Form.Label>
          <Form.Control
            type="text"
            value={fileName}
            id="standard-basic"
            placeholder={I18n.t("print.titleFile", { locale: l })}
            onChange={handleChangeName}
          />
        </Form.Group>
      </Form>
      {params.map((item, index) => {
        return (
          <Row key={index} className="mx-2 border-bottom">
            <Col lg={8} className="py-2 my-auto">
              <Form.Check
                id={item.value}
                type="switch"
                checked={checked[item.value]}
                onChange={handleToggle}
                label={item.name}
                name={item.value}
              />
            </Col>
            <Col className="my-auto text-right">
              <div className="text-secondary">{item.icon}</div>
            </Col>
          </Row>
        );
      })}
    </>
  );
}
