import { useEffect, useState } from "react";
import { Modal, Table, Form, InputGroup, Button } from "react-bootstrap";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import IngredientAPI from "../../../backend/ingredient";
import { temperatures } from "../../../Config/temperatures";
import { getLanguage } from "../../../helpers/auth";
import I18n from "../../../Config/I18n";
import { getIngredientsByTemperature } from "../../../utils/recipes";
import GanacheFormat from "src/utils/ganacheFormat";
const l = getLanguage();

export default function PopupActionsIngredients({
  isTemperaturePopupOpen,
  setIsTemperaturePopupOpen,
  ingredients,
  totalWeight,
  setIngredients,
  getContribution,
}) {
  const MAX_CHAR_DISPLAYED = 27;
  const [selected, setSelected] = useState([]);
  const [tempSelected, setTempSelected] = useState(0);
  const [isInScaleMode, setScaleMode] = useState(false);
  const [indexScale, setIndexScale] = useState(-1);
  const [tempScale, setTempScale] = useState(-1);
  const [weigthScale, setWeigthScale] = useState(totalWeight);

  /*
    [...ingredient] is creating a copy, but the data it's pointing to are the same !!!!!!
    By using JSON.parse(JSON.stringify(ingredients)) we create new address & data
  */
  //const [ingredientsCopy, setIngredientsCopy] = useState(JSON.parse(JSON.stringify(ingredients)))
  const [ingredientsCopy, setIngredientsCopy] = useState(ingredients);

  useEffect(() => {
    setWeigthScale(parseFloat(totalWeight).toFixed(0));
  }, [totalWeight]);
  const setTemperature = async e => {
    const temp = e.target.value;
    setTempSelected(temp);
    for (const ing of selected) {
      //for (var sel of selected.filter(elmt => elmt.id === ing.ingredient._id && elmt.temperature === ing.temperature)) {
      const result = await IngredientAPI.getById(ing.ingredient._id, temp);
      const evaComputed = result.data;

      ing.coefficient = evaComputed.coefficient;
      ing.temperature = temp;
      ing.ingredient = evaComputed.ingredient;

      if (ing.temperature === 0 || ing.temperature === "ambiant") {
        delete ing.temperature;
      } else {
        ing.temperature = parseInt(ing.temperature);
      }
    }
    setSelected([...selected]);
  };

  const isSelect = ing => {
    return selected.includes(ing);
  };

  const CustomTableCellSelect = () => {
    return (
      <Form>
        <InputGroup size="small">
          <InputGroup.Prepend>
            <InputGroup.Text style={{ fontSize: "12px" }}>T°</InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control as="select" value={tempSelected} onChange={setTemperature} style={{ fontSize: "12px" }}>
            {temperatures.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Form.Control>
        </InputGroup>
      </Form>
    );
  };

  const handleClose = () => {
    setIngredientsCopy(JSON.parse(JSON.stringify(ingredients)));
    setScaleMode(false);
    deselectAll();
    setIsTemperaturePopupOpen(false);
  };

  const handleOpen = () => {
    setIngredientsCopy(JSON.parse(JSON.stringify(ingredients)));
    deselectAll();
  };

  const select = (ing, index, temp) => {
    temp = temp ? temp : 0;
    if (isSelect(ing)) {
      selected.splice(selected.indexOf(ing), 1);
    } else {
      selected.push(ing);
    }

    if (selected.length > 0) {
      const selectedTemp = selected[0].temperature;
      if (selected.length === selected.filter(sel => ingredientsCopy[sel]?.temperature === selectedTemp).length)
        setTempSelected(selectedTemp);
    }
    setSelected([...selected]);
  };

  const scaleRecipeFromIngredient = (event, temp, scalerIndex, indexInRecipe) => {
    var newMass = event.target.value;

    if (newMass === 0) return;

    if (isNaN(parseFloat(newMass))) {
      newMass = 0.1;
    }

    const ingPerTemp = getIngredientsByTemperature(ingredientsCopy);
    const tempKey = parseInt(temp) === 0 ? "ambiant" : temp;
    const ingsTemp = ingPerTemp[tempKey];
    const scalerIng = ingsTemp[scalerIndex];
    const oldMass = scalerIng.quantity;
    const massAdd = newMass - oldMass;
    const contrib = getContribution(ingredientsCopy);

    const scalerIngredientPerc = contrib[indexInRecipe].percentageTotal || 0.1;

    const newIngredients = ingredientsCopy.map((row, index) => {
      // modify row qtt
      const newQty = row.quantity + (contrib[index].percentageTotal / scalerIngredientPerc) * massAdd;
      //const newQty = row.quantity + ((scalerIngredientPerc / contrib[index].percentage) * massAdd)
      return { ...row, quantity: newQty < 0 ? 0 : newQty };
    });
    const sumWeight = newIngredients.reduce((previous, cur) => previous + cur.quantity, 0);
    setWeigthScale(parseFloat(sumWeight).toFixed(0));
    setIngredientsCopy(newIngredients);
  };

  const getIngRow = (ing, temp, indexInTemp, indexInRecipe) => {
    let focus = indexScale === indexInTemp && tempScale === temp;
    return (
      <tr>
        <td style={{ display: "flex" }}>
          <label style={{ display: "flex" }}>
            <input style={{ marginTop: "5px" }} type="checkbox" onChange={() => select(ing, indexInTemp)} checked={isSelect(ing)} />
            <div style={{ marginLeft: "5px" }}>
              {ing.ingredient.name.length > MAX_CHAR_DISPLAYED
                ? ing.ingredient.name.substring(0, MAX_CHAR_DISPLAYED) + "..."
                : ing.ingredient.name}
            </div>
          </label>
          <div style={{ marginLeft: "265px", position: "absolute" }}>
            {isInScaleMode && ing.quantity > 0 ? (
              <Form>
                <Form.Control
                  type="number"
                  min="0"
                  value={focus ? parseFloat(ing.quantity.toFixed(1)) : GanacheFormat.round(ing.quantity)}
                  autoFocus={focus}
                  onChange={e => {
                    setTempScale(temp);
                    setIndexScale(indexInTemp);
                    scaleRecipeFromIngredient(e, temp, indexInTemp, indexInRecipe);
                  }}
                  onClick={e => {
                    e.target.select();
                  }}
                  onBlur={e => {
                    setTempScale(-1);
                    setIndexScale(-1);
                  }}
                  style={{ maxWidth: "80px" }}
                />
              </Form>
            ) : (
              "(" + GanacheFormat.round(ing.quantity) + " g)"
            )}
          </div>
          <div
            style={{
              display: "inline-block",
              right: 0,
              marginRight: "20px",
              position: "absolute",
            }}
          >
            <Button variant="light" size="sm" onClick={() => moveIngredientUp2(ing)}>
              <IoIosArrowUp />
            </Button>
            <Button variant="light" size="sm" onClick={() => moveIngredientDown2(ing)}>
              <IoIosArrowDown />
            </Button>
          </div>
        </td>
      </tr>
    );
  };

  const getIngRows = () => {
    const byTemp = {};
    if (ingredientsCopy) {
      for (const ingredientIndex in ingredientsCopy) {
        var ing = ingredientsCopy[ingredientIndex];
        if (!ing.temperature) ing.temperature = 0;
        if (!byTemp[ing.temperature]) byTemp[ing.temperature] = [];
        byTemp[ing.temperature].push(ing);
      }
      return Object.keys(byTemp).map(key => {
        return (
          <>
            <h3>{`${key}` === "0" ? temperatures[0].label : `${key}°C`}</h3>
            <Table striped bordered hover key={key}>
              <tbody>{byTemp[key].map((ing, indexInTemp) => getIngRow(ing, key, indexInTemp, ingredientsCopy.indexOf(ing)))}</tbody>
            </Table>
          </>
        );
      });
    } else {
      return <></>;
    }
  };
  const selectAll = () => {
    for (const ing of ingredientsCopy) {
      if (!isSelect(ing)) select(ing);
    }
  };

  const deselectAll = () => {
    setSelected([]);
  };

  const saveChanges = () => {
    setIsTemperaturePopupOpen(false);
    setScaleMode(false);
    deselectAll();
    setIngredients([...ingredientsCopy]);
  };

  /**
   *
   * @param {import('../../../Type/ingredient').Ingredient} ing
   */
  const moveIngredientUp2 = ing => {
    const ingredientsToMove = ingredientsCopy;
    var last = 0;
    var from = 0;
    for (var i = 0; i < ingredientsToMove.length; i++) {
      const elmt = ingredientsToMove[i];
      if (elmt === ing) {
        from = i;
        break;
      }
      if (elmt.temperature === ing.temperature) last = i;
    }

    var temporary = ingredientsToMove[last]; // up one
    ingredientsToMove[last] = ingredientsToMove[from];
    ingredientsToMove[from] = temporary;

    setIngredientsCopy([...ingredientsToMove]);
  };

  /**
   *
   * @param {import('../../../Type/ingredient').Ingredient} ing
   */
  const moveIngredientDown2 = ing => {
    const ingredientsToMove = ingredientsCopy;
    var to = 0;
    var from = -1;
    for (var i = 0; i < ingredientsToMove.length; i++) {
      const elmt = ingredientsToMove[i];
      if (elmt === ing) {
        from = i;
      } else if (from >= 0 && i > from && elmt.temperature === ing.temperature) {
        to = i;
        break;
      }
    }

    var temporary = ingredientsToMove[to]; // up one
    ingredientsToMove[to] = ingredientsToMove[from];
    ingredientsToMove[from] = temporary;

    setIngredientsCopy([...ingredientsToMove]);
  };

  const setScalingMode = () => {
    const newMode = !isInScaleMode;
    setScaleMode(newMode);
  };

  const scaleRecipe = e => {
    const oldValue = weigthScale;
    const value = e.target.value ? e.target.value : 1;
    let desiredValue = parseFloat(value);
    if (desiredValue < 1) desiredValue = 1;
    const coefficient = desiredValue / oldValue;
    const ingSet = ingredientsCopy.map(ing => ({
      ...ing,
      quantity: ing.quantity * coefficient,
    }));
    setIngredientsCopy(ingSet);
    setWeigthScale(desiredValue.toFixed(0));
  };

  const scaleWeigthFocusOut = () => {
    if (weigthScale >= 1) setWeigthScale(parseFloat(weigthScale).toFixed(0));
  };

  return (
    <Modal show={isTemperaturePopupOpen} onHide={handleClose} onShow={handleOpen}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        {getIngRows()}
        <label>
          {I18n.t("recipeAction.scale", { locale: l }) + " "}
          <input type="checkbox" onClick={setScalingMode} />
        </label>
        <br />
        <label style={{ display: "flex" }}>
          {I18n.t("listIngredients.totals", { locale: l }) + " "}
          <input
            type="number"
            style={{ width: "100px", textAlign: "right" }}
            value={weigthScale}
            onChange={scaleRecipe}
            onBlur={scaleWeigthFocusOut}
            onFocus={e => e.target.select()}
          />
          <div>&nbsp;g</div>
        </label>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <Button onClick={selectAll} variant="link">
            {I18n.t("recipeAction.selectAll", { locale: l })}
          </Button>{" "}
          <Button onClick={deselectAll} variant="link">
            {I18n.t("recipeAction.deselectAll", { locale: l })}
          </Button>
        </div>
        <div>
          <CustomTableCellSelect />
        </div>
        {/*<div>
        <Button onClick={deleteIng} variant='danger'>
            <FaRegTrashAlt />
            {I18n.t('recipeAction.delete', { locale: l })}
        </Button>
        </div>*/}
        <div>
          <Button onClick={saveChanges} variant="info" style={{ marginLeft: "10px" }}>
            {I18n.t("recipeAction.set", { locale: l })}
          </Button>

          <Button onClick={handleClose} variant="warning" style={{ marginLeft: "10px" }}>
            {I18n.t("cancel", { locale: l })}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
