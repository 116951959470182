import React from "react";
import { DELETE } from "../../helpers/requests";
import { Button, Modal } from "react-bootstrap";
import { getLanguage, getUser } from "../../helpers/auth";
import I18n from "../../Config/I18n";


const body = getUser();
const l = getLanguage();
export default function ChangePaymentCard({ open, handleClose, handleOpenSnackbar, setMessageSnackBar, setAlertVariant }) {
  const changePaymentCard = () => {
    DELETE("/payment", body).then(response => {
      if (response.error) {
        setMessageSnackBar("Problem to unsubscribe! Contact the founders please.");
        setAlertVariant("danger");
        handleOpenSnackbar();
        return console.error(response.error.message);
      }
    });
    setMessageSnackBar("You are well unsubscribed !");
    setAlertVariant("success");
    handleOpenSnackbar();
    handleClose();
    //Refresh the page to display correct info (unsubscribe button -> subscribe button + get full access in the menu)
    window.location.assign("/dashboard/changepaymentcard")

  };

  return (
    <Modal show={open} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t("account.manageChangePaymentCard", { locale: l })} </Modal.Title>
      </Modal.Header>
      <Modal.Body>{I18n.t("account.manageChangePaymentCardMessage", { locale: l })}</Modal.Body>
      <Modal.Footer>
        <Button variant="outline-success" onClick={changePaymentCard}>
          {I18n.t("account.continue", { locale: l })}
        </Button>
        <Button variant="primary" onClick={handleClose}>
          {I18n.t("cancel", { locale: l })}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
