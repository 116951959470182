import { POST, GET } from "../helpers/requests";
import { pathFolder } from "../utils";

/**
 *
 * @param {{
 * parentId: String,
 * name: String
 * }} data
 * @return {{
 *  data: {
 *    _id : String,
 *    name : String,
 *    childrens : [String],
 *    recipes : [String]
 *  },
 *  error:{
 *    alreadyExist: Boolean
 *  }
 * }}
 */
const add = async data => {
  const resp = await POST("/folders/add", data);
  if (resp.error) {
    if (resp.data.title === "Exists") resp.error.alreadyExist = true;
  }
  return resp;
};

const getById = async id => {
  const folders = await GET(`/folders/id/${id}`);
  if (folders.error) {
    return console.error(folders.data.message);
  }
  return folders.data;
};

/**
 *
 * @param {String} id
 *
 */
const getPath = async id => {
  const folders = await GET(`/folders/path/${id}`);
  if (folders.error) {
    // return console.error(folders.data.message)
    return console.error(folders);
  }
  return folders.data;
};

const getFromPath = async path => {
  const folder = await GET(`/folders/${path}`);
  if (folder.error) {
    return console.error(folder.error.message);
  }
  return folder.data;
};
const getFromPathExplorer = async path => {
  const folder = await GET(`/folders/explorer/${path}`);
  if (folder.error) {
    return console.error(folder.error.message);
  }
  return folder.data;
};

const getFromPath2 = async path => {
  const folder = await GET(`/folders/2/${path}`);
  if (folder.error) {
    return console.error(folder.error.message);
  }
  return folder.data;
};

/**
 *
 * @param {String} id
 * @return {String}
 */
const getPathByRecipeId = async recipeId => {
  const folders = await GET(`/folders/recipe/${recipeId}`);
  if (folders.error) {
    // return console.error(folders.data.message)
    return console.error(folders);
  }
  return folders.data.path;
};

const getRecipesInside = async folderId => {
  const folders = await GET(`/folders/all/${folderId}`);
  if (folders.error) {
    // return console.error(folders.data.message)
    return console.error(folders);
  }
  return folders.data;
};

const getFolderRecipe = async recipeId => {
  const folderId = await GET(`/folders/getFolder/${recipeId}`);
  if (folderId.error) {
    return console.error(folderId);
  }
  return folderId.data;
};

const rename = async (folderId, name) => {
  const folder = await POST(`/folders/rename/`, {
    folderId,
    name,
  });
  if (folder.error) {
    return console.error(folder);
  }
  return folder.data;
};

const moveRecipe = async (recipeId, newFolderId) => {
  const folder = await POST(`/folders/move/`, {
    newFolderId,
    recipeId,
  });
  if (folder.error) {
    return console.error(folder);
  }
  return folder.data;
};

export const FolderController = {
  add,
  getById,
  getPathByRecipeId,
  getPath,
  getFromPath,
  getFromPath2,
  getFromPathExplorer,
  getRecipesInside,
  getFolderRecipe,
  rename,
  moveRecipe,
};

export default {
  add,
  getById,
  getPathByRecipeId,
  getPath,
  getFromPath,
  getFromPath2,
  getFromPathExplorer,
  getRecipesInside,
  getFolderRecipe,
  rename,
  moveRecipe,
};
