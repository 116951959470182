import React, { useState } from "react";
import Profile from "../components/account/profile";
import Subscription from "../components/account/Subscription";
import InvoicesUser from "../components/account/InvoicesUser";
import Content from "../components/account/Content";
import I18n from "../Config/I18n";
import { getLanguage } from "../helpers/auth";
import { Container, Nav } from "react-bootstrap";
const l = getLanguage();

const subPage = {
  general: "general",
  subscription: "subscription",
  invoices: "invoices",
  content: "content"
};

function Account() {
  const [currentTab, setCurrentTab] = useState("general");
  const tabs = [
    {
      value: subPage.general,
      slug: "account.general",
    },
    {
      value: subPage.subscription,
      slug: "account.subscription",
    },
    {
      value: subPage.invoices,
      slug: "account.invoices",
    },
    {
      value: subPage.content,
      slug: "account.content",
    },
  ];

  const handleTabsChange = (eventKey, event) => {
    setCurrentTab(eventKey);
  };

  const NavItemRecipe = ({ slug, value, setValue }) => {
    return (
      <Nav.Item inline="true" className="mx-1">
        <Nav.Link eventKey={value} onSelect={handleTabsChange}>
          {I18n.t(slug, { locale: l })}
        </Nav.Link>
      </Nav.Item>
    );
  };
  let pageToShow = <Profile />;
  switch (currentTab) {
    case subPage.subscription:
      pageToShow = <Subscription />;
      break;
    case subPage.invoices:
      pageToShow = <InvoicesUser />;
      break;
    case subPage.content:
      pageToShow = <Content />;
      break;
    default:
      pageToShow = <Profile />;
  }
  return (
    <>
      <Nav className="mt-20 mb-2 sm:mt-2 justify-content-center" variant="pills" defaultActiveKey={currentTab}>
        {tabs.map(tab => (
          <NavItemRecipe key={tab.value} value={tab.value} slug={tab.slug} />
        ))}
      </Nav>
      <hr />
      <Container className="pb-5 mt-4">{pageToShow}</Container>
    </>
  );
}

export default Account;
