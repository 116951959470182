import { Redirect, withRouter } from "react-router-dom";
import { Container, Form, Col, Row, Card } from "react-bootstrap";
import Protected from "../routes/Protected";
import { getRole, getLanguage } from "../helpers/auth";
import logo from "../Layouts/logo-white.png";
import { POST } from "../helpers/requests";
import I18n from "../Config/I18n";
import { Url } from "../Config/url";
import { languages } from "../locales/languages";
import SimpleSnackbar from "../components/SimpleSnackbar";
import { useState } from "react";
import { useHistory } from "react-router-dom";

const SignUp = props => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [language, setLanguage] = useState(getLanguage());
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMSg, setSnackBarMsg] = useState("");
  const [snackVariant, setSnackVariant] = useState("");

  const history = useHistory();

  const handleChangeFirstname = e => {
    e.preventDefault();
    const value = e.target.value;
    setFirstname(value);
  };

  const handleChangeLastname = e => {
    e.preventDefault();
    const value = e.target.value;
    setLastname(value);
  };

  const handleChangePassword = e => {
    e.preventDefault();
    const value = e.target.value;
    setPassword(value);
  };

  const handleChangeEmail = e => {
    e.preventDefault();
    const value = e.target.value;
    setEmail(value);
  };

  const handleChangeLanguage = event => {
    setLanguage(event.target.value);
  };

  const handleSubmit = event => {
    event.preventDefault();

    submit();
  };

  const handleClose = (event, reason) => {
    if (reason !== "clickaway") setOpenSnackBar(false);
  };

  const sendSnack = (message, variant = "primary", delay = 2000) => {
    setOpenSnackBar(true);
    setSnackBarMsg(message);
    setSnackVariant(variant);
    setTimeout(handleClose, delay);
  };

  // Submit data. Call the api. Fetch data and get responses
  const submit = async () => {
    const data = {
      name: firstname,
      lastname: lastname,
      email: email,
      password: password,
      language: language,
    };
    const user = await POST("/user/add", data);
    if (user.error) {
      sendSnack(user.data.message, "danger");
      return console.error(user.error.message);
    }
    history.push(Url.payment);
  };

  const SignupPage = (
    <div className="bg-secondary">
      <Container style={{ height: "100vh", width: "100vw" }}>
        <Row className="h-100">
          <Col lg={6} sm={12} className="my-auto">
            <img src={logo} alt="logo" className="w-75" />
          </Col>
          <Col lg={6} sm={12} className="my-auto">
            <Card>
              <Card.Header>
                <h3 className="title-signin">{I18n.t("sign.signup", { locale: language })}</h3>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={handleSubmit}>
                  <Form.Row>
                    <Form.Group as={Col}>
                      <Form.Label>{I18n.t("user.firstnameUC", { locale: language })}</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={I18n.t("user.firstnameUC", {
                          locale: language,
                        })}
                        onChange={handleChangeFirstname}
                        value={firstname}
                        required
                        autoComplete="given-name"
                      />
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label>{I18n.t("user.lastnameUC", { locale: language })}</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={I18n.t("user.lastnameUC", {
                          locale: language,
                        })}
                        onChange={handleChangeLastname}
                        value={lastname}
                        required
                        autoComplete="family-name"
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col}>
                      <Form.Label>{I18n.t("enterEmail", { locale: language })}</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder={I18n.t("enterEmail", { locale: language })}
                        onChange={handleChangeEmail}
                        value={email}
                        required
                        autoComplete="email"
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="4">
                      <Form.Label>{I18n.t("enterPassword", { locale: language })}</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder={I18n.t("enterPassword", {
                          locale: language,
                        })}
                        onChange={handleChangePassword}
                        value={password}
                        required
                        autoComplete="new-password"
                        minLength="6"
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Group>
                    <Form.Label>{I18n.t("language", { locale: language })}</Form.Label>
                    <Form.Control as="select" value={language} onChange={handleChangeLanguage}>
                      {Object.keys(languages).map(slug => {
                        return (
                          <option key={slug} value={slug}>
                            {languages[slug]}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                  <Form.Row>
                    {/*<Form.Label>{I18n.t("language", { locale: language })}</Form.Label>*/}
                    <Form.Check
                      required
                      name="terms"
                      label={
                        <p>
                          {I18n.t("cgv.agree", { locale: language })} {/* eslint-disable-next-line react/jsx-no-target-blank */}
                          <a href={Url.eula} target="_blank">
                            {I18n.t("cgv.terms", { locale: language })}
                          </a>{" "}
                          {I18n.t("cgv.and", { locale: language })} {/* eslint-disable-next-line react/jsx-no-target-blank */}
                          <a href="https://www.iubenda.com/privacy-policy/26150442" target="_blank">
                            {I18n.t("cgv.privacy", { locale: language })}
                          </a>
                        </p>
                      }
                      onChange={() => {}}
                      isInvalid={false}
                      feedback={() => {}}
                      id="validationFormik0"
                    />
                  </Form.Row>

                  <div className="buttons-signin">
                    <button type="submit" className="btn btn-primary btn-block button-signin">
                      {I18n.t("sign.signup", { locale: language })}
                    </button>
                  </div>

                  <p className="text-center forgot-password">
                    <a style={{ color: "rgb(230, 126, 34)" }} href="/">
                      {" "}
                      {I18n.t("alreadyAccount", { locale: language })}
                    </a>
                  </p>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <SimpleSnackbar open={openSnackBar} handleClose={handleClose} message={snackBarMSg} variant={snackVariant} />
    </div>
  );

  return <Protected role={getRole()} perform="signin:connection" yes={() => <Redirect to={Url.welcome} />} no={() => SignupPage} />;
};

export default withRouter(SignUp);
