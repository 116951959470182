import { Button } from "react-bootstrap";

import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

export default function MultipleRecipesPrintPreview({ recipes, value, setValue }) {
  return (
    <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }} hidden={recipes.length === 1}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Button disabled={value === 0} onClick={() => setValue(value - 1)}>
          <FaArrowLeft></FaArrowLeft>
        </Button>
        <h3 style={{ margin: 0 }}>
          {value + 1}/{recipes.length}
        </h3>
      </div>
      <h3 style={{ margin: 0 }}>{recipes[value].name}</h3>
      <Button disabled={!(value < recipes.length - 1)} onClick={() => setValue(value + 1)}>
        <FaArrowRight />
      </Button>
    </div>
  );
}
