export const Url = {
  root: "/",
  login: "/",
  register: "/register",
  eula: "/eula.pdf",
  pwdForgot: "/forgotten",
  welcome: "/welcome",
  payment: "/payment",
  paymentParams: {
    step: "step",
  },
  dashboard: "/dashboard",
  admin: "/dashboard/admin",
  folder: "/dashboard/folders",
  ingredients: "/dashboard/ingredients",
  editIngredient: "/dashboard/ingredient",
  manual: "/dashboard/manual",
  changePaymentCard: "/dashboard/changepaymentcard",
  manualComposition: "/dashboard/manual/composition",
  settings: "/dashboard/settings",
  profile: "/dashboard/profile",
  users: "/dashboard/users",
  coupons: "/dashboard/coupons",
  invoices: "/dashboard/invoices",
  invoice: "/dashboard/invoice",
  recipe: "/dashboard/recipe",
  recipePrint: "/dashboard/recipes/print",
};

export const getIngredientUrl = (id, folderId = "") => {
  let url = `${Url.editIngredient}?id=${id}`;
  if (folderId) {
    url += `&folderId=${folderId}`;
  }
  return url;
};

export const getRecipeUrl = (recipeId, folderId, page = "recipe") => {
  var path = Url.recipe;
  if (recipeId || folderId) path += "?";
  if (recipeId) path += `id=${recipeId}`;
  if (folderId) path += (recipeId ? "&" : "") + `folderId=${folderId}`;
  path += "&page=" + page;
  return path;
};

export const getRecipePrintUrl = recipeId => {
  const name = `${Url.recipePrint}${recipeId}`;
  return name;
};

export const getUrlAfterPayment = step => {
  return `${window.location.origin}${Url.payment}?${Url.paymentParams.step}=${step}`;
};

export const getInvoiceUrl = invoiceId => {
  return Url.invoice + (invoiceId ? `?id=${invoiceId}` : "");
};
