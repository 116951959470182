import { ChangeEventHandler, ComponentPropsWithoutRef, FC } from "react";

/**
 * Select
 */
export const FormSelectOrange: FC<ComponentPropsWithoutRef<"select">> = ({ children, ...props }) => (
  <select
    className="w-full h-8 px-2 placeholder-gray-500 border border-gray-500 rounded-sm focus:outline-none focus:border-ganache-orange-200 focus:ring focus:ring-orange-100"
    {...props}
  >
    {children}
  </select>
);

/**
 * Input text
 */
export const InputOrange: FC<ComponentPropsWithoutRef<"input">> = ({ type = "text", children, ...props }) => (
  <input
    type={type}
    className={`${
      type === "submit"
        ? "px-2 py-1 transition-colors h-max w-max bg-ganache-orange-500 text-ganache-neutral-100 hover:bg-ganache-orange-600 active:text-ganache-neutral-100 focus:ring focus:ring-orange-100 focus:ring-offset-2 active:scale-95"
        : "px-2 bg-transparant placeholder-gray-500 border border-gray-500 focus:border-ganache-orange-200 focus:ring focus:ring-orange-100"
    } h-8 w-full rounded-sm focus:outline-none ${type === "number" && "text-right"}`}
    {...props}
  />
);

/**
 * Input file
 */
export const InputFileOrange: FC<{ id: string; hidden: boolean; download: any; onChange: ChangeEventHandler<HTMLInputElement> }> = ({
  children,
  ...props
}) => {
  return (
    <label
      htmlFor={props.id}
      className="flex items-center justify-center px-3 py-2 mb-0 text-white rounded-sm cursor-pointer gap-x-2 bg-ganache-orange-500 hover:bg-ganache-orange-600"
    >
      {children}
      <input type="file" accept=".csv" {...props} />
    </label>
  );
};
