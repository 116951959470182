import { getLanguage } from "../helpers/auth";

import ManualEn from "../assets/manuals/manual-ganache-en.pdf";
import ManualFr from "../assets/manuals/manual-ganache-fr.pdf";
import ManualIt from "../assets/manuals/manual-ganache-it.pdf";
import ManualRu from "../assets/manuals/manual-ganache-ru.pdf";

import LogoSquarePNG from "../assets/img/logo.png";

export const getManual = () => {
  switch (getLanguage()) {
    case "fr":
      return ManualFr;
    case "it":
      return ManualIt;
    case "ru":
      return ManualRu;
    default:
      return ManualEn;
  }
};

export const LogoSquare = LogoSquarePNG;
