import RecipeCategoryAPI from "../../../backend/recipeCategory";
import { Button, Form, Modal } from "react-bootstrap";
import I18n from "../../../Config/I18n";
import { getLanguage } from "../../../helpers/auth";
import { useState } from "react";

const l = getLanguage();

export default function NewCategory({ open, handleClose, handleMenuClose, categories, setRecipeCategories }) {
  const [name, setName] = useState("");

  const handleChange = event => {
    setName(event.target.value);
  };

  const addCategory = () => {
    submitNewCategory();
    handleClose();
    //handleMenuClose()
  };

  const submitNewCategory = async () => {
    const response = await RecipeCategoryAPI.add(name);
    if (response.error) {
      return console.error(response.error.message);
    } else {
      // Adding the new category to the existing categories
      categories.push(response.data);
      setRecipeCategories([...categories]);
    }
  };

  return (
    <div>
      <Modal centered show={open} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{I18n.t("recipeAction.addCategory", { locale: l })}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label htmlFor="name">{I18n.t("recipeAction.categoryName", { locale: l })}</Form.Label>
            <Form.Control
              type="text"
              id="name"
              placeholder={I18n.t("recipeAction.categoryName", { locale: l })}
              value={name}
              onChange={handleChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={addCategory} color="primary">
            {I18n.t("add", { locale: l })}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
