// Transform day hour minutes
export const dhm = ms => {
  const days = Math.floor(ms / (24 * 60 * 60 * 1000));
  const daysms = ms % (24 * 60 * 60 * 1000);
  const hours = Math.floor(daysms / (60 * 60 * 1000));
  const hoursms = ms % (60 * 60 * 1000);
  const minutes = Math.floor(hoursms / (60 * 1000));
  const minutesms = ms % (60 * 1000);
  const sec = Math.floor(minutesms / 1000);
  return days + "d" + hours + "h" + minutes + "m" + sec;
};

export const convertTime = date => {
  const arrayDate = date.split("");
  const day = [];
  for (let i = 0; i <= 9; i++) {
    day.push(arrayDate[i]);
  }
  const hour = [];
  for (let i = 11; i <= 18; i++) {
    hour.push(arrayDate[i]);
  }
  const time = day.join("") + " à " + hour.join("");
  return time;
};

export const convertTimeDay = date => {
  const arrayDate = date.split("");
  const day = [];
  for (let i = 0; i <= 9; i++) {
    day.push(arrayDate[i]);
  }
  const hour = [];
  for (let i = 11; i <= 18; i++) {
    hour.push(arrayDate[i]);
  }
  const time = day.join("");
  return time;
};

export const retrieveQueryUrl = param => {
  const urlParams = new URLSearchParams(window.location.search);
  const hasQuery = urlParams.has(param);
  if (hasQuery) {
    return urlParams.get(param);
  }
  return null;
};

export const getCurrentUrl = () => {
  /* const url = window.location.href
  const urlArray = url.split(':')
  let pathUrl = urlArray[1]
  if (urlArray.includes('//localhost')) {
    pathUrl = urlArray[2]
  }
  return pathUrl */
  try {
    const name = window.location.pathname.replace("%%", "%25%");
    const uri = decodeURI(name);
    return uri;
  } catch (err) {
    return "/";
  }
};

export const pathFolder = () => {
  const pathUrl = getCurrentUrl();
  const pathArray = pathUrl.split("/");
  let path = "";
  if (pathArray.includes("folders")) {
    const index = pathArray.indexOf("folders");
    const filteredPathArray = pathArray.slice(index, pathArray.length);
    const folderPath = filteredPathArray.join("/");
    path = folderPath;
  } else {
    path = "folders";
  }
  return path;
};

export const arraySubFolders = () => {
  const pathUrl = getCurrentUrl();
  const pathArray = pathUrl.split("/");
  let path = [];
  if (pathArray.includes("folders")) {
    const index = pathArray.indexOf("folders");
    const filteredPathArray = pathArray.slice(index + 1, pathArray.length).filter(pathName => pathName);
    path = filteredPathArray;
  }
  return path;
};

/**
 *
 * @param {[import("../Type/recipeStatus").RecipeStatus]} status
 * @param {String} id
 */
export const getStatusById = (status, id) => {
  return status.find(stat => stat._id === id);
};

export const getUsertypeById = (usertypes, id) => {
  let name = "usertype not found";
  if (usertypes.length !== 0) {
    usertypes.map(usertype => {
      if (usertype._id === id) {
        name = usertype.name;
      }
      return name;
    });
  }
  return name;
};

export const getNameCategoryById = (categories, id) => {
  let name = "";
  // if(categories.length === 0 || categories === undefined || categories === null || typeof categories.then === 'function'){
  //   return null;
  // }
  if (categories.length !== 0 && categories !== undefined && categories !== null) {
    categories.map(category => {
      if (category._id === id) {
        name = category.name;
      }
      return name;
    });
  }
  return name;
};

export const getNameBrandById = (brands, id) => {
  if (!id) return undefined;
  let name = "";
  brands.map(brand => {
    if (brand._id === id) {
      name = brand.name;
    }
    return name;
  });
  return name;
};

export const formatString = (value, maxLength = 20) => {
  if (value.length < maxLength) return value;
  return `${value.substring(0, maxLength - 3)}...`;
};
