import React from "react";
import { Button, Form } from "react-bootstrap";

const ToolbarCoupons = ({ setShowAddCoupon, title }) => {
  return (
    <Form inline className="m-3">
      <h3 className="mr-auto">{title}</h3>
      <Button variant="outline-primary" className="button-add" onClick={() => setShowAddCoupon(true)}>
        +
      </Button>
    </Form>
  );
};

export default ToolbarCoupons;
