import { useEffect, useState } from "react";
import { GET } from "../../helpers/requests";
import ListInvoice from "../invoices/profile/ListInvoice";
import Loader from "../loader";
import { Card } from "react-bootstrap";
import I18n from "../../Config/I18n";
import { getLanguage } from "../../helpers/auth";
const l = getLanguage();

function InvoicesUser({ className, ...rest }) {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const loadData = async () => {
    setLoading(true);
    await loadInvoices();
    setLoading(false);
  };

  const loadInvoices = async () => {
    
    const invoices = await GET("/payment");
    if (invoices.error) {
      return console.error(invoices.error.message);
    }
    return setInvoices(invoices.data);
  };

  return (
    <Card {...rest}>
      <Card.Header>
        <h3 className="my-3 text-3xl font-semibold">{I18n.t("account.invoices", { locale: l })}</h3>
      </Card.Header>
      <Card.Body>{loading ? <Loader /> : <ListInvoice invoices={invoices} />}</Card.Body>
    </Card>
  );
}
export default InvoicesUser;
