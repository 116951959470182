export const validationIngredient = ingredient => {
  if (!ingredient || !ingredient.composition) {
    return false;
  }

  const total = computeTotalIngredient(ingredient.composition);
  return total >= 98 && total <= 102;
};

export const computeTotalIngredient = composition => {
  let subTotalFats = 0;
  if (composition.fats) {
    subTotalFats = subTotal(composition.fats);
  }

  let subTotalDry = 0;
  if (composition.dry) {
    subTotalDry = subTotal(composition.dry);
    subTotalDry -= composition.dry.lactose;
  }

  let subTotalLiquids = 0;
  if (composition.liquids) {
    subTotalLiquids = subTotal(composition.liquids);
  }

  let subTotalSugar = 0;
  if (composition.sugar) {
    subTotalSugar = parseFloat(composition.sugar);
  }

  const total = subTotalFats + subTotalDry + subTotalLiquids + subTotalSugar;
  return total;
};

const subTotal = o => Object.values(o).reduce((sum, n) => sum + parseFloat(n), 0);

export const getPersonnalIngredient = ingredient => {
  return ingredient.name + " " + (ingredient.editable ? "*" : "");
};
