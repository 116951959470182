import { Children, forwardRef, useState } from "react";
import { Button, Dropdown, FormControl, InputGroup } from "react-bootstrap";
import { RiSave3Fill } from "react-icons/ri";
import SearchControler from "src/utils/filter";

const SearchSelect = forwardRef(
  (
    { elements, filterName, handleChange, className, defValue = "", disabled = false, variant = "primary", searchBar = true, searchAdd },
    ref
  ) => {
    const [value, setValue] = useState("");
    const [selectedValue, setSelectedValue] = useState(defValue);

    const CustomToggle = forwardRef(({ children, onClick }, ref) => (
      <Button
        href=""
        ref={ref}
        onClick={e => {
          e.preventDefault();
          onClick(e);
        }}
        className="flex items-center justify-center overflow-x-scroll whitespace-nowrap"
        disabled={disabled}
        variant={variant}
      >
        {children}&nbsp;▼
      </Button>
    ));

    // forwardRef again here!
    // Dropdown needs access to the DOM of the Menu to measure it
    const CustomMenu = forwardRef(({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const toShow = Children.toArray(children).filter(child => {
        let element = typeof child.props.children === "string" ? child.props.children : child.props.children.props.children[0];
        return !value || SearchControler.isSearchingFit(element, value);
      });
      return (
        <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
          {searchBar ? (
            <>
              <InputGroup>
                <FormControl autoFocus placeholder={value} className="" onChange={e => setValue(e.target.value)} value={value} />
                {searchAdd && !toShow.length ? (
                  <InputGroup.Append>
                    <Button variant="primary" onClick={() => searchAdd(value)}>
                      <RiSave3Fill />
                    </Button>
                  </InputGroup.Append>
                ) : (
                  <></>
                )}
              </InputGroup>
              <hr />
            </>
          ) : (
            <></>
          )}
          <ul className="list-unstyled scrollable-menu">{toShow}</ul>
        </div>
      );
    });

    const handleSelect = (eventKey, elem) => {
      handleChange(elem);
      setSelectedValue(elem.name);
    };

    return (
      <div className={className}>
        <Dropdown className="w-full" style={disabled ? { backgroundColor: "transparent" } : null} readOnly={disabled}>
          <Dropdown.Toggle as={CustomToggle}>{selectedValue || filterName}</Dropdown.Toggle>
          {disabled ? null : (
            <Dropdown.Menu as={CustomMenu}>
              {elements.map(elem => {
                return (
                  <Dropdown.Item onSelect={eventKey => handleSelect(eventKey, elem)} key={elem._id} eventKey={elem._id}>
                    {elem.name}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          )}
        </Dropdown>
      </div>
    );
  }
);
export default SearchSelect;
