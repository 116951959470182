import I18n from "../Config/I18n";
import { getLanguage } from "../helpers/auth";
import { Button, Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const l = getLanguage();
export default function ChangePaymentCard() {
    const history = useHistory();

  
    return (
      <div id="changePaymentCard" style={{display:'flex',flexDirection: 'column',alignItems:'center'}}>
     
      <Card body style={{ width: '90%', textAlign:'center',marginTop:'5rem' }}>
      {I18n.t("account.previousCardUnlinked", { locale: l })}
        <br/>
        <Button
            variant="success"
            onClick={() => {
              history.push("/payment");
              window.location.reload();
            }}
          style={{marginTop:'1rem'}}
      
          >
            {I18n.t("account.insertNewCard", { locale: l })}
          </Button>
         </Card>
      </div>
    );
  }
  