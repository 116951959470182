import React, { useState } from "react";
import { Button, Form, FormControl, InputGroup } from "react-bootstrap";
const ToolbarUsers = ({
  title,
  findEmail,
  findFirstName,
  findLastName,
  findAfterExpirationDate,
  findBeforeExpirationDate,
  findMollieId,
}) => {
  const [dateA, setDateAfter] = useState(undefined);
  const [dateB, setDateBefore] = useState(undefined);

  const mail = event => {
    findEmail(event.target.value);
  };
  const firstname = event => {
    findFirstName(event.target.value);
  };
  const lastname = event => {
    findLastName(event.target.value);
  };
  const dateAfter = event => {
    setDateAfter(event.target.value);
    findAfterExpirationDate(event.target.value);
  };
  const dateBefore = event => {
    setDateBefore(event.target.value);
    findBeforeExpirationDate(event.target.value);
  };

  const cleanDateAfter = event => {
    setDateAfter("");
    findAfterExpirationDate(undefined);
  };

  const cleanDateBefore = event => {
    setDateBefore("");
    findBeforeExpirationDate(undefined);
  };

  const mollieId = event => {
    findMollieId(event.target.value);
  };
  return (
    <Form inline className="m-3">
      <h3 className="mr-auto">{title}</h3>
      <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon1">m@il</InputGroup.Text>
        <FormControl type="email" placeholder="email" aria-describedby="basic-addon1" onChange={mail} />
      </InputGroup>
      <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon2">First name</InputGroup.Text>
        <FormControl type="text" placeholder="First name" aria-describedby="basic-addon2" onChange={firstname} />
      </InputGroup>
      <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon3">Last name</InputGroup.Text>
        <FormControl type="text" placeholder="Last name" aria-describedby="basic-addon3" onChange={lastname} />
      </InputGroup>
      <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon4">After</InputGroup.Text>
        <FormControl type="date" placeholder="dd-mm-YYYY" aria-describedby="basic-addon4" onChange={dateAfter} value={dateA} />
        <Button onClick={cleanDateAfter}>X</Button>
      </InputGroup>
      <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon4">Before</InputGroup.Text>
        <FormControl type="date" placeholder="dd-mm-YYYY" aria-describedby="basic-addon4" onChange={dateBefore} value={dateB} />
        <Button onClick={cleanDateBefore}>X</Button>
      </InputGroup>
      <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon2">Mollie Id</InputGroup.Text>
        <FormControl type="text" placeholder="cst_abcde" aria-describedby="basic-addon2" onChange={mollieId} />
      </InputGroup>
      <Button variant="outline-primary" className="button-add" hidden>
        +
      </Button>
    </Form>
  );
};

export default ToolbarUsers;
