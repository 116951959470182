import { FC, useState } from "react";
import { OverlayTrigger, Tooltip, Breadcrumb } from "react-bootstrap";
import { IoMdAdd } from "react-icons/io";
import { FaHome } from "react-icons/fa";
import { getId, getLanguage } from "../../../helpers/auth";
import CreateFolderPopup from "../CreateFolderPopup";
import I18n from "../../../Config/I18n";
import { Url } from "../../../Config/url";
import { arraySubFolders } from "../../../utils";
import { ButtonOutlineIconOrange } from "src/components/buttons/iconButton";
import { Link } from "react-router-dom";

const l = getLanguage();

const PathFolder: FC = () => {
  let { folder: folderUrl } = Url;

  return (
    <Breadcrumb>
      <Breadcrumb.Item href={folderUrl}>
        <Link to={folderUrl}>
          <FaHome size="1.5em" />
        </Link>
      </Breadcrumb.Item>
      {arraySubFolders().map((subFolder, index) => {
        const newFolderUrl = (folderUrl += `/${encodeURI(subFolder)}`);

        return (
          <Breadcrumb.Item key={index}>
            <Link to={newFolderUrl}>{subFolder}</Link>
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
};

const FoldersToolbar: FC<{ folderData: any; toolbarPath: []; addFolder: () => void; path: string }> = ({ folderData, addFolder }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <CreateFolderPopup open={open} handleClose={() => setOpen(false)} linkNewRecipe={Url.folder} addFolder={addFolder} />
      <div className="flex items-end px-3 mb-3 border-b gap-x-3">
        <PathFolder />
        <OverlayTrigger overlay={<Tooltip id="button-tooltip">{I18n.t("folder.addFolder", { locale: l })}</Tooltip>}>
          <div className="mb-3">
            <ButtonOutlineIconOrange onClick={() => setOpen(true)} hidden={folderData.ownerId !== getId()}>
              <IoMdAdd />
            </ButtonOutlineIconOrange>
          </div>
        </OverlayTrigger>
      </div>
    </>
  );
};

export default FoldersToolbar;
