import { useEffect, useState } from "react";
import TableInfoUsers from "./users/TableInfoUsers";
import { Redirect } from "react-router-dom";
import Protected from "../../routes/Protected";
import { getRole } from "../../helpers/auth";
import { GET } from "../../helpers/requests";
import { Url } from "../../Config/url";

export default function UsersAdmin() {
  const [summary, setSummary] = useState({
    users: {
      newMonthAgo: 0,
      lostMonthAgo: 0,
    },
  });

  useEffect(() => {
    getSummary();
    // eslint-disable-next-line
  }, []);

  // Get summary data. Call the api. Fetch data and get responses
  const getSummary = () => {
    GET("/data").then(response => {
      if (response.error) {
        return console.error(response.error.message);
      }
      setSummary(response.data);
    });
  };

  return (
    <Protected
      role={getRole()}
      perform="dashboard-page-admin:visit"
      yes={() => (
        <div className="container page-admin">
          {summary.users.newMonthAgo + summary.users.lostMonthAgo !== 0 ? (
            <div>
              <div class="col-12 col-sm-12">
                <TableInfoUsers />
              </div>
            </div>
          ) : (
            <div>
              <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                <TableInfoUsers />
              </div>
            </div>
          )}
        </div>
      )}
      no={() => <Redirect to={Url.dashboard} />}
    />
  );
}
