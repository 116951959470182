
import { getLanguage } from "../../helpers/auth";
import I18n from "../../Config/I18n";
import { Button, Modal } from "react-bootstrap";
import {Url} from "../../Config/url";

const l = getLanguage();

export default function PopupCorsi({ handleClosePopupCorsi, open, }) {
    // const classes = useStyles();
    // const [search, setSearch] = useState('');


    // Get all recipes in folder




    // const handleChangeSearch = (event) => {
    //   setSearch(event.target.value.substr(0,20));
    // }

    return (
        <>
            <Modal centered scrollable show={open} onHide={handleClosePopupCorsi}>
                <Modal.Header closeButton>
                    Attention
                </Modal.Header>
                <Modal.Body>
                    This account is for a course. If you want to purchase Ganache Solution, please contact us at info@ganachesolution.com
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleClosePopupCorsi} color="primary">
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
