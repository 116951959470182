import I18n from "./I18n.vendor";
import language, { languages } from "../locales/languages";

// Should upgrade the next js config
// https://dev.to/adrai/how-to-properly-internationalize-a-react-application-using-i18next-3hdb

I18n.fallbacks = true;

const loadLanguages = () => {
  const languagesObj = {};
  for (var lang in languages) {
    languagesObj[lang] = language[lang];
  }
  return languagesObj;
};

I18n.translations = loadLanguages();

I18n.keyExists = key => {
  return key in I18n.translations[I18n.defaultLocale];
};

export default I18n;
