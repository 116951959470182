import { FC } from "react";
import { Link } from "react-router-dom";

export const LinkOrange: FC<{ hidden: boolean; redirectUrl: string }> = ({ redirectUrl, children, ...props }) => {
  return (
    <Link
      className="px-2.5 py-2 transition-colors rounded-sm bg-ganache-orange-500 text-ganache-neutral-100 hover:bg-ganache-orange-600 h-max w-max ring-1 ring-ganache-orange-500 hover:no-underline hover:ring-ganache-orange-600 hover:text-ganache-200"
      to={redirectUrl}
      {...props}
    >
      {children}
    </Link>
  );
};
