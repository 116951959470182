import { logout } from "./auth";

const fetch = require("node-fetch");

export const backend = process.env.REACT_APP_API_URL;

/**
 * This function fetch JSON data from the backend.
 *
 * @param {String} method type of the request method.
 * @param {String} path the path after the addresse of the server.
 * @param {Object} body Object to send.
 */
const request = async (method, path, body) => {
  const data = {
    method: method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
    cache: "default",
    mode: "cors",
  };

  if (body) {
    data.body = JSON.stringify(body);
  }
  const response = {};
  const urlApi = backend + path;

  try {
    const res = await fetch(urlApi, data);
    const json = await res.json();

    if (res.status >= 200 && res.status < 300) {
      response.data = json;
    } else {
      if (res.status === 403) logout();
      response.error = json;
      response.data = json;
      throw new Error(`status ${res.status}`);
    }
  } catch (err) {
    response.error = {
      code: -1,
      message: err,
    };
  }
  return response;
};

const requestFile = async (method, path, body) => {
  const data = {
    method: method,
    credentials: "include",
    redirect: "follow",
  };

  if (body) {
    var formdata = new window.FormData();
    Object.keys(body).forEach(key => {
      formdata.append(key, body[key]);
    });
    data.body = formdata;
  }

  const response = {};
  const urlApi = backend + path;

  try {
    const res = await fetch(urlApi, data);
    const json = await res.json();

    if (res.status >= 200 && res.status < 300) {
      response.data = json;
    } else {
      response.error = json;
      response.data = json;
      throw new Error(`status ${res.status}`);
    }
  } catch (err) {
    response.error = {
      code: -1,
      message: err,
    };
  }
  return response;
};

/**
 *
 * @param {string} url
 */
export const getUrlFile = url => {
  if (url.charAt(0) === ".") url = url.slice(1);
  const manualUrl = `${backend}${url}`;
  return manualUrl;
};

export const getRecipeFile = idRecipe => {
  // if (url.charAt(0) === '.') url = url.slice(1)
  return `${backend}/recipe/img/${idRecipe}`;
};

export const getUserFile = () => {
  return `${backend}/user/img`;
};

/**
 * This function fetch JSON data from the backend with a POST method.
 *
 * @param {String} path the path after the addresse of the server.
 * @param {Object} body Object to send.
 */
export const POST = async (path, body) => {
  return await request("POST", path, body);
};
export const POSTFile = async (path, body) => {
  return await requestFile("POST", path, body);
};
/**
 * This function fetch JSON data from the backend with a GET method.
 *
 * @param {String} path the path after the addresse of the server.
 * @param {Object} body Object to send.
 */
export const GET = async path => {
  return await request("GET", path, undefined);
};

export const GETFile = async path => {
  return await requestFile("GET", path, undefined);
};

/**
 * This function fetch JSON data from the backend with a PUT method.
 *
 * @param {String} path the path after the addresse of the server.
 * @param {Object} body Object to send.
 */
export const PUT = async (path, body) => {
  return await request("PUT", path, body);
};

/**
 * This function fetch JSON data from the backend with a DELETE method.
 *
 * @param {String} path the path after the addresse of the server.
 * @param {Object} body Object to send.
 */
export const DELETE = async (path, body) => {
  return await request("DELETE", path, body);
};
