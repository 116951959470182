import { useEffect, useState } from "react";
import CheckboxListRecipes from "./CheckboxListRecipes";
import { POST } from "../../../helpers/requests";
import { getLanguage } from "../../../helpers/auth";
import I18n from "../../../Config/I18n";
import { Button, Modal } from "react-bootstrap";
import { getRecipePrintUrl } from "../../../Config/url";
const l = getLanguage();

export default function PopupRecipesToPrint({ handleClickOpenPopupPrint, handleClosePopupPrint, open, allRecipes, status, categories }) {
  // const classes = useStyles();
  // const [search, setSearch] = useState('');
  const [recipes, setAllRecipes] = useState([]);
  const [recipeSelected, setRecipeSelected] = useState([]);
  const [ids, setIds] = useState("");

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  // Get all recipes in folder
  const loadAllRecipes = async () => {
    const allRecipes = await POST("/recipe/getRecipes");
    if (allRecipes.error) {
      return console.error(allRecipes.error.message);
    }
    setAllRecipes(allRecipes.data);
  };

  const loadData = async () => {
    // setLoading(true);
    await Promise.all([loadAllRecipes()]);
    // setLoading(false);
  };

  // const handleChangeSearch = (event) => {
  //   setSearch(event.target.value.substr(0,20));
  // }

  return (
    <>
      <Modal centered scrollable show={open} onHide={handleClosePopupPrint}>
        <Modal.Header closeButton>
          <Modal.Title>{I18n.t("print.selectRecipes", { locale: l })}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CheckboxListRecipes
            handleClosePopupPrint={handleClosePopupPrint}
            handleClickOpenPopupPrint={handleClickOpenPopupPrint}
            recipes={recipes}
            allRecipes={allRecipes}
            recipeSelected={recipeSelected}
            setRecipeSelected={setRecipeSelected}
            setIds={setIds}
            ids={ids}
            status={status}
            categories={categories}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClosePopupPrint} color="primary">
            {I18n.t("close", { locale: l })}
          </Button>
          <Button href={getRecipePrintUrl(ids)} color="primary" disabled={recipeSelected.length === 0}>
            {I18n.t("print.printRecipe", { locale: l })}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
