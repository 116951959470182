import React, { useEffect, useState } from "react";
import { Breadcrumb, ListGroup, Modal, Row, Button, Form, InputGroup } from "react-bootstrap";
import { FaHome } from "react-icons/fa";

import { getId, getLanguage, getRoot } from "../../helpers/auth";
import I18n from "../../Config/I18n";

import FolderAPI from "../../backend/folder";
import { GiChocolateBar, GiOpenFolder } from "react-icons/gi";
import { ImFolderPlus } from "react-icons/im";
const l = getLanguage();

/**
 *  The param "selectIdFunction" is a function that get as paramater the id of the selected folder
 * @param {{
 * folderId: string,
 * selectButtonTxt: string,
 * selectIdFunction: function
 * }} param0
 * @returns
 */
const FileExplorer = ({
  open,
  close,
  folderId = getRoot(),
  selectButtonTxt = I18n.t("recipeAction.saveHere", { locale: l }),
  selectIdFunction,
}) => {
  const [path, setPath] = useState("");
  const [folder, setFolder] = useState({});
  const [openAddFolder, setOpenAddFolder] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");

  useEffect(() => {
    loadPath();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderId]);

  useEffect(() => {
    loadFolder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  const loadPath = async () => {
    if (!folderId) folderId = getRoot();
    const pathLoaded = await FolderAPI.getPath(folderId);
    const cleanify = pathLoaded.path
      .split("/")
      .filter(pth => pth)
      .join("/");
    setPath(decodeURI(cleanify));
  };

  const loadFolder = async () => {
    const folderFound = await FolderAPI.getFromPathExplorer(path);
    setFolder(folderFound);
  };

  const newFolder = async () => {
    const fold = await FolderAPI.add({
      parentId: folder._id,
      name: newFolderName,
    });
    const childs = [...folder.childrens];
    childs.push(fold.data);
    setFolder({ ...folder, childrens: childs });
    setOpenAddFolder(false);
  };

  const set = (pathString = "") => {
    setPath(decodeURI(pathString));
  };

  const BreadcrumbPath = () => {
    const items = path.split("/").map((elmt, index) => (
      <Breadcrumb.Item
        onClick={() => {
          set(
            path
              .split("/")
              .slice(0, index + 1)
              .join("/")
          );
        }}
      >
        {elmt}
      </Breadcrumb.Item>
    ));
    return (
      <Breadcrumb>
        <Breadcrumb.Item
          onClick={() => {
            set("");
          }}
        >
          <FaHome size="1.5em" />
        </Breadcrumb.Item>
        {items}
      </Breadcrumb>
    );
  };

  const ListChildren = () => {
    const childrensFolder = folder?.childrens
      ? folder?.childrens.map(child => (
          <ListGroup.Item
            action
            onClick={() => {
              setPath(path ? decodeURI(`${path}/${child.name}`) : decodeURI(child.name));
            }}
          >
            <GiOpenFolder /> {child.name}
          </ListGroup.Item>
        ))
      : [];
    const recipes = folder?.recipes
      ? folder.recipes.map(recipe => (
          <ListGroup.Item disabled>
            <GiChocolateBar /> {recipe.name}
          </ListGroup.Item>
        ))
      : [];

    const Childs = () => {
      if (childrensFolder.length || recipes.length)
        return (
          <>
            {childrensFolder}
            {/*recipes*/}
          </>
        );
      return <h6 className="mx-auto my-3 text-muted">{I18n.t("folder.nofolders", { locale: l })}</h6>;
    };
    return (
      <ListGroup style={{ width: "100%", height: "400px", overflow: "scroll" }}>
        <Childs></Childs>
      </ListGroup>
    );
  };

  const isFolderNameAlreadyExists = () => {
    return folder?.childrens ? folder.childrens.filter(child => child.name === newFolderName).length > 0 : true;
  };

  return (
    <>
      <Modal show={open} size="xl" onHide={close}>
        <Modal.Header closeButton>
          <h2>{I18n.t("folder.folders", { locale: l })}</h2>
        </Modal.Header>
        <Modal.Body style={{ margin: "10px" }}>
          <Row>
            <BreadcrumbPath />
          </Row>
          <Row>
            <ListChildren />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="secondary"
              onClick={() => {
                setOpenAddFolder(true);
              }}
              hidden={openAddFolder || getId() !== folder.ownerId}
            >
              <ImFolderPlus />
            </Button>
            <Form
              hidden={!openAddFolder}
              onSubmit={e => {
                e.preventDefault();
                if (!isFolderNameAlreadyExists()) newFolder();
              }}
            >
              <Form.Label>{I18n.t("folder.addFolder", { locale: l })}</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  type="text"
                  value={newFolderName}
                  onChange={e => setNewFolderName(e.target.value)}
                  isInvalid={isFolderNameAlreadyExists()}
                  aria-describedby="addNewFolder"
                />
                <Button id="addNewFolder" type="submit">
                  +
                </Button>
              </InputGroup>
              <Form.Control.Feedback type="invalid">{I18n.t("folder.nameAlreadyExists", { locale: l })}</Form.Control.Feedback>
            </Form>
            <Button
              style={{ marginRight: 0 }}
              disabled={folder.ownerId !== getId()}
              onClick={() => selectIdFunction(folder._id)}
              hidden={getId() !== folder.ownerId}
            >
              {selectButtonTxt}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FileExplorer;
