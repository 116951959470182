import { FC, MouseEventHandler } from "react";

export const TextWithIconButtonOrange: FC<{ hidden: boolean; onClick: MouseEventHandler<HTMLButtonElement> }> = ({
  children,
  ...props
}) => {
  return (
    <button
      className="flex items-center justify-center px-3 py-2 text-white rounded-sm gap-x-2 bg-ganache-orange-500 hover:bg-ganache-orange-600"
      {...props}
    >
      {children}
    </button>
  );
};
