import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import InvoicePDF from "./InvoicePDF";
import { Param } from "../../locales/Param";
import I18n from "../../Config/I18n";
import logo from "../../Layouts/logo.png";
import { GET } from "../../helpers/requests";
import { getLanguage } from "../../helpers/auth";
import { Button, Card, Modal, Table } from "react-bootstrap";
import PaymentAPI from "../../backend/payment";
const l = getLanguage();

function InvoicePreview() {
  const [viewPDF, setViewPDF] = useState(false);
  const [invoice, setInvoice] = useState({});
  const [price, setPrice] = useState("");

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const loadData = async () => {
    await Promise.all([loadInvoice(), loadPrice()]);
  };

  const loadPrice = async () => {
    const price = await PaymentAPI.getPrice(invoice._id);
    if (price.error) {
      return console.error(price.error.message);
    }
    setPrice(price);
  };

  const loadInvoice = async () => {
    const invoiceId = await retrieveInvoiceId();
    if (invoiceId === null) {
      return;
    }
    const invoice = await GET(`/payment/${invoiceId}`);
    if (invoice.error) {
      return console.error(invoice.error.message);
    }
    setInvoice(invoice.data);
  };

  const retrieveInvoiceId = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const hasId = urlParams.has("id");
    if (hasId) {
      const invoiceId = urlParams.get("id");
      return invoiceId;
    }
    return null;
  };

  return (
    <>
      {invoice ? (
        <Card>
          <Card.Header>
            <Card.Title>{I18n.t("invoice.invoice", { locale: l })}</Card.Title>
            <Button onClick={() => setViewPDF(true)}>{I18n.t("invoice.preview", { locale: l })}</Button>
            <PDFDownloadLink
              document={<InvoicePDF invoice={invoice} />}
              fileName={`Invoice-${invoice.invoiceNumber}`}
              style={{ textDecoration: "none" }}
            >
              <Button>{I18n.t("invoice.download", { locale: l })}</Button>
            </PDFDownloadLink>
          </Card.Header>
          <Modal scrollable centered show={viewPDF} size="lg" onHide={() => setViewPDF(false)}>
            <Modal.Header closeButton>
              <Modal.Title>{I18n.t("invoice.preview", { locale: l })}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <PDFViewer
                width="100%"
                height="750px" // size of the pop-up modal
              >
                <InvoicePDF invoice={invoice} />
              </PDFViewer>
            </Modal.Body>
          </Modal>
          <Card.Body>
            <h5>SCS PASTRY&CHOCADVICE</h5>
            <h3 align="right" variant="h3" color="textPrimary">
              <img src={logo} alt="logo" />
            </h3>
            {/* <Typography
                      align="right"
                      variant="h5"
                      color="textPrimary"
                    >
                      Invoice N°
                      {invoice.invoiceNumber}
                    </Typography> */}
            <h5>{I18n.t("invoice.billedTo", { locale: l })}</h5>
            {invoice.address ? (
              <>
                {invoice.name} <br />
                {invoice.address.street} {invoice.address.number} {invoice.address.city} {invoice.address.zipCode} {invoice.address.country}
                <br />
              </>
            ) : (
              <>
                {invoice.name} <br />
              </>
            )}
            <h5>{I18n.t("invoice.paidDate", { locale: l })}</h5>
            {moment(invoice.paidDate).format("DD MMM YYYY")}
            <h5>{I18n.t("invoice.invoiceReference", { locale: l })}</h5>
            {invoice.invoiceNumber}
            <Table>
              <thead>
                <tr>
                  <th>{I18n.t("invoice.description", { locale: l })}</th>
                  <th />
                  <th style={{ textAlign: "right" }}>{I18n.t("invoice.price", { locale: l })}</th>
                </tr>
              </thead>
              {invoice.amount ? (
                <tbody>
                  <tr>
                    <td>
                      {I18n.t("invoice.type", { locale: l })} : {I18n.t("account." + price.frequency, { locale: l })}
                    </td>
                    <td align="left">{I18n.t("invoice.subtotal", { locale: l })}</td>
                    <td align="right">{invoice.amount.value} €</td>
                  </tr>

                  {(invoice.amount.VAT) ? (
                  <tr>
                    <td />
                    <td align="left">
                      {I18n.t("invoice.tva", { locale: l })} ({invoice.amount.VAT.percentage}%)
                    </td>
                    <td align="right">{invoice.amount.VAT.value} €</td>
                  </tr>
                  ) : (
                  <tr>
                    <td />
                    <td align="left">
                      {I18n.t("invoice.tva", { locale: l })} (0%)
                    </td>
                    <td align="right">0 €</td>
                  </tr>       
                  )}

                  {invoice.amount.coupon && invoice.amount.coupon.value !== 0 ? (
                    <tr>
                      <td />
                      <td>{I18n.t("invoice.coupon", { locale: l })}</td>
                      <td align="right">- {invoice.amount.coupon.value} €</td>
                    </tr>
                  ) : null}


                  {(invoice.amount.coupon) ? (
                    <tr>
                      <td />
                      <td>{I18n.t("invoice.total", { locale: l })}</td>
                        {(invoice.amount.VAT) ? ( 
                          <td align="right">{invoice.amount.value + invoice.amount.VAT.value - invoice.amount.coupon.value} €</td>
                        ) : (
                          <td align="right">{invoice.amount.value + 0 - invoice.amount.coupon.value} €</td>
                        )}
                    </tr>
                    ) : (
                      <tr>
                      <td />
                      <td>{I18n.t("invoice.total", { locale: l })}</td>
                      {(invoice.amount.VAT) ? ( 
                        <td align="right">{invoice.amount.value + invoice.amount.VAT.value} €</td>
                        ) : (
                        <td align="right">{invoice.amount.value + 0} €</td>
                      )}
                    </tr>
                    )}
                </tbody>
              ) : null}
            </Table>
            {Param.adress.street} <br />
            {Param.adress.city} <br />
            {Param.adress.country}
            ING BANK - {I18n.t("invoice.account", { locale: l })} {Param.account} <br />
            {I18n.t("invoice.vatRef", { locale: l })} {Param.vat} <br />
            {I18n.t("invoice.iban", { locale: l })} {Param.iban} <br />
            {I18n.t("invoice.email", { locale: l })} : {Param.email} <br />
          </Card.Body>
        </Card>
      ) : null}
    </>
  );
}

InvoicePreview.propTypes = {
  className: PropTypes.string,
  invoice: PropTypes.object,
};

export default InvoicePreview;
