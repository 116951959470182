import { useEffect, useRef, useState } from "react";
import { getLanguage } from "../../../helpers/auth";
import I18n from "../../../Config/I18n";
import { Button, Col, Form, ListGroup, ListGroupItem, Modal, OverlayTrigger, Row, Popover, Table, Badge } from "react-bootstrap";
import IngredientAPI from "../../../backend/ingredient";
import { MdAddCircle } from "react-icons/md";
import { IoMdEye } from "react-icons/io";
import FilterSelect from "../../generic/FilterSelect";
import ReactDOM from "react-dom";
import HelpTooltip from "../../generic/HelpTooltip";
import { BsArrowLeft } from "react-icons/bs";
import SearchControler from "src/utils/filter";
import GanacheFormat from "src/utils/ganacheFormat";

const l = getLanguage();

export default function PopupIngredients({
  isIngredientsPopupOpen,
  setIsIngredientsPopupOpen,
  createData,
  handleNewIngredient,
  recipe,
  setRecipe,
  fitfors,
  contents,
  brands,
  ingredientCategories,
  setIsRecipeModified,
}) {
  const [ingredients, setIngredients] = useState([]);
  const [filterSearch, setFilterSearch] = useState("");
  const [brandId, setBrandId] = useState("");
  const [categoryId, setCategoryId] = useState("");

  //const [ingredientsToShow, setIngredientToShow] = useState([])

  const focusInput = useRef(null);
  const clearForm = useRef(null);

  // const [maxWidth, setMaxWidth] = useState('sm'); //sm
  /*
  // If one of the state below change, we have to filter the ing again.
  useEffect(() => {
    filter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSearch, brandId, categoryId])
*/
  // Loading data once.
  useEffect(() => {
    loadData(); // eslint-disable-next-line
  }, []);

  const loadData = async () => {
    const ingredientsRes = await IngredientAPI.getAll();
    if (ingredientsRes.error) {
      return console.error(ingredientsRes);
    }
    const ingReceived = ingredientsRes.data;
    const ingsTranslate = ingReceived.map(ing => {
      try {
        if (ing.nameTranslation && ing.nameTranslation[l]) ing.name = ing.nameTranslation[l];
        if (ing.commercialNameTranslation && ing.commercialNameTranslation[l]) ing.commercialName = ing.commercialNameTranslation[l];
      } catch (err) {
        console.error(err);
      }
      return ing;
    });
    const ingSortByName = ingsTranslate.sort(compare);
    setIngredients(ingSortByName);
  };

  // Select a new ingredient to add in a recipe
  const handleListItemClick = async value => {
    const newIngredient = await createData(value._id, value.name, 0, 0, true); // 1 = qty default value -> 0 raise an error -> NaN
    const listIngredientsInRecipe = recipe.ingredients ? recipe.ingredients : [];
    listIngredientsInRecipe.push(newIngredient);
    setRecipe({
      ...recipe,
      ingredients: listIngredientsInRecipe,
    });
    setIsRecipeModified(true);

    ReactDOM.findDOMNode(focusInput.current).focus();
  };
  /*
  const filter = () => {
    var filtered = ingredients
    if (filterSearch) {
      const regex = new RegExp(["^.*", filterSearch, ".*$"].join(""), "i")

      filtered = filtered.filter(
        (ing) =>
          ing.name.match(regex) ||
          (ing.commercialName ? ing.commercialName.match(regex) : null)
      )
    }

    if (brandId) {
      filtered = filtered.filter((ing) => ing.brandId === brandId)
    }

    if (categoryId) {
      filtered = filtered.filter((ing) => ing.categoryId === categoryId)
    }
    setIngredients(filtered.sort(compare))
  }
*/
  const handleChangeSearch = event => {
    const name = event.target.value.substr(0, 20);
    setFilterSearch(name);
  };

  const handleChangeBrand = async eventKey => {
    setBrandId(eventKey || "");
  };

  const handleChangeCategory = eventKey => {
    setCategoryId(eventKey || "");
  };

  const getContent = ingredient => {
    var res = "";
    const content = ingredient.flags.contents;
    contents.forEach(c => {
      const cont = content.find(id => id === c._id);
      if (cont) {
        res += c.name + " ";
      }
    });
    return res;
  };

  const getFitFor = ingredient => {
    var res = "";
    const fitfor = ingredient.flags.fit;
    fitfors.forEach(f => {
      const fit = fitfor.find(id => id === f._id);
      if (fit) {
        res += f.name + " ";
      }
    });
    return res;
  };

  // Return the background color an ingredient should have (if already in the recipe or not)
  const getIngColor = ing => {
    var color = "";
    if (recipe.ingredients) {
      if (recipe.ingredients.length !== 0) {
        recipe.ingredients.forEach((ingredient, index) => {
          if (ing._id === ingredient.ingredient._id) {
            color = "#E0E0E0";
          }
        });
      }
    }
    return color;
  };

  const handlePopupClose = () => {
    loadData();
    setFilterSearch("");
    setBrandId("");
    setCategoryId("");
    setIsIngredientsPopupOpen(false);
  };

  function compare(a, b) {
    // Use toUpperCase() to ignore character casing
    const ingredient1 = a.name.toUpperCase();
    const ingredient2 = b.name.toUpperCase();

    let comparison = 0;
    if (ingredient1 > ingredient2) {
      comparison = 1;
    } else if (ingredient1 < ingredient2) {
      comparison = -1;
    }
    return comparison;
  }

  const getBrandName = brandId => {
    /*var name = "";
    brands.forEach((b, index) => {
      if (b._id === brandId) {
        name = b.name;
      }
    });
    return name;
    */
    const brand = brands.find(b => b._id === brandId);
    return brand ? brand.name : "";
  };

  const getIngredientCategoryName = categoryId => {
    const category = ingredientCategories.find(b => b._id === categoryId);
    return category ? category.name : "";
  };

  // Fixed size to show the differents item in dropdown.
  // Modal is the more foreground element -> https://react-bootstrap.github.io/components/modal/
  const modalHeight = parseInt(window.screen.height * 0.95);

  const ingredientsToShow = ingredients.filter(ing => {
    const nameContain = filterSearch && ing.name ? SearchControler.isSearchingFit(ing.name, filterSearch) | false : false;
    const nameCommercialContain =
      filterSearch && ing.commercialName ? SearchControler.isSearchingFit(ing.commercialName, filterSearch) | false : false;
    const brand = brandId ? ing.brandId === brandId : true;
    const catgory = categoryId ? ing.categoryId === categoryId : true;

    const filterByName = filterSearch ? nameContain || nameCommercialContain : true;

    return filterByName && brand && catgory;
  });

  return (
    <>
      <Modal style={{ marginLeft: "20%" }} size="lg" scrollable show={isIngredientsPopupOpen} onHide={handlePopupClose}>
        <Modal.Header close="true" closeButton>
          <Modal.Title id="max-width-dialog-title">{I18n.t("ingredient.selectIngredient", { locale: l })}</Modal.Title>
        </Modal.Header>
        <Modal.Body id="modalBody" style={{ minHeight: `${modalHeight}px` }}>
          <Row>
            <Col>
              <Form
                inline
                ref={clearForm}
                //onFocus={e => e.target.select()}
                onSubmit={e => e.preventDefault()}
              >
                <Form.Group className="mx-2">
                  <Form.Label htmlFor="coupon">{I18n.t("ingredient.nameIngredient", { locale: l })} </Form.Label>
                  <Form.Control
                    // required
                    id="standard-full-width"
                    type="text"
                    placeholder={I18n.t("ingredient.searchIngredient", {
                      locale: l,
                    })}
                    onChange={handleChangeSearch}
                    ref={focusInput}
                    onFocus={e => e.target.select()}
                  />
                  <HelpTooltip brol="recipeObject.addIngHelp" placement="bottom" style={{ marginLeft: "3px" }} />
                  <FilterSelect
                    className="mx-2"
                    elements={brands}
                    filterName={I18n.t("brands", { locale: l })}
                    handleChange={handleChangeBrand}
                  />
                  <FilterSelect
                    className="mx-2"
                    elements={ingredientCategories}
                    filterName={I18n.t("category", { locale: l })}
                    handleChange={handleChangeCategory}
                  />
                </Form.Group>
              </Form>
              {ingredientsToShow.length === 0 ? (
                <OverlayTrigger
                  overlay={
                    <Popover id="popover-basic">
                      <Popover.Title as="h3">{I18n.t("ingredient.addIngredient", { locale: l })}</Popover.Title>
                    </Popover>
                  }
                >
                  <Button size="sm" onClick={() => handleNewIngredient(filterSearch || "")}>
                    {I18n.t("ingredient.addIngredient", { locale: l })} <MdAddCircle />
                  </Button>
                </OverlayTrigger>
              ) : null}
            </Col>
          </Row>
          <ListGroup>
            {ingredientsToShow.map((ingredient, index) => {
              const catName = getIngredientCategoryName(ingredient.categoryId);
              const brandName = getBrandName(ingredient.brandId);

              const ingName = ingredient.nameTranslation && ingredient.nameTranslation[l] ? ingredient.nameTranslation[l] : ingredient.name;
              const ingComercialName =
                ingredient.commercialNameTranslation && ingredient.commercialNameTranslation[l]
                  ? ingredient.commercialNameTranslation[l]
                  : ingredient.commercialName || "";

              return (
                <ListGroupItem
                  action
                  button="true"
                  key={index}
                  onClick={() => handleListItemClick(ingredient)}
                  style={{ backgroundColor: `${getIngColor(ingredient)}` }}
                  className="flex items-center gap-x-3"
                >
                  <div className="px-1 py-1 transition-colors rounded-sm bg-transparant h-max w-max focus:outline-none bg-ganache-orange-500 text-ganache-neutral-100 hover:bg-ganache-orange-600 active:text-ganache-neutral-100">
                    <BsArrowLeft />
                  </div>
                  <div className="flex flex-wrap items-center gap-x-2">
                    <p>{ingName}</p>
                    <p>{ingredient.commercialName && <small>{`(${ingComercialName})`}</small>}</p>
                    <p>{brandName && <Badge variant="info"> {brandName}</Badge>}</p>
                    <p>{catName && <Badge variant="primary">{catName}</Badge>}</p>
                  </div>
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Popover id="popover-basic">
                        <Popover.Title as="h3">{I18n.t("ingredient.content", { locale: l })}</Popover.Title>
                        <Popover.Content>
                          <small>
                            <div>
                              {I18n.t("ingredient.sugar", { locale: l })} : {GanacheFormat.percentage(ingredient?.composition?.sugar)} %
                            </div>
                            <div>
                              {I18n.t("ingredient.cocoaButter", { locale: l })} :{" "}
                              {ingredient?.composition?.dry?.cocoa_solid?.toFixed(1) || 0} %
                            </div>
                            <div>
                              {I18n.t("ingredient.amp", { locale: l })} : {GanacheFormat.percentage(ingredient?.composition?.dry?.amp)} %
                            </div>
                            <div>
                              {I18n.t("ingredient.lactose", { locale: l })} :{" "}
                              {GanacheFormat.percentage(ingredient?.composition?.dry?.lactose)} %
                            </div>
                            <div>
                              {I18n.t("ingredient.othersSolid", { locale: l })} :{" "}
                              {GanacheFormat.percentage(ingredient?.composition?.dry?.other)} %
                            </div>
                            <div>
                              {I18n.t("ingredient.oil", { locale: l })} : {GanacheFormat.percentage(ingredient?.composition?.fats?.oil)} %
                            </div>
                            <div>
                              {I18n.t("ingredient.butter", { locale: l })} :{" "}
                              {GanacheFormat.percentage(ingredient?.composition?.fats?.butter)} %
                            </div>
                            <div>
                              {I18n.t("ingredient.sugar", { locale: l })} :{" "}
                              {GanacheFormat.percentage(ingredient?.composition?.fats?.cocoa_butter)} %
                            </div>
                            <div>
                              {I18n.t("ingredient.water", { locale: l })} :{" "}
                              {GanacheFormat.percentage(ingredient?.composition?.liquids?.water)} %
                            </div>
                            <div>
                              {I18n.t("ingredient.alcohol", { locale: l })} :{" "}
                              {GanacheFormat.percentage(ingredient?.composition?.liquids?.alcohol)} %
                            </div>
                            <div>
                              {I18n.t("ingredient.pod", { locale: l })} : {GanacheFormat.round(ingredient?.composition?.other?.pod)}
                            </div>
                            <div>
                              {I18n.t("ingredient.pac", { locale: l })} : {GanacheFormat.round(ingredient?.composition?.other?.pac)}
                            </div>
                            <div>
                              {I18n.t("ingredient.kj", { locale: l })} : {GanacheFormat.round(ingredient?.composition?.other?.kj)}
                            </div>
                            <div>
                              {I18n.t("ingredient.kcal", { locale: l })} : {GanacheFormat.round(ingredient?.composition?.other?.kcal)}
                            </div>
                            {ingredient.note && (
                              <div>
                                {I18n.t("ingredient.foodCost", { locale: l })} : {GanacheFormat.price(ingredient.note.foodCost)}
                              </div>
                            )}
                            <div>
                              {I18n.t("ingredient.content", { locale: l })} : {getContent(ingredient)}{" "}
                            </div>
                            <div>
                              {I18n.t("ingredient.fitfor", { locale: l })} : {getFitFor(ingredient)}
                            </div>
                            {ingredient.note ? (
                              <Table>
                                <thead>
                                  <tr>
                                    <th>{I18n.t("ingredient.note", { locale: l })}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>{ingredient?.note?.note || " "}</td>
                                  </tr>
                                </tbody>
                              </Table>
                            ) : null}
                          </small>
                        </Popover.Content>
                      </Popover>
                    }
                  >
                    <small className="text-muted">
                      <IoMdEye className="ml-3" />
                    </small>
                  </OverlayTrigger>
                </ListGroupItem>
              );
            })}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handlePopupClose} color="primary">
            {I18n.t("close", { locale: l })}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
