import { useEffect, useState } from "react";
import { Badge, Breadcrumb, Button, Card, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { convertTimeDay } from "../utils";
import { LogoSquare } from "../Config/content";
import CardMoreButton from "./generic/CardMoreButton";
import SimpleSnackbar from "./SimpleSnackbar";
import { RecipeController } from "../backend/recipe";
import RecipeOverview from "./recipes/RecipeOverview";
import { getRecipeUrl, Url } from "../Config/url";
import { FaHome } from "react-icons/fa";
import { FolderController } from "../backend/folder";
import { POST } from "../helpers/requests";
import I18n from "../Config/I18n";
import { getLanguage } from "../helpers/auth";
import { useHistory } from "react-router-dom";
import StringReducer from "./generic/StringReducer";
import Loader from "./loader";

const l = getLanguage();

const RecipeCard2 = ({ recipe, status, categories, folderId, showPath = false, update = () => null }) => {
  const [recipeHoover, setRecipeHoover] = useState({
    name: "Default",
    ingredients: [],
  }); // default value to prevent loading error.
  const [folderPath, setFolderPath] = useState("");
  const [openPopupDelete, setOpenPopupDelete] = useState(false);
  const [isLoadingPath, setLoadingPath] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (showPath) loadFolderPath();
  }, [showPath]);

  const loadFolderPath = async () => {
    setLoadingPath(true);
    const recipeId = recipe._id;
    const path = await FolderController.getPathByRecipeId(recipeId);
    setFolderPath("/" + path);
    setLoadingPath(false);
  };

  const loadRecipeDetail = async () => {
    if (recipeHoover.name === "Default") {
      const result = await RecipeController.getById(recipe._id);
      if (!result.error) setRecipeHoover(result.data);
    }
  };

  const duplicateRecipe = () => {
    const data = {
      recipeId: recipe._id,
      folderId,
    };
    POST("/recipe/duplicate", data).then(response => {
      if (response.error) {
        return console.error(response.error.message);
      }
      const newRecipe = response.data;
      history.push(getRecipeUrl(newRecipe._id, data.folderId));
      window.location.reload();
    });
  };

  const deleteRecipe = async () => {
    const data = {
      parentId: folderId,
      recipeId: recipe._id,
    };
    const recipeToDelete = await POST("/recipe/delete", data);
    if (recipeToDelete.error) {
      return console.error(recipeToDelete.error.message);
    }
    update();
  };

  const path = getRecipeUrl(recipe._id, folderId);

  const thisStatus = status.find(stat => stat._id === recipe.statusId);
  const category = categories.find(cat => cat._id === recipe.categoryId);

  const handleClosePopupDelete = () => {
    setOpenPopupDelete(false);
  };

  const setOpenPopup = () => {
    setOpenPopupDelete(true);
  };

  const name = recipe.nameTranslation && recipe.nameTranslation[l] ? recipe.nameTranslation[l] : recipe.name || "";

  return (
    <>
      <Modal show={openPopupDelete} onHide={handleClosePopupDelete}>
        <Modal.Header closeButton>{I18n.t("recipeAction.deleteSure", { locale: l })}</Modal.Header>
        <Modal.Body>
          {I18n.t("ingredient.recipeSelect", { locale: l })}
          {" : "}
          {name ? name : ""}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClosePopupDelete}> {I18n.t("back", { locale: l })}</Button>
          <Button onClick={deleteRecipe} variant="danger">
            {" "}
            {I18n.t("recipeAction.delete", { locale: l })}
          </Button>
        </Modal.Footer>
      </Modal>
      <Card className="card-recipe h-100">
        <a href={path}>
          <Card.Header className="flex flex-col items-center gap-y-4">
            <img src={LogoSquare} alt="recipe's logo" className="w-20" />
            <div className="recipe-title">
              <StringReducer stringToReduce={name} maxShow={18} />
            </div>
            <small className="text-muted">{category?.name ? category.name : ""}</small>
          </Card.Header>
          <Card.Body className="mt-auto text-center">
            <p>{recipe.author}</p>
            {thisStatus ? <Badge variant={thisStatus.color}>{thisStatus.name}</Badge> : null}
          </Card.Body>
        </a>
        <div onMouseEnter={loadRecipeDetail} className="space-y-2">
          <div className={`flex items-center justify-between mx-3 ${!showPath && "pb-3"}`}>
            <div className="flex flex-col items-center">
              <p className="text-sm">{convertTimeDay(recipe.lastUpdateOn)}</p>
              <RecipeOverview recipeHoover={recipeHoover} brands={[]} />
            </div>
            <CardMoreButton
              recipe={recipe}
              duplicateRecipe={duplicateRecipe}
              deleteRecipe={setOpenPopup}
              notAuthorize={() => {
                history.push(Url.payment);
                history.go(0);
              }}
            />
          </div>
          <div className="mx-3" hidden={!showPath}>
            {isLoadingPath ? <Loader /> : <FolderPath path={folderPath} />}
          </div>
        </div>
        <SimpleSnackbar open={false} handleClose={() => {}} message={() => {}} variant={() => {}} />
      </Card>
    </>
  );
};

const FolderPath = ({ path = "/" }) => {
  const tabs = path.split("/").filter(elmt => elmt);

  return (
    <Breadcrumb className="recipe-path-breadcrumb">
      <Breadcrumb.Item href={Url.folder} className="flex items-center">
        <FaHome />
      </Breadcrumb.Item>
      {tabs.map((elmt, index) => {
        return (
          <OverlayTrigger
            placement={"bottom"}
            overlay={
              <Tooltip id="popover-basic" className="tooltip-path">
                {decodeURI(elmt)}
              </Tooltip>
            }
          >
            <Breadcrumb.Item className="truncate min-w-[40px]" href={`${Url.folder}/${tabs.slice(0, index + 1).join("/")}`}>
              {decodeURI(elmt)}
            </Breadcrumb.Item>
          </OverlayTrigger>
        );
      })}
    </Breadcrumb>
  );
};

export default RecipeCard2;
