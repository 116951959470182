import Protected from "../routes/Protected";
import SignUp from "../pages/SignUp";
import PaymentStepper from "../components/payment/PaymentStepper";
import logo from "../Layouts/logo-white.png";
import { getRole } from "../helpers/auth";
import { Col, Container, Row } from "react-bootstrap";

export default function Welcome() {
  const role = getRole();

  return (
    <Protected
      role={role}
      perform="payment:pay"
      yes={() => (
        <div className="bg-secondary">
          <Container style={{ height: "100vh", width: "100vw" }}>
            <Row className="h-100">
              <Col lg={6} sm={12} className="my-auto">
                <img src={logo} alt="logo" className="w-75" />
              </Col>
              <Col lg={6} sm={12} className="my-auto">
                <PaymentStepper />
              </Col>
            </Row>
          </Container>
        </div>
      )}
      no={() => SignUp}
    />
  );
}
