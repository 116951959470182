import { getId } from "./auth";
import FileType from "file-type/browser";

export function roundNumber(num, dec) {
  return parseFloat(Math.round(num * Math.pow(10, dec)) / Math.pow(10, dec));
}

/**
 *
 * @param {String} num
 * @param {Number} dec
 */
export function roundFloat(num, dec) {
  num = `${num}`;
  while (num.charAt(0) === "0" && num.charAt(1)) {
    num = num.substring(1);
  }
  const lastChar = num.charAt(num.length - 1);
  if (lastChar === "," || lastChar === ".") return num;
  return roundNumber(num, dec);
}

export const formatNumber = (num, dec = 2) => {
  const value = roundFloat(num, dec);
  const conversion = `${value}`.replace(/^0+\d/, "");
  if (conversion.length === 0) return 0;
  return conversion;
};

export const percent = value => {
  if (value < 0) value = 0;
  if (value > 100) value = 100;
  return parseFloat(value);
};

export const getProfilePicture = async () => {
  if (!getId()) return "";

  const data = {
    method: "GET",
    credentials: "include",
    redirect: "follow",
  };

  const fetchRes = await fetch(`${process.env.REACT_APP_API_URL}/user/img?type=base64`, data);
  const base64 = await fetchRes.text();
  const ext = await FileType.fromBuffer(Buffer.from(base64, "base64"));
  return getImage(base64, ext.mime);
};

export const getUrlParams = () => {
  const params = `${window.location.search}`.substring(1);
  const result = {};
  for (var param of params.split("&")) {
    const index = param.indexOf("=");
    const key = param.substring(0, index);
    const value = param.substring(index + 1); // +1 => skip '='
    result[key] = value;
  }
  return result;
};

function getImage(buffer, mimeType) {
  return `data:${mimeType};base64,${buffer}`;
}
