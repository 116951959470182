/**
 *
 * @param {File} image
 */
export const validImage = image => {
  const extensionsAllowed = ["tif", "tiff", "jpg", "jpeg", "gif", "png", "svg"];
  const array = image.name.split(".");
  const extension = array[array.length - 1];
  return {
    imageToBig: image.size > 5 * 1024 * 1024,
    imageExtension: !extensionsAllowed.includes(extension),
  };
};
