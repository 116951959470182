import React from "react";
import { Button, Card } from "react-bootstrap";

export default function CardOverviewAdmin({ card }) {
  const path = card.path;

  return (
    <Card className="card-intro h-100">
      <Card.Header className="text-center">
        {card.icon}
        <br />
        <br />
        {card.name}
      </Card.Header>
      <Card.Body className="mx-auto">
        {card.category}
        <br />
        {card.description}
        <br />
      </Card.Body>
      <Card.Footer className="mx-auto">
        <Button href={path} variant="primary">
          {card.textButton}
        </Button>
      </Card.Footer>
    </Card>
  );
}
