import { FC } from "react";
import { Button, Col, Dropdown, OverlayTrigger, Table, Tooltip, ButtonGroup, Form } from "react-bootstrap";
import { FaEye, FaEyeSlash, FaRegTrashAlt } from "react-icons/fa";
import { HiDotsVertical } from "react-icons/hi";
import { MdAddCircle, MdArrowDownward, MdArrowUpward } from "react-icons/md";
import { canWrite, getLanguage, getRole } from "src/helpers/auth";
import Protected from "src/routes/Protected";
import { getIngredientsByTemperature } from "src/utils/recipes";
import I18n from "../../../../../Config/I18n";
import IngredientAPI from "../../../../../backend/ingredient";
import StringReducer from "../../../../generic/StringReducer";
import GanacheFormat from "src/utils/ganacheFormat";

const l = getLanguage();

const SelectTemp = ({ temperature, changeTemp }) => {
  const tmp = parseInt(temperature);
  const options = [];
  for (let i = 100; i <= 140; i++) {
    options.push(
      <option selected={i === tmp} value={i}>
        {i} °C
      </option>
    );
  }
  return (
    <select onChange={e => changeTemp(tmp, e.target.value)} tabIndex={-1}>
      {options}
    </select>
  );
};

const MakeTemperatureLine: FC<any> = ({ tmp, mass, cooking, recipe, setRecipe, setIsRecipeModified, changeTemp, reducer }) => {
  const quantity = cooking[tmp]?.total?.quantity || 0;
  const totalWeigth = Object.keys(cooking)
    .map(temp => cooking[temp].total.quantity)
    .reduce(reducer, 0);
  const weigthProportion = totalWeigth ? (quantity * 100) / totalWeigth : 0;

  const deleteTemperatureLine = async temperature => {
    temperature = parseInt(temperature);
    var ingRes = [];
    for (const ing of recipe.ingredients) {
      if (ing.temperature && ing.temperature === temperature) {
        ing.temperature = 0; // Getting back to 'ambiant'
        const resp = await IngredientAPI.getById(ing.ingredient._id);
        const ingredientWithoutTemp = resp.data;
        ing.ingredient = ingredientWithoutTemp;
        ing.coefficient = 1;
      }
      ingRes.push(ing);
    }
    // Changing the ingredients in the recipe state
    setRecipe({
      ...recipe,
      ingredients: ingRes,
    });

    setIsRecipeModified(true);
  };

  return (
    <tr className="table-row-temp table-warning">
      {recipe.editable ? (
        <>
          <th>
            <Dropdown drop="right" as={ButtonGroup} tabIndex={-1}>
              <Dropdown.Toggle className="dropdown-ingredient" variant="secondary" tabIndex={-1} />
              <Dropdown.Menu>
                <Dropdown.Item disabled={!recipe.editable} onClick={() => deleteTemperatureLine(tmp)} tabIndex={-1}>
                  <FaRegTrashAlt />
                  {I18n.t("recipeAction.delete", { locale: l })}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </th>
          <th>
            {I18n.t("listIngredients.totalIngredientsTemp", { locale: l })}
            <SelectTemp temperature={tmp} changeTemp={changeTemp}></SelectTemp>
          </th>
        </>
      ) : (
        <>
          <th>
            {I18n.t("listIngredients.totalIngredientsTemp", { locale: l })} {tmp}°
          </th>
        </>
      )}
      <th style={{ textAlign: "center" }}>{GanacheFormat.round(quantity)}</th>

      <th style={{ textAlign: "right" }}>{GanacheFormat.percentage(weigthProportion)} %</th>
    </tr>
  );
};

const CustomTableCell: FC<any> = ({
  ing,
  name,
  setIngVar,
  index,
  isEditIngTemp,
  setIsEditIngTemp,
  isEditIndexIng,
  setIsEditIndexIng,
  recipe,
}) => {
  const focus = ing.temperature === isEditIngTemp && index === isEditIndexIng;
  const value = focus ? ing[name] : GanacheFormat.round(ing[name]);
  return recipe.editable ? (
    <td className="quantity-text-field-td">
      <Form
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <Form.Control
          type="number"
          min="0"
          value={GanacheFormat.round(value)}
          name={name}
          size="sm"
          autoFocus={focus}
          onChange={e => {
            setIsEditIngTemp(ing.temperature);
            setIsEditIndexIng(index);
            setIngVar(e, ing, index);
          }}
          onClick={e => {
            e.target.select();
          }}
          key={name}
          readOnly={false}
          style={{ textAlign: "right" }}
        />
      </Form>
    </td>
  ) : (
    <div style={{ textAlign: "center", marginTop: "15px" }}>{GanacheFormat.round(ing[name])}</div>
  );
};

const GetIngRow: FC<any> = ({
  ing,
  index,
  indexInRecipe,
  isLast,
  brands,
  recipe,
  cooking,
  moveIngredientUp,
  moveIngredientDown,
  hide,
  onToggleDelete,
  notAuthorize,
  setAddFromRecipe,
  setIngredient,
  setIngredientIndex,
  setIngVar,
  isEditIndexIng,
  isEditIngTemp,
  setIsEditIngTemp,
  setIsEditIndexIng,
  reducer,
}) => {
  const brandFitler = brands.filter(b => ing.ingredient && b._id === ing.ingredient.brandId);
  const ingBrand = brandFitler.length > 0 ? "[" + brandFitler[0]?.name + "]" : "";
  const ingCommName = ing.ingredient.commercialName ? "- " + ing.ingredient.commercialName : "";
  const temperature = ing.temperature || 0;
  const quantity = ing.quantity || 0;
  const totalWeigthTemperatureGroup = recipe.ingredients
    .filter(ing => ing.temperature === temperature)
    .map(ing => ing.quantity)
    .reduce(reducer, 0);
  let groupTemperaturePercentage = 0;
  if (totalWeigthTemperatureGroup) groupTemperaturePercentage = (quantity / totalWeigthTemperatureGroup) * 100;
  if (temperature === 0) {
    const totalWeigth =
      Object.keys(cooking)
        .map(temp => cooking[temp].total.quantity)
        .reduce(reducer, 0) +
      recipe.ingredients
        .filter(ing => !ing.temperature && ing.hide)
        .map(ing => ing.quantity)
        .reduce(reducer, 0);
    groupTemperaturePercentage = (quantity * 100) / totalWeigth;
  }

  return (
    <tr key={ing.ingredient._id}>
      {recipe.editable && (
        <td>
          <Dropdown drop="right" as={ButtonGroup}>
            <Dropdown.Toggle className="dropdown-ingredient" variant="secondary" split tabIndex={-1} />
            <Dropdown.Menu>
              {index !== 0 ? (
                <Dropdown.Item className="flex items-center gap-x-3" onClick={() => moveIngredientUp(ing)}>
                  <MdArrowUpward size="1.5em" />
                  {I18n.t("recipeAction.moveUp", { locale: l })}
                </Dropdown.Item>
              ) : null}

              {!isLast ? (
                <Dropdown.Item className="flex items-center gap-x-3" onClick={() => moveIngredientDown(ing)}>
                  <MdArrowDownward size="1.5em" />
                  {I18n.t("recipeAction.moveDown", { locale: l })}
                </Dropdown.Item>
              ) : null}

              {index === 0 && isLast ? <></> : <Dropdown.Divider />}
              <Dropdown.Divider />
              <Dropdown.Item className="flex items-center gap-x-3" onClick={() => hide(ing)}>
                {ing.hide ? (
                  <>
                    <FaEye />
                    {I18n.t("recipeAction.show", { locale: l })}
                  </>
                ) : (
                  <>
                    <FaEyeSlash />
                    {I18n.t("recipeAction.hide", { locale: l })}
                  </>
                )}
              </Dropdown.Item>
              <Dropdown.Divider />
              <Protected
                role={getRole()}
                perform="recipe:edit"
                data={{ canWrite: canWrite() }}
                yes={() => (
                  <Dropdown.Item className="flex items-center gap-x-3" onClick={() => onToggleDelete(indexInRecipe)}>
                    <FaRegTrashAlt />
                    {I18n.t("recipeAction.delete", { locale: l })}
                  </Dropdown.Item>
                )}
                no={() => <Dropdown.Item style={{ padding: 0, margin: 0 }} onClick={notAuthorize} disabled></Dropdown.Item>}
              />
            </Dropdown.Menu>
          </Dropdown>
          <div style={{ display: "inline-block", marginLeft: "1.5em" }} onClick={() => hide(ing)}>
            {ing.hide ? <FaEyeSlash /> : " "}
          </div>
        </td>
      )}
      <td>
        <button
          tabIndex={-1}
          style={{ cursor: "pointer" }}
          onClick={() => {
            setAddFromRecipe(true);
            setIngredient(ing.ingredient);
            setIngredientIndex(indexInRecipe);
          }}
        >
          <StringReducer stringToReduce={ing.ingredient.name + " " + ingCommName + " " + ingBrand} maxShow={30} />
        </button>
      </td>
      <CustomTableCell
        ing={ing}
        name="quantity"
        setIngVar={setIngVar}
        index={index}
        recipe={recipe}
        isEditIndexIng={isEditIndexIng}
        isEditIngTemp={isEditIngTemp}
        setIsEditIngTemp={setIsEditIngTemp}
        setIsEditIndexIng={setIsEditIndexIng}
      />
      <td style={{ textAlign: "right" }}>{GanacheFormat.percentage(groupTemperaturePercentage)} %</td>
    </tr>
  );
};

const GetIngredientRowsByTemp: any = ({
  recipe,
  brands,
  cooking,
  moveIngredientUp,
  moveIngredientDown,
  hide,
  onToggleDelete,
  notAuthorize,
  setAddFromRecipe,
  setIngredient,
  setIngredientIndex,
  setIngVar,
  isEditIndexIng,
  isEditIngTemp,
  setIsEditIngTemp,
  setIsEditIndexIng,
  setIsRecipeModified,
  setRecipe,
  changeTemp,
  reducer,
}) => {
  const ingredientsByTemperature = getIngredientsByTemperature(recipe.ingredients);
  return Object.keys(ingredientsByTemperature).map(tmp => {
    let mass = 0;
    return (
      <>
        {ingredientsByTemperature[tmp].map((ingByTemp, index) => {
          let ingTemp;
          if (tmp !== "ambiant") {
            ingTemp = recipe.ingredients.filter(ing => `${ing.temperature}` === tmp)[index];
          } else {
            ingTemp = recipe.ingredients.filter(ing => !ing.temperature || !parseInt(ing.temperature))[index];
          }

          const indexInRecipe = recipe.ingredients.indexOf(ingTemp);
          const ing = recipe.ingredients[indexInRecipe];
          const isLast = parseInt(index) === ingredientsByTemperature[tmp].length - 1;
          mass += ing.coefficient * ing.quantity;
          return (
            <GetIngRow
              ing={ing}
              index={index}
              indexInRecipe={indexInRecipe}
              isLast={isLast}
              recipe={recipe}
              brands={brands}
              cooking={cooking}
              moveIngredientUp={moveIngredientUp}
              moveIngredientDown={moveIngredientDown}
              hide={hide}
              onToggleDelete={onToggleDelete}
              notAuthorize={notAuthorize}
              setAddFromRecipe={setAddFromRecipe}
              setIngredient={setIngredient}
              setIngredientIndex={setIngredientIndex}
              setIngVar={setIngVar}
              isEditIndexIng={isEditIndexIng}
              isEditIngTemp={isEditIngTemp}
              setIsEditIngTemp={setIsEditIngTemp}
              setIsEditIndexIng={setIsEditIndexIng}
              reducer={reducer}
            />
          );
        })}
        {tmp !== "ambiant" && (
          <MakeTemperatureLine
            tmp={tmp}
            mass={mass}
            cooking={cooking}
            recipe={recipe}
            setRecipe={setRecipe}
            setIsRecipeModified={setIsRecipeModified}
            changeTemp={changeTemp}
            reducer={reducer}
          />
        )}
      </>
    );
  });
};

export const ListTable: FC<any> = ({
  recipe,
  cooking,
  reducer,
  setIsTemperaturePopupOpen,
  setIsIngredientsPopupOpen,
  brands,
  moveIngredientUp,
  moveIngredientDown,
  hide,
  onToggleDelete,
  notAuthorize,
  setAddFromRecipe,
  setIngredient,
  setIngredientIndex,
  setIngVar,
  isEditIndexIng,
  isEditIngTemp,
  setIsEditIngTemp,
  setIsEditIndexIng,
  setIsRecipeModified,
  setRecipe,
  changeTemp,
  totalWeigth,
}) => {
  return (
    <Col lg={4} md={6}>
      <h3 className="text-3xl font-semibold">{I18n.t("listIngredients.list", { locale: l })}</h3>
      <div className="absolute right-2 top-1" hidden={!recipe.editable}>
        {I18n.t("recipeAction.groupAction", { locale: l })}
        <Button variant="light" onClick={() => setIsTemperaturePopupOpen(true)} tabIndex={-1}>
          <HiDotsVertical />
        </Button>
      </div>
      <Table className="table-ingredients table-ingredients-main" striped bordered hover responsive={true} aria-label="simple table">
        <thead tabIndex={-1}>
          <tr>
            {recipe.editable ? (
              <th>
                <OverlayTrigger overlay={<Tooltip id="2">{I18n.t("ingredient.addIngredient", { locale: l })}</Tooltip>} placement="auto">
                  <Button size="sm" onClick={() => setIsIngredientsPopupOpen(true)} tabIndex={-1}>
                    <MdAddCircle />
                  </Button>
                </OverlayTrigger>
              </th>
            ) : null}

            <th>{I18n.t("ingredient.nameIngredient", { locale: l })}</th>
            <th>{I18n.t("listIngredients.qty", { locale: l })}&nbsp;(g)</th>
            <th>{I18n.t("listIngredients.qty", { locale: l })}&nbsp;(%)</th>
          </tr>
        </thead>
        {recipe.ingredients && (
          <tbody>
            <GetIngredientRowsByTemp
              recipe={recipe}
              brands={brands}
              cooking={cooking}
              moveIngredientUp={moveIngredientUp}
              moveIngredientDown={moveIngredientDown}
              hide={hide}
              onToggleDelete={onToggleDelete}
              notAuthorize={notAuthorize}
              setAddFromRecipe={setAddFromRecipe}
              setIngredient={setIngredient}
              setIngredientIndex={setIngredientIndex}
              setIngVar={setIngVar}
              isEditIndexIng={isEditIndexIng}
              isEditIngTemp={isEditIngTemp}
              setIsEditIngTemp={setIsEditIngTemp}
              setIsEditIndexIng={setIsEditIndexIng}
              setIsRecipeModified={setIsRecipeModified}
              setRecipe={setRecipe}
              changeTemp={changeTemp}
              reducer={reducer}
            />
            <tr className="table-primary text-right">
              {recipe.editable ? <td /> : null}
              <td>{I18n.t("listIngredients.totals", { locale: l })}</td>
              <td>{`${parseInt(totalWeigth)} g`}</td>
              <td>100%</td>
            </tr>
          </tbody>
        )}
      </Table>
    </Col>
  );
};
