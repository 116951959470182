import { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { FolderController } from "../../backend/folder";
import { RecipeController } from "../../backend/recipe";
import I18n from "../../Config/I18n";
import { getLanguage } from "../../helpers/auth";
import { PUT } from "../../helpers/requests";
import { arraySubFolders, pathFolder } from "../../utils";
import Loader from "../loader";
import RecipeCard2 from "../RecipeCard2";
import RecipesToolbar from "./toolbars/recipesToolbar";
import FolderCard2 from "./FolderCard";
import FoldersToolbar from "./toolbars/foldersToolbar";
import { useLocation } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

const l = getLanguage();

const FolderContent = ({ path, status, categories }) => {
  const [loadingFolder, setLoadingFolder] = useState(false);
  const [loadingRecipes, setLoadingRecipes] = useState(false);
  const [folder, setFolder] = useState({});
  const [subFoldersIds, setSubFoldersIds] = useState([]);
  const [recipes, setRecipes] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false); // snackbar
  const [messageSnackBar, setMessageSnackBar] = useState("");
  const [alertVariant, setAlertVariant] = useState("dark");
  const [pageQuantity, setPageQuantity] = useState(1);

  const recursivePath = {
    pathUrl: "/dashboard/" + path,
    pathToolbar: arraySubFolders(),
    pathApi: pathFolder(),
  };

  const location = useLocation();

  useEffect(() => {
    loadFolder();
  }, [location.pathname]);

  const loadFolder = async () => {
    setLoadingFolder(true);
    setLoadingRecipes(true);
    setRecipes([]);
    const folderLoaded = await FolderController.getFromPath2(path);
    setSubFoldersIds(folderLoaded.childrens);
    setFolder(folderLoaded);

    setLoadingFolder(false);
    const folderRecipes = await RecipeController.getSummaryByIds(folderLoaded.recipes);
    setRecipes(folderRecipes.sort(compareRecipeName));
    setLoadingRecipes(false);
  };

  const updateFolder = async () => {
    const folderLoaded = await FolderController.getFromPath2(path);
    setSubFoldersIds(folderLoaded.childrens);
  };

  const updateRecipes = async () => {
    const folderLoaded = await FolderController.getFromPath2(path);
    const folderRecipes = await RecipeController.getSummaryByIds(folderLoaded.recipes);
    setRecipes(folderRecipes.sort(compareRecipeName));
  };

  const compareRecipeName = (recipe1, recipe2) => {
    const name1 = recipe1.nameTranslation && recipe1.nameTranslation[l] ? recipe1.nameTranslation[l] : recipe1.name;
    const name2 = recipe2.nameTranslation && recipe2.nameTranslation[l] ? recipe2.nameTranslation[l] : recipe2.name;

    return name1.localeCompare(name2);
  };

  const handleOpen = () => {
    setOpenSnackbar(true);
  };
  const handleClose = (event, reason) => {
    if (reason !== "clickaway") return setOpenSnackbar(false);
  };

  const sendSnack = (message, type, time = 2000) => {
    setMessageSnackBar(message);
    setAlertVariant(type);
    handleOpen();
    setTimeout(handleClose, time);
  };

  const addFolder = async newFolder => {
    const data = {
      parentId: folder._id,
      name: newFolder,
    };
    const result = await FolderController.add(data);
    if (result.error) {
      if (result.error.alreadyExist) sendSnack("Folder already exists", "danger");
    } else {
      sendSnack("ok :)", "success");

      updateFolder();
    }
  };
  const loadSize = 20;
  const recipesToShow = recipes.slice(0, loadSize * pageQuantity);

  return (
    <div className="space-y-8">
      <div>
        <FoldersToolbar folderData={folder} addFolder={addFolder} />
        <div className="flex flex-wrap">
          {loadingFolder ? (
            <Loader />
          ) : subFoldersIds?.length ? (
            subFoldersIds.map(folderId => {
              return (
                <Col key={`folder_${folderId}`} className="col-auto mb-3">
                  <FolderCard2 id={folderId} update={updateFolder} />
                </Col>
              );
            })
          ) : (
            <h6 className="mx-auto my-3 text-muted">{I18n.t("folder.nofolders", { locale: l })}</h6>
          )}
        </div>
      </div>
      <div>
        <RecipesToolbar folderData={folder} recipeCount={recipes.length} />
        <div className="flex flex-wrap">
          {loadingRecipes ? (
            <Loader />
          ) : recipes.length ? (
            /*
            recipes?.map(recipe => (
              <Col key={recipe._id} className="col-auto mb-3" hidden={!recipe.name}>
                <RecipeCard2 recipe={recipe} status={status} categories={categories} folderId={folder._id} update={updateRecipes} />
              </Col>
            ))
            */
            <InfiniteScroll
              dataLength={recipesToShow.length}
              hasMore={recipesToShow.length < recipes.length}
              next={() => {
                setPageQuantity(pageQuantity + 1);
              }}
              loader={<Loader />}
              className="flex flex-wrap"
            >
              {recipesToShow.map((recipe, index) => {
                return (
                  <Col key={recipe._id} className="col-auto mb-3" hidden={!recipe.name}>
                    <RecipeCard2 recipe={recipe} status={status} categories={categories} folderId={folder._id} update={updateRecipes} />
                  </Col>
                );
              })}
            </InfiniteScroll>
          ) : (
            <h6 className="mx-auto my-3 text-muted">{I18n.t("recipeObject.norecipes", { locale: l })}</h6>
          )}
        </div>
      </div>
    </div>
  );
};

export default FolderContent;
