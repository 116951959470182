import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import {getId, getLanguage, getUser} from "../../helpers/auth";
import I18n from "../../Config/I18n";
import SearchBoxToggle from "../generic/SearchBoxToggle";
import FilterSelect from "../generic/FilterSelect";
import { Url } from "../../Config/url";
import { AiOutlineCloudUpload, AiOutlineCloudDownload } from "react-icons/ai";
import IngredientAPI from "../../backend/ingredient";
import BrandAPI from "../../backend/brand";
import { IoMdAdd } from "react-icons/io";
import { TextWithIconButtonOrange } from "../buttons/textWithIconButton";
import { IconLinkOrange } from "src/components/links/iconLinkOrangeOutline";
import { InputFileOrange, InputOrange } from "../form";
import { getRole, Roles } from "../../helpers/auth";
import PopupDemo from "../payment/PopupDemo";

const l = getLanguage();
const role = getRole();

let filterValue = {
  name: "",
  brandName: "",
  categoryName: "",
};

export default function IngredientsPageToolbar({
  brands,
  categories,
  getIngredientsFilter,
  search,
  filterSearch,
  setFilterSearch,
  setLoading,
  snack,
  setSnack,
  handleCloseSnack,
}) {
  const [isSearching, setIsSearching] = useState(false);
  const [isPopupDemoOpen, setIsPopupDemoOpen] = useState(false);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    await loadUrlData();
  };

  const loadUrlData = async () => {
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has("name")) {
      const name = urlParams.get("name");
      filterSearch.name = name;
      filterValue.name = name;
      setIsSearching(!isSearching);
    }

    if (urlParams.has("brand")) {
      const brandId = urlParams.get("brand");
      filterSearch.brandId = brandId;
      const brandName = (await BrandAPI.get()).data.filter(b => b._id === brandId);
      if (brandName && brandName[0]) {
        filterValue.brandName = brandName[0].name;
        filterSearch.brandId = brandName[0]._id;
      } else {
        filterValue.brandName = "";
        delete filterSearch.brandId;
      }
      setIsSearching(!isSearching);
    }

    if (urlParams.has("category")) {
      const categoryId = urlParams.get("category");
      filterSearch.categoryId = categoryId;
      const catName = (await IngredientAPI.getCategories()).data.filter(c => c._id === categoryId);
      if (catName && catName[0]) {
        filterValue.categoryName = catName[0].name;
        filterSearch.categoryId = catName[0]._id;
      } else {
        filterValue.categoryName = "";
        delete filterSearch.categoryId;
      }
      setIsSearching(!isSearching);
    }
    setFilterSearch({ ...filterSearch });
  };

  const handleChangeSearch = event => {
    const name = event.target.value.substr(0, 20);
    filterSearch.name = name.length > 0 ? name : undefined;
    setFilterSearch({ ...filterSearch });
    filterValue.name = name.length > 0 ? name : undefined;

    // getIngredientsFilter()
  };

  const handleChangeCategory = eventKey => {
    filterSearch.categoryId = eventKey || undefined;
    setFilterSearch({ ...filterSearch });

    // getIngredientsFilter()
  };

  const handleChangeBrand = eventKey => {
    filterSearch.brandId = eventKey || undefined;
    setFilterSearch({ ...filterSearch });

    // getIngredientsFilter()
  };

  const handleSelectFile = async e => {
    setLoading(true);
    const resp = await IngredientAPI.importFoodCost({
      file: e.target.files[0],
    });
    let snackMsg = "";
    let snackVariant = "";
    if (resp.data) {
      if (resp.data.ok) {
        snackMsg = I18n.t("importSuccess", { locale: l });
        snackVariant = "success";
      } else {
        snackMsg = I18n.t("importFailure", { locale: l }) + ", " + resp.data.message;
        snackVariant = "danger";
      }
    }
    setSnack({
      snackOpen: true,
      snackMessage: snackMsg,
      snackVariant: snackVariant,
    });
    setTimeout(handleCloseSnack, 2000);
    setLoading(false);
  };

  const clearFilter = () => {
    filterSearch.name = "";
    setFilterSearch({ ...filterSearch });
  };

  const handleExport = () => {
    setLoading(true);
    window.location.href = process.env.REACT_APP_API_URL + "/ingredient/export";
    // 'fake loading'
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  };

  const handleDownloadIngredientTranslation = () => {
    setLoading(true);
    window.location.href = IngredientAPI.downloadIngredientTranslationUrl();
    // 'fake loading'
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  };

  const handleUploadIngredientTranslation = async e => {
    setLoading(true);
    const resp = await IngredientAPI.uploadIngTranslation(e.target.files[0]);
    let snackMsg = "";
    let snackVariant = "";
    if (resp?.data?.data) {
      snackMsg = I18n.t("importSuccess", { locale: l });
      snackVariant = "success";
    } else {
      snackMsg = I18n.t("importFailure", { locale: l }) + ", " + resp.data.message;
      snackVariant = "danger";
    }
    setSnack({
      snackOpen: true,
      snackMessage: snackMsg,
      snackVariant: snackVariant,
    });
    setTimeout(handleCloseSnack, 2000);
    setLoading(false);
  };

  const handleDownloadIngredientsData = () => {
    setLoading(true);
    window.location.href = IngredientAPI.downloadIngredientDataUrl(); //process.env.REACT_APP_API_URL + "/ingredient/exportAll"
    // 'fake loading'
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  };

  const handleUploadIngredientsData = async e => {
    setLoading(true);
    let snackMsg = "";
    let snackVariant = "";
    const resp = await IngredientAPI.uploadIngTData(e.target.files[0]);
    if (resp?.data?.data) {
      snackMsg = I18n.t("importSuccess", { locale: l });
      snackVariant = "success";
    } else {
      snackMsg = I18n.t("importFailure", { locale: l }) + ", " + resp.data.message;
      snackVariant = "danger";
    }
    setSnack({
      snackOpen: true,
      snackMessage: snackMsg,
      snackVariant: snackVariant,
    });
    setTimeout(handleCloseSnack, 2000);
    setLoading(false);
  };

  const handleAddIngredientClick = () => {
    if (role === "demo") {
      setIsPopupDemoOpen(true);
    }
  };

  const handleClosePopupDemo = () => {
    setIsPopupDemoOpen(false);
  };

  const renderAddIngredientButton = () => {
    if (role === "demo") {
      return (
          <div>
          {isPopupDemoOpen && (
              <PopupDemo
                  open={isPopupDemoOpen}
                  handleClosePopupDemo={handleClosePopupDemo}
              />
          )}
          <button onClick={handleAddIngredientClick} className="icon-link-orange">
            <IoMdAdd />
          </button>
          </div>
      );
    }

    return (
        <IconLinkOrange redirectUrl={Url.editIngredient}>
          <IoMdAdd />
        </IconLinkOrange>
    );
  };

  return (
    <>
      <div className="flex flex-wrap justify-between gap-4">
        <div className="flex items-center gap-x-4">
          <h3 className="self-center text-xl font-bold">{I18n.t("ingredients", { locale: l })}</h3>
          {renderAddIngredientButton()}
        </div>
        {isSearching && (
          <div className="flex flex-wrap gap-2">
            <TextWithIconButtonOrange onClick={handleExport}>
              {I18n.t("importFoodCost", { locale: l })}
              <AiOutlineCloudDownload className="mt-1" />
            </TextWithIconButtonOrange>
            <InputFileOrange id="download-food-cost" type="file" accept=".xls" onChange={handleSelectFile} hidden>
              {I18n.t("exportFoodCost", { locale: l })}
              <AiOutlineCloudUpload className="mt-1" />
            </InputFileOrange>
            {getUser().userType === "administrator" && (
              <>
                <TextWithIconButtonOrange onClick={handleDownloadIngredientsData}>
                  Download ingredients <AiOutlineCloudDownload className="mt-1" />
                </TextWithIconButtonOrange>
                <InputFileOrange
                  id="export-ingredients-data"
                  type="file"
                  accept=".xls"
                  onChange={handleUploadIngredientsData}
                  hidden
                  download
                >
                  Export ingredients
                  <AiOutlineCloudUpload className="mt-1" />
                </InputFileOrange>
                <TextWithIconButtonOrange onClick={handleDownloadIngredientTranslation}>
                  {I18n.t("importIngTranslation", { locale: l })} <AiOutlineCloudDownload className="mt-1" />
                </TextWithIconButtonOrange>
                <InputFileOrange
                  id="export-ingredients-translation"
                  type="file"
                  accept=".csv"
                  onChange={handleUploadIngredientTranslation}
                  hidden
                  download
                >
                  {I18n.t("exportIngTranslation", { locale: l })}
                  <AiOutlineCloudUpload className="mt-1" />
                </InputFileOrange>
              </>
            )}

            <FilterSelect
              elements={brands}
              filterName={I18n.t("brands", { locale: l })}
              handleChange={handleChangeBrand}
              defaultValue={filterValue.brandName}
            />
            <FilterSelect
              elements={categories}
              filterName={I18n.t("category", { locale: l })}
              handleChange={handleChangeCategory}
              defaultValue={filterValue.categoryId}
            />
            <InputOrange value={filterSearch.name} onChange={handleChangeSearch} placeholder={I18n.t("searchByName", { locale: l })} />
            {filterSearch.name !== "" && filterSearch.name !== undefined ? <Button onClick={clearFilter}>X</Button> : null}
          </div>
        )}
        <SearchBoxToggle isSearching={isSearching} setIsSearching={setIsSearching} />
      </div>
    </>
  );
}
