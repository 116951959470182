import { ContributionSimple } from "src/Type/contribution2";
import { Recipe } from "src/Type/recipe2";
import recipe from "../backend/recipe";

export const getRecipe = data => {
  if (!data) {
    return {
      createdOn: Date.now(),
      editable: false,
      ingredients: [],
      lastUpdateOn: Date.now(),
      name: "",
      note: "",
      owner: "",
      procedure: "",
      source: "",
      statusId: "",
      _id: "",
    };
  }
  const recipe = {
    createdOn: new Date(data.createdOn),
    editable: data.editable === true,
    ingredients: getGroupTemperature(data.ingredients),
    lastUpdateOn: new Date(data.lastUpdateOn),
    name: `${data.name}`,
    note: `${data.note}`,
    owner: `${data.owner}`,
    procedure: `${data.procedure}`,
    source: `${data.source}`,
    statusId: `${data.statusId}`,
    _id: `${data._id}`,
  };
  return recipe;
};

/**
 *
 * @param {{
 *  quantity: number,
 *  temperature: number,
 *  ingredient: {
 *    brandId: string,
 *    categoryId: string,
 *    commercial: string,
 *    composition: import("../Type/ingredientComposition").IngredientComposition,
 *    editable: boolean,
 *    flags:{
 *      contents: string[],
 *      fit: string[]
 *    },
 *    fromTechnicalSheet: boolean,
 *    name: string,
 *    note:{
 *      foodCost: number,
 *      note: string
 *    },
 *    owner: string,
 *    _id: string
 *  }
 * }[]} ingredients
 *
 * @returns {Object.<number, import("../Type/recipe").GroupTemperatureData>}
 */
const getGroupTemperature = async ingredients => {
  const result = {};
  ingredients.forEach(ing => {
    if (ing.hide) return;
    const temperature = ing.temperature || 0;
    if (!result[temperature]) {
      result[temperature] = {
        ingredients: [],
      };
    }

    result[temperature].ingredients.push({
      quantity: ing.quantity,
      composition: ing.ingredient.composition,
    });
  });
  for (const temperature of Object.keys(result)) {
    const elements = result[temperature].ingredients;
    result[temperature]["total"] = await computeTemperatures({
      temperature,
      elements,
    });
  }
  return result;
};

/**
 *
 * @param {import("../Type/recipe").GroupTemperature} group
 * @returns {Promise<import("../Type/recipe").Composition>}
 */
export const computeTemperatures = async group => {
  const total = await recipe.computeEvaporation(group.temperature, group.elements);
  return total;
};

/**
 *
 * @param {import("../Type/recipe").Recipe} recipe
 */
export const getGroupTemperatureFromRecipe = async recipe => {
  const groups = await getGroupTemperature(recipe?.ingredients || []);
  return groups;
};

export const getEmptyContribution: () => ContributionSimple = () => ({
  sugar: 0,
  butter: 0,
  cocoa_butter: 0,
  oil: 0,
  amp: 0,
  lactose: 0,
  cocoa_solid: 0,
  other: 0,
  water: 0,
  alcohol: 0,
  pod: 0,
  pac: 0,
  kcal: 0,
  kj: 0,
});

export const getTotalWeigthAfterCooking: (cooked) => number = cooked => {
  if (!cooked) return 0;

  const reducer = (accumulator, value) => accumulator + value;

  return Object.keys(cooked)
    .map(temp => {
      const qty = cooked[temp].total.quantity || 0;
      return qty;
    })
    .reduce(reducer, 0);
};

export const getTotalContributions: (cooking) => ContributionSimple[] = cooking => {
  const totalWeigth = getTotalWeigthAfterCooking(cooking);
  return Object.keys(cooking).map(temp => {
    const temperature = parseInt(temp);
    const group = cooking[temperature];
    const coef = group.total.quantity / totalWeigth;
    const composition = group.total.composition;

    const contribution = {
      sugar: composition.sugar * coef,
      butter: composition.fats.butter * coef,
      cocoa_butter: composition.fats.cocoa_butter * coef,
      oil: composition.fats.oil * coef,
      amp: composition.dry.amp * coef,
      lactose: composition.dry.lactose * coef,
      cocoa_solid: composition.dry.cocoa_solid * coef,
      other: composition.dry.other * coef,
      water: composition.liquids.water * coef,
      alcohol: composition.liquids.alcohol * coef,
      pod: composition.other.pod * coef,
      pac: composition.other.pac * coef,
      kcal: composition.other.kcal,
      kj: composition.other.kj,
    };
    return contribution;
  });
};
