import { getLanguage } from "../helpers/auth";

import I18n from "../Config/I18n";
const l = getLanguage();

export const temperatures = [
  {
    value: 0,
    label: I18n.t("ambiant", { locale: l }),
  },
  {
    value: 100,
    label: "100",
  },
  {
    value: 101,
    label: "101",
  },
  {
    value: 102,
    label: "102",
  },
  {
    value: 103,
    label: "103",
  },
  {
    value: 104,
    label: "104",
  },
  {
    value: 105,
    label: "105",
  },
  {
    value: 106,
    label: "106",
  },
  {
    value: 107,
    label: "107",
  },
  {
    value: 108,
    label: "108",
  },
  {
    value: 109,
    label: "109",
  },
  {
    value: 110,
    label: "110",
  },
  {
    value: 111,
    label: "111",
  },
  {
    value: 112,
    label: "112",
  },
  {
    value: 113,
    label: "113",
  },
  {
    value: 114,
    label: "114",
  },
  {
    value: 115,
    label: "115",
  },
  {
    value: 116,
    label: "116",
  },
  {
    value: 117,
    label: "117",
  },
  {
    value: 118,
    label: "118",
  },
  {
    value: 119,
    label: "119",
  },
  {
    value: 120,
    label: "120",
  },
  {
    value: 121,
    label: "121",
  },
  {
    value: 122,
    label: "122",
  },
  {
    value: 123,
    label: "123",
  },
  {
    value: 124,
    label: "124",
  },
  {
    value: 125,
    label: "125",
  },
  {
    value: 126,
    label: "126",
  },
  {
    value: 127,
    label: "127",
  },
  {
    value: 128,
    label: "128",
  },
  {
    value: 129,
    label: "129",
  },
  {
    value: 130,
    label: "130",
  },
  {
    value: 131,
    label: "131",
  },
  {
    value: 132,
    label: "132",
  },
  {
    value: 133,
    label: "133",
  },
  {
    value: 134,
    label: "134",
  },
  {
    value: 135,
    label: "135",
  },
  {
    value: 136,
    label: "136",
  },
  {
    value: 137,
    label: "137",
  },
  {
    value: 138,
    label: "138",
  },
  {
    value: 139,
    label: "139",
  },
  {
    value: 140,
    label: "140",
  },
];
