import { FC } from "react";
import { Spinner } from "react-bootstrap";

const Loader: FC<{ variant?: string }> = ({ variant = "primary" }) => {
  return (
    <div className="flex items-center justify-center">
      <Spinner animation="border" variant={variant} />
    </div>
  );
};

export default Loader;
