import React from "react";
import Protected from "../routes/Protected";
import SignUp from "./SignUp";
import { getRole, getName, getLanguage } from "../helpers/auth";
import I18n from "../Config/I18n";
import { Url } from "../Config/url";
import { LinkOrange } from "../components/links/linkOrange";

const l = getLanguage();

export default function Welcome() {
  const name = getName();
  const role = getRole();

  return (
    <Protected
      role={role}
      perform="welcome:visit"
      yes={() => (
        <div className="flex items-center justify-center h-full bg-ganache-brown-500 text-ganache-neutral-900">
          <div className="px-3 py-4 mx-3 space-y-2 rounded-sm shadow-sm md:mx-8 bg-ganache-neutral-100 shadow-ganache-neutral-100/80">
            <h1 className="text-xl font-semibold">
              {I18n.t("hello", { locale: l })} {name}
            </h1>
            <p>{I18n.t("welcomeMessage", { locale: l })}</p>
            <div className="flex justify-start pt-4 sm:justify-end">
              <LinkOrange to={Url.admin}>{I18n.t("letStart", { locale: l })}</LinkOrange>
            </div>
          </div>
        </div>
      )}
      no={() => SignUp}
    />
  );
}
