import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import paginationFactory from "react-bootstrap-table2-paginator";
import SimpleSnackbar from "../../../components/SimpleSnackbar";
import UserAPI from "../../../backend/user";
import ToolbarUsers from "./ToolbarUsers";
import I18n from "../../../Config/I18n";
import { getLanguage } from "../../../helpers/auth";
import { Button, Modal } from "react-bootstrap";
import { MdVisibility } from "react-icons/md";
import CancelSubscriptionAdmin from "../../account/CancelSubscriptionAdmin";
const l = getLanguage();

const variants = {
  info: "info",
  error: "danger",
  success: "success",
};



export default function TableInfoUsers() {
  /**
   * @type {[[import('../../../Type/user').UserData],Function]}
   */
  const [users, setUsers] = useState([]);
  const [usersEmail, setUsersEmail] = useState("");
  const [usersFirstName, setUsersFirstName] = useState("");
  const [usersLastName, setUsersLastName] = useState("");
  const [dateAfter, setDateAfter] = useState(undefined);
  const [dateBefore, setDateBefore] = useState(undefined);
  const [mollieIds, setMollieIds] = useState("");
  const [open, setOpen ] = useState(false);
  const [mollieId, setMollieId] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState("");
  const [messageSnackBar, setMessageSnackBar] = useState("");
  const [alertVariant, setAlertVariant] = useState("dark");
  /**
   * @type {[[import('../../../Type/user').UserType],Function]}
   */
  const [userTypes, setUserTypes] = useState([]);
  const [snack, setSnack] = useState({
    open: false,
    message: "",
    variant: "info",
  });

  useEffect(() => {
    loadUsers();
    loadUserType();
  }, []);

  const loadUsers = async () => {
    const res = await UserAPI.getUsers();
    setUsers(res);
  };

  const loadUserType = async () => {
    const res = await UserAPI.getUserType();
    setUserTypes(res);
  };
  // close snackbar
  const handleClose = (event, reason) => {
    if (reason !== "clickaway")
      return setSnack({
        open: false,
        message: "",
        variant: "info",
      });
  };

  const sendSnack = (message = "", variant = variants.info, delay = 2000) => {
    const newSnack = {
      open: true,
      message,
      variant,
    };
    setSnack(newSnack);
    setTimeout(handleClose, delay);
  };

  /**
   *
   * @param {import('../../../Type/user').UserData} user
   */
  const updateUser = async user => {
    const old = users.find(u => u._id === user._id);
    if (userTypes.find(ut => ut._id === old.userTypeId).name === "administrator") old.expirationDate = undefined;
    const data = {
      name: user.name,
      lastname: user.lastname,
      email: user.email,
      expirationDate: user.expirationDate,
      userTypeId: user.userTypeId,
      no_upgrade: user.no_upgrade,
      demo_upgraded: user.demo_upgraded
    };
    console.log("data",data);
    const resp = await UserAPI.update(data, user._id);
    if (resp.error) return sendSnack("User not saved", variants.error);
    sendSnack("User saved", variants.info);
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };

  // close snackbar
  const handleCloseSnackbar = (event, reason) => {
    if (reason !== "clickaway") return setOpenSnackbar(false);
  };

  function handleClickOpenPopupCancelSubscription(mollieId) {
    setOpen(true);
    setMollieId(mollieId);
  };


  const handleNoUpgradeChange = async (row) => {
    const updatedUser = { ...row, no_upgrade: !row.no_upgrade };
    console.log("updatedUser",updatedUser);
    await updateUser(updatedUser);
    setUsers(users.map(user => user._id === row._id ? updatedUser : user));
  };

  const handleDemoUpgradedChange = async (row) => {
    const updatedUser = { ...row, demo_upgraded: !row.demo_upgraded };
    await updateUser(updatedUser);
    setUsers(users.map(user => user._id === row._id ? updatedUser : user));
  };


  const handleClosePopupCancelSubscription = () => {
    setOpen(false);
  };

  const columns = [
    {
      text: I18n.t("coupon._id", l),
      dataField: "_id",
      hidden: true,
      editable: false,
    },

    {
      text: I18n.t("user.email", l),
      dataField: "email",
      editor: {
        type: Type.TEXT,
      },
    },
    {
      text: I18n.t("user.firstname", l),
      dataField: "name",
      editor: {
        type: Type.TEXT,
      },
    },
    {
      text: I18n.t("user.lastname", l),
      dataField: "lastname",
      editor: {
        type: Type.TEXT,
      },
    },
    {
      text: I18n.t("admin.clients.expirationDate", l),
      dataField: "expirationDate",
      formatter: expirationDate => {
        if (!expirationDate) return "Free";
        let dateObj = expirationDate;
        if (typeof cell !== "object") {
          dateObj = new Date(expirationDate);
        }
        return `${("0" + dateObj.getUTCDate()).slice(-2)}/${("0" + (dateObj.getUTCMonth() + 1)).slice(-2)}/${dateObj.getUTCFullYear()}`;
      },
      editor: {
        type: Type.DATE,
      },
    },
    {
      text: I18n.t("admin.clients.statusUser", l),
      dataField: "userTypeId",
      formatter: userTypeId => {
        const found = userTypes.find(type => type._id === userTypeId);
        return found ? found.name : "...";
      },
      editor: {
        type: Type.SELECT,
        options: userTypes.map(type => {
          return {
            value: type._id,
            label: type.name,
          };
        }),
      },
    },
    {
      text: I18n.t("user.mollieId", l),
      dataField: "mollieId",
      editable: false,
      formatter: mollieId => {
        if (!mollieId) return "No mollie Id";
        return mollieId;
      },
    },
    {
      text: I18n.t("user.customerNumber", l),
      dataField: "customerNumber",
      editable: false,
    },
    {
      text: I18n.t("actions", l),
      dataField: "mollieId",
      editable: false,
      formatter: (cell, row) => {
        let newDate = new Date();
        let dateObj = new Date(row.expirationDate);
        if (dateObj > newDate && row.mollieId) 
          return  <Button size="sm" variant="outline-danger" onClick={() => handleClickOpenPopupCancelSubscription(row.mollieId)}>
            {I18n.t("account.cancelSubscription", { locale: l })}
          </Button>
      },
    },
    {
      text: I18n.t("user.no_upgrade", l),
      dataField: "no_upgrade",
      formatter: (cell, row) => (
          <input
              type="checkbox"
              checked={cell}
              onChange={() => handleNoUpgradeChange(row)}
          />
      ),
      editable: false,
    },
    {
      text: I18n.t("user.demo_upgraded", l),
      dataField: "demo_upgraded",
      formatter: (cell, row) => (
          <input
              type="checkbox"
              checked={cell}
              onChange={() => handleDemoUpgradedChange(row)}
          />
      ),
      editable: false,
    },
  ];

  const isAfter = (limit, date) => {
    return new Date(date) >= limit;
  };
  const isBefore = (limit, date) => {
    return new Date(date) <= limit;
  };

  const noData = <h5 className="m-3">No data</h5>;
  const listUsers = users
    .filter(user => (usersEmail ? user.email.toLowerCase().includes(usersEmail) : true))
    .filter(user => (usersFirstName ? user.name.toLowerCase().includes(usersFirstName) : true))
    .filter(user => (usersLastName ? user.lastname.toLowerCase().includes(usersLastName) : true))
    .filter(user => (dateAfter ? isAfter(dateAfter, user.expirationDate) : true))
    .filter(user => (dateBefore ? isBefore(dateBefore, user.expirationDate) : true))
    .filter(user => (mollieIds ? (user?.mollieId ? user.mollieId.includes(mollieIds) : false) : true));

  return (
    <div>
      {open ? (
          <CancelSubscriptionAdmin
            open={open}
            handleClose={handleClosePopupCancelSubscription}
            handleOpenSnackbar={handleOpenSnackbar}
            setMessageSnackBar={setMessageSnackBar}
            setAlertVariant={setAlertVariant}
            mollieId={mollieId}

       
          />
        ) : null}
      <div className="header">
        <ToolbarUsers
          title={I18n.t("users.users", l)}
          findEmail={email => setUsersEmail(email.toLowerCase())}
          findFirstName={name => setUsersFirstName(name.toLowerCase())}
          findLastName={name => setUsersLastName(name.toLowerCase())}
          findAfterExpirationDate={date => setDateAfter(date ? new Date(date) : undefined)}
          findBeforeExpirationDate={date => setDateBefore(date ? new Date(date) : undefined)}
          findMollieId={mollie => setMollieIds(mollie)}
        />
      </div>
      <SimpleSnackbar open={openSnackbar} handleClose={handleCloseSnackbar} message={messageSnackBar} variant={alertVariant} />
      <div className="mt-3">
        {listUsers.length}/{users.length}
        <BootstrapTable
          responsive
          keyField="_id"
          striped
          hover
          bordered
          className="table-primary"
          data={listUsers}
          columns={columns}
          cellEdit={cellEditFactory({
            mode: "click",
            blurToSave: true,
            afterSaveCell: (oldValue, newValue, row, column) => {
              updateUser(row);
            },
          })}
          noDataIndication={noData}
          insertRow
          //caption='Hello this is table, hi.'
          pagination={paginationFactory()}
        />
     
      </div>
      <SimpleSnackbar open={snack.open} handleClose={handleClose} message={snack.message} variant={snack.variant} />
      
    </div>
  );
}
