import { getLanguage } from "../../helpers/auth";
import I18n from "../../Config/I18n";
import { GiInfo } from "react-icons/gi";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
const l = getLanguage();

export default function HelpTooltip({ brol, placement = "top", style = {} }) {
  return (
    <OverlayTrigger placement={placement} overlay={<Tooltip>{I18n.t(brol, { locale: l })}</Tooltip>}>
      <GiInfo style={style} className="w-4 h-4 shrink-0" />
    </OverlayTrigger>
  );
}
