import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CancelSubscription from "./CancelSubscription";
import ChangePaymentCard from "./ChangePaymentCard";
import SimpleSnackbar from "../SimpleSnackbar";
import { POST, GET } from "../../helpers/requests";
import I18n from "../../Config/I18n";
import { Param } from "../../locales/Param";
import { getLanguage } from "../../helpers/auth";
import imgProduct from "../../Layouts/favicon.ico";
import { Badge, Button, Card, Col, Image, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import PaymentAPI from "../../backend/payment";

const l = getLanguage();
function Subscription({ className, ...rest }) {
  const [price, setPrice] = useState({});
  const [subscription, setSubscription] = useState({});
  const [open, setOpen ] = useState(false);
  const [openChangePaymentCard, setOpenChangePaymentCard ] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState("");
  const [messageSnackBar, setMessageSnackBar] = useState("");
  const [alertVariant, setAlertVariant] = useState("dark");
  const [noUpgrade, setNoUpgrade] = useState(false);

  const history = useHistory();

  useEffect(() => {
    loadData();
    checkNoUpgrade();
    // eslint-disable-next-line
  }, []);

  const loadData = async () => {
    await Promise.all([loadPrice(), hasASubscription()]);
  };

  const loadPrice = async () => {
    const price = await POST("/payment/price");
    if (price.error) {
      return console.error(price.error.message);
    }

    setPrice(price.data);
  };

  const checkNoUpgrade = async () => {
    console.log('1');
    const noup = await PaymentAPI.hasNoUpgrade();
    console.log('noup', noup);
    if (noup.data.noupgrade === true) {
      console.log("sono qui");
      setNoUpgrade(true);
    }
  };

  const hasASubscription = async () => {
    const hasSubscription = await GET("/payment/subscription");

    if (hasSubscription.error) {
      return console.error(hasSubscription.error.message);
    }
    setSubscription(hasSubscription.data.subscription);
  };

  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };

  // close snackbar
  const handleCloseSnackbar = (event, reason) => {
    if (reason !== "clickaway") return setOpenSnackbar(false);
  };

  const handleClickOpenPopupCancelSubscription = () => {
    setOpen(true);
  };

  const handleClosePopupCancelSubscription = () => {
    setOpen(false);
  };


  const handleClickOpenPopupChangePaymentCard = () => {
    setOpenChangePaymentCard(true);
  };

  const handleClosePopupChangePaymentCard = () => {
    setOpenChangePaymentCard(false);
  };

  

  return (
    <>
      <Card {...rest}>
        {open ? (
          <CancelSubscription
            open={open}
            handleClose={handleClosePopupCancelSubscription}
            handleOpenSnackbar={handleOpenSnackbar}
            setMessageSnackBar={setMessageSnackBar}
            setAlertVariant={setAlertVariant}
          />
        ) : null}
        {openChangePaymentCard ? (
          <ChangePaymentCard
            open={openChangePaymentCard}
            handleClose={handleClosePopupChangePaymentCard}
            handleOpenSnackbar={handleOpenSnackbar}
            setMessageSnackBar={setMessageSnackBar}
            setAlertVariant={setAlertVariant}
          />
        ) : null}
        <Card.Header>
          <h3 className="my-3 text-3xl font-semibold">{I18n.t("account.manageSubscription", { locale: l })}</h3>
        </Card.Header>
        <div className="px-4 py-4 space-y-3 divide-y">
          <div className="flex items-center justify-between">
            <div>
              {price.value ? (
                <h3>
                  €{price.value + price.VAT.value - price.coupon.value}
                  <small>
                    {I18n.t("account." + price.frequency, { locale: l })}
                    {price.VAT.value > 0 ? " " + I18n.t("account.tvac", { locale: l }) : null}
                  </small>
                </h3>
              ) : (
                <h4>
                  €0
                  <small>{I18n.t("account.byMonth", { locale: l })}</small>
                </h4>
              )}
            </div>
            <div className="flex justify-end text-right">
              <Badge pill variant="primary" className="flex items-center px-3 py-2 w-max gap-x-2">
                <Image alt="Product" src={imgProduct} />
                {"  " + I18n.t("account.standard", { locale: l })}
              </Badge>
            </div>
          </div>
        </div>
        <Card.Footer>
          <small className="text-muted">
            {I18n.t("account.question", { locale: l })} {Param.email}
          </small>
        </Card.Footer>
        <SimpleSnackbar open={openSnackbar} handleClose={handleCloseSnackbar} message={messageSnackBar} variant={alertVariant} />
      </Card>
      <div className="mt-3 text-right">
        {subscription && !noUpgrade ? (
          <Button size="sm" variant="outline-info" onClick={handleClickOpenPopupChangePaymentCard}>
            {I18n.t("account.changePaymentCard", { locale: l })}
          </Button>
        ) : (
<div></div>
        )}
      </div>
      <div className="mt-3 text-right">
        {subscription ? (
          <Button size="sm" variant="outline-danger" onClick={handleClickOpenPopupCancelSubscription}>
            {I18n.t("account.cancelSubscription", { locale: l })}
          </Button>
        ) : (
            !noUpgrade && (
          <Button
            variant="success"
            onClick={() => {
              history.push("/payment");
              window.location.reload();
            }}
          >
            {I18n.t("account.subscribeMe", { locale: l })}
          </Button>
            )
        )}
      </div>
    </>
  );
}

Subscription.propTypes = {
  className: PropTypes.string,
};

export default Subscription;
