/* eslint-disable react/no-array-index-key */
import { FC, useEffect, useState } from "react";
import moment from "moment";
import { Document, Page, View, Text, Image, StyleSheet, Font } from "@react-pdf/renderer";
import { Style } from "@react-pdf/types";
import I18n from "../../../Config/I18n";
import { getStatusById, getNameCategoryById } from "../../../utils/index";
import { getPersonnalIngredient } from "../../../utils/ingredientValidation";
import { getContentIds, getFitForIds, getIngredients, getIngredientsByTemperature } from "../../../utils/recipes";
import CarrotRed from "../../../assets/img/carrot_red.png";
import CarrotGreen from "../../../assets/img/carrot_green.png";
import FlaxRed from "../../../assets/img/flax_red.png";
import FlaxGreen from "../../../assets/img/flax_green.png";
import Cookie from "../../../assets/img/cookie.png";
import MilkCarton from "../../../assets/img/milk-carton.png";
import Peanut from "../../../assets/img/peanut.png";
import logoGanache from "../../../assets/img/logo3.jpg";
import GanacheFormat from "src/utils/ganacheFormat";
import {
  getEmptyContribution,
  getGroupTemperatureFromRecipe,
  getTotalContributions,
  getTotalWeigthAfterCooking,
} from "../../../utils/Recipe";
import { Recipe } from "src/Type/recipe2";
import { getLanguage } from "src/helpers/auth";
import { ContributionSimple } from "src/Type/contribution2";

const l = getLanguage();

const htmlToText = require("html-to-text");

// For the russian Alphabet
Font.register({
  family: "Roboto",
  src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf",
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    padding: 24,
    height: "100vh",
    fontFamily: "Roboto", // Setting the page body to Roboto font (cyrillic) -> https://github.com/diegomura/react-pdf/issues/719
  },
  h1: {
    fontSize: 24,
    fontWeight: 500,
  },
  h5: {
    fontSize: 12,
    fontWeight: 500,
  },
  h6: {
    fontSize: 10,
    fontWeight: 500,
  },
  body1: {
    fontSize: 9,
    lineHeight: 1.5,
  },
  body2: {
    fontSize: 8,
    lineHeight: 1.5,
  },
  mb1: {
    marginBottom: 0,
  },
  header: {
    flexDirection: "row-reverse",
    justifyContent: "space-between",
  },
  headerTitle: {
    borderBottomStyle: "solid",
    borderBottomWidth: "5",
    borderBottom: "1px",
    borderBottomColor: "black",
    width: "100%",
    color: "#E67E22",
  },
  footer: {
    flexDirection: "row-reverse",
    position: "absolute",
    bottom: "5px",
    borderTopStyle: "solid",
    borderTopWidth: "1",
    borderTop: "1px",
    borderTopColor: "black",
    width: "100%",
  },
  brand: {
    // maxHeight: 124,
    // maxWidth: 124,
    // marginVertical: 15,
    // marginHorizontal: 100,

    padding: 10,
  },
  image: {
    marginBottom: 10,
    //objectFit: 'cover',
    height: "80px",
    width: "60px",
    objectFit: "scale-down",
  },
  company: {
    marginTop: 32,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  references: {
    marginTop: 32,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  billing: {
    marginTop: 32,
  },
  items: {
    marginTop: 0,
  },
  notes: {
    marginTop: 0,
  },
  tables: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

const reducer = (accumulator, value) => accumulator + value;

/**
 *
 * @param {{
 * recipes: [import('../../../Type/recipe').Recipe],
 * checked: Object<string,Boolean>,
 * contents: [import('../../../Type/recipeContent').RecipeContent]
 * fitfor: [import('../../../Type/recipeFitfor').RecipeFitFor]
 * status: [import('../../../Type/recipeStatus').RecipeStatus]
 * categories: [import('../../../Type/recipeCategorie').RecipeCategories]
 * fileName: String
 * }} param0
 */
export default function RecipePDF({ recipes, checked, contents, fitfor, status, categories, fileName, combinedPrint, userLogo }) {
  /**
   * @type {[Object.<number,import("../../../Type/recipe").GroupTemperatureData>[], Function]}
   */
  const [cooking, setCooking] = useState({});
  /**
   * @type {[number[], Function]}
   */

  useEffect(() => {
    computeEvaporation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipes]);
  const reducer = (accumulator, value) => accumulator + value;

  const computeEvaporation = async () => {
    const cooks = [];
    const totalWeights = [];
    for (const index in recipes) {
      const cooked = await getGroupTemperatureFromRecipe(recipes[index]);
      const totalWeigthRecipe = getTotalWeigthAfterCooking(cooked);
      cooks.push(cooked);
      totalWeights.push(totalWeigthRecipe.toFixed(1));
    }
    setCooking(cooks);
  };

  const Pic = ({ checked }) => {
    if (!checked.picture) return null;
    return <Image src={userLogo} style={styles.image} />;
  };

  const Header = ({ checked, fileName }) => {
    return (
      <View style={styles.header}>
        <View>
          <Pic checked={checked} />
        </View>
        <View>
          <Text style={[styles.h5, styles.headerTitle]}>{fileName}</Text>
        </View>
      </View>
    );
  };

  var numRecipe = 1;
  const recipesArranged = splitRecipes(recipes, combinedPrint);

  if (combinedPrint) {
    return (
      <Document>
        {recipesArranged.map((recipeArr, index) => {
          if (recipeArr.length === 1) {
            const recipe = recipes[recipeArr[0]];
            const ingredients = getIngredients(recipe);
            const contentIds = getContentIds(ingredients);
            const arrayContentInRecipe = getArrayContents(contents, contentIds);
            const fitforIds = getFitForIds(ingredients);
            const arrayFitforInRecipe = getArrayFitFor(fitfor, fitforIds);
            const statut = getStatusById(status, recipe.statusId);
            const category = getNameCategoryById(categories, recipe.categoryId);
            const weight = getTotalWeigthAfterCooking(cooking[recipeArr[0]]);
            const priceTotal = recipe.ingredients.map(ing => (ing.ingredient.note.foodCost * ing.quantity) / 1000).reduce(reducer, 0); //getTotalContributionRecipe(recipe).foodCost
            const price = priceTotal * (1000 / weight);
            const ret = (
              <Page size="A4" style={styles.page} key={`page-one-${index}`}>
                <Header checked={checked} fileName={fileName} />
                <View style={styles.notes}>
                  <Text style={[styles.h5, styles.mb1]}>
                    R.{numRecipe}. | {recipe.name}
                  </Text>
                </View>
                <View style={styles.tables}>
                  <View style={styles.items}>
                    <TableIngredients recipe={recipe} showCommercialName={checked.commercialName} cooked={cooking[recipeArr]} />
                  </View>

                  <View style={styles.items}>
                    <TableInfoRecipe
                      recipe={recipe}
                      statut={statut}
                      category={category}
                      checked={checked}
                      arrayContentInRecipe={arrayContentInRecipe}
                      arrayFitforInRecipe={arrayFitforInRecipe}
                      price={price}
                      source={recipe.source}
                    />
                  </View>
                </View>

                {checked.totals ? <TableComposition recipe={recipe} cooked={cooking[recipeArr]} /> : null}
                <Footer />
              </Page>
            );
            numRecipe += 1;
            return ret;
          } else if (recipeArr.length === 2) {
            const recipe = recipes[recipeArr[0]];
            const recipe1 = recipes[recipeArr[1]];
            const cooked = cooking[recipeArr[0]];
            const cooked1 = cooking[recipeArr[1]];
            const ingredients = getIngredients(recipe);
            const ingredients1 = getIngredients(recipe1);
            const contentIds = getContentIds(ingredients);
            const contentIds1 = getContentIds(ingredients1);
            const arrayContentInRecipe = getArrayContents(contents, contentIds);
            const arrayContentInRecipe1 = getArrayContents(contents, contentIds1);
            const fitforIds = getFitForIds(ingredients);
            const fitforIds1 = getFitForIds(ingredients1);
            const arrayFitforInRecipe = getArrayFitFor(fitfor, fitforIds);
            const arrayFitforInRecipe1 = getArrayFitFor(fitfor, fitforIds1);
            const statut = getStatusById(status, recipe.statusId);
            const statut1 = getStatusById(status, recipe1.statusId);
            const category = getNameCategoryById(categories, recipe.categoryId);
            const category1 = getNameCategoryById(categories, recipe1.categoryId);

            const weight = getTotalWeigthAfterCooking(cooking[recipeArr[0]]);
            const priceTotal = recipe.ingredients.map(ing => (ing.ingredient.note.foodCost * ing.quantity) / 1000).reduce(reducer, 0); //getTotalContributionRecipe(recipe).foodCost
            const price = priceTotal * (1000 / weight);

            const weight1 = getTotalWeigthAfterCooking(cooking[recipeArr[0]]);
            const priceTotal1 = recipe.ingredients.map(ing => (ing.ingredient.note.foodCost * ing.quantity) / 1000).reduce(reducer, 0); //getTotalContributionRecipe(recipe).foodCost
            const price1 = priceTotal1 * (1000 / weight1);
            const ret = (
              <>
                <Page size="A4" style={styles.page} wrap={false} key={`page-two-${index}`}>
                  <Header checked={checked} fileName={fileName} />

                  <View style={styles.notes}>
                    <Text style={[styles.h5, styles.mb1]}>
                      R.{numRecipe}. | {recipe.name}
                    </Text>
                  </View>
                  <View style={styles.tables}>
                    <View style={styles.items}>
                      <TableIngredients recipe={recipe} showCommercialName={checked.commercialName} cooked={cooked} />
                    </View>

                    <View style={styles.items}>
                      <TableInfoRecipe
                        recipe={recipe}
                        statut={statut}
                        category={category}
                        checked={checked}
                        arrayContentInRecipe={arrayContentInRecipe}
                        arrayFitforInRecipe={arrayFitforInRecipe}
                        price={price}
                        source={recipe.source}
                      />
                    </View>
                  </View>
                  <View style={styles.notes}>
                    <Text style={[styles.h5, styles.mb1]}>
                      R.{numRecipe + 1}. | {recipe1.name}
                    </Text>
                  </View>
                  <View style={styles.tables}>
                    <View style={styles.items}>
                      <TableIngredients recipe={recipe1} showCommercialName={checked.commercialName} cooked={cooked1} />
                    </View>

                    <View style={styles.items}>
                      <TableInfoRecipe
                        recipe={recipe1}
                        statut={statut1}
                        category={category1}
                        checked={checked}
                        arrayContentInRecipe={arrayContentInRecipe1}
                        arrayFitforInRecipe={arrayFitforInRecipe1}
                        price={price1}
                        source={recipe.source}
                      />
                    </View>
                  </View>

                  {checked.totals ? (
                    <TableComposition2 numRecipe={numRecipe} recipe={recipe} recipe1={recipe1} cooked={cooked} cooked1={cooked1} />
                  ) : null}
                  <Footer />
                </Page>
              </>
            );
            numRecipe += 2;
            return ret;
          }
          return <></>;
        })}

        <Page size="A4" style={styles.page}>
          <Header checked={checked} fileName={fileName} />

          {recipes.map((recipe, index) => {
            const methodeAndNote = [];
            const procedureRecipe = htmlToText.fromString(recipe.procedure, null) || "";
            if (checked.procedure)
              methodeAndNote.push(
                <View style={styles.notes} key={index}>
                  <Text style={[styles.h5, styles.mb1]}>
                    R {index + 1} | {I18n.t("recipeObject.method", { locale: l })}
                  </Text>
                  <Text style={styles.body1}>{procedureRecipe}</Text>
                </View>
              );
            const noteRecipe = htmlToText.fromString(recipe.note, null) || "";
            if (checked.note)
              methodeAndNote.push(
                <View style={styles.notes} key={index}>
                  <Text style={[styles.h5, styles.mb1]}>
                    R {index + 1} | {I18n.t("recipeObject.note", { locale: l })}
                  </Text>
                  <Text style={styles.body1}>{noteRecipe}</Text>
                </View>
              );
            return methodeAndNote;
          })}
          <Footer />
        </Page>
      </Document>
    );
  } else {
    return (
      <Document>
        {recipesArranged.map(recipeArr => {
          const recipe = recipes[recipeArr[0]];
          const ingredients = getIngredients(recipe);
          const contentIds = getContentIds(ingredients);
          const arrayContentInRecipe = getArrayContents(contents, contentIds);
          const fitforIds = getFitForIds(ingredients);
          const arrayFitforInRecipe = getArrayFitFor(fitfor, fitforIds);
          const statut = getStatusById(status, recipe.statusId);
          const category = getNameCategoryById(categories, recipe.categoryId);
          const procedureRecipe = htmlToText.fromString(recipe.procedure, null) || "";
          const noteRecipe = htmlToText.fromString(recipe.note, null) || "";
          const weight = getTotalWeigthAfterCooking(cooking[recipeArr[0]]);
          const priceTotal = recipe.ingredients.map(ing => (ing.ingredient.note.foodCost * ing.quantity) / 1000).reduce(reducer, 0); //getTotalContributionRecipe(recipe).foodCost
          const price = priceTotal * (1000 / weight);
          //const price = getTotalContributionRecipe(recipe).foodCost
          const source = recipe.source;
          const ret = (
            <Page size="A4" style={styles.page} key={recipe._id}>
              <Header checked={checked} fileName={fileName} />

              <View style={styles.notes}>
                <Text style={[styles.h5, styles.mb1]}>
                  R.{numRecipe}. | {recipe.name}
                </Text>
              </View>
              <View style={styles.tables}>
                <View style={styles.items}>
                  <TableIngredients recipe={recipe} showCommercialName={checked.commercialName} cooked={cooking[recipeArr]} />
                </View>

                <View style={styles.items}>
                  <TableInfoRecipe
                    recipe={recipe}
                    statut={statut}
                    category={category}
                    checked={checked}
                    arrayContentInRecipe={arrayContentInRecipe}
                    arrayFitforInRecipe={arrayFitforInRecipe}
                    price={price}
                    source={source}
                  />
                </View>
              </View>
              {checked.procedure ? (
                <View style={styles.notes}>
                  <Text style={[styles.h5, styles.mb1]}>{I18n.t("recipeObject.method", { locale: l })}</Text>
                  <Text style={styles.body1}>{procedureRecipe}</Text>
                </View>
              ) : (
                <></>
              )}
              {checked.note && (
                <View style={styles.notes}>
                  <Text style={[styles.h5, styles.mb1]}>{I18n.t("recipeObject.note", { locale: l })}</Text>
                  <Text style={styles.body1}>{noteRecipe}</Text>
                </View>
              )}
              {checked.totals && <TableComposition recipe={recipe} cooked={cooking[recipeArr]} />}
              <Footer />
            </Page>
          );
          numRecipe += 1;
          return ret;
        })}
      </Document>
    );
  }
}

function splitRecipes(recipes, combinedPrint) {
  const ret = [];
  for (var i = 0; i < recipes.length; i++) {
    if (combinedPrint && i + 1 < recipes.length) {
      ret.push([i, i + 1]);
      i++;
    } else {
      ret.push([i]);
    }
  }
  return ret;
}
const fitforicons = [
  { green: FlaxGreen, red: FlaxRed },
  { green: CarrotGreen, red: CarrotRed },
];
const getArrayFitFor = (fitfor, fitforids) => {
  var ret = [];
  fitfor.forEach((ff, index) => {
    if (fitforids.includes(ff._id)) {
      ret.push(fitforicons[index].green);
    } else {
      ret.push(fitforicons[index].red);
    }
  });
  return ret;
};

const contenticons = [Cookie, MilkCarton, Peanut];
const getArrayContents = (contents, contentids) => {
  var ret = [];
  contents.forEach((c, index) => {
    if (contentids.includes(c._id)) {
      ret.push(contenticons[index]);
    }
  });
  return ret;
};

const Footer = () => {
  return (
    <View style={styles.footer} fixed={true}>
      <Image src={logoGanache} style={{ height: "30px" }} />
    </View>
  );
};

function Info({ odd, name, data }) {
  const COL1_WIDTH = "40%";
  const COLN_WIDTH = "60%";

  return (
    <TableRow odd={odd}>
      <TableCell width={COL1_WIDTH}>{name}</TableCell>

      <TableCell width={COLN_WIDTH}>{data}</TableCell>
    </TableRow>
  );
}

function InfoArray({ odd, name, data }) {
  const COL1_WIDTH = "40%";
  const COLN_WIDTH = "60%";
  return (
    <TableRow odd={odd}>
      <TableCell width={COL1_WIDTH}>{name}</TableCell>

      <TableCell width={COLN_WIDTH}>
        {data.map((src, index) => {
          return (
            <>
              <Image key={index} src={src} />
              {"  "}
            </>
          );
        })}
      </TableCell>
    </TableRow>
  );
}

/**
 * Table with the list of ingredients
 *
 * @param {Object<number,import("../../../Type/recipe").GroupTemperatureData>} cooked
 */
function TableIngredients({ recipe, showCommercialName, cooked }) {
  const COL1_WIDTH = 180;
  const COLN_WIDTH = (330 - COL1_WIDTH) / 2;
  if (!cooked)
    return (
      <Table debug maxWidth={330}>
        <TableHeader>
          <TableRow>
            <TableCell width={COL1_WIDTH}>{I18n.t("recipeObject.ingredientName", { locale: l })}</TableCell>
            <TableCell width={COLN_WIDTH} alignRight>
              {I18n.t("listIngredients.qty", { locale: l })}&nbsp;(g)
            </TableCell>
            <TableCell width={COLN_WIDTH} alignRight>
              {I18n.t("listIngredients.qty", { locale: l })}&nbsp;(%)
            </TableCell>
          </TableRow>
        </TableHeader>

        <View>
          <TableRow total>
            <TableCell width={COL1_WIDTH}>{I18n.t("listIngredients.totals", { locale: l })}</TableCell>
            <TableCell width={COLN_WIDTH} alignRight>
              0 g
            </TableCell>
            <TableCell width={COLN_WIDTH} alignRight>
              100 %
            </TableCell>
          </TableRow>
        </View>
      </Table>
    );
  const totalWeight =
    Object.keys(cooked)
      .map(temp => cooked[parseInt(temp)].total.quantity)
      .reduce(reducer, 0) +
    recipe.ingredients
      .filter(ing => ing.hide)
      .map(ing => ing.quantity)
      .reduce(reducer, 0);
  return (
    <Table debug maxWidth={330}>
      <TableHeader>
        <TableRow>
          <TableCell width={COL1_WIDTH}>{I18n.t("recipeObject.ingredientName", { locale: l })}</TableCell>
          <TableCell width={COLN_WIDTH} alignRight>
            {I18n.t("listIngredients.qty", { locale: l })}&nbsp;(g)
          </TableCell>
          <TableCell width={COLN_WIDTH} alignRight>
            {I18n.t("listIngredients.qty", { locale: l })}&nbsp;(%)
          </TableCell>
        </TableRow>
      </TableHeader>

      <View>
        {getIngredientRowsByTemp(recipe, showCommercialName, cooked)}
        <TableRow total>
          <TableCell width={COL1_WIDTH}>{I18n.t("listIngredients.totals", { locale: l })}</TableCell>
          <TableCell width={COLN_WIDTH} alignRight>
            {GanacheFormat.round(totalWeight)} g
          </TableCell>
          <TableCell width={COLN_WIDTH} alignRight>
            100 %
          </TableCell>
        </TableRow>
      </View>
    </Table>
  );
}

/**
 *
 * @param {*} recipe
 * @param {*} showCommercialName
 * @param {import("../../../Type/recipe").GroupTemperatureData} cooked
 * @returns
 */
const getIngredientRowsByTemp = (recipe, showCommercialName, cooked) => {
  const ingredientsByTemperature = getIngredientsByTemperature(recipe.ingredients);
  const ret = [];
  const temps = Object.keys(ingredientsByTemperature);
  for (var tmp of temps) {
    let mass = 0;
    for (var index in ingredientsByTemperature[tmp]) {
      const ing = ingredientsByTemperature[tmp][index];
      ret.push(getIngRow(ing, index, recipe, showCommercialName, cooked));
      mass += ing.coefficient * ing.quantity;
    }
    if (tmp !== "ambiant") {
      ret.push(makeTemperatureLine(tmp, mass, recipe, cooked));
    }
  }
  return ret;
};

/**
 * @param {import("../../../Type/recipeIngredient").RecipeIngredient} ing
 * @param {import("../../../Type/recipe").Recipe} recipe
 * @param {boolean} showCommercialName
 * @param {import("../../../Type/recipe").GroupTemperatureData[]} cooked
 */
const getIngRow = (ing, index, recipe, showCommercialName, cooked) => {
  const COL1_WIDTH = 180;
  const COLN_WIDTH = (330 - COL1_WIDTH) / 2;

  const commercialName = showCommercialName && ing.ingredient.commercialName ? `(${ing.ingredient.commercialName})` : "";
  if (!cooked)
    return (
      <TableRow odd={index % 2 !== 0} key={index}>
        <TableCell width={COL1_WIDTH}>{getPersonnalIngredient(ing.ingredient) + commercialName}</TableCell>
        <TableCell width={COLN_WIDTH} alignRight>
          0 g
        </TableCell>
        <TableCell width={COLN_WIDTH} alignRight>
          0 %
        </TableCell>
      </TableRow>
    );

  const temp = parseInt(ing.temperature) || 0;
  const totalweightGroup = temp
    ? cooked[temp].ingredients.map(ing => ing.quantity).reduce(reducer, 0)
    : Object.keys(cooked)
        .map(tmp => cooked[parseInt(tmp)].total.quantity)
        .reduce(reducer, 0) +
      recipe.ingredients
        .filter(ing2 => ing2.hide)
        .map(ing2 => ing2.quantity)
        .reduce(reducer, 0);
  const propotion = (ing.quantity * 100) / totalweightGroup;
  return (
    <TableRow odd={index % 2 !== 0} key={index}>
      <TableCell width={COL1_WIDTH}>{getPersonnalIngredient(ing.ingredient) + commercialName}</TableCell>
      <TableCell width={COLN_WIDTH} alignRight>
        {GanacheFormat.round(ing.quantity)} g
      </TableCell>
      <TableCell width={COLN_WIDTH} alignRight>
        {GanacheFormat.percentage(propotion)} %
      </TableCell>
    </TableRow>
  );
};

/**
 * @param {import("../../../Type/recipe").GroupTemperatureData[]} cooked
 */
const makeTemperatureLine = (tmp, mass, recipe, cooked) => {
  const COL1_WIDTH = 180;
  const COLN_WIDTH = (330 - COL1_WIDTH) / 2;
  if (!cooked)
    return (
      <TableRow temp>
        <TableCell width={COL1_WIDTH}>
          {I18n.t("listIngredients.totalIngredientsTemp", { locale: l })} {tmp}°
        </TableCell>
        <TableCell width={COLN_WIDTH} alignRight>
          0 g
        </TableCell>
        <TableCell width={COLN_WIDTH} alignRight>
          0 %
        </TableCell>
      </TableRow>
    );
  const cooking = cooked[tmp];
  const weightAfterCooking = cooking.total.quantity;
  const totalWeight = Object.keys(cooked)
    .map(key => cooked[key].total.quantity)
    .reduce(reducer, 0);
  const proportion = (weightAfterCooking * 100) / totalWeight;
  return (
    <TableRow temp>
      <TableCell width={COL1_WIDTH}>
        {I18n.t("listIngredients.totalIngredientsTemp", { locale: l })} {tmp}°
      </TableCell>
      <TableCell width={COLN_WIDTH} alignRight>
        {GanacheFormat.round(weightAfterCooking) + " g"}
      </TableCell>
      <TableCell width={COLN_WIDTH} alignRight>
        {GanacheFormat.percentage(proportion)} %
      </TableCell>
    </TableRow>
  );
};

function TableInfoRecipe({ recipe, category, statut, checked, arrayContentInRecipe, arrayFitforInRecipe, price, source }) {
  var odd = true;
  const getOdd = () => {
    odd = !odd;
    return odd;
  };

  const rows = [];
  if (checked.name)
    rows.push(<Info key="infoName" odd={getOdd()} name={I18n.t("print.category.nameRecipe", { locale: l })} data={recipe.name} />);
  if (checked.owner)
    rows.push(<Info key="infoOwner" odd={getOdd()} name={I18n.t("print.category.owner", { locale: l })} data={recipe.owner} />);
  if (checked.lastUpdateOn)
    rows.push(
      <Info
        key="infoLastUpdate"
        odd={getOdd()}
        name={I18n.t("print.category.lastUpdate", { locale: l })}
        data={moment(recipe.lastUpdateOn).format("DD MMM YYYY")}
      />
    );
  if (checked.contents)
    rows.push(
      <InfoArray key="infoContent" odd={getOdd()} name={I18n.t("print.category.content", { locale: l })} data={arrayContentInRecipe} />
    );
  if (checked.fitfor)
    rows.push(
      <InfoArray key="infoFitFor" odd={getOdd()} name={I18n.t("print.category.fitfor", { locale: l })} data={arrayFitforInRecipe} />
    );
  if (checked.categories && category)
    rows.push(<Info key="infoCategory" odd={getOdd()} name={I18n.t("print.category.category", { locale: l })} data={category} />);
  if (checked.status && statut)
    rows.push(<Info key="infoStatus" odd={getOdd()} name={I18n.t("print.category.status", { locale: l })} data={statut.name} />);
  if (checked.price)
    rows.push(
      <Info key="infoPrice" odd={getOdd()} name={I18n.t("print.category.price", { locale: l })} data={GanacheFormat.price(price) + "/kg"} />
    );
  if (checked.source)
    rows.push(<Info key="infoSource" odd={getOdd()} name={I18n.t("print.category.source", { locale: l })} data={source} />);

  return (
    <Table debug maxWidth={190}>
      <TableHeader>
        <TableRow>
          <TableCell>{I18n.t("recipeObject.recipeInfo", { locale: l })}</TableCell>
        </TableRow>
      </TableHeader>

      <TableBody>{rows.map(row => row)}</TableBody>
    </Table>
  );
}

const getFinalContribution: (recipe: Recipe, cooking) => ContributionSimple = (recipe, cooking) => {
  if (!cooking) return getEmptyContribution();

  const totalContributions = getTotalContributions(cooking);

  const reducerContribution = (accumulator, currentValue) => {
    return {
      sugar: accumulator.sugar + currentValue.sugar,
      butter: accumulator.butter + currentValue.butter,
      cocoa_butter: accumulator.cocoa_butter + currentValue.cocoa_butter,
      oil: accumulator.oil + currentValue.oil,
      amp: accumulator.amp + currentValue.amp,
      lactose: accumulator.lactose + currentValue.lactose,
      cocoa_solid: accumulator.cocoa_solid + currentValue.cocoa_solid,
      other: accumulator.other + currentValue.other,
      water: accumulator.water + currentValue.water,
      alcohol: accumulator.alcohol + currentValue.alcohol,
      pod: accumulator.pod + currentValue.pod,
      pac: accumulator.pac + currentValue.pac,
      kcal: accumulator.kcal + currentValue.kcal,
      kj: accumulator.kj + currentValue.kj,
    };
  };
  const contribution = totalContributions.reduce(reducerContribution, getEmptyContribution());

  const price = recipe.ingredients.map(ing => (ing.ingredient.note.foodCost / 1000) * ing.quantity).reduce(reducer, 0);
  contribution.foodCost = price;
  return contribution;
};

const TableComposition: FC<{ recipe: Recipe; cooked }> = ({ recipe, cooked }) => {
  const composition = getFinalContribution(recipe, cooked); //getTotalContributionRecipe(recipe)
  const headWidth = 120;
  const contentWidth = 60;

  const sugarComp = composition.sugar;
  const drySubComp = composition.cocoa_solid + composition.amp + composition.other;
  const liquidsComp = composition.alcohol + composition.water;
  const fatsComp = composition.oil + composition.butter + composition.cocoa_butter;
  const cocoaComp = composition.cocoa_solid;
  const waterComp = composition.water;
  const oilComp = composition.oil;
  const ampComp = composition.amp;
  const lactoseComp = composition.lactose;
  const alcoholComp = composition.alcohol;
  const butterComp = composition.butter;
  const otherComp = composition.other;
  const podComp = composition.pod;
  const cocoaButterComp = composition.cocoa_butter;

  const alLiquids = (composition.alcohol / (composition.alcohol + composition.water)) * 100;

  const solid = sugarComp + drySubComp + fatsComp;

  const solidComp = solid;
  const pacComp = GanacheFormat.round(isNaN(composition.pac) ? 0 : composition.pac);

  return (
    <Table totals>
      <TableHeader>
        <TableRow>
          <TableCell width={headWidth}>{I18n.t("listIngredients.totals", { locale: l })}</TableCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        <TableRow>
          <TableCell width={headWidth} emph>
            {I18n.t("ingredient.sugars", { locale: l }).toUpperCase()}
          </TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.percentage(sugarComp)} %
          </TableCell>
          <TableCell width={headWidth} emph>
            {I18n.t("ingredient.drySubstances", { locale: l }).toUpperCase()}
          </TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.percentage(drySubComp)} %
          </TableCell>
          <TableCell width={headWidth} emph>
            {I18n.t("ingredient.liquids", { locale: l }).toUpperCase()}
          </TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.percentage(liquidsComp)} %
          </TableCell>
        </TableRow>
        <TableRow odd>
          <TableCell width={headWidth} emph>
            {I18n.t("ingredient.fats", { locale: l }).toUpperCase()}
          </TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.percentage(fatsComp)} %
          </TableCell>
          <TableCell width={headWidth}>{I18n.t("ingredient.cocoa", { locale: l })}</TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.percentage(cocoaComp)} %
          </TableCell>
          <TableCell width={headWidth}>{I18n.t("ingredient.water", { locale: l })}</TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.percentage(waterComp)} %
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell width={headWidth}>{I18n.t("ingredient.oil", { locale: l })}</TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.percentage(oilComp)} %
          </TableCell>
          <TableCell width={headWidth}>{I18n.t("ingredient.amp", { locale: l })}</TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.percentage(ampComp)} %
          </TableCell>
          <TableCell width={headWidth}>{I18n.t("ingredient.alcohol", { locale: l })}</TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.percentage(alcoholComp)} %
          </TableCell>
        </TableRow>
        <TableRow odd>
          <TableCell width={headWidth}>{I18n.t("ingredient.butter", { locale: l })}</TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.percentage(butterComp)} %
          </TableCell>
          <TableCell width={headWidth}>{I18n.t("ingredient.lactose", { locale: l })}</TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.percentage(lactoseComp)} %
          </TableCell>
          <TableCell width={headWidth}>{I18n.t("ingredient.alcoholLiquids", { locale: l })}</TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.percentage(alLiquids)} %
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell width={headWidth}>{I18n.t("ingredient.cocoaButter", { locale: l })}</TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.percentage(cocoaButterComp)} %
          </TableCell>
          <TableCell width={headWidth}>{I18n.t("ingredient.other", { locale: l })}</TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.percentage(otherComp)} %
          </TableCell>
          <TableCell width={headWidth} emph>
            {I18n.t("ingredient.pod", { locale: l })}
          </TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.round(podComp)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell width={headWidth}></TableCell>
          <TableCell width={contentWidth} alignRight></TableCell>
          <TableCell width={headWidth} emph>
            {I18n.t("ingredient.solids", { locale: l }).toUpperCase()}
          </TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.percentage(solidComp)} %
          </TableCell>
          <TableCell width={headWidth} emph>
            {I18n.t("ingredient.pac", { locale: l })}
          </TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.round(pacComp)}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

function TableComposition2({ numRecipe, recipe, recipe1, cooked, cooked1 }) {
  const composition = getFinalContribution(recipe, cooked); //getTotalContributionRecipe(recipe)
  const composition1 = getFinalContribution(recipe1, cooked1); //getTotalContributionRecipe(recipe1)
  const headWidth = 100;
  const contentWidth = 40;

  return (
    <Table table>
      <TableHeader>
        <TableRow>
          <TableCell width={headWidth}>{I18n.t("listIngredients.totals", { locale: l })}</TableCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        <TableRow>
          <TableCell width={headWidth} />
          <TableCell width={contentWidth} alignRight>
            R.{numRecipe}.
          </TableCell>
          <TableCell width={contentWidth} alignRight>
            R.{numRecipe + 1}.
          </TableCell>
          <TableCell width={headWidth} />
          <TableCell width={contentWidth} alignRight>
            R.{numRecipe}.
          </TableCell>
          <TableCell width={contentWidth} alignRight>
            R.{numRecipe + 1}.
          </TableCell>
          <TableCell width={headWidth} />
          <TableCell width={contentWidth} alignRight>
            R.{numRecipe}.
          </TableCell>
          <TableCell width={contentWidth} alignRight>
            R.{numRecipe + 1}.
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell width={headWidth} emph>
            {I18n.t("ingredient.sugars", { locale: l }).toUpperCase()}
          </TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.percentage(composition.sugar)} %
          </TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.percentage(composition1.sugar)} %
          </TableCell>
          <TableCell width={headWidth} emph>
            {I18n.t("ingredient.drySubstances", { locale: l }).toUpperCase()}
          </TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.percentage(composition.cocoa_solid + composition.lactose + composition.other)} %
          </TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.percentage(composition1.cocoa_solid + composition1.lactose + composition1.other)} %
          </TableCell>
          <TableCell width={headWidth} emph>
            {I18n.t("ingredient.liquids", { locale: l }).toUpperCase()}
          </TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.percentage(composition.alcohol + composition.water)} %
          </TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.percentage(composition1.alcohol + composition1.water)} %
          </TableCell>
        </TableRow>
        <TableRow odd>
          <TableCell width={headWidth} emph>
            {I18n.t("ingredient.fats", { locale: l }).toUpperCase()}
          </TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.percentage(composition.oil + composition.butter + composition.cocoa_butter)} %
          </TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.percentage(composition1.oil + composition1.butter + composition1.cocoa_butter)} %
          </TableCell>
          <TableCell width={headWidth}>{I18n.t("ingredient.cocoa", { locale: l })}</TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.percentage(composition.cocoa_solid)} %
          </TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.percentage(composition1.cocoa_solid)} %
          </TableCell>
          <TableCell width={headWidth}>{I18n.t("ingredient.water", { locale: l })}</TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.percentage(composition.water)} %
          </TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.percentage(composition1.water)} %
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell width={headWidth}>{I18n.t("ingredient.oil", { locale: l })}</TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.percentage(composition.oil)} %
          </TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.percentage(composition1.oil)} %
          </TableCell>
          <TableCell width={headWidth}>{I18n.t("ingredient.lactose", { locale: l })}</TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.percentage(composition.lactose)} %
          </TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.percentage(composition1.lactose)} %
          </TableCell>
          <TableCell width={headWidth}>{I18n.t("ingredient.alcohol", { locale: l })}</TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.percentage(composition.alcohol)} %
          </TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.percentage(composition1.alcohol)} %
          </TableCell>
        </TableRow>
        <TableRow odd>
          <TableCell width={headWidth}>{I18n.t("ingredient.butter", { locale: l })}</TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.percentage(composition.butter)} %
          </TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.percentage(composition1.butter)} %
          </TableCell>
          <TableCell width={headWidth}>{I18n.t("ingredient.other", { locale: l })}</TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.percentage(composition.other)} %
          </TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.percentage(composition1.other)} %
          </TableCell>
          <TableCell width={headWidth}>{I18n.t("ingredient.alcoholLiquids", { locale: l })}</TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.percentage((composition.alcohol / (composition.alcohol + composition.water)) * 100)} %
          </TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.percentage((composition1.alcohol / (composition1.alcohol + composition1.water)) * 100)} %
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell width={headWidth}>{I18n.t("ingredient.cocoaButter", { locale: l })}</TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.percentage(composition.cocoa_butter)} %
          </TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.percentage(composition1.cocoa_butter)} %
          </TableCell>
          <TableCell width={headWidth} emph>
            {I18n.t("ingredient.solids", { locale: l }).toUpperCase()}
          </TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.percentage(
              composition.oil +
                composition.butter +
                composition.cocoa_butter +
                composition.cocoa_solid +
                composition.lactose +
                composition.other
            )}{" "}
            %
          </TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.percentage(
              composition1.oil +
                composition1.butter +
                composition1.cocoa_butter +
                composition1.cocoa_solid +
                composition1.lactose +
                composition1.other
            )}{" "}
            %
          </TableCell>
          <TableCell width={headWidth} emph>
            {I18n.t("ingredient.pod", { locale: l })}
          </TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.round(composition.pod)}
          </TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.round(composition1.pod)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell width={headWidth} emph></TableCell>
          <TableCell width={contentWidth} alignRight></TableCell>
          <TableCell width={contentWidth} alignRight></TableCell>
          <TableCell width={headWidth} emph></TableCell>
          <TableCell width={contentWidth} alignRight></TableCell>
          <TableCell width={contentWidth} alignRight></TableCell>

          <TableCell width={headWidth} emph>
            {I18n.t("ingredient.pac", { locale: l })}
          </TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.round(composition.pac)}
          </TableCell>
          <TableCell width={contentWidth} alignRight>
            {GanacheFormat.round(composition1.pac)}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

function Table(props) {
  const table: Style = {
    display: "flex",
    width: "auto",
    minWidth: 100,
    borderWidth: 1,
    borderColor: "#eeeeee",
    maxWidth: props.maxWidth,
    marginTop: "5px",
  };
  const tableTot: Style = {
    position: "absolute",
    bottom: 40,
    left: 26,
    right: 26,
    display: "flex",
    width: "auto",
    fontSize: 12,
    borderWidth: 1,
    borderColor: "#eeeeee",
  };

  return <View style={props.totals ? tableTot : table}>{props.children}</View>;
}

function TableHeader(props) {
  const tableHeader = {
    borderWidth: 1,
    borderColor: "black",
  };

  return <View style={tableHeader}>{props.children}</View>;
}

function TableBody(props) {
  const tableBody = {
    fontSize: 12,
  };
  return <View style={tableBody}>{props.children}</View>;
}

function TableRow(props) {
  const tableRow: Style = {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: "#eeeeee",
    borderStyle: "solid",
    //height: "60%",
  };
  const tableRowOdd: Style = {
    backgroundColor: "#eeeeee",
  };
  const tableRowTemp: Style = {
    backgroundColor: "#ffddba",
  };
  const tableRowTot: Style = {
    backgroundColor: "aliceblue",
  };
  return (
    <View style={[tableRow, props.odd ? tableRowOdd : {}, props.temp ? tableRowTemp : {}, props.total ? tableRowTot : {}]}>
      {props.children}
    </View>
  );
}

function TableCell(props) {
  const tableCell: Style = {
    fontSize: 8,
    padding: 4,
    width: props.width ? props.width : "100%",
    textAlign: props.alignRight ? "right" : "left",
    //height: "0%",
  };
  const emph: Style = {
    textDecoration: "underline",
  };
  return (
    <View style={tableCell}>
      <Text style={props.emph ? emph : null}>{props.children}</Text>
    </View>
  );
}
