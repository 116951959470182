import React, { useState, memo, useEffect } from "react";
import { MdAdd, MdControlPointDuplicate } from "react-icons/md";
import { GiPencil, GiSaveArrow, GiTrashCan } from "react-icons/gi";
import NewCategory from "./NewCategory";
import Protected from "../../../routes/Protected";
import { canWrite, canRead, getRole, getLanguage } from "../../../helpers/auth";
import I18n from "../../../Config/I18n";
import { Button, Dropdown, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import {getRecipePrintUrl, Url} from "../../../Config/url";
import { useHistory } from "react-router-dom";
import {IoMdAdd} from "react-icons/io";
import {IconLinkOrange} from "../../links/iconLinkOrangeOutline";
import PopupDemo from "../../payment/PopupDemo";


const l = getLanguage();
const role = getRole();

function GenericMoreButton(props) {
  const [openPopupCategory, setOpenPopupCategory] = useState(false);
  const [openPopupDelete, setOpenPopupDelete] = useState(false);
  const [openPopupSave, setOpenPopupSave] = useState(false);
  const [openPopupSaveAsIng, setOpenPopupSaveAsIng] = useState(false);
  const [isIdPresent, setIdPresent] = useState(false);
  const [isPopupDemoOpen, setIsPopupDemoOpen] = useState(false);

  const history = useHistory();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    const params = new URLSearchParams(window.location.search);
    setIdPresent(params.has("id"));
  };

  const closePopupCategory = () => {
    setOpenPopupCategory(false);
  };

  const closeOpenPopupDelete = () => {
    setOpenPopupDelete(false);
  };

  const closeOpenPopupSave = () => {
    setOpenPopupSave(false);
  };

  const closePopupSaveAsIng = () => {
    setOpenPopupSaveAsIng(false);
  };

  const saveRecipeAndRedirectPrint = () => {
    props.submitRecipe();
    redirectToPrint();
  };

  const saveRecipe = () => {
    // If the recipe is not editable by the user -> No need to ask for a save, he can't modify this recipe
    if (!props.recipe.editable && props.recipe) {
      redirectToPrint();
    } else {
      setOpenPopupSave(true);
    }
  };

  const redirectToPrint = () => {
    const urlParams = new URLSearchParams(window.location.search);
    let page = "recipe";
    if (urlParams.has("page")) {
      page = urlParams.get("page");
    }

    history.push(getRecipePrintUrl("/" + props.recipe._id) + `?page=${page}`);
  };

  const handlePrintDemo = () => {
    if (role === "demo") {
      handleOpenPopupDemo();
    }
  };
  const handleOpenPopupDemo = () => {
    setIsPopupDemoOpen(true);
  };

  const handleClosePopupDemo = () => {
    setIsPopupDemoOpen(false);
  };

  const renderPrintButton = () => {
    if (role === "demo") {
      return (
          <Dropdown.Item className="flex items-center gap-x-3" onClick={handlePrintDemo}>
            <GiSaveArrow size="1.5em" />
            {I18n.t("recipeAction.print", { locale: l })}
            </Dropdown.Item>
      );
    }

    return (
        <Dropdown.Item className="flex items-center gap-x-3" onClick={() => saveRecipe()}>
          <GiSaveArrow size="1.5em" />
          {I18n.t("recipeAction.print", { locale: l })}
        </Dropdown.Item>
    );
  };

  return (
    <div {...props} className="w-max">
      {isPopupDemoOpen && (
          <PopupDemo
              open={isPopupDemoOpen}
              handleClosePopupDemo={handleClosePopupDemo}
          />
      )}



      {openPopupCategory ? (
        <NewCategory
          open={openPopupCategory}
          handleClose={closePopupCategory}
          categories={props.categories}
          setRecipeCategories={props.setRecipeCategories}
        />
      ) : null}
      <Dropdown>
        <OverlayTrigger placement="left" overlay={<Tooltip>{I18n.t("recipeAction.moreOptions", { locale: l })}</Tooltip>}>
          <Dropdown.Toggle>{I18n.t("recipeAction.options", { locale: l })}</Dropdown.Toggle>
        </OverlayTrigger>
        <Dropdown.Menu>
          {isIdPresent ? (
            <Protected
              role={getRole()}
              perform="recipe:print"
              data={{
                canWrite: canWrite(),
                canRead: canRead(),
              }}
              yes={() => (
                  renderPrintButton()
                // <Dropdown.Item className="flex items-center gap-x-3" onClick={() => saveRecipe()}>
                //   <GiSaveArrow size="1.5em" />
                //   {I18n.t("recipeAction.print", { locale: l })}
                // </Dropdown.Item>
              )}
              no={() => (
                <Dropdown.Item className="flex items-center gap-x-3" onClick={() => props.notAuthorize()}>
                  <GiSaveArrow size="1.5em" />
                  {I18n.t("recipeAction.print", { locale: l })}
                </Dropdown.Item>
              )}
            />
          ) : null}

          <Protected
            role={getRole()}
            perform="recipe:copy"
            data={{
              canWrite: canWrite(),
              canRead: canRead(),
            }}
            yes={() => (
              <Dropdown.Item className="flex items-center gap-x-3" onClick={() => props.duplicateRecipe()}>
                <MdControlPointDuplicate size="1.5em" />
                {I18n.t("recipeAction.copy", { locale: l })}
              </Dropdown.Item>
            )}
            no={() => (
              <Dropdown.Item className="flex items-center gap-x-3" onClick={() => props.notAuthorize()}>
                <MdControlPointDuplicate size="1.5em" />
                {I18n.t("recipeAction.copy", { locale: l })}
              </Dropdown.Item>
            )}
          />

          {props.recipe.editable && canWrite() ? (
            <Dropdown.Item className="flex items-center gap-x-3" onClick={() => setOpenPopupSaveAsIng(true)}>
              <GiPencil size="1.5em" />
              {I18n.t("recipeAction.saveAsIngredient", { locale: l })}
            </Dropdown.Item>
          ) : null}

          {props.recipe.editable ? (
            <>
              <Protected
                role={getRole()}
                perform="recipe:edit"
                data={{
                  canWrite: canWrite(),
                }}
                yes={() => (
                  <Dropdown.Item className="flex items-center gap-x-3" onClick={() => setOpenPopupCategory(true)}>
                    <MdAdd size="1.5em" />
                    {I18n.t("recipeAction.addCategory", { locale: l })}
                  </Dropdown.Item>
                )}
                no={() => (
                  <Dropdown.Item className="flex items-center gap-x-3" onClick={() => props.notAuthorize()}>
                    <MdAdd size="1.5em" />
                    {I18n.t("recipeAction.addCategory", { locale: l })}
                  </Dropdown.Item>
                )}
              />
              <Protected
                role={getRole()}
                perform="recipe:delete"
                data={{
                  canWrite: canWrite(),
                  canRead: canRead(),
                }}
                yes={() => (
                  <Dropdown.Item className="flex items-center gap-x-3" onClick={() => setOpenPopupDelete(true)}>
                    <GiTrashCan size="1.5em" />
                    {I18n.t("recipeAction.delete", { locale: l })}
                  </Dropdown.Item>
                )}
                no={() => (
                  <Dropdown.Item className="flex items-center gap-x-3" onClick={() => props.notAuthorize()}>
                    <GiTrashCan size="1.5em" />
                    {I18n.t("recipeAction.delete", { locale: l })}
                  </Dropdown.Item>
                )}
              />
            </>
          ) : null}
        </Dropdown.Menu>
      </Dropdown>
      <Modal show={openPopupDelete} onHide={closeOpenPopupDelete}>
        <Modal.Header closeButton>{I18n.t("recipeAction.deleteSure", { locale: l })}</Modal.Header>
        <Modal.Body>
          {I18n.t("recipeAction.deleteRecipe", { locale: l })} {props.recipe.name} ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => props.deleteRecipe()}>
            {I18n.t("recipeAction.delete", { locale: l })}
          </Button>
          <Button variant="primary" onClick={closeOpenPopupDelete}>
            {I18n.t("cancel", { locale: l })}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={openPopupSave && props.recipe.editable} onHide={closeOpenPopupSave}>
        <Modal.Header closeButton>
          <Modal.Title>{I18n.t("recipeAction.saveAndPrint", { locale: l })}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{I18n.t("recipeAction.saveAndPrintText", { locale: l })}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={redirectToPrint}>
            {I18n.t("recipeAction.print", { locale: l })}
          </Button>
          <Button variant="success" onClick={saveRecipeAndRedirectPrint}>
            {I18n.t("recipeAction.saveAndPrint", { locale: l })}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal scrollable show={openPopupSaveAsIng && props.recipe.editable} onHide={closePopupSaveAsIng}>
        <Modal.Header closeButton>
          <Modal.Title>{I18n.t("recipeAction.saveAsIngredient", { locale: l })}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{I18n.t("recipeAction.info.saveAsIngredient", { locale: l })}</Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={() => props.saveAsIngredient()}>
            {I18n.t("recipeAction.goToIngPage", { locale: l })}
          </Button>
          <Button variant="danger" onClick={closePopupSaveAsIng}>
            {I18n.t("cancel", { locale: l })}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default memo(GenericMoreButton);
