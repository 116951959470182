import { Switch, Route } from "react-router-dom";
import { Url } from "../Config/url";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import RecoverPassword from "../pages/RecoverPassword";
import Welcome from "../pages/Welcome";
import Dashboard from "../pages/Dashboard";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Payment from "../pages/Payment";

export default function Routes() {
  return (
    <Switch>
      <Route path={Url.login} exact component={SignIn} />
      <Route path={Url.register} component={SignUp} />
      <Route path={Url.pwdForgot} component={RecoverPassword} />
      <PrivateRoute path={Url.welcome} component={Welcome} />
      <PrivateRoute path={Url.payment} component={Payment} />
      <PrivateRoute path={Url.dashboard} component={Dashboard} />

      {/* redirect user to SignIn page if route does not exist and user is not authenticated */}
      <PublicRoute component={SignIn} />
    </Switch>
  );
}
