import { Modal, Button, ListGroup } from "react-bootstrap";
import I18n from "../../Config/I18n";
import FolderAPI from "../../backend/folder";
import { getRecipeUrl } from "../../Config/url";
import { getLanguage } from "../../helpers/auth";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { useHaveWriteAccess } from "src/helpers/access";

const l = getLanguage();

const PopUpFolderDelete = ({ folderId, folderName, open = true, closeFunction, deleteFunction }) => {
  const [oldFolderId, setOldFolderId] = useState("");
  const [recipes, setRecipes] = useState([]);

  const history = useHistory();

  useHaveWriteAccess(open);

  const loadRecipe = async () => {
    if (!folderId) return;
    const resp = await FolderAPI.getRecipesInside(folderId);
    if (!resp) return;
    setRecipes(resp);
    setOldFolderId(folderId);
  };

  if (recipes.length === 0 && open && oldFolderId !== folderId) {
    loadRecipe();
  }

  const close = () => {
    setRecipes([]);
    setOldFolderId("");
    closeFunction();
  };

  const recipesUsed = () => {
    return recipes.length ? (
      <>
        <br />
        <h3>{I18n.t("folder.recipesInside", { locale: l })} :</h3>
        <ListGroup>
          {recipes.map((recipe, id) => (
            <ListGroup.Item key={recipe._id} onClick={() => history.push(getRecipeUrl(recipe._id))}>
              {recipe.name}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </>
    ) : (
      ""
    );
  };

  return (
    <Modal show={open} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>
          {I18n.t("folder.delete", { locale: l })} {folderName}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {I18n.t("folder.deleteSure", { locale: l })} {folderName}?
        <br />
        {recipesUsed()}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={() => deleteFunction()}>
          {I18n.t("folder.deleteAnyway", { locale: l })}
        </Button>
        <Button variant="primary" onClick={close}>
          {I18n.t("cancel", { locale: l })}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PopUpFolderDelete;
