import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import Protected from "../routes/Protected";
import { getLanguage, getName, getRole } from "../helpers/auth";
import CardOverviewAdmin from "../components/admin/CardOverviewAdmin";
import { GET } from "../helpers/requests";
import { Col, Row } from "react-bootstrap";
import I18n from "../Config/I18n";
import { GiChart, GiHumanPyramid, GiPieChart, GiPriceTag } from "react-icons/gi";
import DashboardHeader from "../Layouts/DashboardHeader";
import { Url } from "../Config/url";
const l = getLanguage();

export default function MainPageAdmin() {
  const [summary, setSummary] = useState({
    users: {
      sum: "",
      expired: "",
      newMonthAgo: "",
      lostMonthAgo: "",
      demoAlive: "",
      usersAlive: "",
      TranslatorsAlive: "",
      admins: "",
      usersEternal: "",
    },
    coupons: {
      sum: "",
      expired: "",
      available: "",
      futur: "",
    },
    invoices: {
      AmountFromLastMonth: "",
      VATFromLastMonth: "",
      InvoicesNumber: "",
      quarter: {
        num: "",
        startAt: "",
        endAt: "",
        gain: "",
        vat: "",
      },
    },
  });

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const loadData = async () => {
    await getSummary();
  };

  // Get summary data. Call the api. Fetch data and get responses
  const getSummary = async () => {
    const summary = await GET("/data");
    if (summary.error) {
      return console.error(summary.error.message);
    }
    setSummary(summary.data);
  };

  const cards = [
    {
      id: "1",
      name: "Coupons overview",
      category: `Coupons expired : ${summary.coupons.expired}`,
      description: `Coupons available : ${summary.coupons.available}`,
      path: Url.coupons,
      icon: <GiPriceTag size="3em" />,
      textButton: `${I18n.t("DashboardUser.textButton", { locale: l })}`,
    },
    {
      id: "2",
      name: "Users overview",
      category: `User's status Demo : ${summary.users.demoAlive}`,
      description: `User's status Activate : ${summary.users.usersAlive}`,
      path: Url.users,
      icon: <GiHumanPyramid size="3em" />,
      textButton: `${I18n.t("DashboardUser.textButton", { locale: l })}`,
    },
    {
      id: "3",
      name: "New/lost users last month",
      category: `New users : ${summary.users.newMonthAgo}`,
      description: `Users lost : ${summary.users.lostMonthAgo}`,
      path: Url.users,
      icon: <GiPieChart size="3em" />,
      textButton: `${I18n.t("DashboardUser.textButton", { locale: l })}`,
    },
    {
      id: "4",
      name: "Invoices overview",
      category: `Gain last month : ${summary.invoices.AmountFromLastMonth} €`,
      description: `VAT last month : ${summary.invoices.VATFromLastMonth} € `,
      path: Url.invoices,
      icon: <GiChart size="3em" />,
      textButton: `${I18n.t("DashboardUser.textButton", { locale: l })}`,
    },
  ];

  return (
    <Protected
      role={getRole()}
      perform="dashboard-page-admin:visit"
      yes={() => (
        <>
          <Row className="my-5">
            <Col lg={6} className="mx-3 my-auto justify-content-center">
              <h1>
                {I18n.t("goodMorning", { locale: l })} {getName()}
                {","}
              </h1>
              <h4>{I18n.t("admin.mainText", { locale: l })}</h4>
            </Col>
            <Col className="mx-auto justify-content-center">
              <DashboardHeader />
            </Col>
          </Row>
          <Row className="mx-2 justify-content-center">
            {cards.map((card, index) => {
              return (
                <Col key={index} className="col-auto mb-3">
                  <CardOverviewAdmin card={card} />
                </Col>
              );
            })}
          </Row>
        </>
      )}
      no={() => <Redirect to={Url.welcome} />}
    />
  );
}
