import { FC } from "react";
import { Col, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { getLanguage } from "src/helpers/auth";
import GanacheFormat from "src/utils/ganacheFormat";
import { getIngredientsByTemperature } from "src/utils/recipes";
import I18n from "../../../../../Config/I18n";
import { getTotalWeight } from "../IngredientsTable";

const l = getLanguage();

const GetContributionCells = ({ ing }) => {
  if (!Object.keys(ing).length) {
    return (
      <>
        <td>{GanacheFormat.percentage(0)} %</td>
        <td>{GanacheFormat.percentage(0)} %</td>
        <td>{GanacheFormat.percentage(0)} %</td>
        <td>{GanacheFormat.percentage(0)} %</td>
        <td>{GanacheFormat.percentage(0)} %</td>
        <td>{GanacheFormat.percentage(0)} %</td>
        <td>{GanacheFormat.percentage(0)} %</td>
        <td>{GanacheFormat.percentage(0)} %</td>
        <td>{GanacheFormat.percentage(0)} %</td>
        <td>{GanacheFormat.percentage(0)} %</td>
        <td>{GanacheFormat.round(0)}</td>
        <td>{GanacheFormat.round(0)}</td>
        <td>{GanacheFormat.round(0)}</td>
        <td>{GanacheFormat.price(0)}</td>
      </>
    );
  }

  return (
    <>
      <td>{GanacheFormat.percentage(ing.sugar)} %</td>
      <td>{GanacheFormat.percentage(ing.oil)} %</td>
      <td>{GanacheFormat.percentage(ing.butter)} %</td>
      <td>{GanacheFormat.percentage(ing.cocoa_butter)} %</td>
      <td>{GanacheFormat.percentage(ing.cocoa_solid)} %</td>
      <td>{GanacheFormat.percentage(ing.amp)} %</td>
      <td>{GanacheFormat.percentage(ing.lactose)} %</td>
      <td>{GanacheFormat.percentage(ing.other)} %</td>
      <td>{GanacheFormat.percentage(ing.water)} %</td>
      <td>{GanacheFormat.percentage(ing.alcohol)} %</td>
      <td>{GanacheFormat.round(ing.pod)}</td>
      <td>{GanacheFormat.round(ing.pac)}</td>
      <td>{GanacheFormat.round(ing.kcal)}</td>
      <td>{GanacheFormat.price(ing.foodCost)}</td>
    </>
  );
};

const GetIngContributionRow: FC<any> = ({ ing }) => {
  return (
    <tr className="text-right" key={ing._id}>
      <GetContributionCells ing={ing} />
    </tr>
  );
};

const MakeTemperatureLineComp: FC<any> = ({ cont }) => {
  return (
    <tr className="table-row-temp table-warning">
      <GetContributionCells ing={cont} />
    </tr>
  );
};

const GetContributionRowsByTemp: any = ({ recipe, cooking, reducer }) => {
  const ingredientsByTemperature = getIngredientsByTemperature(recipe.ingredients);
  return Object.keys(ingredientsByTemperature).map(tmp => {
    const price = recipe.ingredients
      .filter(ing => ing.temperature === parseInt(tmp))
      .map(ing => (ing.ingredient.note.foodCost / 1000) * ing.quantity || 0)
      .reduce(reducer, 0);
    let contribution = {};
    if (cooking[parseInt(tmp)]) {
      const composition = cooking[parseInt(tmp)].total.composition;
      const weigth = cooking[parseInt(tmp)].total.quantity;
      const totalWeigth = getTotalWeight(cooking, reducer);
      //@ts-ignore
      const coef = weigth / totalWeigth;
      contribution = {
        sugar: composition.sugar * coef,
        butter: composition.fats.butter * coef,
        cocoa_butter: composition.fats.cocoa_butter * coef,
        oil: composition.fats.oil * coef,
        amp: composition.dry.amp * coef,
        lactose: composition.dry.lactose * coef,
        cocoa_solid: composition.dry.cocoa_solid * coef,
        other: composition.dry.other * coef,
        water: composition.liquids.water * coef,
        alcohol: composition.liquids.alcohol * coef,
        pod: (composition.other.pod * coef).toFixed(0),
        pac: (composition.other.pac * coef).toFixed(0),
        kcal: composition.other.kcal.toFixed(0),
        kj: composition.other.kj,
        foodCost: price,
      };
    }

    return (
      <>
        {ingredientsByTemperature[tmp].map((ingByTemp, index) => {
          let ingTemp;
          let temperature = 0;
          if (tmp !== "ambiant") {
            temperature = parseInt(tmp);
            ingTemp = recipe.ingredients.filter(ing => `${ing.temperature}` === tmp)[index];
          } else {
            const ings = recipe.ingredients.filter(ing => !ing.temperature || !parseInt(ing.temperature));
            ingTemp = ings[index];
          }

          const temperatureGroupWeigthBeforeCooking = temperature
            ? cooking[temperature]?.ingredients.map(ing => (ing.hide ? 0 : ing.quantity)).reduce(reducer, 0)
            : getTotalWeight(cooking, reducer);
          const quantity = ingTemp.quantity;

          let contribution = {};
          if (cooking[temperature] && !ingTemp.hide) {
            const composition = ingTemp.ingredient.composition;
            const coef = quantity / temperatureGroupWeigthBeforeCooking;
            contribution = {
              sugar: composition.sugar * coef,
              butter: composition.fats.butter * coef,
              cocoa_butter: composition.fats.cocoa_butter * coef,
              oil: composition.fats.oil * coef,
              amp: composition.dry.amp * coef,
              lactose: composition.dry.lactose * coef,
              cocoa_solid: composition.dry.cocoa_solid * coef,
              other: composition.dry.other * coef,
              water: composition.liquids.water * coef,
              alcohol: composition.liquids.alcohol * coef,
              pod: (composition.other.pod * coef).toFixed(0),
              pac: (composition.other.pac * coef).toFixed(0),
              kcal: ((composition.other.kcal / 100) * quantity).toFixed(0),
              kj: (composition.other.kj / 100) * quantity,
              foodCost: (ingTemp.ingredient.note.foodCost / 1000) * quantity,
            };
          }
          return <GetIngContributionRow ing={contribution} />;
        })}
        {tmp !== "ambiant" && <MakeTemperatureLineComp cont={contribution} />}
      </>
    );
  });
};

const GetTotalContributionRow = ({ contribution }) => {
  return (
    <tr className="table-primary">
      <td>{GanacheFormat.percentage(contribution.sugar)} %</td>
      <td>{GanacheFormat.percentage(contribution.oil)} %</td>
      <td>{GanacheFormat.percentage(contribution.butter)} %</td>
      <td>{GanacheFormat.percentage(contribution.cocoa_butter)} %</td>
      <td>{GanacheFormat.percentage(contribution.cocoa_solid)} %</td>
      <td>{GanacheFormat.percentage(contribution.amp)} %</td>
      <td>{GanacheFormat.percentage(contribution.lactose)} %</td>
      <td>{GanacheFormat.percentage(contribution.other)} %</td>
      <td>{GanacheFormat.percentage(contribution.water)} %</td>
      <td>{GanacheFormat.percentage(contribution.alcohol)} %</td>
      <td>{GanacheFormat.round(contribution.pod)}</td>
      <td>{GanacheFormat.round(contribution.pac)}</td>
      <td>{GanacheFormat.round(contribution.kcal)}</td>
      <td>{GanacheFormat.price(contribution.foodCost)}</td>
    </tr>
  );
};

export const DetailsTable: FC<any> = ({ recipe, cooking, reducer, contribution }) => {
  return (
    <Col lg={8} md={6}>
      <h3 className="text-3xl font-semibold">{I18n.t("listIngredients.details", { locale: l })}</h3>
      <Table className="table-ingredients" striped bordered hover responsive aria-label="simple table">
        <thead>
          <tr>
            <th>{I18n.t("ingredient.sugar", { locale: l })}</th>
            <th>{I18n.t("ingredient.oil", { locale: l })}</th>
            <th>{I18n.t("ingredient.butter", { locale: l })}</th>
            <OverlayTrigger overlay={<Tooltip id="3">{I18n.t("ingredient.cocoaButter", { locale: l })}</Tooltip>}>
              <th>{I18n.t("ingredient.cocoaButterShort", { locale: l })}</th>
            </OverlayTrigger>
            <th>{I18n.t("ingredient.cocoa", { locale: l })}</th>
            <th>{I18n.t("ingredient.amp", { locale: l })}</th>
            <th>{I18n.t("ingredient.lactose", { locale: l })}</th>
            <th>{I18n.t("ingredient.other", { locale: l })}</th>
            <th>{I18n.t("ingredient.water", { locale: l })}</th>
            <th>{I18n.t("ingredient.alcohol", { locale: l })}</th>
            <th className="p-col">{I18n.t("ingredient.pod", { locale: l })}</th>
            <th className="p-col">{I18n.t("ingredient.pac", { locale: l })}</th>
            <th>{`${I18n.t("ingredient.kcal", { locale: l })}`}</th>
            <th>{`${I18n.t("ingredient.foodCost", { locale: l })}`}</th>
          </tr>
        </thead>
        {recipe.ingredients && (
          <tbody>
            <GetContributionRowsByTemp recipe={recipe} cooking={cooking} reducer={reducer} />
            <GetTotalContributionRow contribution={contribution} />
          </tbody>
        )}
      </Table>
    </Col>
  );
};
