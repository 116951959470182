const rules = {
  visitor: {
    static: ["signin:visit", "signup:visit", "reset-password:visit", "home-page:visit"],
  },
  demo: {
    static: [
      "welcome:visit",
      "signin:visit",
      "signup:visit",
      "signin:connection",
      "signin-page:connection",
      "reset-password:visit",
      "dashboard-page-ingredients:visit",
      "dashboard-page-folders:visit",
      "dashboard-page-profile:visit",
      "payment:pay",
    ],
    dynamic: {
      "dashboard-page-ingredients:edit": ({ canWrite }) => {
        return canWrite;
      },
      "recipe:edit": ({ canWrite }) => {
        return canWrite;
      },
      "recipe:print": ({ canWrite, canRead }) => {
        if (canRead) {
          return canWrite;
        }
        return canRead;
      },
      "recipe:copy": ({ canWrite, canRead }) => {
        if (canRead) {
          return canWrite;
        }
        return canRead;
      },
      "recipe:delete": ({ canWrite, canRead }) => {
        if (canRead) {
          return canWrite;
        }
        return canRead;
      },
      "payment:must-pay": ({ canWrite, canRead }) => {
        if (!canRead && !canWrite) {
          return canRead;
        }
        return true;
      },
      "folder:delete": ({ canWrite, canRead }) => {
        if (canRead) {
          return canWrite;
        }
        return canRead;
      },
    },
  },
  user: {
    static: [
      "welcome:visit",
      "signin:visit",
      "signup:visit",
      "signin:connection",
      "signin-page:connection",
      "reset-password:visit",
      "dashboard-page-ingredients:visit",
      "dashboard-page-folders:visit",
      "dashboard-page-profile:visit",
      "payment:pay",
    ],
    dynamic: {
      "dashboard-page-ingredients:edit": ({ canWrite }) => {
        return canWrite;
      },
      "recipe:edit": ({ canWrite }) => {
        return canWrite;
      },
      "recipe:print": ({ canWrite, canRead }) => {
        if (canRead) {
          return canWrite;
        }
        return canRead;
      },
      "recipe:copy": ({ canWrite, canRead }) => {
        if (canRead) {
          return canWrite;
        }
        return canRead;
      },
      "recipe:delete": ({ canWrite, canRead }) => {
        if (canRead) {
          return canWrite && canRead;
        }
        return canRead;
      },
      "payment:must-pay": ({ canWrite, canRead }) => {
        if (!canRead && !canWrite) {
          return canRead;
        }
        return true;
      },
      "folder:delete": ({ canWrite, canRead }) => {
        if (canRead) {
          return canWrite;
        }
        return canRead;
      },
      "ingredient:delete": ({ canWrite, canRead, isEditable }) => {
        return canWrite && isEditable;
      },
    },
  },
  translator: {
    static: [
      "welcome:visit",
      "signin:visit",
      "signup:visit",
      "signin:connection",
      "signin-page:connection",
      "reset-password:visit",
      "home-page:visit",
      "dashboard-page:visit",
      "payment:must-pay",
    ],
  },
  administrator: {
    static: [
      "welcome:visit",
      "signin:visit",
      "signup:visit",
      "signin:connection",
      "reset-password:visit",
      "dashboard-page-ingredients:visit",
      "dashboard-page-admin:visit",
      "dashboard-page-folders:visit",
      "dashboard-page-profile:visit",
      "signin-page:connection",
      "dashboard-page-admin:route",
      "settings-page-admin:action",
      "payment:pay",
      "recipe:print",
      "recipe:copy",
      "recipe:delete",
      "payment:must-pay",
    ],
    dynamic: {
      "recipe:edit": ({ canWrite }) => {
        return canWrite;
      },
      "folder:delete": ({ canWrite, canRead }) => {
        if (canRead) {
          return canWrite;
        }
        return canRead;
      },
      "ingredient:delete": ({ canWrite, canRead, isEditable }) => {
        return canWrite && isEditable;
      },
    },
  },
};

export default rules;

// dynamic: {
//   "dashboard-page-ingredients:edit": ({userId, ownerId}) => {
//     if (!userId || !ownerId) return false;
//     return userId === ownerId;
//   }
// }
