/***********************************************
 ***           AUTHOR : MAXIME SOHET          ***
 ***********************************************/
import Routes from "./routes/Routes";
import { CookiesProvider } from "react-cookie";
import "./App.scss";
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <CookiesProvider>
      <Router>
        <Routes />
      </Router>
    </CookiesProvider>
  );
}

export default App;
