import recipe from "../backend/recipe";

// data
const ingredientDetails = {
  sugar: {
    name: "sugar",
    color: "#b76935",
  },
  cocoa_solid: {
    name: "cocoa_solid",
    color: "#a56336",
  },
  amp: {
    name: "amp",
    color: "#935e38",
  },
  lactose: {
    name: "lactose",
    color: "#815839",
  },
  other: {
    name: "other",
    color: "#6f523b",
  },
  oil: {
    name: "oil",
    color: "#5c4d3c",
  },
  butter: {
    name: "butter",
    color: "#4a473e",
  },
  cocoa_butter: {
    name: "cocoa_butter",
    color: "#38413f",
  },
  water: {
    name: "water",
    color: "#263c41",
  },
  alcohol: {
    name: "alcohol",
    color: "#263c41",
  },
};

// return an object of data fot ingredient in recipe
export const getIngredient = (ingredient, quantite = 0, temperature = 0, coefficient = 1, mode = false) => {
  if (!ingredient) {
    return {};
  }
  const ingredientUpdate = {
    id: ingredient.ingredient._id,
    name: ingredient.ingredient.name,
    quantity: ingredient.quantity || quantite,
    temperature: ingredient.temperature || temperature,
    coefficient: ingredient.coefficient || coefficient,
    isEditMode: mode,
    sugar: ingredient.ingredient.composition.sugar,
    cocoa_solid: ingredient.ingredient.composition.dry.cocoa_solid,
    amp: ingredient.ingredient.composition.dry.amp,
    lactose: ingredient.ingredient.composition.dry.lactose,
    other: ingredient.ingredient.composition.dry.other,
    oil: ingredient.ingredient.composition.fats.oil,
    butter: ingredient.ingredient.composition.fats.butter,
    cocoa_butter: ingredient.ingredient.composition.fats.cocoa_butter,
    water: ingredient.ingredient.composition.liquids.water,
    alcohol: ingredient.ingredient.composition.liquids.alcohol,
    pod: parseInt(ingredient.ingredient.composition.other.pod),
    pac: parseInt(ingredient.ingredient.composition.other.pac),
    kj: ingredient.ingredient.composition.other.kj,
    kcal: parseInt(ingredient.ingredient.composition.other.kcal),
    foodCost: ingredient.ingredient.note.foodCost,
    flags: ingredient.ingredient.flags,
  };
  return ingredientUpdate;
};

/**
 * getContribution data of a recipe with ingredients
 * @param {[import("../Type/recipeIngredient").RecipeIngredient]} ingredients
 * @return {Object.<string, import("../Type/recipe").Contribution>}
 */
export const getContribution = ingredients => {
  if (!ingredients) {
    return [];
  }
  const totalWeight = totalComputableWeightRecipe(ingredients);
  const totalWeightTotal = totalWeightRecipe(ingredients);
  const contributionArray = [];
  ingredients.map(row => {
    const coefficient = parseFloat(row.coefficient);
    const quantity = parseFloat(row.quantity);
    const percentage = (quantity * coefficient) / totalWeight;
    const percentageTotal = (quantity * coefficient) / totalWeightTotal;

    const masseProportionBeforeCooking = quantity / totalWeightRecipeNoCoef(ingredients);
    const compositionIngredient = {
      _id: row.ingredient._id,
      sugar: row.hide ? 0 : row.ingredient.composition.sugar * percentage,
      cocoa_solid: row.hide ? 0 : row.ingredient.composition.dry.cocoa_solid * percentage,
      amp: row.hide ? 0 : row.ingredient.composition.dry.amp * percentage,
      lactose: row.hide ? 0 : row.ingredient.composition.dry.lactose * percentage,
      other: row.hide ? 0 : row.ingredient.composition.dry.other * percentage,
      oil: row.hide ? 0 : row.ingredient.composition.fats.oil * percentage,
      butter: row.hide ? 0 : row.ingredient.composition.fats.butter * percentage,
      cocoa_butter: row.hide ? 0 : row.ingredient.composition.fats.cocoa_butter * percentage,
      water: row.hide ? 0 : row.ingredient.composition.liquids.water * percentage,
      alcohol: row.hide ? 0 : row.ingredient.composition.liquids.alcohol * percentage,
      pod: row.hide ? 0 : parseInt(row.ingredient.composition.other.pod * masseProportionBeforeCooking),
      pac: row.hide ? 0 : parseInt(row.ingredient.composition.other.pac * masseProportionBeforeCooking),
      kj: row.ingredient.composition.other.kj * coefficient,
      kcal: parseInt((row.ingredient.composition.other.kcal * coefficient * quantity) / 100),
      foodCost: (row.ingredient.note.foodCost * quantity) / 1000,
      percentage: percentage,
      percentageTotal: percentageTotal,
    };
    contributionArray.push(compositionIngredient);
    return contributionArray;
  });
  return contributionArray;
};

/**
 * Compute the total contribution in a recipe. Return an object
 * @param {import("../Type/recipe").Recipe} recipe
 */
export const getTotalContributionRecipe = recipe => {
  const contributionOfRecipe = {
    sugar: 0,
    cocoa_solid: 0,
    amp: 0,
    lactose: 0,
    other: 0,
    oil: 0,
    butter: 0,
    cocoa_butter: 0,
    water: 0,
    alcohol: 0,
    pod: 0,
    pac: 0,
    kj: 0,
    kcal: 0,
    foodCost: 0,
  };

  // if (!recipe || !recipe.ingredients) return contributionOfRecipe

  const ingredientsContributions = getContribution(recipe.ingredients);
  const ingIdList = Object.keys(ingredientsContributions);
  ingIdList.map((id, index) => {
    contributionOfRecipe.sugar += isNaN(parseFloat(ingredientsContributions[id].sugar))
      ? 0
      : parseFloat(ingredientsContributions[id].sugar);
    contributionOfRecipe.cocoa_solid += isNaN(parseFloat(ingredientsContributions[id].cocoa_solid))
      ? 0
      : parseFloat(ingredientsContributions[id].cocoa_solid);
    contributionOfRecipe.amp += isNaN(parseFloat(ingredientsContributions[id].amp)) ? 0 : parseFloat(ingredientsContributions[id].amp);
    contributionOfRecipe.lactose += isNaN(parseFloat(ingredientsContributions[id].lactose))
      ? 0
      : parseFloat(ingredientsContributions[id].lactose);
    contributionOfRecipe.other += isNaN(parseFloat(ingredientsContributions[id].other))
      ? 0
      : parseFloat(ingredientsContributions[id].other);
    contributionOfRecipe.oil += isNaN(parseFloat(ingredientsContributions[id].oil)) ? 0 : parseFloat(ingredientsContributions[id].oil);
    contributionOfRecipe.butter += isNaN(parseFloat(ingredientsContributions[id].butter))
      ? 0
      : parseFloat(ingredientsContributions[id].butter);
    contributionOfRecipe.cocoa_butter += isNaN(parseFloat(ingredientsContributions[id].cocoa_butter))
      ? 0
      : parseFloat(ingredientsContributions[id].cocoa_butter);
    contributionOfRecipe.water += isNaN(parseFloat(ingredientsContributions[id].water))
      ? 0
      : parseFloat(ingredientsContributions[id].water);
    contributionOfRecipe.alcohol += isNaN(parseFloat(ingredientsContributions[id].alcohol))
      ? 0
      : parseFloat(ingredientsContributions[id].alcohol);
    contributionOfRecipe.pod += isNaN(parseInt(ingredientsContributions[id].pod)) ? 0 : parseInt(ingredientsContributions[id].pod);
    contributionOfRecipe.pac += isNaN(parseInt(ingredientsContributions[id].pac)) ? 0 : parseInt(ingredientsContributions[id].pac);
    contributionOfRecipe.kj += isNaN(parseFloat(ingredientsContributions[id].kj)) ? 0 : parseFloat(ingredientsContributions[id].kj);
    contributionOfRecipe.kcal += isNaN(parseInt(ingredientsContributions[id].kcal)) ? 0 : parseInt(ingredientsContributions[id].kcal);
    contributionOfRecipe.foodCost += isNaN(parseFloat(ingredientsContributions[id].foodCost))
      ? 0
      : parseFloat(ingredientsContributions[id].foodCost);

    return contributionOfRecipe;
  });

  return contributionOfRecipe;
};

/**
 *
 * @param {any[]} ingredients
 * @return {Object.<Number, Array>}
 */
export const getIngredientsByTemperature = ingredients => {
  const ret = { ambiant: [] };
  if (!ingredients) {
    return ret;
  }
  for (var item of ingredients) {
    if (!item.temperature || item.temperature === 0 || item.temperature === "0" || item.temperature === "ambiant") {
      ret.ambiant.push(item);
    } else {
      if (!ret[item.temperature]) {
        ret[item.temperature] = [];
      }
      ret[item.temperature].push(item);
    }
  }
  return ret;
};

/**
 *
 * @param {Array} ingredients
 */
export const computeTemperatures = async ingredients => {
  const result = {};
  ingredients.forEach(ing => {
    const temperature = ing.temperature || 0;
    if (!result[temperature]) {
      result[temperature] = {
        elements: [],
      };
    }
    result[temperature].elements.push({
      quantity: ing.quantity,
      composition: ing.ingredient.composition,
    });
  });
  for (const temp of Object.keys(result)) {
    const elements = result[temp].elements;
    result[temp]["total"] = await recipe.computeEvaporation(temp, elements);
  }
  return result;
};

// return the coefficient to adapt the width of the stacked chart
export const getPercentageContributionWidth = ingredients => {
  if (!ingredients) {
    return 1;
  }
  const totalWeight = totalComputableWeightRecipe(ingredients);
  const percentageContribution = [];
  ingredients.map((row, index) => {
    const percentage = (parseFloat(row.quantity) * parseFloat(row.coefficient)) / totalWeight;
    percentageContribution.push(percentage);
    return percentageContribution;
  });
  const max = Math.max(...percentageContribution);
  const coefficientWidth = parseFloat(1 / max).toFixed(2);
  return coefficientWidth;
};

// Get data contribution recipe for chart
export const getDataContributionChart = contribution => {
  if (!contribution) {
    return [];
  }
  const data = [
    {
      value: parseFloat(contribution.sugar),
      description: ingredientDetails.sugar.name,
      color: ingredientDetails.sugar.color,
    },
    {
      value: parseFloat(contribution.cocoa_solid),
      description: ingredientDetails.cocoa_solid.name,
      color: ingredientDetails.cocoa_solid.color,
    },
    {
      value: parseFloat(contribution.amp),
      description: ingredientDetails.amp.name,
      color: ingredientDetails.amp.color,
    },
    {
      value: parseFloat(contribution.lactose),
      description: ingredientDetails.lactose.name,
      color: ingredientDetails.lactose.color,
    },
    {
      value: parseFloat(contribution.other),
      description: ingredientDetails.other.name,
      color: ingredientDetails.other.color,
    },
    {
      value: parseFloat(contribution.oil),
      description: ingredientDetails.oil.name,
      color: ingredientDetails.oil.color,
    },
    {
      value: parseFloat(contribution.butter),
      description: ingredientDetails.butter.name,
      color: ingredientDetails.butter.color,
    },
    {
      value: parseFloat(contribution.cocoa_butter),
      description: ingredientDetails.cocoa_butter.name,
      color: ingredientDetails.cocoa_butter.color,
    },
    {
      value: parseFloat(contribution.water),
      description: ingredientDetails.water.name,
      color: ingredientDetails.water.color,
    },
    {
      value: parseFloat(contribution.alcohol),
      description: ingredientDetails.alcohol.name,
      color: ingredientDetails.alcohol.color,
    },
  ];
  const dataFiltered = data.filter(function (object) {
    return object.value !== 0;
  });
  return dataFiltered;
};

// Get data composition recipe for chart
export const getDataCompositionRecipeChart = ingredient => {
  if (!ingredient) {
    return [];
  }

  const infoIngredient = getIngredient(ingredient);

  const data = [
    {
      value: parseFloat(infoIngredient.sugar),
      description: ingredientDetails.sugar.name,
      color: ingredientDetails.sugar.color,
    },
    {
      value: parseFloat(infoIngredient.cocoa_solid),
      description: ingredientDetails.cocoa_solid.name,
      color: ingredientDetails.cocoa_solid.color,
    },
    {
      value: parseFloat(infoIngredient.amp),
      description: ingredientDetails.amp.name,
      color: ingredientDetails.amp.color,
    },
    {
      value: parseFloat(infoIngredient.lactose),
      description: ingredientDetails.lactose.name,
      color: ingredientDetails.lactose.color,
    },
    {
      value: parseFloat(infoIngredient.other),
      description: ingredientDetails.other.name,
      color: ingredientDetails.other.color,
    },
    {
      value: parseFloat(infoIngredient.oil),
      description: ingredientDetails.oil.name,
      color: ingredientDetails.oil.color,
    },
    {
      value: parseFloat(infoIngredient.butter),
      description: ingredientDetails.butter.name,
      color: ingredientDetails.butter.color,
    },
    {
      value: parseFloat(infoIngredient.cocoa_butter),
      description: ingredientDetails.cocoa_butter.name,
      color: ingredientDetails.cocoa_butter.color,
    },
    {
      value: parseFloat(infoIngredient.water),
      description: ingredientDetails.water.name,
      color: ingredientDetails.water.color,
    },
    {
      value: parseFloat(infoIngredient.alcohol),
      description: ingredientDetails.alcohol.name,
      color: ingredientDetails.alcohol.color,
    },
  ];

  const dataFiltered = data.filter(function (object) {
    return object.value !== 0;
  });
  return dataFiltered;
};

/**
 * return total weight of an recipe with ingredients
 * @param {[import("../Type/recipeIngredient").RecipeIngredient]} ingredients
 */
export const totalWeightRecipe = ingredients => {
  let total = 0.0;
  ingredients.map(ingredient => {
    if (ingredient.quantity) {
      total += parseFloat(ingredient.quantity * ingredient.coefficient);
    }
    return total;
  });
  return total;
};
export const totalComputableWeightRecipe = ingredients => {
  let total = 0.0;
  ingredients.map(ingredient => {
    if (ingredient.quantity /*&& !ingredient.hide*/) {
      total += parseFloat(ingredient.quantity * ingredient.coefficient);
    }
    return total;
  });
  return total;
};

/**
 * return total raw (before cooking) weight of an recipe with ingredients
 * @param {[import("../Type/recipeIngredient").RecipeIngredient]} ingredients
 */
export const totalWeightRecipeNoCoef = ingredients => {
  let total = 0.0;
  ingredients.map((ingredient, index) => {
    if (ingredient.quantity) {
      total += parseFloat(ingredient.quantity);
    }
    return total;
  });
  return total;
};

// /**
//  * Give ingredients present
//  * @author Malo
//  * @param {import("../Type/recipe").Recipe} recipe
//  * @return {[import("../type/ingredient").Ingredient]}
//  */
export const getIngredients = recipe => {
  if (!recipe.ingredients) return [];
  return recipe.ingredients.map(data => data.ingredient);
};

/**
 *Check contents of a recipe
 *@author Max
 *@deprecated use instead getContentIds
 */
export const setContentRecipe = ingredients => {
  const contents = [];
  if (!ingredients) {
    return contents;
  }
  if (ingredients.length <= 0) {
    return contents;
  }
  ingredients.map((row, index) => {
    const ingredientContent = row.ingredient.flags.contents;
    if (ingredientContent.length !== 0) {
      ingredientContent.map((id, index) => {
        if (!contents.includes(id)) {
          contents.push(id);
        }
        return contents;
      });
    }
    return contents;
  });
  return contents;
};

// /**
//  * Get all contents ids from a list of ingredients
//  * @author Malo
//  * @param {[import("../type/ingredient").Ingredient]} ingredients
//  * @return {[String]} Array of content id's
//  */
export const getContentIds = ingredients => {
  const content = [];
  for (var ingredient of ingredients) {
    for (var contentId of ingredient.flags.contents) {
      if (!content.includes(contentId)) content.push(contentId);
    }
  }
  return content;
};

// /**
//  * Get all fitfor ids from a list of ingredients
//  * @author Malo
//  * @param {[import("../type/ingredient").Ingredient]} ingredients
//  * @return {[String]} Array of fitfor id's
//  */
export const getFitForIds = ingredients => {
  const allFitforPresent = [];
  for (var ingredient of ingredients) {
    for (var fitId of ingredient.flags.fit) {
      if (!allFitforPresent.includes(fitId)) allFitforPresent.push(fitId);
    }
  }

  const fitfors = [];
  for (var index of allFitforPresent) {
    // const fitforId = allFitforPresent[index]
    const fitforId = index;
    const ingredientsFitings = ingredients.filter(ingredient => ingredient.flags.fit.includes(fitforId));
    if (ingredients.length === ingredientsFitings.length) fitfors.push(fitforId);
  }

  return fitfors;
};

/**
 * Check if this fitFor is includes with these ingredients
 * @param {[import("../Type/ingredient").Ingredient]} ingredients
 * @param {String} idFitfor
 * @return {Boolean} true if all ingredient are allowed for this fit
 */
export const setFitforRecipe = (ingredients, idFitfor) => {
  /*
  let checkFitfor = true
  if (!ingredients) {
    return false
  }
  if (ingredients.length <= 0) {
    return false
  }
  ingredients.map((row, index) => {
    const ingredientFitfor = row.ingredient.flags.fit
    if (!ingredientFitfor.includes(idFitfor)) {
      checkFitfor = false
    }
    return checkFitfor
  })
  return checkFitfor
  */
  const ingredientsAllowed = ingredients.filter(ingredient => ingredient.flags.fit.includes(idFitfor));
  return ingredientsAllowed.length === ingredients.length;
};

/**
 *Convert a recipe to the data to send for create or update recipe request
 * @param {import("../Type/recipe").Recipe} recipe
 */
export const submitDataRecipe = recipe => {
  const ingredientsList = recipe.ingredients
    ? recipe.ingredients.map(element => {
        const data = {
          _id: element.ingredient._id,
          quantity: element.quantity,
          hide: element.hide,
        };
        if (element.temperature && element.temperature >= 100) {
          data.temperature = element.temperature;
        }
        return data;
      })
    : [];

  const dataToSend = {
    _id: recipe._id,
    name: recipe.name,
    categoryId: recipe.categoryId,
    statusId: recipe.statusId,
    ingredients: ingredientsList,
    note: recipe.note,
    procedure: recipe.procedure,
    folderId: recipe.folderId,
    source: recipe.source,
    flags: recipe.flags,
  };
  return dataToSend;
};

// return data, labels, color for Pie chart
export const getDataCompositionRecipePieChart = ingredients => {
  if (!ingredients) {
    return {
      data: [],
      backgroundColor: [],
      labels: [],
    };
  }

  const ingredientsContributions = getContribution(ingredients);

  const dataObject = {
    sugar: 0,
    cocoa_solid: 0,
    amp: 0,
    lactose: 0,
    other: 0,
    oil: 0,
    butter: 0,
    cocoa_butter: 0,
    water: 0,
    alcohol: 0,
  };

  ingredientsContributions.map((row, index) => {
    dataObject.sugar += parseInt(row.sugar);
    dataObject.cocoa_solid += parseInt(row.cocoa_solid);
    dataObject.amp += parseInt(row.amp);
    dataObject.lactose += parseInt(row.lactose);
    dataObject.other += parseInt(row.other);
    dataObject.oil += parseInt(row.oil);
    dataObject.butter += parseInt(row.butter);
    dataObject.cocoa_butter += parseInt(row.cocoa_butter);
    dataObject.water += parseInt(row.water);
    dataObject.alcohol += parseInt(row.alcohol);

    return dataObject;
  });

  const labels = [
    ingredientDetails.sugar.name,
    ingredientDetails.cocoa_solid.name,
    ingredientDetails.amp.name,
    ingredientDetails.lactose.name,
    ingredientDetails.other.name,
    ingredientDetails.oil.name,
    ingredientDetails.butter.name,
    ingredientDetails.cocoa_butter.name,
    ingredientDetails.water.name,
    ingredientDetails.alcohol.name,
  ];

  const backgroundColor = [
    ingredientDetails.sugar.color,
    ingredientDetails.cocoa_solid.color,
    ingredientDetails.amp.color,
    ingredientDetails.lactose.color,
    ingredientDetails.other.color,
    ingredientDetails.oil.color,
    ingredientDetails.butter.color,
    ingredientDetails.cocoa_butter.color,
    ingredientDetails.water.color,
    ingredientDetails.alcohol.color,
  ];

  const data = [
    parseInt(dataObject.sugar),
    parseInt(dataObject.cocoa_solid),
    parseInt(dataObject.lactose),
    parseInt(dataObject.other),
    parseInt(dataObject.oil),
    parseInt(dataObject.butter),
    parseInt(dataObject.cocoa_butter),
    parseInt(dataObject.water),
    parseInt(dataObject.alcohol),
  ];

  const result = {
    data: data,
    labels: labels,
    backgroundColor: backgroundColor,
  };
  return result;
};

// move index item in an array
export const moveArrayItemToNewIndex = (arr, oldIndex, newIndex) => {
  if (newIndex < 0 || newIndex >= arr.length || arr.length === 1) {
    return arr;
  }
  arr.splice(newIndex, 1, arr.splice(oldIndex, 1, arr[newIndex])[0]);
  return arr;
};
