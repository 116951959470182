import React, { useEffect, useState } from "react";
import PaymentAPI from "../../backend/payment";
import I18n from "../../Config/I18n";
import { getLanguage } from "../../helpers/auth";
import { Spinner } from "react-bootstrap";

const l = getLanguage();

export default function ReviewPayment({ hasASubscription, subscription }) {
  const [isPaid, setIsPaid] = useState(false);

  useEffect(() => {
    checkPaid();
  }, []);

  const checkPaid = async () => {
    const rep = await PaymentAPI.isPaid();
    if (rep) {
      setIsPaid(rep);
    } else {
      setTimeout(checkPaid, 1000);
    }
  };

  if (!isPaid)
    return (
      <div>
        {I18n.t("payment.paymentProcess", { local: l })}
        <Spinner variant="primary" animation="border" />
      </div>
    );

  return <h6>{I18n.t("payment.paymentSucceed", { local: l })}</h6>;
}
