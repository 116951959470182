import { GET, POST } from "../helpers/requests";

const get = async () => {
  return GET("/brand");
};

const add = async brand => {
  return POST("/brand", brand);
};

export default {
  get,
  add,
};
