import React, { useEffect, useState, FC } from "react";
import { Redirect } from "react-router-dom";
import Protected from "../routes/Protected";
import { getRole } from "../helpers/auth";
import Loader from "../components/loader";
import { backend, GET, GETFile } from "../helpers/requests";
import { Url } from "../Config/url";
import { AdminInvoicesTable } from "src/components/invoices/AdminInvoicesTable";
import { Invoice } from "src/Type/invoice";
import { format } from "date-fns";

export const PageAdminInvoices: FC = () => {
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const loadData = async () => {
    setLoading(true);
    await loadInvoices();
    setLoading(false);
  };

  const loadInvoices = async () => {
    const result = await GET("/payment/all");
    if (result.error) {
      return console.error(result.error.message);
    }
    const invoicesReceived: Invoice[] = result.data;
    setInvoices(invoicesReceived.sort((a, b) => new Date(b.paidDate).getTime() - new Date(a.paidDate).getTime()));
  };

  const changeStartDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = new Date(e.target.value);
    setStartDate(newDate);
  };
  const changeEndDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = new Date(e.target.value);
    setEndDate(newDate);
  };

  const formatDate = (date: Date): string => {
    return format(date, "yyyy-MM-dd");
  };

  const isDownloadable = startDate !== undefined && endDate !== undefined;
  return (
    <Protected
      role={getRole()}
      perform="dashboard-page-admin:visit"
      yes={() => (
        <>
          <div className="page-folders">
            {loading ? (
              <Loader />
            ) : (
              <>
                <div className="flex p-5">
                  <div className="p-1">
                    <label htmlFor="company-website" className="block text-sm font-medium text-gray-700">
                      Start Date
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                        start date
                      </span>
                      <input
                        type="date"
                        name="invoice-start"
                        id="invoice-start"
                        onChange={changeStartDate}
                        value={startDate !== undefined ? formatDate(startDate) : ""}
                        className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
                        placeholder="2022-01-08"
                      />
                    </div>
                  </div>
                  <div className="p-1">
                    <label htmlFor="company-website" className="block text-sm font-medium text-gray-700">
                      End Date
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                        end date
                      </span>
                      <input
                        type="date"
                        name="invoice-end"
                        id="invoice-end"
                        onChange={changeEndDate}
                        value={endDate !== undefined ? formatDate(endDate) : ""}
                        className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
                        placeholder="2022-01-08"
                      />
                    </div>
                  </div>
                  <a
                    type="button"
                    href={isDownloadable ? `${backend}/payment/invoicesxlsx?start=${formatDate(startDate)}&end=${formatDate(endDate)}` : ""}
                    target="_blank"
                    rel="noreferrer"
                    className={`m-1 inline-flex items-center px-3 py-2.5 border border-transparent text-xs font-medium rounded shadow-sm text-white ${
                      isDownloadable ? "bg-ganache-orange-400 hover:bg-ganache-orange-600" : "bg-gray-400"
                    } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ganache-orange-500 h-fit self-end`}
                    onClick={event => {
                      if (!isDownloadable) event.preventDefault();
                    }}
                  >
                    Download invoices
                  </a>
                </div>
                <AdminInvoicesTable invoices={invoices} />
              </>
            )}
          </div>
        </>
      )}
      no={() => <Redirect to={Url.dashboard} />}
    />
  );
};
