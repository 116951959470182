import { canWrite } from "./auth";
import { useHistory } from "react-router-dom";
import { Url } from "src/Config/url";

export const useHaveWriteAccess: (wantAccess: boolean) => void = wantAccess => {
  const history = useHistory();
  if (wantAccess && !canWrite()) {
    history.push(Url.payment);
    return history.go(0);
  }
};
