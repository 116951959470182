import logo from "../Layouts/logo-white.png";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { getLanguage, getRole } from "../helpers/auth";
import { Redirect, withRouter } from "react-router-dom";
import Protected from "../routes/Protected";
import I18n from "../Config/I18n";
import { POST } from "../helpers/requests";
import { Url } from "../Config/url";
import SimpleSnackbar from "../components/SimpleSnackbar";
import { useState } from "react";

const l = getLanguage();

const SignIn = props => {
  const [email, setEmail] = useState("");
  const [emailErrors, setEmailErrors] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState(getRole());
  const [isPwdInvalid, setPwdInvalid] = useState(false);

  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [snackVariant, setSnackVariant] = useState("danger");
  const [openSnackBar, setOpenSnackBar] = useState(false);

  // email validation regex
  const emailRegex = RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);

  // validation input form
  const formValid = () => {
    let valid = true;

    // validate form errors being empty
    if (emailErrors.length > 0) {
      valid = false;
    }

    // validate the form was filled out
    if (email === null || email.length < 3 || password === null || password === "") {
      valid = false;
    }

    return valid;
  };

  const handleChangePassword = e => {
    e.preventDefault();
    const value = e.target.value;
    setPassword(value);
    setPwdInvalid(false);
  };

  const handleChangeEmail = e => {
    e.preventDefault();
    const value = e.target.value;
    setEmail(value);
    setEmailErrors("");
  };

  const handleSubmit = event => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    event.preventDefault();
    const error = emailRegex.test(email) ? "" : "invalid email address";
    setEmailErrors(error);
    if (formValid() === true) {
      submit();
    } else {
      sendSnack(emailErrors, snackVariant);
    }
  };

  // Submit data. Call the api. Fetch data and get responses
  const submit = async () => {
    const data = {
      email: email,
      password: password,
    };
    const user = await POST("/user", data);
    if (user.error) {
      // setErrors(user.error.message)
      if (user.data.title === "password") {
        setPwdInvalid(true);
        sendSnack(user.data.message, snackVariant);
      }
      sendSnack(user.data.message, snackVariant);
    }
    const newRole = await getRole();
    setRole(newRole);
    window.location.reload();
  };

  const handleClose = (event, reason) => {
    if (reason !== "clickaway") return setOpenSnackBar(false);
  };

  const sendSnack = (message, variant = "primary", delay = 2000) => {
    setOpenSnackBar(true);
    setSnackBarMsg(message);
    setSnackVariant(variant);
    setTimeout(handleClose, delay);
  };

  const SigninPage = (
    <div className="bg-secondary">
      <Container style={{ height: "100vh", width: "100vw" }}>
        <Row className="h-100">
          <Col lg={6} sm={12} className="my-auto">
            <img src={logo} alt="logo" className="w-75" />
          </Col>
          <Col lg={6} sm={12} className="my-auto">
            <Card>
              <Card.Header>
                <h3>{I18n.t("sign.signin", { locale: l })}</h3>
              </Card.Header>
              <Card.Body>
                <Form className="form-signin" onSubmit={e => handleSubmit(e)}>
                  <div className="form-group">
                    <Form.Group>
                      <Form.Label htmlFor="inputEmail"></Form.Label>
                      <Form.Control
                        type="email"
                        id="inputEmail"
                        aria-describedby="passwordHelpInline"
                        placeholder={I18n.t("enterEmail", { locale: l })}
                        onChange={e => handleChangeEmail(e)}
                        required
                        autoComplete="email"
                      />
                      <Form.Control.Feedback type="invalid">Please choose a username.</Form.Control.Feedback>
                    </Form.Group>
                    {
                      // {emailErrors.length > 0 && (
                      // <span className="errorMessage">{emailErrors}</span>
                      // )}
                    }
                  </div>

                  <div className="form-group">
                    <Form.Group>
                      <Form.Label htmlFor="inputPwd"></Form.Label>
                      <Form.Control
                        type="password"
                        id="inputPwd"
                        aria-describedby="passwordHelpInline"
                        placeholder={I18n.t("enterPassword", { locale: l })}
                        onChange={e => handleChangePassword(e)}
                        isInvalid={isPwdInvalid}
                        autoComplete="current-password"
                        required
                      />
                      <Form.Control.Feedback type="invalid">incorrect password</Form.Control.Feedback>
                      <p className="forgot-password">
                        <a style={{ color: "rgb(230, 126, 34)" }} href="/forgotten">
                          {I18n.t("forgotPassword", { locale: l })}
                        </a>
                      </p>
                    </Form.Group>
                  </div>

                  <div className="buttons-signin">
                    <button type="submit" className="btn btn-primary btn-block button-signin">
                      {I18n.t("connect", { locale: l })}
                    </button>
                    <a className="btn btn-primary btn-block button-signin" href="/register">
                      {I18n.t("sign.signup", { locale: l })}
                    </a>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <SimpleSnackbar message={snackBarMsg} variant={snackVariant} open={openSnackBar} handleClose={handleClose} />
    </div>
  );

  return (
    <>
      {role !== "administrator" ? (
        <Protected role={role} perform="signin-page:connection" yes={() => <Redirect to={Url.dashboard} />} no={() => SigninPage} />
      ) : (
        <Protected role={role} perform="signin-page:connection" yes={() => <Redirect to={Url.admin} />} no={() => SigninPage} />
      )}
    </>
  );
};

export default withRouter(SignIn);
