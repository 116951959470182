import { useEffect, useState } from "react";
import { Form, Card, Col, Badge, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import { getLanguage, getRoot } from "../../../../helpers/auth";
import I18n from "../../../../Config/I18n";
import SearchSelect from "../../../generic/SearchSelect";
import { getNameCategoryById, getStatusById } from "../../../../utils";
import { GiCarrot, GiMilkCarton, GiAcorn, GiGrain } from "react-icons/gi"; // GiPeanut , GiCookie
import { RiLeafFill } from "react-icons/ri";
import { getContentIds, getFitForIds, getIngredients } from "../../../../utils/recipes";
import RecipeCategoryAPI from "../../../../backend/recipeCategory";
import FolderAPI from "../../../../backend/folder";
import HelpTooltip from "../../../generic/HelpTooltip";
import { FaHome } from "react-icons/fa";
import TextEditor from "../../../TextEditor";

const l = getLanguage();

const snackStyle = {
  error: "danger",
  success: "success",
  warning: "warning",
};

/**
 *
 * @param {{
 *  recipe: import('../../../../Type/recipe').Recipe,
 *  setRecipe: Function,
 *  saveRecipe: Function,
 *  categories: [import('../../../../Type/recipeCategorie').RecipeCategories]
 *  status: [import('../../../../Type/recipeStatus').RecipeStatus]
 *  contents: [import('../../../../Type/recipeContent').RecipeContent]
 *  fitfors: [import('../../../../Type/recipeFitfor').RecipeFitFor]
 * }} param0
 */
export default function EditRecipe({
  recipe,
  setRecipe,
  saveRecipe,
  categories,
  loadCategories,
  status,
  contents,
  fitfors,
  notification,
  createData,
  setIsRecipeModified,
}) {
  const [path, setPath] = useState("");
  const [userLocation, setUserLocation] = useState("");

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    let folderId = undefined;
    if (recipe._id) {
      const folder = await FolderAPI.getFolderRecipe(recipe._id);
      folderId = folder ? folder._id : getRoot();
    } else {
      folderId = urlParams.has("folderId") ? urlParams.get("folderId") : getRoot();
    }

    const path = await FolderAPI.getPath(folderId);
    const userPath = await FolderAPI.getPath(urlParams.has("folderId") ? urlParams.get("folderId") : getRoot());
    if (userPath.path) setUserLocation("/" + decodeURI(userPath.path));
    if (path.path) setPath("/" + decodeURI(path.path));

    if (urlParams.has("ingredientId")) {
      const ingRow = await createData(urlParams.get("ingredientId"), "", 0);
      recipe.ingredients.push(ingRow);
      // Removing the 'ingredientId in the url to not add this ingredient many times.
      urlParams.delete("ingredientId");

      window.history.replaceState(
        null,
        "",
        (urlParams.has("folderId") ? "?folderId=" + urlParams.get("folderId") : "") +
          (urlParams.has("id") ? "&id=" + urlParams.get("id") : "")
      );
    }
  };

  const handleChange = event => {
    setRecipe({
      ...recipe,
      [event.target.name]: event.target.value,
    });
    if (recipe.editable) setIsRecipeModified(true);
  };

  const handleChangeCatgory = eventKey => {
    setRecipe({
      ...recipe,
      categoryId: eventKey._id,
    });
    if (recipe.editable) setIsRecipeModified(true);
  };

  const handleChangeStatus = eventKey => {
    setRecipe({
      ...recipe,
      statusId: eventKey._id,
    });
    if (recipe.editable) setIsRecipeModified(true);
  };

  const handleChangeNote = value => {
    setRecipe({
      ...recipe,
      note: value,
    });
    if (recipe.editable) setIsRecipeModified(true);
  };

  const save = async () => {
    let savingFolderId = undefined;
    // Saving in the recipe folder
    if (recipe._id) {
      const folder = await FolderAPI.getFolderRecipe(recipe._id);
      savingFolderId = folder ? folder._id : getRoot();
    } else {
      // No id, new recipe.
      const urlParams = new URLSearchParams(window.location.search);
      savingFolderId = urlParams.has("folderId") ? urlParams.get("folderId") : getRoot();
    }
    saveRecipe(savingFolderId);
  };

  const fitforIds = getFitForIds(getIngredients(recipe));
  const fitforArray = [];

  fitfors.forEach(ff => {
    if (fitforIds.includes(ff._id)) {
      fitforArray.push(true);
    } else if (recipe.ingredients.length === 0) {
      // If there is not ingredients in the recipe, the recipe fit for all.
      fitforArray.push(true);
    } else {
      fitforArray.push(false);
    }
  });

  const contentIds = getContentIds(getIngredients(recipe));
  const contentArray = [];

  contents.forEach(ff => {
    contentArray.push(contentIds.includes(ff._id));
  });

  const addCategory = async name => {
    // <IoMdAdd size='2em' />
    const resp = await RecipeCategoryAPI.add(name);
    if (resp.error) return notification(I18n.t("recipeAction.info.categoryNotSaved", { locale: l }), snackStyle.error);
    loadCategories();
    return notification(I18n.t("recipeAction.info.categorySaved", { locale: l }), snackStyle.success);
  };

  // If no fit fors -> DANGER. If fitFor -> ids, Success for these ids.
  // If content milk -> DANGER. If no content -> Success
  return (
    <Card>
      <Card.Header>
        <Card.Title className="my-auto">
          {!recipe._id ? I18n.t("recipeObject.createRecipe", { locale: l }) : I18n.t("recipeObject.editRecipe", { locale: l })}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Form className="col">
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label htmlFor="recipeName">{I18n.t("user.name", { locale: l })}</Form.Label>
              <Form.Control
                type="text"
                id="recipeName"
                name="name"
                placeholder={I18n.t("user.name", { locale: l })}
                value={recipe.name}
                onChange={handleChange}
                required
                isInvalid={!recipe.name}
                readOnly={!recipe.editable}
              />
              <Form.Control.Feedback type="invalid">{I18n.t("recipeObject.nameRequired", { locale: l })}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label htmlFor="category">{I18n.t("category", { locale: l })}</Form.Label>
              <div style={{ display: "flex" }}>
                <SearchSelect
                  elements={categories}
                  filterName={I18n.t("recipeObject.selectCategory", {
                    locale: l,
                  })}
                  handleChange={handleChangeCatgory}
                  defValue={getNameCategoryById(categories, recipe.categoryId)}
                  disabled={!recipe.editable}
                  searchAdd={addCategory}
                />
                <HelpTooltip brol="recipeObject.addCategoryHelp" placement="bottom" style={{ marginTop: "12px", marginLeft: "2px" }} />
              </div>
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label htmlFor="status">{I18n.t("recipeObject.status", { locale: l })}</Form.Label>

              <SearchSelect
                elements={status}
                filterName={I18n.t("recipeObject.selectStatus", { locale: l })}
                handleChange={handleChangeStatus}
                defValue={getStatusById(status, recipe.statusId) ? getStatusById(status, recipe.statusId).name : ""}
                disabled={!recipe.editable}
                variant={getStatusById(status, recipe.statusId) ? getStatusById(status, recipe.statusId).color : "primary"}
                searchBar={false}
              />
            </Form.Group>
          </Form.Row>
          <hr />
          <Row>
            <Col>
              {I18n.t("recipeObject.recipeFitfor", { locale: l })} :<br />
              <OverlayTrigger key={0} placement="bottom" overlay={<Tooltip>{I18n.t("fitfors.vegetalian", { locale: l })}</Tooltip>}>
                <Badge className="mx-1" variant={fitforArray[0] ? "success" : "danger"}>
                  <RiLeafFill size="2em" />
                </Badge>
              </OverlayTrigger>
              <OverlayTrigger key={1} placement="bottom" overlay={<Tooltip>{I18n.t("fitfors.vegetable", { locale: l })}</Tooltip>}>
                <Badge className="mx-1" variant={fitforArray[1] ? "success" : "danger"}>
                  <GiCarrot size="2em" />
                </Badge>
              </OverlayTrigger>
              <br />
              <br />
            </Col>
            <Col>
              {I18n.t("recipeObject.recipeContents", { locale: l })} :<br />
              {contentArray[1] ? (
                <OverlayTrigger key={2} placement="bottom" overlay={<Tooltip>{I18n.t("contents.milk", { locale: l })}</Tooltip>}>
                  <Badge className="mx-1" variant={contentArray[1] ? "danger" : ""}>
                    <GiMilkCarton size="2em" />
                  </Badge>
                </OverlayTrigger>
              ) : (
                <></>
              )}
              {contentArray[2] ? (
                <OverlayTrigger key={3} placement="bottom" overlay={<Tooltip>{I18n.t("contents.nut", { locale: l })}</Tooltip>}>
                  <Badge className="mx-1" variant={contentArray[2] ? "danger" : ""}>
                    <GiAcorn size="2em" />
                  </Badge>
                </OverlayTrigger>
              ) : (
                <></>
              )}
              {contentArray[0] ? (
                <OverlayTrigger key={4} placement="bottom" overlay={<Tooltip>{I18n.t("contents.gluten", { locale: l })}</Tooltip>}>
                  <Badge className="mx-1" variant={contentArray[0] ? "danger" : ""}>
                    <GiGrain size="2em" />
                  </Badge>
                </OverlayTrigger>
              ) : (
                <></>
              )}
              <br />
              <br />
            </Col>

            <Col>
              {I18n.t("recipeObject.author", { locale: l })} :<br />
              <Badge variant="primary">{recipe.owner}</Badge>
              <br />
              <br />
            </Col>
            <Form.Group as={Col}>
              <Form.Label htmlFor="source">{I18n.t("recipeObject.source", { locale: l })} : </Form.Label>
              <Form.Control
                type="text"
                id="source"
                name="source"
                placeholder={I18n.t("recipeObject.source", { locale: l })}
                value={recipe.source}
                onChange={handleChange}
                readOnly={!recipe.editable}
              />
            </Form.Group>

            <Col>
              {I18n.t("recipeObject.lastUpdate", { locale: l })} :<br />
              <Badge variant="primary">{moment(recipe.lastUpdateOn).format("DD MMM YYYY — H:mm:ss")}</Badge>
              <br />
              <br />
            </Col>
          </Row>
          <Form.Group>
            <TextEditor
              title={I18n.t("recipeObject.note", { locale: l })}
              defaultValue={recipe.note}
              maxLines={4}
              alertTooLong={I18n.t("recipeObject.procedureTooLong", {
                locale: l,
              })}
              handleChange={handleChangeNote}
              readOnly={!recipe.editable}
            />
          </Form.Group>
        </Form>
      </Card.Body>
      <div className="flex justify-between flex-wrap px-3 py-2.5 bg-gray-100 gap-y-4">
        <div className="flex flex-col gap-y-3">
          <div className="inline-flex items-center gap-x-1">
            {I18n.t("recipeObject.location", { locale: l })}
            <FaHome />
            {path.replace(/\//g, " / ")}
          </div>
          <div className="inline-flex items-center gap-x-1">
            {I18n.t("recipeObject.userLocation", { locale: l })}
            <FaHome />
            {userLocation.replace(/\//g, " / ")}
          </div>
        </div>
        <div>
          {/* Old save button */}
          {/* <Button onClick={save} disabled={!recipe.editable} hidden={!recipe.editable}>
            {I18n.t("save", { locale: l })}
          </Button> */}
          {/* <SaveRecipeButton submitRecipe={folderId => submitRecipe(folderId, false)} setRecipe={setRecipe} recipe={recipe} /> */}
        </div>
      </div>
    </Card>
  );
}
