import Protected from "../routes/Protected";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { getRole } from "../helpers/auth";
import RecipesPage from "./recipes";
import Ingredients from "./Ingredients";
import Ingredient from "./Ingredient";
import Account from "./Profile";
import MainPageAdmin from "./MainPageAdmin";
import { PageAdminInvoices } from "./PageAdminInvoices";
import TableCoupons from "../components/admin/coupons/TableCoupons";
import UsersAdmin from "../components/admin/UsersAdmin";
import DashboardUser from "../components/DashboardUser";
import RecipesPreview from "../components/recipes/print/RecipesPreview";
import Recipe from "./Recipe";
import Manual from "./Manual";
import ChangePaymentCard from "./ChangePaymentCard";
import ManualComposition from "./ManualComposition";
import InvoicePreview from "../components/invoices/InvoicePreview";
import { Url } from "../Config/url";
import { Drawer } from "../components/drawer";

export default function Dashboard() {
  return (
    <Router>
      <Drawer>
        <Protected
          role={getRole()}
          perform="dashboard-page-admin:route"
          yes={() => (
            <Switch>
              <Route path={Url.admin} component={MainPageAdmin} />
              <Route path={Url.users} component={UsersAdmin} />
              <Route path={Url.coupons} component={TableCoupons} />
              <Route path={Url.invoices} component={PageAdminInvoices} />
              <Route path={Url.ingredients} component={Ingredients} />
              <Route path={Url.editIngredient} component={Ingredient} />
              <Route path={Url.folder} component={RecipesPage} />
              <Route path={Url.recipe} component={Recipe} />
              <Route path={Url.recipePrint} component={RecipesPreview} />
              <Route path={Url.invoice} component={InvoicePreview} />
              <Route path={Url.profile} component={Account} />
              <Route path={Url.manual} exact component={Manual} />
              <Route path={Url.manualComposition} exact component={ManualComposition} />
              <Route path={Url.dashboard} component={DashboardUser} />
            </Switch>
          )}
          no={() => (
            <Switch>
              <Route path={Url.ingredients} component={Ingredients} />
              <Route path={Url.editIngredient} component={Ingredient} />
              <Route path={Url.folder} component={RecipesPage} />
              <Route path={Url.recipe} component={Recipe} />
              <Route path={Url.recipePrint} component={RecipesPreview} />
              <Route path={Url.invoice} component={InvoicePreview} />
              <Route path={Url.profile} component={Account} />
              <Route path={Url.manual} exact component={Manual} />
              <Route path={Url.changePaymentCard} exact component={ChangePaymentCard} />
              <Route path={Url.manualComposition} exact component={ManualComposition} />
              <Route path={Url.dashboard} component={DashboardUser} />
            </Switch>
          )}
        />
      </Drawer>
    </Router>
  );
}
