import React, { memo, useState } from "react";
import { MdControlPointDuplicate } from "react-icons/md";
import Protected from "../../routes/Protected";
import { canWrite, canRead, getRole, getLanguage, getId } from "../../helpers/auth";
import I18n from "../../Config/I18n";
import { AiFillPrinter } from "react-icons/ai";
import { GiTrashCan } from "react-icons/gi";
import { RiFolderSharedFill } from "react-icons/ri";
import { Dropdown } from "react-bootstrap";
import { getRecipePrintUrl } from "../../Config/url";
import FileExplorer from "../folders/FileExplorer";
import FolderAPI from "../../backend/folder";
import { useHistory } from "react-router-dom";

const l = getLanguage();

function CardMoreButton({ recipe, duplicateRecipe, deleteRecipe, notAuthorize, folderId }) {
  const [openFileExplorer, setOpenFileExplorer] = useState(false);

  const history = useHistory();

  const redirectToPrint = () => {
    history.push(getRecipePrintUrl("/" + recipe._id));
    window.location.reload();
  };

  const moveRecipe = async newFolderId => {
    const folder = await FolderAPI.moveRecipe(recipe._id, newFolderId);
    if (!folder) return;
    setOpenFileExplorer(false);
    window.location.reload(true);
  };

  const fileExplorer = () => {
    setOpenFileExplorer(true);
  };

  const isEditable = recipe.ownerId === getId() && canWrite();
  return (
    <Dropdown drop="down">
      <Dropdown.Toggle split variant="outline-primary" />
      <Dropdown.Menu>
        <Protected
          role={getRole()}
          perform="recipe:print"
          data={{
            canWrite: canWrite(),
            canRead: canRead(),
          }}
          yes={() => (
            <Dropdown.Item className="flex items-center gap-x-3" onClick={redirectToPrint}>
              <AiFillPrinter size="1.5em" />
              {I18n.t("recipeAction.print", { locale: l })}
            </Dropdown.Item>
          )}
          no={() => (
            <Dropdown.Item className="flex items-center gap-x-3" onClick={notAuthorize}>
              <AiFillPrinter size="1.5em" />
              {I18n.t("recipeAction.print", { locale: l })}
            </Dropdown.Item>
          )}
        />
        <Protected
          role={getRole()}
          perform="recipe:copy"
          data={{
            canWrite: canWrite(),
            canRead: canRead(),
          }}
          yes={() => (
            <Dropdown.Item className="flex items-center gap-x-3" onClick={duplicateRecipe}>
              <MdControlPointDuplicate size="1.5em" />
              {I18n.t("recipeAction.copy", { locale: l })}
            </Dropdown.Item>
          )}
          no={() => (
            <Dropdown.Item className="flex items-center gap-x-3" onClick={notAuthorize}>
              <MdControlPointDuplicate size="1.5em" />
              {I18n.t("recipeAction.copy", { locale: l })}
            </Dropdown.Item>
          )}
        />

        {isEditable ? (
          <>
            <Dropdown.Item className="flex items-center gap-x-3" onClick={fileExplorer}>
              <RiFolderSharedFill size="1.5em" />
              {I18n.t("recipeAction.move", { locale: l })}
            </Dropdown.Item>
            <Dropdown.Item className="flex items-center gap-x-3" onClick={deleteRecipe}>
              <GiTrashCan size="1.5em" />
              {I18n.t("recipeAction.delete", { locale: l })}
            </Dropdown.Item>
          </>
        ) : null}
      </Dropdown.Menu>
      {openFileExplorer ? (
        <FileExplorer open={fileExplorer} close={() => setOpenFileExplorer(false)} folderId={folderId} selectIdFunction={moveRecipe} />
      ) : null}
    </Dropdown>
  );
}

export default memo(CardMoreButton);
