import { GET } from "../helpers/requests";

/**
 *
 * @param {String = 'en'} lang
 */
const getCountries = async (lang = "origin") => {
  const resp = await GET(`/data/countries/${lang}`);
  return resp;
};

/**
 *
 * @param {String} VAT
 * @return {{
 * countryCode: String,
 *  vatNumber: String,
 *  requestDate: Date,
 *  valid: Boolean,
 *  name: String,
 *  address: String,
 *  eu: Boolean
 * }}
 */
const checkVAT = async (VAT = "") => {
  const resp = await GET(`/data/vat/${VAT}`);

  return resp.data;
};

const DataAPI = {
  getCountries,
  checkVAT,
};

export default DataAPI;
