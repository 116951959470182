import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import SimpleSnackbar from "../../../components/SimpleSnackbar";
import paginationFactory from "react-bootstrap-table2-paginator";
import { GET, POST } from "../../../helpers/requests";
import { Button, Form, FormControl, InputGroup, Modal } from "react-bootstrap";
import I18n from "../../../Config/I18n";
import { getLanguage } from "../../../helpers/auth";
import ToolbarCoupons from "../../ToolbarCoupons";
import { GiTrashCan } from "react-icons/gi";

const variants = {
  info: "info",
  error: "danger",
  success: "success",
};

const l = getLanguage();
export default function TableCoupons() {
  const [snack, setSnack] = useState({
    open: false,
    message: "",
    variant: "info",
  });
  /**
   * @type {[[Coupon],Function]}
   */
  const [data, setData] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const loadData = async () => {
    GET("/coupon").then(response => {
      if (response.error) {
        return sendSnack("Error", variants.error);
      }
      setData(response.data);
    });
  };

  // Submit data. Call the api. Fetch data and get responses
  const addCoupon = newData => {
//    POST("/coupon/add/", newData).then(response => {
      POST("/coupon/add/", newData);
      // if (response.error) {
      //   return sendSnack(response.error.message, variants.error);
      // }
    //  sendSnack("Coupon added", variants.success);
   // });
  };

  /**
   *
   * @param {Coupon} coupon
   */
  const checkCoupon = coupon => {
    if (coupon.date_expiration < coupon.date_begin) {
      sendSnack("Invalid dates", variants.error);
      return false;
    }
    if (data.find(c => c.name === coupon.name && c._id !== coupon._id)) {
      // name already exist
      sendSnack("Name already exists", variants.error);
      return false;
    }
    return true;
  };

  const updateCoupon = newData => {
    if (!checkCoupon(newData)) return;
    const updatedCoupon = {
      _id: newData._id,
      name: newData.name,
      discount: newData.discount,
      available: newData.available,
      enable: newData.enable,
      date_begin: newData.date_begin,
      date_expiration: newData.date_expiration,
    };
    POST("/coupon/", updatedCoupon).then(response => {
      if (response.error) {
        return sendSnack(response.error.message, variants.error);
      }
      sendSnack("Coupon saved");
    });
  };

  // close snackbar
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnack({
      open: false,
      message: "",
      variant: "info",
    });
  };

  const sendSnack = (message = "", variant = variants.info, delay = 2000) => {

    let errorMessage = '';
    if (typeof message === 'string') {
      errorMessage = message;
    } else if (typeof message === 'object' && message instanceof Error) {
      errorMessage = message.message;
    }

    
    const newSnack = {
      open: true,
      message: errorMessage,
      variant,
    };
    setSnack(newSnack);
    setTimeout(handleClose, delay);
  };

  const updateCouponAvailable = row => {
    row.enable = !row.enable;
    updateCoupon(row);
  };
  const deleteCoupon = cell => {
    /* Promise((resolve) => {
      setTimeout(() => {
        resolve()
        setData((prevState) => {
          const data = [...prevState.data]
          data.splice(data.indexOf(cell), 1)
          return { ...prevState, data }
        })
      }, 600)
    }) // C'est de la merde */

    POST(`/coupon/delete/${cell._id}`, {}).then(response => {
      if (response.error) {
        return sendSnack(response.error.message, variants.error);
      }
      sendSnack("Coupon deleted", variants.info);
      const coupons = data.filter(coupon => coupon._id !== cell._id);
      setData(coupons);
    });
  };

  const columns = [
    {
      text: I18n.t("coupon._id", l),
      dataField: "_id",
      hidden: true,
      editable: false,
    },
    {
      text: I18n.t("coupon.name", l),
      dataField: "name",
      editor: {
        type: Type.TEXT,
      },
    },
    {
      text: I18n.t("coupon.discount", l),
      dataField: "discount",
    },
    {
      text: I18n.t("coupon.quantity", l),
      dataField: "available",
    },
    {
      text: I18n.t("coupon.used", l),
      dataField: "users.length",
      editable: false,
    },
    {
      text: I18n.t("coupon.issueDate", l),
      dataField: "date_begin",
      formatter: cell => {
        let dateObj = cell;
        if (typeof cell !== "object") {
          dateObj = new Date(cell);
        }
        return `${("0" + dateObj.getUTCDate()).slice(-2)}/${("0" + (dateObj.getUTCMonth() + 1)).slice(-2)}/${dateObj.getUTCFullYear()}`;
      },
      editor: {
        type: Type.DATE,
      },
    },
    {
      text: I18n.t("coupon.endDate", l),
      dataField: "date_expiration",
      formatter: cell => {
        let dateObj = cell;
        if (typeof cell !== "object") {
          dateObj = new Date(cell);
        }
        return `${("0" + dateObj.getUTCDate()).slice(-2)}/${("0" + (dateObj.getUTCMonth() + 1)).slice(-2)}/${dateObj.getUTCFullYear()}`;
      },
      editor: {
        type: Type.DATE,
      },
    },
    {
      text: I18n.t("coupon.active", l),
      dataField: "enable",
      formatter: (cellContent, row) => (
        <div>
          <label>
            <input type="checkbox" checked={row.enable} onChange={() => updateCouponAvailable(row)} />
          </label>
        </div>
      ),
      editable: false,
    },
    {
      text: I18n.t("coupon.delete", l),
      dataField: "coupondelete",
      formatter: (cell, row) => {
        return (
          <Button size="sm" variant="outline-danger" onClick={cell => deleteCoupon(row)}>
            <GiTrashCan size="1.5em" />
          </Button>
        );
      },
      isDummyField: true,
      editable: false,
    },
  ];

  const submitAddCoupon = event => {
    const newData = event.currentTarget;
    const updatedCoupon = {
      name: newData.couponName.value,
      discount: newData.couponDiscount.value,
      available: newData.couponQuantity.value,
      enable: true,
      date_begin: newData.couponIssueDate.value,
      date_expiration: newData.couponEndDate.value,
    };
    if (!checkCoupon(updatedCoupon)) return sendSnack("invalid coupon", variants.error);
    addCoupon(updatedCoupon);
  };

  const addCouponForm = (
    <Modal show={showAddModal} onHide={() => setShowAddModal(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t("coupon.addCoupon", l)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={submitAddCoupon}>
          <InputGroup className="mb-2">
            <InputGroup.Prepend>
              <InputGroup.Text>{I18n.t("coupon.name")}</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl id="couponName" placeholder={I18n.t("coupon.name")} />
          </InputGroup>
          <InputGroup className="mb-2">
            <InputGroup.Prepend>
              <InputGroup.Text>{I18n.t("coupon.discount")}</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl id="couponDiscount" placeholder={I18n.t("coupon.discount")} />
          </InputGroup>
          <InputGroup className="mb-2">
            <InputGroup.Prepend>
              <InputGroup.Text>{I18n.t("coupon.issueDate")}</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl type="date" id="couponIssueDate" placeholder={I18n.t("coupon.issueDate")} />
          </InputGroup>
          <InputGroup className="mb-2">
            <InputGroup.Prepend>
              <InputGroup.Text>{I18n.t("coupon.endDate")}</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl type="date" id="couponEndDate" placeholder={I18n.t("coupon.endDate")} />
          </InputGroup>
          <InputGroup className="mb-2">
            <InputGroup.Prepend>
              <InputGroup.Text>{I18n.t("coupon.quantity")}</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl id="couponQuantity" placeholder={I18n.t("coupon.quantity")} />
          </InputGroup>
          <div className="text-right">
            <Button type="submit" className="button-submit">
              {I18n.t("save", l)}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );

  const noData = <h5 className="m-3">No data</h5>;

  return (
    <div>
      {addCouponForm}
      <div className="header">
        <ToolbarCoupons setShowAddCoupon={setShowAddModal} title={I18n.t("coupon.coupons", l)} />
      </div>
      <div className="mt-3">
        <BootstrapTable
          responsive
          keyField="_id"
          striped
          hover
          bordered
          className="table-primary"
          data={data}
          columns={columns}
          cellEdit={cellEditFactory({
            mode: "click",
            blurToSave: true,
            afterSaveCell: (oldValue, newValue, row, column) => {
              updateCoupon(row);
            },
          })}
          noDataIndication={noData}
          insertRow
          //caption='Hello this is table, hi.'
          pagination={paginationFactory()}
        />
      </div>
      <SimpleSnackbar open={snack.open} handleClose={handleClose} message={snack.message} variant={snack.variant} />
    </div>
  );
}
// Anciennes fonctions :
/* <MaterialTable
        title='Coupons'
        columns={state.columns}
        data={state.data}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                resolve()
                addCoupon(newData)
                setState((prevState) => {
                  const data = [...prevState.data]
                  data.push(newData)
                  return { ...prevState, data }
                })
              }, 600)
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                resolve()
                if (oldData) {
                  updateCoupon(newData)
                  setState((prevState) => {
                    const data = [...prevState.data]
                    data[data.indexOf(oldData)] = newData
                    return { ...prevState, data }
                  })
                }
              }, 600)
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                resolve()
                setState((prevState) => {
                  const data = [...prevState.data]
                  data.splice(data.indexOf(oldData), 1)
                  return { ...prevState, data }
                })
              }, 600)
            })
        }}
      /> */

// { name: 'COUPON-134', discount: '15', available: 3, date_begin: new Date(Date.now()).toString(),  date_expiration: new Date(Date.now()).toString(), enable : true },
// { name: 'COUPON-135', discount: '10', available: 0, date_begin: new Date(Date.now()).toString(),  date_expiration: new Date(Date.now()).toString(), enable : true }
