import { GET, POST, POSTFile } from "../helpers/requests";

/**
 *
 * @return {{
 * data: import('../Type/user').UserData,
 * error: {}
 * }}
 */
const getInfo = async () => {
  const resp = await GET("/user/info");
  return resp;
};

/**
 * @return {[import('../Type/user').UserData]}
 */
const getUsers = async () => {
  const resp = await GET("/user/");
  return resp.data;
};

/**
 *
 * @param {{
 * email?: String,
 * password?:String,
 * name?:String,
 * lastname?:String,
 * phone?:String,
 * language?: String,
 * userTypeId?: String,
 * company?:{
 *  _id?: String
 *  name?: String
 *  VAT?: String
 * },
 * address?:{
 *  street: String,
 *  city: String,
 *  zipCode: String,
 *  country: String
 * },
 *  image?: File
 * }} data
 * @param {String=} id
 * @return {{
 *  data: import('../Type/user').UserInfo,
 *  error: {
 *    message:String,
 *    invalidEmail: Boolean,
 *    notFound: Boolean
 *    imageToBig:Boolean,
 *    imageExtension:Boolean
 * }
 * }}
 */
const update = async (data, id) => {
  if (!id) id = "";
  delete data.customerNumber;
  const resp = await POST(`/user/update/${id}`, data);
  if (resp.error && resp?.data?.title) {
    switch (resp.data.title) {
      case "email":
        resp.error.invalidEmail = true;
        break;
      case "Not found":
        resp.error.notFound = true;
        break;
      case "no_upgrade":
        resp.error.invalidNoUpgrade = true;
        break;
      case "demo_upgraded":
        resp.error.invalidDemoUpgraded = true;
        break;
      default:
        console.error(`Unexpected value : ${resp.data.title}`);
    }
  }
  return resp;
};

const updatePicture = async (data, id) => {
  if (!id) id = "";
  if (data.image) {
    const validation = validImage(data.image);
    if (Object.keys(validation).find(key => validation[key])) {
      return {
        error: { ...validation, message: "wrong image" },
      };
    }
  }
  const resp = await POSTFile(`/user/update/${id}`, data);
  if (resp.error) {
    switch (resp.data.title) {
      case "email":
        resp.error.invalidEmail = true;
        break;
      case "Not found":
        resp.error.notFound = true;
        break;
      default:
        console.error(`Unexpected value : ${resp.data.title}`);
    }
    switch (resp.data.code) {
      case "LIMIT_FILE_SIZE":
        resp.error.imageToBig = true;
        break;
      default:
        console.error(`Unexpected value : ${resp.data.code}`);
    }
  }
  return resp;
};

/**
 *
 * @param {File} image
 */
const validImage = image => {
  //const extensionsAllowed = ['tif', 'tiff', 'jpg', 'jpeg', 'gif', 'png', 'svg']
  const extensionsAllowed = ["jpg", "jpeg", "png"];
  const array = image.name.split(".");
  const extension = array[array.length - 1];
  return {
    imageToBig: image.size > 3 * 1024 * 1024,
    imageExtension: !extensionsAllowed.includes(extension),
  };
};

const logout = async () => {
  const resp = await POST("/user/logout", undefined);
  return resp;
};

const getUserType = async () => {
  const resp = await GET("/usertype/");
  return resp.data;
};

const userAPI = { getUsers, getInfo, update, updatePicture, logout, getUserType };

export default userAPI;
